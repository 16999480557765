<template>
  <div :id="id">
    <b-input-group
      :id="`${id}-input-group`"
      class="search d-flex"
      :class="{'extra-margin': !noMargin, 'disabled': disabled}"
    >
      <span class="px-2 tci-magnifying-glass" />
      <b-form-input
        v-if="lazyEmit"
        v-model.lazy="searchTerm"
        type="text"
        class="px-0 mr-5 search-bar-input"
        :disabled="disabled"
        :placeholder="placeholder"
        @change="searchBarCriteriaEvent()"
        @blur="$emit('blur')"
      />
      <b-form-input
        v-else
        v-model="searchTerm"
        type="text"
        class="px-0 mr-5 search-bar-input"
        :disabled="disabled"
        :placeholder="placeholder"
        @keyup.native="searchBarCriteriaEvent()"
        @blur="$emit('blur')"
      />
      <span
        v-if="searchTerm !== ''"
        class="mr-4 delete-icon tci-cross"
        @click="searchBarRemoveCriteriaEvent()"
      />
    </b-input-group>
  </div>
</template>

<script>

export default {
  name: 'SearchBar',
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    lazyEmit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      searchTerm: ''
    }
  },
  methods: {
    searchBarCriteriaEvent () {
      this.$emit('search-bar-criteria', { searchTerm: this.searchTerm })
    },
    searchBarRemoveCriteriaEvent () {
      this.searchTerm = ''
      this.$emit('search-bar-criteria', { searchTerm: this.searchTerm })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/variables";

  .search {
    background-color: var(--t-color-surface);
    border: solid var(--t-border-size-1) var(--t-color-border);
    align-items: center;
    justify-content: center;
    font-size: 24px;

    &.extra-margin {
      margin-bottom: 24px;
    }

    .delete-icon {
      cursor: pointer;
    }

    .search-bar-input{
      font-size: 14px;
      border: 0;
    }
  }

  .search.disabled, .disabled > .search-bar-input {
    background-color: $input-disabled-bg;
    cursor: not-allowed;
  }

  .form-control {
    height: 36px;
  }
</style>
