<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
    data-test="observer"
  >
    <tc-dialog
      id="credit-card-dialog"
      :value="showDialog"
      :title="getDialogTitle"
      :cancel-button-text="$t('portalfrontendApp.coreCreditCard.dialog.cancel')"
      :confirm-button-text="$t('portalfrontendApp.coreCreditCard.dialog.saveCreditCard')"
      :is-confirm-button-disabled="invalid"
      :is-loading="addingCard"
      :show-loading-spinner="isLoading"
      :account-currency="currentGroupAccount.currencyCode"
      data-test="tcDialog"
      @cancel="closeDialog"
      @confirm="handleSubmit"
    >
      <div class="form-fields">
        <v-btn
          v-if="showRemoveCcButton && !isLoading"
          class="btn-text mb-4"
          data-testid="remove-cc-button"
          @click="handleRemoveCcAccountClicked"
        >
          <v-icon class="m-0 pr-2">
            mdi-trash-can-outline
          </v-icon>
          {{ $t('portalfrontendApp.coreCreditCard.dialog.removeCreditCard') }}
        </v-btn>

        <credit-card-details
          v-if="!isLoading"
          :cc-details="creditCard"
          @credit-card-details="updateCreditCardDetails"
        />
        <credit-card-billing-address
          v-if="!isLoading"
          :cc-billing-address="billingAddress"
          @billing-address="updateBillingAddress"
        />
      </div>
      <v-expansion-panels
        accordion
        flat
      >
        <!-- Section for receipts -->
        <v-expansion-panel v-if="!isLoading">
          <v-expansion-panel-header
            class="py-0"
            hide-actions
          >
            <template #default="{ open }">
              <div>
                <v-icon>
                  {{ open ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                </v-icon>
                <span>
                  {{ $t('portalfrontendApp.coreCreditCard.dialog.receiptsInfo.receipts') }}
                  ({{ emailAddressCount }})
                </span>
              </div>
            </template>
          </v-expansion-panel-header>
          <credit-card-receipts
            :primary-email-address="primaryEmailAddress"
            :email-addresses="getEmailAddressesString"
            @email-addresses="updateEmailAddresses"
          />
        </v-expansion-panel>
      </v-expansion-panels>
    </tc-dialog>
  </validation-observer>
</template>

<script>
import TcDialog from '@/components/shared/tc-dialog/TcDialog.vue'
import CreditCardDetails from '@/components/platform/funding/credit-card/dialog/CreditCardDetails'
import CreditCardBillingAddress from '@/components/platform/funding/credit-card/dialog/CreditCardBillingAddress'
import CreditCardReceipts from '@/components/platform/funding/credit-card/dialog/CreditCardReceipts'
import { ValidationObserver } from 'vee-validate'
import GeographyService from '@/components/shared/GeographyService'
import { mapGetters } from 'vuex'
import { GET_SELECTED_CUSTOMER_ACCOUNT, PRINCIPLE_GET_USER } from '@/store/get-types'
import moment from 'moment'
import * as FundingConstants from '@/components/platform/funding/FundingConstants'
import { capitalize } from '@/components/shared/utils/StringUtils'
import { ERROR } from '@/components/shared/alert/snack-constants'
import { PaymentSourceType } from '@/components/platform/funding/FundingConstants'
import { uniq } from 'lodash'

const NUM_PRIMARY_EMAIL_ADDRESSES = 1

export default {
  name: 'AddEditCreditCardDialog',
  components: {
    'credit-card-details': CreditCardDetails,
    'credit-card-billing-address': CreditCardBillingAddress,
    'credit-card-receipts': CreditCardReceipts,
    TcDialog,
    ValidationObserver
  },
  props: {
    isNewCard: {
      type: Boolean,
      required: true
    },
    submitted: {
      type: Boolean,
      required: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    ccToken: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      addingCard: false,
      creditCard: {},
      billingAddress: {},
      emailAddresses: [],
      primaryEmailAddress: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: PRINCIPLE_GET_USER,
      currentGroupAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    customerIdentifier () {
      return this.currentGroupAccount.customerIdentifier
    },
    accountIdentifier () {
      return this.currentGroupAccount.accountIdentifier
    },
    emailAddressCount () {
      if (!this.emailAddresses.length) {
        return NUM_PRIMARY_EMAIL_ADDRESSES
      }
      return this.emailAddresses.length + NUM_PRIMARY_EMAIL_ADDRESSES
    },
    showRemoveCcButton () {
      return this.ccToken
    },
    getDialogTitle () {
      return this.ccToken
        ? this.$t('portalfrontendApp.coreCreditCard.dialog.editCreditCard')
        : this.$t('portalfrontendApp.coreCreditCard.dialog.addCreditCard')
    },
    getEmailAddressesString () {
      return this.emailAddresses.join(', ')
    }
  },
  async mounted () {
    if (this.ccToken) {
      await this.loadCreditCard()
    } else {
      this.primaryEmailAddress = this.currentUser.username
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog')
    },
    async loadCreditCard () {
      try {
        this.isLoading = true
        const response = await this.$http.get(
          `api/creditCards/alldetails/${this.ccToken}?accountId=${this.accountIdentifier}`)
        const cardDetails = this.creditCardFromEditCriteria(response.data)
        this.creditCard = {
          creditCardNumber: cardDetails?.lastFour,
          nameOnCard: `${cardDetails?.firstName} ${cardDetails?.lastName}`,
          expirationDate: `${cardDetails?.expirationMonth} / ${cardDetails?.expirationYear}`,
          cvc: cardDetails?.cvc || null,
          nickname: cardDetails?.cardNickname
        }
        this.billingAddress = {
          country: cardDetails?.country,
          stateProvince: cardDetails?.state,
          addressLine1: cardDetails?.addressOne,
          addressLine2: cardDetails?.addressTwo,
          city: cardDetails?.city,
          postalCode: cardDetails?.postalCode
        }
        this.emailAddresses = cardDetails?.contactInformation
        this.primaryEmailAddress = this.setPrimaryEmailAddress(cardDetails)
      } catch (error) {
        console.error(error)
        this.$snack(this.$t('portalfrontendApp.coreCreditCard.edit.errorLoadingCard'), false, ERROR, -1)
      } finally {
        this.isLoading = false
      }
    },
    removeEmailAddress (email) {
      const index = this.emailAddresses.indexOf(email)
      if (index !== -1) {
        return this.emailAddresses.splice(index, 1)
      }
    },
    setPrimaryEmailAddress (criteria) {
      const contact = criteria.contactInformation.find(contact => contact.isPrimary)
      if (contact) {
        // remove primary email address from the emailAddresses since it has a dedicated input
        this.removeEmailAddress(contact)
      }
      this.emailAddresses = this.emailAddresses.map((contact) => {
        return contact.emailAddress
      })
      return !contact ? '' : contact.emailAddress
    },
    handleRemoveCcAccountClicked () {
      const ccLabel = `${this.creditCard.nickname} ${this.creditCard.creditCardNumber}`
      const eventPayload = {
        paymentSourceType: PaymentSourceType.CREDIT_CARD,
        label: ccLabel,
        token: this.ccToken
      }
      this.$emit('confirm-removal', eventPayload)
    },
    getFullCreditCardNickname () {
      const { creditCardType, nickname } = this.creditCard
      return nickname || (FundingConstants.CreditCardName[creditCardType] ?? capitalize(creditCardType ?? FundingConstants.PaymentSourceType.CREDIT_CARD))
    },
    updateCreditCardDetails (creditCard) {
      this.creditCard = creditCard
    },
    updateBillingAddress (billingAddress) {
      this.billingAddress = billingAddress
    },
    updateEmailAddresses (emailAddresses) {
      this.emailAddresses = uniq(emailAddresses.replace(/\s/g, '').split(',').filter(e => e))
    },
    getTwoDigitYear (year) {
      return year.length === 4 ? year.substr(2) : year
    },
    creditCardFromEditCriteria (criteria) {
      const state = (criteria?.state.length === 2)
        ? GeographyService.getState(criteria?.state, criteria?.country)
        : criteria?.state
      return {
        number: `****${criteria?.lastFour}`,
        lastFour: `****${criteria?.lastFour}`,
        token: this.ccToken,
        expirationMonth: criteria?.expirationMonth,
        expirationYear: this.getTwoDigitYear(criteria?.expirationYear),
        cardNickname: criteria?.cardNickname,
        firstName: criteria?.firstName,
        lastName: criteria?.lastName,
        addressOne: criteria?.addressOne,
        addressTwo: criteria?.addressTwo,
        city: criteria?.city,
        state,
        country: GeographyService.getCountry(criteria?.country),
        postalCode: criteria?.postalCode,
        contactInformation: criteria?.contactInformation,
        emailAddress: this.primaryEmailAddress
      }
    },
    handleSubmit () {
      this.addingCard = true
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$snack(this.$t('portalfrontendApp.coreCreditCard.dialog.addCardPending'), true)

          if (this.ccToken) {
            // filter out primary email address from additional emails
            this.removeEmailAddress(this.primaryEmailAddress)

            this.$emit('update:edited', true)
            this.$emit('edited', {
              label: this.getFullCreditCardNickname(),
              customerIdentifier: this.customerIdentifier,
              accountIdentifier: this.accountIdentifier,
              creditCard: {
                number: this.creditCard.creditCardNumber.replace(/\D/g, ''),
                verificationNumber: this.creditCard.cvc,
                expiration: moment(this.creditCard.expirationDate, 'MM / YY').format('YYYY-MM'),
                cardNickname: this.creditCard.nickname
              },
              billingAddress: {
                firstName: this.creditCard.nameOnCard.split(' ')[0],
                lastName: this.creditCard.nameOnCard.split(' ').pop(),
                addressLine1: this.billingAddress.addressLine1,
                addressLine2: this.billingAddress.addressLine2,
                city: this.billingAddress.city,
                state: GeographyService.getStateCode(this.billingAddress.stateProvince, GeographyService.getCountryCode(this.billingAddress.country)),
                country: this.billingAddress.country,
                postalCode: this.billingAddress.postalCode
              },
              contactInformation: this.emailAddresses.map((email) => {
                return {
                  emailAddress: email,
                  isPrimary: false,
                  originalEmail: email
                }
              })
            })
          } else {
            this.$emit('update:submitted', true)
            this.$emit('submitted', {
              label: this.getFullCreditCardNickname(),
              customerIdentifier: this.customerIdentifier,
              accountIdentifier: this.accountIdentifier,
              creditCard: {
                number: this.creditCard.creditCardNumber.replace(/\D/g, ''),
                verificationNumber: this.creditCard.cvc,
                expiration: moment(this.creditCard.expirationDate, 'MM / YY').format('YYYY-MM')
              },
              billingAddress: {
                firstName: this.creditCard.nameOnCard.split(' ')[0],
                lastName: this.creditCard.nameOnCard.split(' ').pop(),
                addressLine1: this.billingAddress.addressLine1,
                addressLine2: this.billingAddress.addressLine2,
                city: this.billingAddress.city,
                state: GeographyService.getStateCode(this.billingAddress.stateProvince, GeographyService.getCountryCode(this.billingAddress.country)),
                country: this.billingAddress.country,
                postalCode: this.billingAddress.postalCode,
                emailAddress: this.primaryEmailAddress
              },
              contactInformation: uniq(this.emailAddresses).map((email) => {
                return { emailAddress: email, isPrimary: false }
              })
            })
          }
        } else {
          this.$toast(this.$t('portalfrontendApp.coreCreditCard.dialog.cardError'), 'warning')
          this.$emit('update:submitted', false)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.form-fields *:last-child {
  margin-block-start: 1.5rem;
}
.v-expansion-panel-header {
 font-weight: 500;
 padding-left: 0;
 margin-left: -4px;
}
#add-card-nickname {
 font-weight: 500;
}
</style>
