// These categories (and CXXXX IDs) are defined by OneTrust
export class CookieCategories {
  // necessary for the website to function and cannot be switched off in our systems
  static NECESSARY = 'C0001'
  // allow us to count visits and traffic sources so we can measure and improve the performance of our site
  static PERFORMANCE = 'C0002'
  // enable the website to provide enhanced functionality and personalisation
  static FUNCTIONAL = 'C0003'
  // may be set through our site by our advertising partners. They may be used by those companies to build a profile of
  // your interests and show you relevant adverts on other sites
  static TARGETING = 'C0004'
  // set by a range of social media services that we have added to the site to enable you to share our content with your
  // friends and networks
  static SOCIAL_MEDIA = 'C0005'
}

export const SCRIPT_DOMAIN_ID_ATTRIBUTE = 'data-domain-script'
export const SCRIPT_ID = 'one-trust-script'
export const SCRIPT_SRC = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
