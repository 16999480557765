import { TemplateMarkupCustomization } from '@/dto/order/TemplateMarkupCustomization'

export class EmailTemplateMarkupCustomization extends TemplateMarkupCustomization {
  _senderName
  _subject

  constructor (args) {
    const { senderName = '', subject = '', ...superProps } = args || {}
    super(superProps)
    this._senderName = senderName
    this._subject = subject
  }

  get senderName () {
    return this._senderName
  }

  set senderName (senderName) {
    this._senderName = senderName
  }

  get subject () {
    return this._subject
  }

  set subject (subject) {
    this._subject = subject
  }

  equals (otherEmailTemplateOrderCustomization = {}) {
    return this.senderName === otherEmailTemplateOrderCustomization.senderName &&
      this.subject === otherEmailTemplateOrderCustomization.subject &&
      this.message === otherEmailTemplateOrderCustomization.message
  }

  toJSON () {
    const { senderName, subject, message } = this
    return { senderName, subject, message }
  }
}
