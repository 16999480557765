import { COMMON_MODAL_SHOW, COMMON_MODAL_DISMISS } from './modal-events'

class Modal {
  _eventBus

  constructor (vue) {
    this._eventBus = vue.prototype.$bus
  }

  /**
   * Spawns a generic Alert style modal with a single button and a custom
   * message and title.  The onClose callback is optional.
   * @param title The title of the modal
   * @param message the text content of the modal (set via a v-text property)
   * @param onClose callback called regardless of how the dialog is closed. A return value of false will prevent the dialog from closing.
   */
  alert (title, message, onClose) {
    this.show({
      message,
      title,
      okOnly: true,
      okTitle: 'Ok',
      hideCallback: onClose
    })
  }

  /**
   * Spawns a generic Confirmation/Prompt style modal with a cancel button, an action button (with custom text),
   * custom message and custom title. The callbacks (onOk, onCancel and onClose) are Optional. onOk/onCancel are called
   * only when the related button is pressed. onClose is called on any button is pressed after the button specific callback.
   * onClose is also called on an close mechanism (clicking the background, close button or issuing a this.$modal.dismiss() call.
   * @param title The title of the modal
   * @param message the text content of the modal (set via a v-text property)
   * @param buttonTitle the textual content of the action button (set via v-text)
   * @param onOk callback called when the action button is clicked. A return value of false will prevent the dialog from closing.
   * @param onCancel callback called when the cancel button is clicked. A return value of false will prevent the dialog from closing.
   * @param onClose callback called regardless of how the dialog is closed. A return value of false will prevent the dialog from closing.
   */
  prompt (title, message, buttonTitle, onOk, onCancel, onClose) {
    this.show({
      message,
      title,
      okOnly: false,
      cancelTitle: 'Cancel',
      okTitle: buttonTitle,
      okCallback: onOk,
      cancelCallback: onCancel,
      hideCallback: onClose
    })
  }

  /**
   * Dismisses the common modal
   */
  dismiss () {
    this._eventBus.$emit(COMMON_MODAL_DISMISS)
  }

  /**
   * Spawns an instance of the common modal. Any options not specified or of an invalid type in the opts parameter will
   * have the following default values:
   *       message: '',
   *       okOnly: false,
   *       okTitle: 'Ok',
   *       okCallback: undefined,
   *       okDisabled: false,
   *       cancelTitle: 'Cancel',
   *       cancelCallback: undefined,
   *       cancelDisabled: false,
   *       title: 'Generic Popup',
   *       centered: true,
   *       busy: false,
   *       lazy: false,
   *       hideCallback: undefined
   * @param opts a JSON object that contains all of the optional parameters used to configure the modal
   */
  show (opts) {
    this._eventBus.$emit(COMMON_MODAL_SHOW, opts)
  }
}
function ModalPlugin (Vue) {
  Vue.prototype.$modal = new Modal(Vue)
}

export {
  ModalPlugin
}
