/* eslint-disable no-undef */

import store from '../../../store'
import uuidv4 from 'uuid/v4'
import { isProduction } from '@/components/shared/environment'
import { PRINCIPLE_GET_USER } from '@/store/get-types'

function jsKey () {
  return isProduction() ? '995eb00c52' : 'cbfab77b8c'
}

function ls () {
  const e = document.createElement('script')
  e.src = 'https://cdn.siftscience.com/s.js'
  document.body.appendChild(e)
}

class SiftModule {
  // Set up Sift Science JS API
  constructor (Vue, router) {
    // Only run this if we are not emulating
    if (!Vue.prototype.$isEmulationSession()) {
      // Set to the user's ID Hash or '' if not yet known.
      // Note: This depends on the entire app being wrapped in a call to PRINCIPLE_GET_ASYNC
      const user = store.getters[PRINCIPLE_GET_USER]
      const userId = user ? user.identityHash : ''

      // Set to a unique session ID for the visitor's current browsing session.
      let sessionId = Vue.prototype.$getSessionId()

      // If this has not been created yet, generate one and set the cookie
      if (!sessionId) {
        sessionId = uuidv4()
        Vue.prototype.$setSessionId(sessionId)
      }

      const sift = window._sift = window._sift || []
      sift.push(['_setAccount', jsKey()])
      sift.push(['_setUserId', userId])
      sift.push(['_setSessionId', sessionId])
      sift.push(['_trackPageview'])
      if (window.attachEvent) {
        window.attachEvent('onload', ls)
      } else {
        window.addEventListener('load', ls, false)
      }

      // Init router
      router.afterEach((to, from) => {
        if (to.name !== 'Login' && from.name !== to.name) {
          sift.push(['_trackPageview'])
        }
      })
    }
  }
}

function SiftPlugin (Vue, router) {
  Vue.prototype.$sift = new SiftModule(Vue, router)
}

export default SiftPlugin
