import * as mutations from '../store/mutation-types'
import store from '../store'
import i18n from '../i18n'

export default class {
  static use (router) {
    router.beforeEach((to, from, next) => {
      const title = i18n.t(to.meta.pageTitle)
      if (!title) {
        console.error('Page title for route could not be translated', to)
        throw new Error('Page title for route could not be translated')
      }
      store.commit(mutations.APP_SET_TITLE, title)
      next()
    })
  }
}
