<template>
  <b-modal
    id="common-modal-dialog"
    :centered="centered"
    :visible="isActive"
    :title="title"
    :ok-disabled="okDisabled"
    :cancel-disabled="cancelDisabled"
    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    :busy="busy"
    :lazy="lazy"
    :no-close-on-esc="noCloseOnEscape"
    :no-close-on-backdrop="noCloseOnBackDrop"
    :hide-header-close="hideHeaderClose"
    ok-variant="success"
    cancel-variant="default"
    @cancel="onCancel"
    @change="onChange"
    @hide="onHide"
    @ok="onOk"
  >
    <p v-text="message" />
    <div slot="modal-footer">
      <b-btn
        class="float-right cursor-pointer"
        @click="onOk"
      >
        {{ okTitle }}
      </b-btn>
      <b-btn
        v-if="!okOnly"
        class="float-right cursor-pointer"
        variant="default"
        @click="onCancel"
      >
        {{ cancelTitle }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { COMMON_MODAL_SHOW, COMMON_MODAL_DISMISS } from './modal/modal-events'

export default {
  name: 'CommonModal',
  data () {
    return {
      isActive: false,
      message: '',
      okOnly: false,
      okTitle: 'Ok',
      okCallback: undefined,
      okDisabled: false,
      cancelTitle: 'Cancel',
      cancelCallback: undefined,
      cancelDisabled: false,
      title: 'Generic Popup',
      centered: true,
      busy: false,
      lazy: false,
      hideCallback: undefined,
      noCloseOnEscape: false,
      noCloseOnBackDrop: false,
      hideHeaderClose: false
    }
  },
  mounted: async function () {
    await this.$nextTick() // TODO(ben.ward) https://tangocard.atlassian.net/browse/POR-1473
    this.$bus.$on(COMMON_MODAL_DISMISS, this.dismiss)
    this.$bus.$on(COMMON_MODAL_SHOW, this.show)
  },
  methods: {
    dismiss () {
      this.isActive = false
    },
    show (opts) {
      // All properties are optional and fallback to default values
      if (opts) {
        this.title = opts.title ? opts.title : ''
        this.message = opts.message ? opts.message : ''
        this.okOnly = opts.okOnly === true
        this.okTitle = opts.okTitle ? opts.okTitle : ''
        this.okCallback = typeof opts.okCallback === 'function' ? opts.okCallback : undefined
        this.okDisabled = opts.okDisabled === true
        this.cancelTitle = opts.cancelTitle ? opts.cancelTitle : ''
        this.cancelCallback = typeof opts.cancelCallback === 'function' ? opts.cancelCallback : undefined
        this.cancelDisabled = opts.cancelDisabled === true
        this.centered = opts.centered === true
        this.busy = opts.busy === true
        this.lazy = opts.lazy === true
        this.hideCallback = typeof opts.hideCallback === 'function' ? opts.hideCallback : undefined
        this.isActive = true

        // Closing options
        this.noCloseOnEscape = opts.noCloseOnEscape === true
        this.noCloseOnBackDrop = opts.noCloseOnBackDrop === true
        this.hideHeaderClose = opts.hideHeaderClose === true
      }
    },
    onOk () {
      if (typeof this.okCallback === 'function') {
        if (this.okCallback() !== false) {
          this.$modal.dismiss()
        }
      } else {
        this.$modal.dismiss()
      }
    },
    onCancel () {
      if (typeof this.cancelCallback === 'function') {
        if (this.cancelCallback() !== false) {
          this.$modal.dismiss()
        }
      } else {
        this.$modal.dismiss()
      }
    },
    onChange (isVisible) {
      if (isVisible === false) this.$modal.dismiss()
    },
    onHide (bvEvt) {
      if (typeof this.hideCallback === 'function') {
        if (this.hideCallback() === false) {
          bvEvt.preventDefault()
        } else {
          this.$modal.dismiss()
        }
      } else {
        this.$modal.dismiss()
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
