// PRINCIPLE

export const PRINCIPLE_SET_USER = 'PRINCIPLE_SET_USER'
export const PRINCIPLE_SET_AUTHENTICATED = 'PRINCIPLE_SET_AUTHENTICATED'

// TOAST

export const TOAST_SET_HTML = 'TOAST_SET_HTML'
export const TOAST_SET_TYPE = 'TOAST_SET_TYPE'
export const TOAST_SET_DURATION = 'TOAST_SET_DURATION'
export const TOAST_SET_VARIANT = 'TOAST_SET_VARIANT'

// SNACK

export const SNACK_SET_MESSAGE = 'SNACK_SET_MESSAGE'
export const SNACK_SET_LOADING = 'SNACK_SET_LOADING'
export const SNACK_SET_TIMEOUT = 'SNACK_SET_TIMEOUT'
export const SNACK_SET_VARIANT = 'SNACK_SET_VARIANT'
export const SNACK_SET_LINK_URL = 'SNACK_SET_LINK_URL'
export const SNACK_SET_LINK_TEXT = 'SNACK_SET_LINK_TEXT'
export const SNACK_SET_CALLBACK = 'SNACK_SET_CALLBACK'

// APP

export const APP_SET_TITLE = 'APP_SET_TITLE'
export const APP_SET_LOADING = 'APP_SET_LOADING'
export const APP_SET_USING_NEW_LAYOUT = 'APP_SET_USING_NEW_LAYOUT'
export const APP_SET_TOPBAR_VISIBLE = 'APP_SET_TOPBAR_VISIBLE'
export const APP_SET_TOPBAR_MENU_VISIBLE = 'APP_SET_TOPBAR_MENU_VISIBLE'
export const APP_SET_TOPBAR_FIRST_ENTER = 'APP_SET_TOPBAR_FIRST_ENTER'
export const APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE = 'APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE'
export const APP_SET_APPLICATION_SPLIT_IS_INITIALIZED = 'APP_SET_APPLICATION_SPLIT_IS_INITIALIZED'
export const APP_SET_SPLIT_IS_INITIALIZED = 'APP_SET_SPLIT_IS_INITIALIZED'
export const SET_UNDELIVERABLE_EMAIL_COUNT = 'SET_UNDELIVERABLE_EMAIL_COUNT'

// LOGIN

export const LOGIN_SET_ORIGINAL_ROUTE = 'LOGIN_SET_ORIGINAL_ROUTE'

// DELIVERY TEMPLATE

export const SET_TEMPLATE_DRAFT = 'SET_TEMPLATE_DRAFT'
export const CACHE_TEMPLATE = 'CACHE_TEMPLATE'
export const REMOVE_TEMPLATE_FROM_CACHE = 'REMOVE_TEMPLATE_FROM_CACHE'
export const SET_SELECTED_DEFAULT_TEMPLATE = 'SET_SELECTED_DEFAULT_TEMPLATE'
export const SET_EMAIL_TEMPLATE_PERMISSION_LEVEL = 'SET_EMAIL_TEMPLATE_PERMISSION_LEVEL'

// ORDER
export const SET_ORDER_TOTAL_SUMMARY = 'SET_ORDER_TOTAL_SUMMARY'
export const SET_ORDER_DRAFT = 'SET_ORDER_DRAFT'
export const SET_ORDER_METADATA = 'SET_ORDER_METADATA'
export const CLEAR_ORDER_VALUES = 'CLEAR_ORDER_VALUES'
export const SET_PREVIOUSLY_DISPLAYED_CART_COUNT = 'SET_PREVIOUSLY_DISPLAYED_CART_COUNT'
export const SET_BLACKLISTED_EMAIL_DOMAINS = 'GET_BLACKLISTED_EMAIL_DOMAINS'

// ONBOARDING

export const SET_ONBOARD_DRAFT = 'SET_ONBOARD_DRAFT'

// CUSTOMER/ACCOUNT

export const SET_CUSTOMER_ACCOUNT_DEFAULT = 'SET_CUSTOMER_ACCOUNT_DEFAULT'
export const SET_LAST_SELECTED_CUSTOMER_ACCOUNT = 'SET_LAST_SELECTED_CUSTOMER_ACCOUNT'
export const SET_SELECTED_CUSTOMER_ACCOUNT = 'SET_SELECTED_CUSTOMER_ACCOUNT'
export const SET_ACCOUNTS_FOR_SELECTED_CUSTOMER = 'SET_ACCOUNTS_FOR_SELECTED_CUSTOMER'
export const SET_ONLY_SHOW_USD_ACCOUNTS = 'SET_ONLY_SHOW_USD_ACCOUNTS'
export const SET_ACCOUNTS_BY_GROUP = 'SET_ACTIVE_ACCOUNTS_BY_GROUP'
export const SET_NEW_ACCOUNT = 'SET_NEW_ACCOUNT'

// TREATMENT

export const ADD_TREATMENT = 'ADD_TREATMENT'

// PLATFORM

export const SET_PLATFORM = 'SET_PLATFORM'

// RECIPIENT WHITELIST

export const SET_RECIPIENT_WHITELIST = 'SET_RECIPIENT_WHITELIST'
export const SET_ORDER_WHITELIST_RECIPIENTS = 'SET_ORDER_WHITELIST_RECIPIENTS'

// REPORT ALERTS

export const SET_REPORT_ALERT_COUNT = 'SET_REPORT_ALERT_COUNT'

// CATALOG

export const SET_CATALOG = 'SET_CATALOG'
export const SET_ITEMS = 'SET_ITEMS'
export const SET_CATALOG_SEARCH_PARAMS = 'SET_CATALOG_SEARCH_PARAMS'
export const SET_SEARCH_IS_VISIBLE = 'SET_SEARCH_IS_VISIBLE'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'

// Login Errors
export const LOGIN_UPDATE_ERROR = 'LOGIN_UPDATE_ERROR'

// Shopping Cart
export const SET_SHOPPING_CART = 'SET_SHOPPING_CART'
export const CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART'
export const SHOPPING_CART_SUMMARY_ACTIVE = 'SHOPPING_CART_SUMMARY_ACTIVE'
