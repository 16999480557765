import Vue from 'vue'
import { COMMON_MODAL_SHOW } from '../components/shared/modal/modal-events'
import i18n from '@/i18n'

export default class {
  static use (router) {
    router.onError(error => {
      console.error(error)

      // On all chunk failures we should just force reload the page
      // This scenario is more than likely caused by an app redeploy and the user has stale file name hashes which a reload will fix
      // Checking for both chunk and css chunk since it depends which one fails first
      if (error.message && (error.message.match('Loading chunk .* failed') ||
            error.message.match('Loading CSS chunk .* failed'))) {
        const opts = {
          title: i18n.t('error.outOfSync.title'),
          message: i18n.t('error.outOfSync.message'),
          okOnly: true,
          okTitle: i18n.t('error.outOfSync.actionButton'),
          okCallback: () => window.location.reload(),
          okDisabled: false,
          centered: true,
          isActive: true,
          noCloseOnEscape: true,
          noCloseOnBackDrop: true,
          hideHeaderClose: true
        }

        Vue.prototype.$bus.$emit(COMMON_MODAL_SHOW, opts)
      }
    })
  }
}
