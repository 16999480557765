// General
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const IDENTITY_PROVIDER = {
  GOOGLE: 'GOOGLE',
  SAML: 'SAML'
}
export const SSO_LOGIN = 'SSOLogin'
export const LOGIN = 'Login'

// Email Templates
export const EMAIL_TEMPLATES_MANAGE = 'EMAIL_TEMPLATES_MANAGE'
export const EMAIL_TEMPLATES_ACCESS = 'EMAIL_TEMPLATES_ACCESS'

// Place Order
export const PLACE_ORDER_ACCESS = 'PLACE_ORDER_ACCESS'

// Order History / Line Item History
export const ORDER_HISTORY_ACCESS = 'ORDER_HISTORY_ACCESS'
export const ORDER_HISTORY_MANAGE = 'ORDER_HISTORY_MANAGE'
export const INDIVIDUAL_ORDER_HISTORY_ACCESS = 'INDIVIDUAL_ORDER_HISTORY_ACCESS'
export const INDIVIDUAL_ORDER_HISTORY_MANAGE = 'INDIVIDUAL_ORDER_HISTORY_MANAGE'

// Print Templates
export const PRINT_TEMPLATES_MANAGE = 'PRINT_TEMPLATES_MANAGE'

// Funding
export const FUNDING_ACCESS = 'FUNDING_ACCESS'
export const FUNDING_MANAGE = 'FUNDING_MANAGE'

// Whitelisting
export const RECIPIENT_WHITELIST_MANAGE = 'RECIPIENT_WHITELIST_MANAGE'

// View balance
export const VIEW_BALANCE_ACCESS = 'VIEW_BALANCE_ACCESS'

// Users
export const USER_ADMINISTRATION = 'USER_ADMINISTRATION'

// Accounts
export const ACCOUNT_ACCESS = 'ACCOUNT_ACCESS'
export const ACCOUNT_MANAGE = 'ACCOUNT_MANAGE'

// Reports
export const ORDER_REPORT_ACCESS = 'ORDER_REPORT_ACCESS'
export const ORDER_REPORT_MANAGE = 'ORDER_REPORT_MANAGE'

// RaaS API keys and Oauth client creds
export const API_KEYS_MANAGE = 'API_KEYS_MANAGE'

// Qualtrics
export const INTEGRATIONS_ACCESS = 'INTEGRATIONS_ACCESS'
export const INTEGRATIONS_MANAGE = 'INTEGRATIONS_MANAGE'
export const QUALTRICS_API_KEYS_ACCESS = 'QUALTRICS_API_KEYS_ACCESS'
export const QUALTRICS_API_KEYS_MANAGE = 'QUALTRICS_API_KEYS_MANAGE'

// Role Management
export const ROLE_MANAGEMENT_MANAGE = 'ROLE_MANAGEMENT_MANAGE'

// Order Settings Management
export const PLATFORM_ORDER_SETTINGS = 'PLATFORM_ORDER_SETTINGS'

// Reward Freeze, Cancel, Resissue
export const FREEZE_REWARDS_MANAGE = 'FREEZE_REWARDS_MANAGE'
export const CANCEL_REWARDS_MANAGE = 'CANCEL_REWARDS_MANAGE'
export const REISSUE_REWARDS_MANAGE = 'REISSUE_REWARDS_MANAGE'
export const REISSUE_EXPIRED_PROMO_LINK_REWARDS_MANAGE = 'REISSUE_EXPIRED_PROMO_LINK_REWARDS_MANAGE'

// Auth0 settings Management
export const AUTH_MANAGEMENT_MANAGE = 'AUTH_MANAGEMENT_MANAGE'

export const AUTH_SESSION_ERROR = 'expiredSession'

export const AUTH_SESSION_TIMEOUT = 30 // minutes
export const AUTH_SESSION_MILLISECONDS = 60000 // minutes to milliseconds conversion

// API Credential Type
export const API_CREDENTIAL_TYPE = Object.freeze({
  RAAS: 'RAAS_CREDENTIALS',
  OAUTH: 'OAUTH_CREDENTIALS',
  QUALTRICS: 'QUALTRICS_CREDENTIALS'
})
