import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const createBlankOnboarding = () => ({
  steps: [
    // TODO - This will be re-added later
    // {
    //   name: 'WELCOME',
    //   displayName: 'Welcome',
    //   display: false,
    //   visited: false,
    //   selected: true,
    //   completed: false,
    //   previous: null,
    //   next: 'REWARDS_USAGE'
    // },
    {
      name: 'REWARDS_USAGE',
      displayName: 'Rewards Usage',
      display: true,
      visited: true,
      selected: true,
      completed: false,
      previous: null,
      next: 'COMPANY_INFO'
    },
    {
      name: 'COMPANY_INFO',
      displayName: 'Company Info',
      display: true,
      visited: false,
      selected: false,
      completed: false,
      previous: 'REWARDS_USAGE',
      next: 'ADDITIONAL_INFO'
    },
    {
      name: 'ADDITIONAL_INFO',
      displayName: 'Additional Info',
      display: true,
      visited: false,
      selected: false,
      completed: false,
      previous: 'COMPANY_INFO',
      next: null
    }
  ],
  rewardsUsageOptions: [],
  company: {
    companyName: null,
    country: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    postalCode: null,
    phoneNumber: null,
    taxId: null
  }
})

const modules = {
  onboarding: {
    state: {
      loaded: false,
      onboard: createBlankOnboarding()
    },
    actions: {
      [actionTypes.CLEAR_ONBOARDING] (context) {
        context.state.onboard = createBlankOnboarding()
        context.state.loaded = false
        localStorage.removeItem('onboarding-draft')
      }
    },
    getters: {
      [getTypes.GET_ONBOARD_DRAFT]: (state) => {
        if (!state.loaded) {
          state.loaded = true
          const draft = localStorage.getItem('onboarding-draft')
          if (draft) {
            state.onboard = JSON.parse(draft)
          }
        }
        return state.onboard
      }
    },
    mutations: {
      [mutationTypes.SET_ONBOARD_DRAFT] (state, onboard) {
        state.onboard = onboard
        localStorage.setItem('onboarding-draft', JSON.stringify(onboard))
      }
    }
  }
}

export default {
  modules
}
