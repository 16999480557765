import 'moment-timezone'

function expirationDate (date) {
  if (!date || !date.includes('-')) {
    return date
  }
  const split = date.split('-')
  if (split.length !== 2) {
    return date
  }
  return split[1] + '/' + split[0]
}

function ExpirationDatePlugin (Vue) {
  Vue.prototype.$expirationDate = expirationDate
}

export {
  ExpirationDatePlugin
}
