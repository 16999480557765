function capitalize (words) {
  if (!words) {
    return words
  }
  return words.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
}

function CapitalizePlugin (Vue) {
  Vue.prototype.$capitalize = capitalize
}

export {
  CapitalizePlugin
}
