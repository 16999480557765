export default class {
  static use (router) {
    router.beforeEach((to, from, next) => {
      if (from.name === 'RegisterPhone' && to.name === 'MfaReturn') {
        to.meta.unvalidated = true
      } else {
        to.meta.unvalidated = false
      }
      next()
    })
  }
}
