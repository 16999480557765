function trunc (word, length) {
  if (!word || word.length <= (length || 40)) {
    return word
  }

  return word.substring(0, (length || 40)) + '…'
}

function TruncatePlugin (Vue) {
  Vue.prototype.$trunc = trunc
}

export {
  TruncatePlugin
}
