<template>
  <div />
</template>

<script>
import { CYBERSOURCE_SALE_TRANSACTION, CYBERSOURCE_SUCCESS_REASON_CODE } from '@/common/cybersource-constants'
import { RG_ACH_REDESIGN } from '@/components/shared/split/split-constants'
import { SUCCESS, ERROR } from '@/components/shared/alert/snack-constants'

export default {
  // TODO REPLACE/REMOVE when we get rid of cybersource ACH redirect when depositing https://tangocard.atlassian.net/browse/RG-6787
  name: 'AchRequestMessaging',

  data () {
    return {
      request: undefined
    }
  },

  computed: {
    achRedesignSplitEnabled () {
      return this.$isSplitEnabled(RG_ACH_REDESIGN)
    },
    requestReferenceNumber () {
      return this.$route.params?.requestReferenceNumber
    },
    requestUnknownError () {
      return this.requestReferenceNumber === 'error'
    },
    requestErrored () {
      const { cybersourceReasonCode = 0 } = this.request || {}
      return cybersourceReasonCode !== CYBERSOURCE_SUCCESS_REASON_CODE || this.requestUnknownError
    },
    isSaleTransactionType () {
      const { transactionType } = this.request || {}
      return transactionType === CYBERSOURCE_SALE_TRANSACTION
    }
  },

  async mounted () {
    if (this.$route.params?.requestReferenceNumber) {
      await this.setRequestDetails()
      if (this.isSaleTransactionType) {
        this.showRequestToast()
      }
    }
  },

  methods: {
    async setRequestDetails () {
      if (this.requestUnknownError) {
        this.request = null
      } else {
        try {
          this.request = (await this.$http.get(`api/ach/request/${this.requestReferenceNumber}`)).data
        } catch (err) {
          console.error(err)
        }
      }
    },
    showRequestToast () {
      if (this.requestErrored || this.requestUnknownError) {
        if (this.achRedesignSplitEnabled) {
          this.$snack(this.$t('portalfrontendApp.coreAch.snackBarAlertMessages.fundingRequestFailed'), false, ERROR, -1)
        } else {
          this.$toast(this.$t('portalfrontendApp.coreFunding.depositTab.error'), 'danger')
        }
      } else {
        if (this.achRedesignSplitEnabled) {
          this.$snack(this.$t('portalfrontendApp.coreAch.snackBarAlertMessages.fundingRequestSubmitted'), false, SUCCESS)
        } else {
          this.$toast(this.$t('portalfrontendApp.coreFunding.depositTab.success'), 'success')
        }
      }
    }
  }
}
</script>
