import { RewardItem } from '@/dto/order/RewardItem'
import { Recipient } from '@/dto/order/recipient/Recipient'
import { SelectedEmailTemplate } from '@/dto/order/email-templates/SelectedEmailTemplate'
import { SelectedPhysicalTemplate } from '@/dto/order/physical-templates/SelectedPhysicalTemplate'
import { SelectedRewardItemMetadata } from '@/dto/order/SelectedRewardItemMetadata'
import { FulfillmentType } from '@/dto/order/FulfillmentType'
import { RecipientDigital } from '@/dto/order/recipient/RecipientDigital'
import { RecipientPhysical } from '@/dto/order/recipient/RecipientPhysical'

const defaultQuantity = 1

export class SelectedRewardItem {
  _rewardItem
  _recipient
  _deliveryTemplate
  _metadata

  constructor ({ rewardItem, recipient, amount, deliveryTemplate, metadata, quantity, identifier, wyndhamLocationId, wyndhamTourId, scheduledDeliveryTime } = {}) {
    this.rewardItem = rewardItem
    this.recipient = recipient
    this.amount = amount
    this.identifier = identifier
    this.deliveryTemplate = deliveryTemplate
    this.metadata = metadata
    this.quantity = quantity === undefined ? defaultQuantity : quantity
    this.wyndhamLocationId = wyndhamLocationId
    this.wyndhamTourId = wyndhamTourId
    this.scheduledDeliveryTime = scheduledDeliveryTime
  }

  set rewardItem (rewardItem) {
    this._rewardItem = rewardItem instanceof RewardItem ? rewardItem : new RewardItem(rewardItem)
  }

  get rewardItem () {
    return this._rewardItem
  }

  set recipient (recipientIn) {
    const { fulfillmentType } = recipientIn || {}
    let recipient = recipientIn

    if (!(recipientIn instanceof Recipient)) {
      switch (fulfillmentType) {
        case FulfillmentType.DIGITAL:
          recipient = new RecipientDigital(recipient)
          break
        case FulfillmentType.PHYSICAL:
          recipient = new RecipientPhysical(recipient)
          break
        default:
          recipient = new Recipient(recipient)
      }
    }

    this._recipient = recipient
  }

  get recipient () {
    return this._recipient
  }

  set deliveryTemplate (deliveryTemplate) {
    if (this.isPhysical) {
      this._deliveryTemplate = deliveryTemplate instanceof SelectedPhysicalTemplate ? deliveryTemplate : new SelectedPhysicalTemplate(deliveryTemplate)
    } else {
      this._deliveryTemplate = deliveryTemplate instanceof SelectedEmailTemplate ? deliveryTemplate : new SelectedEmailTemplate(deliveryTemplate)
    }
  }

  get deliveryTemplate () {
    return this._deliveryTemplate
  }

  set metadata (metadata) {
    this._metadata = metadata instanceof SelectedRewardItemMetadata ? metadata : new SelectedRewardItemMetadata(metadata)
  }

  get metadata () {
    return this._metadata
  }

  get isPrintableRewardLink () {
    return this.isPhysical && this.isRewardLink
  }

  get isRewardLink () {
    return this.rewardItem.isRewardLink
  }

  get isPhysical () {
    return this.recipient instanceof RecipientPhysical && this.rewardItem.isPhysical
  }

  get personalizationFieldsCompleted () {
    return !!(
      this.deliveryTemplate.markupCustomization.toName &&
      this.deliveryTemplate.markupCustomization.fromName &&
      this.deliveryTemplate.markupCustomization.message
    )
  }

  get showOnDeliveryTemplatePage () {
    const { firstName, lastName, email, address = {} } = this.recipient || {}
    return !!(!!this.amount || firstName || lastName || email || !!address.streetLine1)
  }

  // Used to persist data to localStorage
  toJSON () {
    const { rewardItem, recipient, amount, deliveryTemplate, metadata, wyndhamLocationId, wyndhamTourId, scheduledDeliveryTime } = this
    return { rewardItem, recipient, amount, deliveryTemplate, metadata, wyndhamLocationId, wyndhamTourId, scheduledDeliveryTime }
  }
}
