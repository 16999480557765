export const BannerType = Object.freeze({
  EMULATION: 'emulation',
  FROZEN: 'frozen',
  KYB: 'kyb'
})

export const IconAlign = Object.freeze({
  CENTER: 'center',
  TOP: 'top',
  BOTTOM: 'bottom'
})
