const cardTypeRegex = {
  visa: /^4[0-9]{2,}$/,
  mastercard: /^5[1-5][0-9]+$/,
  amex: /^3[47][0-9]+$/,
  discover: /^6(011|22|4[4-9]|5)\d+$/
}

// Returns 'visa' if it matches the visa regex
// Is meant to detect the card type early, not to validate the card number is perfect
function detectCardType (cardNumber) {
  for (const key in cardTypeRegex) {
    if (cardTypeRegex[key].test(cardNumber)) {
      return key
    }
  }
  return 'other'
}

export {
  detectCardType
}
