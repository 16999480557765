import * as getTypes from '../get-types'
import * as actionTypes from '../action-types'
import * as types from '../mutation-types'
import { HttpClient } from '@/components/shared/security/http-client'

const state = { platform: null }

const getters = {
  [getTypes.GET_PLATFORM]: state => state.platform
}

const actions = {
  async [actionTypes.GET_PLATFORM_ASYNC] ({ commit, state }, { platformClientId, forceRefresh }) {
    // convert the id values to be Numbers to ensure strict comparisons
    if (state.platform && Number(state.platform.platformClientId) === Number(platformClientId) && !forceRefresh) {
      return Promise.resolve(state.platform)
    }

    try {
      const platformResponse = await HttpClient.get(`api/platforms/${platformClientId}`)
      const platform = platformResponse.data
      commit(types.SET_PLATFORM, platform)
      return platform
    } catch (error) {
      console.error(error)
      commit(types.SET_PLATFORM, null)
    }
  }
}

const mutations = {
  [types.SET_PLATFORM] (state, platform) {
    state.platform = platform
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
