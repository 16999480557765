import * as actions from '../store/action-types'
import store from '../store'

export default class {
  static use (router) {
    router.beforeEach((to, from, next) => {
      store.dispatch(actions.RESET_TOPBAR)
      next()
    })
  }
}
