// PRINCIPLE

export const PRINCIPLE_CLEAR_USER = 'PRINCIPLE_CLEAR_USER'
export const PRINCIPLE_GET_ASYNC = 'PRINCIPLE_GET_ASYNC'

// TOAST

export const TOAST = 'TOAST'
// SNACK
export const SNACK = 'SNACK'

// CUSTOMER/ACCOUNT

export const REMOVE_CUSTOMER_ACCOUNT_DEFAULT = 'REMOVE_CUSTOMER_ACCOUNT_DEFAULT'
export const REMOVE_LAST_SELECTED_CUSTOMER_ACCOUNT = 'REMOVE_LAST_SELECTED_CUSTOMER_ACCOUNT'
export const GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC = 'GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC'
export const FETCH_AND_SET_THE_SELECTED_ACCOUNT = 'FETCH_AND_SET_THE_SELECTED_ACCOUNT'
export const UPDATE_TOKEN_FOR_CUSTOMER_ACCOUNT = 'UPDATE_TOKEN_FOR_CUSTOMER_ACCOUNT'
export const SET_SELECTED_CUSTOMER_ACCOUNT = 'SET_SELECTED_CUSTOMER_ACCOUNT'
export const UPDATE_THE_SELECTED_ACCOUNT = 'UPDATE_THE_SELECTED_ACCOUNT'
export const CLEAR_SELECTED_CUSTOMER_ACCOUNT = 'CLEAR_SELECTED_CUSTOMER_ACCOUNT'
export const SET_ONLY_SHOW_USD_ACCOUNTS = 'SET_ONLY_SHOW_USD_ACCOUNTS'
export const SET_CUSTOMER_ACCOUNTS = 'SET_CUSTOMER_ACCOUNTS'
export const FETCH_ACCOUNTS_BY_GROUPS = 'FETCH_ACCOUNTS_BY_GROUPS'
export const UPDATE_ACCOUNT_STATUS = 'UPDATE_ACCOUNT_STATUS'
export const FETCH_ACCOUNT_CONTACTS = 'FETCH_ACCOUNT_CONTACTS'
export const UPDATE_ACCOUNT_CONTACT_FUNDING_EMAILS = 'UPDATE_ACCOUNT_CONTACT_FUNDING_EMAILS'
export const CREATE_NEW_ACCOUNT = 'CREATE_NEW_ACCOUNT'
export const CREATE_NEW_GROUP = 'CREATE_NEW_GROUP'

// ORDER

export const CLEAR_ORDER = 'CLEAR_ORDER'
export const SET_ORDER_DRAFT = 'SET_ORDER_DRAFT'
export const UPDATE_ORDER_TOTAL_SUMMARY = 'UPDATE_ORDER_TOTAL_SUMMARY'
export const NAMESPACED_UPDATE_ORDER_TOTAL_SUMMARY = 'rewardFirstOrder/UPDATE_ORDER_TOTAL_SUMMARY'
export const GET_ORDER_TOTAL_SUMMARY = 'GET_ORDER_TOTAL_SUMMARY'
export const UPDATE_ORDER_METADATA = 'UPDATE_ORDER_METADATA'
export const ADD_ITEMS_TO_CART = 'ADD_ITEMS_TO_CART'
export const CLEAR_ALL_ITEMS_IN_CART = 'CLEAR_ALL_ITEMS_IN_CART'
export const DELETE_REWARD_ITEM_FROM_CART = 'DELETE_REWARD_ITEM_FROM_CART'
export const UPDATE_REWARD_ITEM_IN_CART = 'UPDATE_REWARD_ITEM_FROM_CART'
export const UPDATE_PREVIOUSLY_DISPLAYED_CART_COUNT = 'UPDATE_PREVIOUSLY_DISPLAYED_CART_COUNT'
export const FETCH_DRAFT_ORDER = 'FETCH_DRAFT_ORDER'
export const UPLOAD_ORDER = 'UPLOAD_ORDER'
export const FETCH_BLACKLISTED_EMAIL_DOMAINS = 'GET_BLACKLISTED_EMAIL_DOMAINS'
export const SET_INVALID_ETID_ARRAY = 'SET_INVALID_ETID_ARRAY'

// SHOPPING CART
export const FETCH_SHOPPING_CART_BY_ID = 'FETCH_SHOPPING_CART_BY_ID'
export const FETCH_ACTIVE_SHOPPING_CART = 'FETCH_ACTIVE_SHOPPING_CART'
export const CREATE_SHOPPING_CART = 'CREATE_SHOPPING_CART'
export const REMOVE_OUT_OF_SYNC_CARTS = 'REMOVE_OUT_OF_SYNC_CARTS'

// ONBOARDING

export const CLEAR_ONBOARDING = 'CLEAR_ONBOARDING'

// PLATFORM

export const GET_PLATFORM_ASYNC = 'GET_PLATFORM_ASYNC'

// LOGIN

export const LOGIN_CLEAR_ORIGINAL_URL = 'LOGIN_CLEAR_ORIGINAL_URL'

// TOPBAR

export const UPDATE_TOPBAR_VISIBILITY = 'UPDATE_TOPBAR_VISIBILITY'
export const UPDATE_TOPBAR_MENU_VISIBLITY = 'UPDATE_TOPBAR_MENU_VISIBLITY'
export const UPDATE_TOPBAR_FIRST_ENTER = 'UPDATE_TOPBAR_FIRST_ENTER'
export const RESET_TOPBAR = 'RESET_TOPBAR'

// APP

export const UPDATE_USING_NEW_LAYOUT = 'UPDATE_USING_NEW_LAYOUT'
export const APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE = 'APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE'
export const APP_SET_LOADING = 'APP_SET_LOADING'
export const APP_SET_APPLICATION_SPLIT_IS_INITIALIZED = 'APP_SET_APPLICATION_SPLIT_IS_INITIALIZED'
export const APP_SET_SPLIT_IS_INITIALIZED = 'APP_SET_SPLIT_IS_INITIALIZED'
export const FETCH_UNDELIVERABLE_EMAIL_COUNT = 'FETCH_UNDELIVERABLE_EMAIL_COUNT'

// SPLIT

export const ADD_TREATMENT = 'ADD_TREATMENT'

// RECIPIENT WHITELIST

export const CREATE_RECIPIENT_WHITELIST = 'CREATE_RECIPIENT_WHITELIST'
export const UPDATE_RECIPIENT_WHITELIST = 'UPDATE_RECIPIENT_WHITELIST'
export const GET_RECIPIENT_WHITELIST_ASYNC = 'GET_RECIPIENT_WHITELIST_ASYNC'
export const GET_ORDER_WHITELIST_RECIPIENTS_ASYNC = 'GET_ORDER_WHITELIST_RECIPIENTS_ASYNC'

// REPORT ALERTS

export const SET_REPORT_ALERT_COUNT = 'SET_REPORT_ALERT_COUNT'
export const CLEAR_REPORT_ALERT_COUNT = 'CLEAR_REPORT_ALERT_COUNT'

// CATALOG

export const RETRIEVE_AND_SET_CATALOG_IF_EMPTY = 'RETRIEVE_AND_SET_CATALOG'
export const SET_ITEMS = 'SET_ITEMS'
export const SET_CATALOG_SEARCH_PARAMS = 'SET_CATALOG_SEARCH_PARAMS'
export const SET_SEARCH_IS_VISIBLE = 'SET_SEARCH_IS_VISIBLE'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'

// DELIVERY TEMPLATES

export const RETRIEVE_AND_SET_TEMPLATE_IF_EMPTY = 'RETRIEVE_AND_SET_TEMPLATE_IF_EMPTY'
export const CLEAR_TEMPLATE_CACHE_FOR_DELIVERY_TEMPLATE_IDENTIFIER = 'CLEAR_TEMPLATE_CACHE_FOR_DELIVERY_TEMPLATE_IDENTIFIER'
export const CLEAR_DEFAULT_DELIVERY_TEMPLATES = 'CLEAR_DEFAULT_DELIVERY_TEMPLATES'
export const SELECT_DEFAULT_TEMPLATE = 'SELECT_DEFAULT_TEMPLATE'
export const SET_TANGOCARD_STANDARD_TEMPLATE_AS_SELECTED = 'SET_TANGOCARD_STANDARD_TEMPLATE_AS_SELECTED'
export const SET_USER_DEFAULT_DELIVERY_TEMPLATE_AS_SELECTED = 'SET_USER_DEFAULT_DELIVERY_TEMPLATE_AS_SELECTED'
export const NEW_PRINT_TEMPLATE_DRAFT = 'NEW_PRINT_TEMPLATE_DRAFT'
export const NEW_PRINT_TEMPLATE_DRAFT_WITH_NAME = 'NEW_PRINT_TEMPLATE_DRAFT_WITH_NAME'
export const SET_TEMPLATE_DRAFT = 'SET_TEMPLATE_DRAFT'

export const BUILD_EMAIL_TEMPLATE_PERMISSION_LEVEL = 'BUILD_EMAIL_TEMPLATE_PERMISSION_LEVEL'
export const RETRIEVE_DEFAULT_CUSTOMER_SUPPORT_MESSAGE = 'RETRIEVE_DEFAULT_CUSTOMER_SUPPORT_MESSAGE'

// Login Errors
export const LOGIN_SET_ERROR = 'LOGIN_SET_ERROR'
