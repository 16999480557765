export const PlatformKybStatus = Object.freeze({
  NONE: 'NONE',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING_KYB: 'PENDING_KYB'
})

export const APPROVED_KYB_STATUSES = [
  PlatformKybStatus.NONE,
  PlatformKybStatus.APPROVED
]
