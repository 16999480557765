import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const state = {
  treatments: []
}

const getters = {
  [getTypes.GET_TREATMENTS]: state => state.treatments,
  [getTypes.GET_TREATMENT_ENABLED]: (state, getters) => treatmentName => {
    const treatment = getters[getTypes.GET_SINGLE_TREATMENT](treatmentName)
    return treatment ? treatment.enabled : false
  },
  [getTypes.GET_SINGLE_TREATMENT]: state => name => {
    const potentialTreatment = state.treatments.find(treatment => treatment.name === name)
    if (potentialTreatment) {
      return potentialTreatment
    }
    return { enabled: false }
  }
}

const actions = {
  [actionTypes.ADD_TREATMENT] ({ commit }, initialized) {
    commit(mutationTypes.ADD_TREATMENT, initialized)
  }
}

const mutations = {
  [mutationTypes.ADD_TREATMENT] (state, treatment) {
    state.treatments.push(treatment)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
