<template>
  <transition
    enter-active-class="animated fadeIn fastest"
    leave-active-class="animated fadeOut fastest"
  >
    <v-snackbar
      class="fs-unmask"
      :value="showEmailPopup"
      :timeout="-1"
      v-bind="$attrs"
      bottom
      left
    >
      <div class="container-snackbar">
        <div class="container-icon">
          <v-icon
            :class="'icon-red'"
            small
          >
            {{ 'mdi-exclamation-thick' }}
          </v-icon>
        </div>
        <div class="container-content">
          <p
            id="message"
            class="mb-0"
          >
            {{ message }}
          </p>

          <p
            class="mt-1 mb-0"
          >
            <router-link
              class="snack-link"
              :to="routeToOrderHistoryLineItems"
            >
              {{ $t('undeliverableEmailPopup.view') }}
            </router-link>
          </p>
        </div>
        <div
          class="container-dismiss-button"
        >
          <v-btn
            icon
            small
            @click="snoozePolling()"
          >
            <v-icon>
              {{ 'mdi-close' }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-snackbar>
  </transition>
</template>

<script>
import AuthenticatedGuard from '@/router/authenticated-guard'
import { mapActions, mapGetters } from 'vuex'
import { GET_UNDELIVERABLE_EMAIL_COUNT } from '@/store/get-types'
import { FETCH_UNDELIVERABLE_EMAIL_COUNT } from '@/store/action-types'
import { ORDER_HISTORY_LINE_ITEMS } from '@/router/route-name-constants'

// Default snooze duration
const TEN_MINUTES_IN_MS = 600000
const FIFTEEN_SECONDS_IN_MS = 15000
const LOCAL_STORAGE_SNOOZE_NAME = 'snoozed-undeliverable-email-polling-at'

export default {
  name: 'UndeliverableEmailPopup',
  data () {
    return {
      intervalId: null,
      snoozed: false,
      snoozeDuration: TEN_MINUTES_IN_MS,
      timeoutInterval: FIFTEEN_SECONDS_IN_MS
    }
  },
  computed: {
    ...mapGetters({
      undeliverableEmailCount: GET_UNDELIVERABLE_EMAIL_COUNT
    }),
    getRewardPluralityVerbiage () {
      return this.undeliverableEmailCount > 1
        ? this.$t('undeliverableEmailPopup.rewards')
        : this.$t('undeliverableEmailPopup.reward')
    },
    message () {
      return `${this.undeliverableEmailCount} ${this.getRewardPluralityVerbiage} ${this.$t('undeliverableEmailPopup.message')}`
    },
    routeToOrderHistoryLineItems () {
      return { name: ORDER_HISTORY_LINE_ITEMS, params: { setHistoryTableFiltersForUndeliverableRewards: true } }
    },
    showEmailPopup () {
      return !this.snoozed && !!this.undeliverableEmailCount && AuthenticatedGuard.isLoggedIn()
    }
  },
  mounted () {
    this.initialPollingSetup()
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapActions({
      fetchUndeliverableEmailCount: FETCH_UNDELIVERABLE_EMAIL_COUNT
    }),
    initialPollingSetup () {
      // Need to call it immediately once, to get the initial count, then start polling
      this.pollForUndeliverableEmails()
      this.intervalId = setInterval(this.pollForUndeliverableEmails, this.timeoutInterval)
    },
    pollForUndeliverableEmails () {
      this.updateSnooze()
      if (AuthenticatedGuard.isLoggedIn() && !this.snoozed) {
        this.fetchUndeliverableEmailCount()
      }
    },
    snoozePolling () {
      localStorage.setItem(LOCAL_STORAGE_SNOOZE_NAME, Date.now().toString())
      this.snoozed = true
    },
    updateSnooze () {
      const snoozedDateTime = localStorage.getItem(LOCAL_STORAGE_SNOOZE_NAME)
      if (snoozedDateTime) {
        const stillSnoozed = Date.now() - snoozedDateTime <= this.snoozeDuration
        if (stillSnoozed) {
          this.snoozed = true
        } else {
          localStorage.removeItem(LOCAL_STORAGE_SNOOZE_NAME)
          this.snoozed = false
        }
      } else {
        this.snoozed = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#snackbar-parent-container {
  margin-inline-start: 0.5rem;
}
#snackbar-parent-container ::v-deep .v-snack__wrapper {
  width: initial;
  background-color: var(--t-color-navigation-surface-inverted);
}
p {
  display: block;
  font-weight: var(--t-font-weight-6);
  color: var(--t-color-text-inverted);
  margin: 0;
}
#snackbar-parent-container ::v-deep .v-snack__content {
  display: initial;
}
.container-snackbar {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
.container-icon {
  display: flex;
  flex-shrink: 0;
}
.container-content {
  flex: 1;
  margin-block-start: .125rem;
}
.container-secondary-actions {
  display: flex;
  margin-inline-start: -3rem;
}
.v-icon {
  color: var(--t-color-text-inverted);
  border-radius: var(--t-radius-round);
  padding: .25rem;
}
.icon-red {
  background-color: var(--t-color-status-danger-weak);
}
.container-dismiss-button {
  margin-block-start: -.125rem;
}
.v-snack {
  width: 300px !important;
  max-width: 500px !important;
  z-index: 11001 !important;
  top: -100px
}
#app a.snack-link {
  color: var(--t-color-text-link-inverted) !important;
  text-decoration: underline;
}
</style>
