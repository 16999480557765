import { getFormattedEnvironment, isNotProduction } from '@/components/shared/environment'
import { createSegmentModule } from '@tangocard/mango'
import {
  createGroupDetails,
  createIdentifyDetails,
  createProperties
} from '@/components/shared/segment/trackers'

export const segmentModule = createSegmentModule({
  writeKey: process.env.VUE_APP_SEGMENT_WRITE_KEY,
  createProperties,
  createIdentifyDetails,
  createGroupDetails,
  environment: getFormattedEnvironment(),
  logEnvironmentName: isNotProduction(),
  // Required by mango segment module to avoid errors retrieving properties common to all event
  createOptionsForTracking: () => Promise.resolve({})
})

export const SegmentPlugin = (Vue) => {
  Vue.prototype.$segment = segmentModule
}

export const AttachOneTrustToRGScripts = (CookieCategories, OneTrustCookieBlocker) => {
  const segmentScriptSrc = 'https://cdn.segment.com/analytics.js/v1/'
  const segmentScriptElement = document.querySelector(`script[src^="${segmentScriptSrc}"]`)
  OneTrustCookieBlocker.addRequiredScriptAttributes(segmentScriptElement, CookieCategories.PERFORMANCE, 'tcSegmentScript')
}
