import { CHAMELEON_INIT_TOKEN } from '@/plugins/chameleon/ChameleonConstants'
import chmln from '@chamaeleonidae/chmln'
import { createIdentifyDetails } from '@/components/shared/segment/trackers'

/**
 * Chameleon is a plugin that injects Chameleon into the application
 */
export default class Chameleon {
  constructor () {
    this._chameleonLoaded = false
  }

  /**
   * Initializes chameleon
   * @Return {<void>}
   */
  init () {
    try {
      if (!window.chmln) {
        chmln.init(CHAMELEON_INIT_TOKEN, {
          forceOverride: true
        })
      }
      this._chameleonLoaded = true
      console.debug('Chameleon is initialized')
    } catch (err) {
      this._chameleonLoaded = false
      console.debug('Chameleon did not initialize', err)
    }
  }

  /**
   * Set user identifiable information
   * @Return {<void>}
   */
  async identify () {
    if (this._chameleonLoaded) {
      try {
        const identifyDataSet = await createIdentifyDetails()
        await chmln.identify(identifyDataSet.userId, identifyDataSet.details)
        console.debug('Chameleon set user identity')
      } catch (err) {
        console.debug('Chameleon did not set identity', err)
      }
    } else {
      console.debug('script is not loaded - identify')
    }
  }
}
