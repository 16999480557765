export class SessionManager {
  /** @private @type {string|null|undefined} */
  _sessionId

  static SESSION_ID_HEADER_NAME = 'x-tango-portal-session-id'
  static SESSION_ID_KEY = '_tpsid'

  constructor () {
    this._sessionId = sessionStorage.getItem(SessionManager.SESSION_ID_KEY)
  }

  /** @return {string|null|undefined} The current session id */
  get sessionId () {
    return this._sessionId
  }

  /** @param sessionId {string|null|undefined} The new session id to track */
  set sessionId (sessionId) {
    sessionStorage.setItem(SessionManager.SESSION_ID_KEY, sessionId)
    this._sessionId = sessionId
  }
}

export default new SessionManager()
