<template>
  <div
    id="sidebar"
    ref="sideBar"
    class="fs-unmask"
    :class="{ 'controls-open': sidebarOpen }"
  >
    <div
      id="sidebar-menu"
      class="sidebar-menu"
    >
      <v-navigation-drawer
        permanent
        :class="{'has-referral':getHasRewards}"
      >
        <div class="nav-icon-container">
          <v-tooltip
            max-width="19rem"
            right
            :disabled="menuTransitioning"
          >
            <template #activator="{ on, attrs }">
              <v-app-bar-nav-icon @click.stop="toggleControls">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ menuIcon }}
                </v-icon>
              </v-app-bar-nav-icon>
            </template>
            {{ menuTooltip }}
          </v-tooltip>
        </div>

        <div class="controls-container">
          <!--top controls-->
          <v-list
            nav
            dense
          >
            <account-switcher-dialog-button
              :sidebar-open="sidebarOpen"
              @click="trackNavigation(trackAccountSwitcherOpened)"
            />
            <v-list-item
              v-for="control in filteredNavigationControls"
              :key="control.iconName"
              :class="{ 'active': isActive(control.routePath, control.iconName) }"
              link
              @click="navigate(control.routePath, control.trackEventName)"
            >
              <v-tooltip
                max-width="19rem"
                right
                :disabled="sidebarOpen"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div
                      v-if="showNotification(control.iconName)"
                      id="notification-icon"
                      class="icon-badge"
                    />
                    <v-icon>
                      {{ control.icon }}
                    </v-icon>
                  </v-list-item-icon>
                </template>
                {{ $t(control.translationKey) }}
              </v-tooltip>
              <v-list-item-title>
                {{ $t(control.translationKey) }}
              </v-list-item-title>
              <v-list-item-icon
                v-if="control.chevron"
              >
                <v-icon>
                  {{ teamSettingsMenuChevron }}
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <team-settings-menu
              v-if="isTeamSettingsVisible"
            />
          </v-list>
        </div>
      </v-navigation-drawer>
      <!--bottom controls-->
      <v-list
        class="account-nav"
        nav
        dense
      >
        <!--refer-->
        <v-list-item
          v-if="showReferralButton"
          id="referral_link"
          link
          :href="defaultReferralLink"
          target="_blank"
          @click="trackNavigation(trackReferralButtonClicked)"
        >
          <v-tooltip
            max-width="19rem"
            right
            :disabled="sidebarOpen"
          >
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-gift-outline
                </v-icon>
              </v-list-item-icon>
            </template>
            {{ $t('sidebar.navigation.referral') }}
          </v-tooltip>
          <v-list-item-title>
            {{ $t('sidebar.navigation.referral') }}
          </v-list-item-title>
        </v-list-item>

        <!--help-->
        <v-menu
          :close-on-content-click="true"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-tooltip
                max-width="19rem"
                right
                :disabled="sidebarOpen || attrs.expanded"
              >
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-list-item-icon
                    v-bind="tooltipAttrs"
                    v-on="tooltipOn"
                  >
                    <v-icon>
                      mdi-help-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                </template>
                {{ $t('sidebar.navigation.help') }}
              </v-tooltip>
              <v-list-item-title>
                {{ $t('sidebar.navigation.help') }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <div class="side-nav-popover">
            <v-list>
              <v-list-item
                v-for="disclaimer of disclaimers"
                :key="disclaimer.key"
                :target="disclaimer.target"
                :href="disclaimer.href"
              >
                <v-list-item-content>
                  {{ $t(disclaimer.text) }}
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item
                link
                :href="currentHelpRedirectLink"
                target="_blank"
                @click="trackNavigation(trackHelpButtonClicked)"
              >
                <v-list-item-content>
                  {{ $t('sidebar.branding.help') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>

        <!--account-->
        <v-menu
          :close-on-content-click="true"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              class="fs-mask"
              link
              :class="{ 'active': isActive('/settings/user') }"
              v-on="on"
            >
              <v-tooltip
                max-width="19rem"
                right
                :disabled="sidebarOpen || attrs.expanded"
              >
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-list-item-icon
                    v-bind="tooltipAttrs"
                    v-on="tooltipOn"
                  >
                    <v-icon>
                      mdi-account-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                </template>
                {{ userName }}
              </v-tooltip>
              <v-list-item-title>
                {{ userName }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <div class="side-nav-popover">
            <v-list elevation="0">
              <v-list-item @click="navigate('/settings/user', trackReferralProfileButtonClicked)">
                <v-list-item-content>
                  {{ $t('sidebar.user.myProfile') }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-content>
                  {{ $t('sidebar.user.logout') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </v-list>
    </div>
  </div>
</template>

<script>
import { Disclaimers, NavigationControls, RedirectLookupTable, SettingsPath } from './control-models'
import AuthenticationProvider from '../shared/security/authentication-provider'
import {
  FETCH_DRAFT_ORDER,
  PRINCIPLE_GET_ASYNC,
  FETCH_ACTIVE_SHOPPING_CART
} from '@/store/action-types'
import { GET_HAS_PENDING_ORDER, PRINCIPLE_GET_USER } from '@/store/get-types'
import { mapActions, mapGetters } from 'vuex'
import {
  AUTHENTICATED_SALESFORCE,
  POR_1968_RG_REFERRAL_PROGRAM
} from '../shared/split/split-constants'
import { OrderReportsSplitMixin } from '../platform/reports/shared/order-reports-split-mixin'

import * as AuthorityConstants from '@/components/shared/constants/authority.constants'
import { SIGN_OUT_CLICKED } from '@/components/shared/segment/track-user-management'
import AccountSwitcherDialogButton from '@/components/sidebar/account-switcher/AccountSwitcherDialogButton'
import { getParentRoute } from '@/common/routing-helpers'
import {
  ACCOUNT_SWITCHER_OPENED,
  HELP_BUTTON_CLICKED,
  MY_PROFILE_NAV_CLICKED,
  REFERRAL_BUTTON_CLICKED
} from '@/components/shared/segment/track-side-navigation'
import TeamSettingsMenu from '@/components/sidebar/TeamSettingsMenu'
import { getRgFrontendHost } from '@/components/shared/rgfe-api'

export default {
  name: 'Sidebar',

  components: {
    AccountSwitcherDialogButton,
    TeamSettingsMenu
  },

  mixins: [OrderReportsSplitMixin],

  props: {
    sidebarOpen: Boolean,
    viewport: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      menuTransitioning: false,
      controlsClosedByNavigating: false,
      navigationControls: NavigationControls,
      controlsTimer: 0
    }
  },

  computed: {
    authenticatedSF () {
      return this.$isSplitEnabled(AUTHENTICATED_SALESFORCE)
    },
    teamSettingsMenuChevron () {
      return this.isTeamSettingsVisible ? 'mdi-chevron-down' : 'mdi-chevron-right'
    },
    ...mapGetters({
      user: PRINCIPLE_GET_USER
    }),
    ...mapGetters('rewardFirstOrder', {
      hasPendingOrder: GET_HAS_PENDING_ORDER
    }),
    menuIcon () {
      return this.sidebarOpen ? 'mdi-chevron-double-left' : 'mdi-menu'
    },
    menuTooltip () {
      // Keep showing the collapse tooltip while the menu is closing, expand while opening
      const collapse = this.menuTransitioning ? !this.sidebarOpen : this.sidebarOpen
      return this.$t(`sidebar.navigation.${collapse ? 'collapse' : 'expand'}`)
    },
    userName () {
      return `${this.user.firstName} ${this.user.lastName}`
    },
    getHasRewards () {
      return this.$isSplitEnabled(POR_1968_RG_REFERRAL_PROGRAM)
    },
    showReferralButton () {
      return this.getHasRewards && this.$hasAuthority('IS_LOGGED_IN')
    },
    filteredNavigationControls () {
      return this.navigationControls.filter((control) => {
        return control.enabled && this.$hasAuthority('IS_LOGGED_IN') && this.$hasAnyAuthority(control.authorities)
      })
    },
    activeRoute () {
      return this.$route.path
    },
    trackAccountSwitcherOpened () {
      return ACCOUNT_SWITCHER_OPENED
    },
    trackHelpButtonClicked () {
      return HELP_BUTTON_CLICKED
    },
    trackReferralButtonClicked () {
      return REFERRAL_BUTTON_CLICKED
    },
    trackReferralProfileButtonClicked () {
      return MY_PROFILE_NAV_CLICKED
    },
    defaultHelpdeskLink () {
      return 'https://help.rewardsgenius.com/en/'
    },
    defaultReferralLink () {
      return 'https://info.tangocard.com/referral-program'
    },
    disclaimers () {
      return Disclaimers
    },
    currentHelpRedirectLink () {
      if (this.authenticatedSF) {
        return getRgFrontendHost() + '/api/salesforce/redirect'
      } else {
        const found = RedirectLookupTable.find((item) => item.path === this.activeRoute)
        return found ? found.href : this.defaultHelpdeskLink
      }
    },
    isTeamSettingsVisible () {
      return this.sidebarOpen && getParentRoute(this.activeRoute) === SettingsPath
    }
  },

  mounted () {
    this.$store.dispatch(PRINCIPLE_GET_ASYNC)
    if (this.$hasAuthority(AuthorityConstants.PLACE_ORDER_ACCESS)) {
      this.fetchActiveShoppingCart()
      this.fetchDraftOrder()
    }
  },

  methods: {
    ...mapActions('rewardFirstOrder', {
      fetchDraftOrder: FETCH_DRAFT_ORDER,
      fetchActiveShoppingCart: FETCH_ACTIVE_SHOPPING_CART
    }),
    showNotification (iconName) {
      if (iconName === 'menu-place-order') {
        const { hasPendingOrder } = this
        const pendingBulkOrder = !!localStorage.getItem('order-draft-file-upload-v2')
        return hasPendingOrder || pendingBulkOrder
      }
    },
    isActive (routePath, iconName) {
      if (iconName === 'menu-settings') {
        return getParentRoute(this.activeRoute) === SettingsPath
      }
      return routePath === getParentRoute(this.activeRoute)
    },
    toggleControls () {
      this.$emit('open-sidebar', !this.sidebarOpen)

      // keep track of if it's transitioning for the purpose of not changing the tooltip too soon
      this.menuTransitioning = true
      setTimeout(() => {
        this.menuTransitioning = false
      }, 500)
    },
    async logout () {
      const identityProviders = AuthenticationProvider.getIdentityProviders()
      if (!identityProviders) return

      const isSAML = identityProviders.some(identityProvider => identityProvider.toUpperCase() === AuthorityConstants.IDENTITY_PROVIDER.SAML)
      await this.$http.post('/api/users/logout')
      this.$segment.track(SIGN_OUT_CLICKED)
      AuthenticationProvider.clearTokens()
      const redirect = isSAML ? AuthorityConstants.SSO_LOGIN : AuthorityConstants.LOGIN
      this.$router.replace({ name: redirect })
    },
    navigate (routePath, trackEventName) {
      if (getParentRoute(routePath) === SettingsPath && !this.sidebarOpen) {
        this.toggleControls()
      }
      this.$router.push({ path: routePath })
      this.trackNavigation(trackEventName)
    },
    trackNavigation (trackEventName) {
      try {
        if (trackEventName) {
          this.$segment.track(trackEventName)
        }
      } catch (error) {
        console.error(`Error arose in segment ${trackEventName} event`, error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/variables";

#sidebar {
  .sidebar-menu {
    position: fixed;
    z-index: $zindex-iconav;
    width: $iconav-width-closed;
    transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    overflow: hidden;
    ::v-deep {
      // the sidebar padding needs 1 extra px here to account for the border on the right
      .v-list--nav {
        padding-right: 9px;
      }

      .v-list-item.v-list-item--link {
        padding: 0 11px;

        &.active {
          background-color: #e9e8e8;
          border-radius: 12px;
        }

        &::before {
          border-radius: 12px;
        }

        .v-ripple__container {
          border-radius: 12px;
        }

        .icon-badge {
          position: absolute;
          z-index: 1;
          left: 26px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #E51522;
          border: var(--t-border-size-1) solid var(--t-color-surface-raised);
        }

        &.active .icon-badge,
        &:hover .icon-badge {
          border: var(--t-border-size-1) solid #e9e8e8;
        }

        .v-icon {
          color: $iconav-brand-color !important;
        }

        .v-list-item__title {
          color: $iconav-brand-color !important;
          font-size: 14px;
        }

        .v-list-item__icon:first-child {
          margin-right: 14px;
        }
      }
    }
  }

  &.controls-open {
    .sidebar-menu {
      width: $iconav-width-open;
      left: 0;
    }

    &::before {
      content: "";
      backdrop-filter: blur(4px);
      transition: 250ms;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: $zindex-iconav;

      @include media-breakpoint-up(md) {
        display: none;
        overflow: hidden;

        &.emulating {
          top: 40px !important;
        }
      }

      &.controls-open {
        .sidebar-menu {
          width: $iconav-width-open;
          left: 0;
        }

        &::before {
          content: "";
          backdrop-filter: blur(4px);
          transition: 250ms;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: $zindex-iconav;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }

  // new styles
  .nav-icon-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 4px 13px 0;
  }

  .side-nav-popover {
    width: 200px;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;

    .v-divider {
      margin: 8px 0;
    }

    .v-list-item__content {
      color: var(--t-color-text) !important;
    }
  }

  ::v-deep .v-navigation-drawer {
    .v-navigation-drawer__content {
      display: flex;
      flex-direction: column;
      background-color: var(--t-color-surface-raised);
      height: calc(100% - 100px);
    }
    &.has-referral{
      .v-navigation-drawer__content {
        height: calc(100% - 144px);
      }
    }
  }

  .controls-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
  }

  // allow menus to set their own width
  .v-menu__content.v-menu__content--fixed {
    min-width: 0 !important;
    margin-left: 16px;
  }

  .account-nav{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    background-color: var(--t-color-surface-raised);
    border-right:1px solid var(--t-color-border-weak);

    .v-list-item__title{
      font-size: 14px;
      font-weight: 500;
      line-height: 1rem;
    }
  }
}

hr {
  margin-bottom: 0px;
  margin-top: 0px;
}

::v-deep .v-navigation-drawer__border {
  background-color: var(--t-color-border-weak) !important;
}
</style>
