import { ParseError, parsePhoneNumberWithError } from 'libphonenumber-js'
import carriers from '@/assets/mobileCarriers.json'

/**
 * @typedef {Object} PhoneNumberFormattedForDisplay
 * @property {string} original
 * @property {string|null} formatted
 * @property {boolean} hasError
 */

export class PhoneNumber {
  static FORMAT = 'E.164'

  constructor (props) {
    /** @property {string|null} countryCode **/
    this.countryCode = props?.countryCode || null
    /** @property {string|null} phoneNumber **/
    this.phoneNumber = props?.phoneNumber || null
  }

  /**
   * Parse a phone number
   * @param {string} [canonical]
   * @returns {PhoneNumber}
   */
  static parse (canonical) {
    let input = `${canonical || ''}`
    if (/^\d/.test(input)) {
      input = `+${input}`
    }

    if (!input) return new PhoneNumber()

    try {
      const phoneNumber = parsePhoneNumberWithError(input)
      return new PhoneNumber({
        countryCode: phoneNumber?.countryCallingCode,
        phoneNumber: phoneNumber?.nationalNumber
      })
    } catch (err) {
      console.error('Error parsing phone number', err)
      return new PhoneNumber()
    }
  }

  /**
   * Format a phone number for display
   * @param {string} input
   * @returns {PhoneNumberFormattedForDisplay|null}
   */
  static formatForDisplay (input) {
    if (!input && !/^0+$/.test(`${input}`)) return null
    const formatted = {
      original: input,
      formatted: null,
      hasError: false
    }
    try {
      const phoneNumber = /^\d/.test(`${input}`) ? `+${input}` : `${input}`
      formatted.formatted = parsePhoneNumberWithError(phoneNumber).formatInternational()
    } catch (error) {
      formatted.hasError = true
    }

    return formatted
  }

  /**
   * Format a phone number to be saved in the API
   * @returns {string}
   */
  format () {
    const countryCode = this.countryCode || '000000' // force INVALID_COUNTRY error if missing
    const phoneNumber = this.phoneNumber || ''

    // The library is stripping non-numeric characters from the phone number but not the country code.
    // Let's throw this error in the event that the number contains "non-typical" non-numeric characters
    // for the phone number portion, so we can provide feedback during user entry if needed.
    if (/[^\d()-.\s]/.test(phoneNumber)) {
      throw new ParseError('NOT_A_NUMBER')
    }

    return parsePhoneNumberWithError(`+${countryCode} ${phoneNumber}`).format(PhoneNumber.FORMAT)
  }

  /**
   * Determine if this phone number is equal to another
   * @param {PhoneNumber} compareTo
   * @returns {boolean}
   */
  isEqual (compareTo) {
    return compareTo instanceof PhoneNumber && `${compareTo.countryCode}` === `${this.countryCode}` && `${compareTo.phoneNumber}` === `${this.phoneNumber}`
  }
}

// initialize a Set of mms and sms domains to reference below
const carrierSet = new Set()
for (const entry of carriers) {
  const mms = getDomain(entry['email-to-mms'])
  if (mms !== null) carrierSet.add(mms)
  const sms = getDomain(entry['email-to-sms'])
  if (sms !== null) carrierSet.add(sms)
}

/**
 * Provided an email address, determine if it is an email to a carrier
 * - If so, return the formatted phone number
 * - Otherwise, return null
 * @param email
 * @returns {string|null}
 */
export function parsePhoneNumberFromEmail (email) {
  const domain = getDomain(email)
  if (carrierSet.has(domain)) {
    const [phone] = email.split('@')
    return phone.length === 10 ? `1${phone}` : phone
  }
  return null
}

/**
 * Return the domain for a provided email address
 * @param email
 * @returns {*|null}
 */
export function getDomain (email) {
  if (email && email.includes('@')) {
    const [, domain] = email.split('@')
    return domain
  }
  return null
}
