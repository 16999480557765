<template>
  <div class="select-payment-method-wrapper">
    <div v-if="hasPaymentSourcesOrFunding">
      <div
        v-if="onlyOnePaymentSource"
        class="mb-4"
      >
        <funding-method-option
          :funding-method="paymentSources[0]"
          @show-dialog="showDialog"
        />
      </div>
      <div v-else-if="lessThanSixPaymentSources">
        <funding-method-radio-group
          :selected-method="selectedMethod"
          :funding-methods="paymentSources"
          @selected-method="selectMethod"
          @show-dialog="showDialog"
        />
      </div>
      <div v-else>
        <funding-method-dropdown
          :selected-method="selectedMethod"
          :funding-methods="paymentSources"
          @selected-method="selectMethod"
        />
      </div>
    </div>
    <div
      v-else
      class="empty-state"
    >
      <span data-testid="no-payment-options">
        {{ noPaymentSourcesText }}
      </span>
    </div>

    <payment-method-details-add-payment-dropdown
      v-if="userCanAddFunds"
      :disabled="disableAddingPayments"
      @add-bank-account="$emit('add-bank-account')"
      @add-credit-card="$emit('add-credit-card')"
    />

    <ach-pending-dialog
      :show-pending-dialog="showPendingDialog"
      :currency-code="currencyCode"
      :total-pending="totalPending"
      :total-balance="totalBalance"
      :available-balance="availableBalance"
      @hide-dialog="hideDialog"
    />
  </div>
</template>

<script>
import FundingMethodDropdown from '@/components/shared/lists/FundingMethodDropdown'
import FundingMethodOption from '@/components/shared/lists/FundingMethodOption'
import FundingMethodRadioGroup from '@/components/shared/lists/FundingMethodRadioGroup'
import AchPendingDialog from './AchPendingDialog.vue'
import { LAST_USED_PAYMENT_SOURCE } from '@/components/shared/constants/localstorage.constants'
import { PaymentSourceType } from '@/components/platform/funding/FundingConstants'
import {
  QUERY_COUNT, QUERY_DIRECTION,
  QUERY_PREDICATE,
  USD_CURRENCY_CODE
} from '@/components/platform/funding/ach/constants'
import { statusPending } from '@/components/platform/funding/history/__constance__/FundingHistoryConstance'
import {
  FUNDING_ACCESS,
  FUNDING_MANAGE,
  VIEW_BALANCE_ACCESS
} from '@/components/shared/constants/authority.constants'
import PaymentMethodDetailsAddPaymentDropdown from '@/components/platform/orders/phys-order-flow/checkout/payment-method/PaymentMethodDetailsAddPaymentDropdown.vue'

export default {
  name: 'SelectPaymentMethod',

  components: {
    AchPendingDialog,
    FundingMethodDropdown,
    FundingMethodOption,
    FundingMethodRadioGroup,
    PaymentMethodDetailsAddPaymentDropdown
  },

  provide () {
    return {
      availableMessage: this.availableMessage
    }
  },

  props: {
    paymentSources: {
      type: Array,
      default: () => []
    },
    noPaymentSourcesText: {
      type: String,
      default: function () {
        return this.$t('portalfrontendApp.placeOrder.paymentMethod.selectPaymentMethod.noPaymentMethodsDefault')
      }
    },
    disableAddingPayments: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      i18nPrefix: 'portalfrontendApp.placeOrder.paymentMethod.selectPaymentMethod',
      selectedMethod: null,
      showPendingDialog: false,
      currencyCode: USD_CURRENCY_CODE,
      totalPending: '',
      availableBalance: '',
      totalBalance: '',
      availableMessage: {
        show: false
      }
    }
  },

  computed: {
    hasPaymentSources () {
      return this.paymentSources.length > 0
    },
    hasPaymentSourcesOrFunding () {
      return this.hasPaymentSources &&
        this.selectedMethod &&
        // if they only have 1 payment source and it's account balance with zero funds, we want to
        // show the empty state
        !this.onlyHasAccountBalanceWithNoFunds
    },
    lessThanSixPaymentSources () {
      return this.paymentSources.length < 6
    },
    onlyHasAccountBalanceWithNoFunds () {
      if (this.onlyOnePaymentSource && this.paymentSources[0].paymentSourceType === PaymentSourceType.ACCOUNT_FUNDS) {
        return this.paymentSources[0].accountSourceDTO.availableBalance === 0
      }
      return false
    },
    onlyOnePaymentSource () {
      return this.paymentSources.length === 1
    },
    userCanAddFunds () {
      return this.$hasAuthority(VIEW_BALANCE_ACCESS) && this.$hasAuthority(FUNDING_MANAGE)
    }
  },

  watch: {
    paymentSources () {
      if (!this.selectedMethod) {
        this.getPendingFunds()
        this.selectDefaultMethod()
      }
    }
  },

  mounted () {
    this.getPendingFunds()
    this.selectDefaultMethod()
  },

  methods: {
    selectDefaultMethod () {
      // no-op if there are no payment sources
      if (!this.hasPaymentSources) return

      const lastPaymentSource = localStorage.getItem(LAST_USED_PAYMENT_SOURCE)
      if (lastPaymentSource) {
        const lastMethod = this.paymentSources.find(method => method.paymentSourceIdentifier === lastPaymentSource)
        if (lastMethod) {
          this.selectMethod(lastMethod)
        }
      }
      if (!this.selectedMethod) {
        const method = this.paymentSources.find(m => m.default) ?? this.paymentSources[0]
        this.selectMethod(method)
      }
    },
    selectMethod (method) {
      this.selectedMethod = method
      this.$emit('payment-source-changed', method)
      if (method.paymentSourceIdentifier) {
        localStorage.setItem(LAST_USED_PAYMENT_SOURCE, method.paymentSourceIdentifier)
      }
    },
    hideDialog () {
      this.showPendingDialog = false
    },
    showDialog () {
      this.showPendingDialog = true
    },
    async getPendingFunds () {
      if (!this.hasPaymentSources || !this.$hasAuthority(FUNDING_ACCESS)) return

      const params = {
        path: this.$route.path,
        query: {
          page: 0,
          pageSize: QUERY_COUNT,
          predicate: QUERY_PREDICATE,
          sortDirection: QUERY_DIRECTION
        }
      }
      let totalPending = 0
      let availableBalance = 0

      try {
        const resp = await this.$http.get('api/funding', { params })
        if (resp?.data) {
          resp.data.forEach((item) => {
            if (item.status === statusPending) {
              totalPending += Number(item.amount)
              if (this.currencyCode !== item.currencyCode) {
                this.currencyCode = item.currencyCode
              }
            }
          })
          this.paymentSources.forEach((method) => {
            if (method.paymentSourceType === PaymentSourceType.ACCOUNT_FUNDS && totalPending) {
              availableBalance += Number(method?.accountSourceDTO?.availableBalance)
              // Added to show toggle button text.
              this.availableMessage.show = true
            }
          })
        }
      } catch (error) {
        console.log('Unable to get funding transactions information', error)
      }
      this.totalPending = this.$moneyIntl(totalPending, this.currencyCode)
      this.availableBalance = this.$moneyIntl(availableBalance, this.currencyCode)
      this.totalBalance = this.$moneyIntl((totalPending + availableBalance), this.currencyCode)
    }
  }
}
</script>

<style scoped lang="scss">
.select-payment-method-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.empty-state {
  background-color: var(--t-color-background-raised);
  border-radius: var(--t-border-size-4);
  color: var(--t-color-text-weak);
  padding: 2rem;
  text-align: center;
}
</style>
