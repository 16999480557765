import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const state = {
  html: '',
  type: '',
  duration: 0,
  variant: ''
}

const getters = {
  [getTypes.TOAST_GET_HTML]: state => state.html,
  [getTypes.TOAST_GET_TYPE]: state => state.type,
  [getTypes.TOAST_GET_DURATION]: state => state.duration,
  [getTypes.TOAST_GET_VARIANT]: state => state.variant
}

const mutations = {
  [mutationTypes.TOAST_SET_HTML] (state, html) {
    state.html = html
  },

  [mutationTypes.TOAST_SET_TYPE] (state, type) {
    state.type = type
  },

  [mutationTypes.TOAST_SET_DURATION] (state, duration) {
    state.duration = duration
  },

  [mutationTypes.TOAST_SET_VARIANT] (state, variant) {
    state.variant = variant
  }
}

const actions = {
  [actionTypes.TOAST] (context, { html, type, duration, variant }) {
    context.commit(mutationTypes.TOAST_SET_HTML, html)
    context.commit(mutationTypes.TOAST_SET_TYPE, type || 'success')
    context.commit(mutationTypes.TOAST_SET_DURATION, duration || 6000)
    context.commit(mutationTypes.TOAST_SET_VARIANT, variant || 'normal')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
