/* global $ */

// Define popup template.
$.extend($.FroalaEditor.POPUP_TEMPLATES, {
  'mtPlugin.popup': '[_CUSTOM_LAYER_]'
})

// The custom popup is defined inside a plugin (new or existing).
$.FroalaEditor.PLUGINS.mtPlugin = function (editor) {
  // Create custom popup.
  function initPopup () {
    // Load popup template.
    const template = {
      custom_layer: buildTagTemplate(editor.opts.mTags)
    }

    // Create popup.
    const $popup = editor.popups.create('mtPlugin.popup', template)
    $('body').off('click').on('click', '.tango-fr-plugin-list li', function ($event) {
      editor.html.insert($($event.target).text())
      $(this).parent().parent().removeClass('fr-active')
    })

    return $popup
  }

  // Show the popup
  function showPopup () {
    // Get the popup object defined above.
    let $popup = editor.popups.get('mtPlugin.popup')

    // If popup doesn't exist then create it.
    // To improve performance it is best to create the popup when it is first needed
    // and not when the editor is initialized.
    if (!$popup) $popup = initPopup()

    // Set the editor toolbar as the popup's container.
    editor.popups.setContainer('mtPlugin.popup', editor.$tb)

    // This will trigger the refresh event assigned to the popup.
    // editor.popups.refresh('mtPlugin.popup')

    // This custom popup is opened by pressing a button from the editor's toolbar.
    // Get the button's object in order to place the popup relative to it.
    const $btn = editor.$tb.find('.fr-command[data-cmd="mtButton"]')

    // Set the popup's position.
    const left = $btn.offset().left + $btn.outerWidth() / 2
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10)

    // Show the custom popup.
    // The button's outerHeight is required in case the popup needs to be displayed above it.
    editor.popups.show('mtPlugin.popup', left, top, $btn.outerHeight())
  }

  // Hide the custom popup.
  function hidePopup () {
    editor.popups.hide('mtPlugin.popup')
  }

  function buildTagTemplate (tags) {
    let template = '<ul class="tango-fr-plugin-list">'
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i]
      const isRewardNameSubject = tag === 'reward_name'
      template += isRewardNameSubject ? '<li>{{{reward_name}}}</li>' : '<li>{{' + tag + '}}</li>'
    }
    template += '</ul>'
    return template
  }

  // Methods visible outside the plugin.
  return {
    showPopup,
    hidePopup
  }
}

// Define an icon and command for the button that opens the custom popup.
$.FroalaEditor.DefineIconTemplate('mtIconTemplate', 'Insert dynamic tag<i class="tci tci-caret-down d-inline-block" aria-hidden="true"></i>')
$.FroalaEditor.DefineIcon('mtIcon', { NAME: 'list-alt', template: 'mtIconTemplate' })
$.FroalaEditor.RegisterCommand('mtButton', {
  title: 'Insert Dynamic Tag',
  icon: 'mtIcon',
  undo: false,
  focus: false,
  plugin: 'mtPlugin',
  callback: function () {
    this.mtPlugin.showPopup()
  }
})
