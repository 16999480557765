<template>
  <div>
    <div
      v-if="showCustomNoResultsContent"
      class="h-100"
    >
      <slot name="no-results" />
    </div>
    <table
      v-else
      :id="'server-table-' + id"
      class="table"
      :class="{'hover-effect': usingNewLayout && hoverEffect}"
    >
      <thead class="fs-unmask">
        <tr>
          <th
            v-for="c in columns"
            :id="`${id}-col-${c.name}`"
            :key="c.name"
            :class="{header: true, 'cursor-default': !columnSortable(c)}"
            :width="c.width || ''"
          >
            <div
              v-if="columnSortable(c)"
              class="sort"
              @click="toggleSort(c)"
            >
              <span class="table-heading-name">{{ $t(c.displayKey) }}</span>
              <span
                class="sort-arrow"
                :class="sortClassFor(c)"
              />
            </div>
            <span v-else>
              <span>{{ $t(c.displayKey) }}</span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-if="rows.length > 0">
        <slot
          v-for="(row, index) in rows"
          :id="id"
          name="row"
          :row="row"
          :rows="rows"
          :index="index"
          :columns="columns"
        >
          <tr
            :id="id + '-row-' + index"
            :key="index"
            class="table-name-row"
          >
            <td
              v-for="(col, cindex) in columns"
              :id="`${id}-row-${index}-col-${cindex}`"
              :key="col.name"
            >
              {{ col.name ? row[col.name] : '' }}
            </td>
          </tr>
        </slot>
      </tbody>
      <tbody v-else>
        <slot
          :id="id"
          name="empty-rows"
          :rows="rows"
        >
          <tr
            :id="`${id}-row-0`"
            :key="'row-0'"
          >
            <td
              width="100%"
              colspan="3"
              class="table-name-cell"
            >
              <div class="stacked-row">
                <p
                  v-if="emptyDataMessage"
                  :id="'template-display-name-0'"
                  class="table-name-display"
                >
                  {{ emptyDataMessage }}
                </p>
              </div>
            </td>
          </tr>
        </slot>
      </tbody>
    </table>
    <loading
      v-if="showDefaultLoading"
      visible
    />
    <div
      v-else-if="!showCustomNoResultsContent"
      class="info item-count text-center fs-unmask"
    >
      <span id="item-count">{{ $t('global.item-count', itemCountData) }}</span>
    </div>
    <!-- Use totalRows as a key here to force render of b-pagination when this changes -->
    <b-pagination
      v-if="(hasError || !showCustomNoResultsContent) && totalRows > pageSize"
      :key="`${id}-pagination-${totalRows}`"
      :value="page"
      class="fs-unmask"
      size="md"
      :total-rows="totalRows"
      :per-page="pageSize"
      :disabled="isLoading"
      @change="changePage"
    />
  </div>
</template>

<script>
import { RELOAD_SERVER_TABLE, RELOADED_SERVER_TABLE, SERVER_TABLE_EMIT, RESULTS_EXCEDE_MAX_ALLOWED, ERROR_RETRIEVING_RESULTS, MAX_ALLOWED_PAGE_REACHED } from './server-table-events'
import { mapGetters } from 'vuex'
import { APP_GET_USING_NEW_LAYOUT } from '../../../store/get-types'
import Loading from '@/components/shared/Loading'

export default {
  name: 'ServerTable',
  components: { Loading },
  props: {
    url: {
      type: String,
      required: false,
      default: undefined
    },
    columns: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    hoverEffect: {
      type: Boolean,
      required: false,
      default: false
    },
    useCustomNoResults: {
      type: Boolean,
      required: false,
      default: false
    },
    disableDefaultSort: {
      type: Boolean,
      required: false,
      default: false
    },
    mockedData: {
      type: [Function, Object],
      required: false,
      default: () => {}
    },
    emptyDataMessage: {
      type: String,
      required: false,
      default: null
    },
    useDefaultLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      rows: [],
      totalRows: 0,
      page: 0,
      pageSize: 0,
      predicate: '',
      sortDirection: '',
      additionalQueryParameters: {},
      hasAlreadyLoadedRows: false,
      hasError: false,
      totalExceedsMaxAllowed: false,
      isLoading: true
    }
  },
  computed: {
    itemCountData () {
      const startIndex = ((this.page - 1) * this.pageSize) + 1
      return {
        first: this.totalRows ? startIndex : 0,
        second: startIndex + this.rows.length - 1,
        total: this.totalRows
      }
    },
    ...mapGetters({
      usingNewLayout: APP_GET_USING_NEW_LAYOUT
    }),
    showCustomNoResultsContent () {
      return this.useCustomNoResults && this.hasAlreadyLoadedRows && this.rows && Array.isArray(this.rows) && this.rows.length === 0
    },
    showDefaultLoading () {
      return !this.hasAlreadyLoadedRows && this.useDefaultLoading
    },
    maxPage () {
      return (this.totalRows && this.pageSize) ? this.totalRows / this.pageSize : 0
    },
    maxPageReached () {
      return this.totalExceedsMaxAllowed && this.page === this.maxPage
    }
  },
  watch: {
    options: function () {
      this.additionalQueryParameters = {}
      const params = this.options.additionalQueryParameters
      if (Object.keys(params).length > 0) {
        this.page = 1
        Object.keys(params).forEach((v) => {
          this.additionalQueryParameters[v] = params[v]
        })
      }
      this.invalidate()
    }
  },
  mounted () {
    console.assert(!!this.url, 'Resource url is a required property of a server table.')
    console.assert(!!this.columns, 'Column definitions are a required property of a server table.')
    console.assert(!!this.id, 'Table id is a required property of a server table.')

    this.$bus.$on(RELOAD_SERVER_TABLE, this.invalidate)

    const options = this.options || {}
    const defaultSortColumn = this.determineDefaultSort(this.columns)
    const defaultSortColumnName = defaultSortColumn ? defaultSortColumn.name : null
    this.page = parseInt(this.$route.query.page || 1, 10)
    this.pageSize = parseInt(this.$route.query.pageSize || 0, 10) || options.pageSize || 20
    this.predicate = this.$route.query.predicate || options.predicate || defaultSortColumnName
    this.sortDirection = this.$route.query.sortDirection || options.sortDirection || 'desc'
    this.additionalQueryParameters = options.additionalQueryParameters || {}

    this.invalidate()
  },
  destroyed () {
    this.$bus.$off(RELOAD_SERVER_TABLE, this.invalidate)
  },
  methods: {
    determineDefaultSort (columns) {
      if (!this.disableDefaultSort) {
        return columns.find((c) => typeof c.sortable === 'undefined' || c.sortable)
      } else {
        return null
      }
    },
    changePage (page) {
      this.page = page
      this.invalidate()
      if (this.maxPageReached) {
        this.$emit(MAX_ALLOWED_PAGE_REACHED, this.totalRows)
      }
    },
    toggleSort (column) {
      if (this.isLoading) return // don't let the user spam the sort buttons, each time is a call to core
      if (this.predicate === column.name) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.predicate = column.name
        this.sortDirection = 'asc'
      }

      this.page = 1
      this.invalidate()
    },
    columnSortable (c) {
      return c.name && (c.sortable || typeof c.sortable === 'undefined')
    },
    sortClassFor (column) {
      return {
        tci: (typeof column.sortable === 'undefined' || column.sortable),
        'tci-sort': column.name !== this.predicate,
        'tci-sort-asc': column.name === this.predicate && this.sortDirection === 'asc',
        'tci-sort-desc': column.name === this.predicate && this.sortDirection === 'desc'
      }
    },
    afterThen (resp) {
      console.assert(!!resp.headers['x-total-count'], 'Server responses for tabular data must include a \'x-total-count\' header')
      let totalCount = parseInt(resp.headers['x-total-count'], 10)
      const maxAllowedHeader = resp.headers['x-max-allowed-results']
      const maxAllowed = parseInt(maxAllowedHeader, 10)

      if (maxAllowedHeader && totalCount > maxAllowed) {
        totalCount = maxAllowed
        this.totalExceedsMaxAllowed = true
        this.$emit(RESULTS_EXCEDE_MAX_ALLOWED, maxAllowed)
      }

      this.totalRows = totalCount
      this.rows = resp.data
      this.page = parseInt(this.$route.query.page, 10)
      this.$bus.$emit(RELOADED_SERVER_TABLE)
      // Going forward, consume this emit in parent components instead of the bus
      this.$emit(SERVER_TABLE_EMIT)
      this.hasAlreadyLoadedRows = true
      this.isLoading = false
    },
    catchError (error) {
      const { response: { data: { description } = {} } = {} } = error
      console.error('Error retrieving server table results:', error)
      this.$toast(description, 'danger')
      this.rows = []
      this.hasError = true
      this.$emit(ERROR_RETRIEVING_RESULTS)
      this.isLoading = false
    },
    mockedPath () {
      return new Promise((resolve) => {
        return resolve(this.mockedData)
      }).then((resp) => { this.afterThen(resp) })
        .catch((error) => { this.catchError(error) })
    },
    livePath (params) {
      this.$http
        .get(this.url, { params })
        .then((resp) => { this.afterThen(resp) })
        .catch((error) => { this.catchError(error) })
    },
    invalidate () {
      this.hasError = false
      this.isLoading = true
      this.$router.replace({
        path: this.$route.path,
        query: {
          page: this.page,
          pageSize: this.pageSize,
          predicate: this.predicate,
          sortDirection: this.sortDirection
        }
      })
      const params = Object.assign({
        page: this.page - 1,
        size: this.pageSize
      }, this.additionalQueryParameters)

      if (this.predicate) params.sort = `${this.predicate},${this.sortDirection}`

      return typeof this.url === 'undefined' ? this.mockedPath() : this.livePath(params)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/variables';
  @import '~@/assets/scss/mixins';

  .infinite-scroll {
    overflow: scroll;
  }

  table {
    table-layout: fixed;
    @include gotham-book;

    span.tci {
      line-height: 21px;
      height: 21px;
    }

    .header {
      font-weight: 500;
    }
  }

  .sort-arrow {
    margin-left: 16px;
    font-size: 18px;
  }

  table.hover-effect{
    th {
      border-top: 0;
      @include gotham-medium;
    }
    tbody{
      &:hover {
        background: var(--t-color-surface);
      }
      tr {
        height: 62px;

        &:hover {
          background: var(--t-color-surface-raised);
        }
      }
    }
  }

  .block {
    display: block;
  }
  .date-display {
    margin: 0;
  }
  .server-table {
    color: var(--t-color-text);

    .table-name-row {

      &:nth-child(even) {
        background: var(--t-color-surface);
      }
    }

    .table-name-cell {
      padding: 0;

      .table-name-image {
        display: none;
      }

      &.has-image {

        .table-name-image {
          display: block;
          float: left;
        }

        .stacked-row {
          margin-left: 80px;

          .portal-pill {
            display: inline-block;
            border: 1px solid var(--t-color-border);
            padding: 2px 16px;
            margin-top: 4px;
            border-radius: 16px;
            transition: all 225ms linear;
            box-shadow: none;
            background: var(--t-color-surface);

            &:hover {
              background: var(--t-color-status-info);
              color: var(--t-color-text-inverted);
              border: 1px solid var(--t-color-border);
              transition: all 225ms linear;
              box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.15);
            }
          }
        }
      }
    }

    .table-name-display {
      display: block;
      color: $secondary-text-color;
      font-size: 16px;
      @include gotham-medium();
      margin: 0;
    }

    .stacked-row {
      display: block;
      text-align: left;
      padding: 8px 0;

      .portal-pill {
        display: block;
      }

    }

    .action-group {
      position: relative;

      .action-group-container {
        display: flex;
        flex-wrap: wrap;

        button {
          height: 36px;
        }
      }

      .action-button {
        min-width: 88px;
        margin: 0 2px;
        padding: 5px 24px;
        color: #333D46;
        transition: color 275ms linear;

        &:not(:before) {
          @include gotham-medium();
        }

        &:hover {
          color: #54C7DD;
          transition: color 275ms linear;
        }

        &.icon {
          &:before {
            left: -16px;
          }
        }

        &.flat-variant {
          border: 0;
        }
      }
    }
  }
</style>
