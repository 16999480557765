<template>
  <form>
    <v-select
      id="funding-method-dropdown"
      :value="selectedMethod"
      :items="fundingMethods"
      dense
      hide-details
      outlined
      :item-text="(fundingMethod) => `${fundingMethod.displayName}`"
      :item-value="getFundingMethodId"
      return-object
      @change="$emit('selected-method', $event)"
    >
      <template #selection="{ item }">
        <funding-method-option
          is-dropdown
          :funding-method="item"
        />
      </template>
      <template #item="{ item }">
        <funding-method-option
          is-dropdown
          :funding-method="item"
        />
      </template>
    </v-select>
  </form>
</template>

<script>
import FundingMethodOption from '@/components/shared/lists/FundingMethodOption'

export default {
  name: 'FundingMethodDropdown',

  components: { FundingMethodOption },

  props: {
    selectedMethod: {
      type: Object,
      required: true
    },
    fundingMethods: {
      type: Array,
      required: true
    }
  },

  methods: {
    getFundingMethodId (fundingMethod) {
      // Find the unique identifier for the given funding method.
      //   All payment sources from the /payment-sources endpoint use "paymentSourceIdentifier"
      //   ACH accounts from api/ach/subscriptions use "identifier"
      //   Credit cards from api/creditCards use "token"
      // If we ever update the FundingDialog to use the payment-sources endpoint, this can be removed
      return fundingMethod.paymentSourceIdentifier ?? fundingMethod.identifier ?? fundingMethod.token
    }
  }
}
</script>
