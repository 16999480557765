<template>
  <div>
    <v-list-item
      class="account-switcher"
      link
      @click="isAccountSwitchDialogOpen = true"
    >
      <v-tooltip
        max-width="19rem"
        right
        :disabled="sidebarOpen"
      >
        <template #activator="{ on, attrs }">
          <v-list-item-icon>
            <img
              v-bind="attrs"
              src="@/assets/images/tango-smile.svg"
              alt="tango-smile"
              v-on="on"
            >
          </v-list-item-icon>
        </template>
        {{ $t('portalfrontendApp.coreFunding.switchAccountsButton.tooltip') }}
      </v-tooltip>
      <div class="titles">
        <v-list-item-title>
          {{ selectedAccount?.accountName }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="showAccountBalance">
          {{ selectedAccountBalance }}
        </v-list-item-subtitle>
      </div>
    </v-list-item>

    <account-switcher-dialog
      v-if="isAccountSwitchDialogOpen"
      :show-dialog="isAccountSwitchDialogOpen"
      @close-dialog="isAccountSwitchDialogOpen = false"
      @open-funding-dialog="openFundingDialogForAccount"
    />
    <add-funds-dialog
      :show-dialog="isFundingDialogOpen"
      :selected-customer-account="fundingDialogAccount"
      :account-balance="fundingDialogAccountBalance"
      @close-dialog="handleCloseFundingDialog"
      @open-dialog="openFundingDialogForAccount"
      @left-btn-action-event="isFundingDialogOpen = false"
    />
    <ach-request-messaging /> <!-- Temporary until we remove ACH redirect -->
  </div>
</template>

<script>
import AccountSwitcherDialog from '@/components/sidebar/account-switcher/AccountSwitcherDialog.vue'
import AddFundsDialog from '@/components/platform/funding/add-funds-dialog/AddFundsDialog.vue'
import { GET_SELECTED_CUSTOMER_ACCOUNT } from '@/store/get-types'
import { mapGetters } from 'vuex'
import AchRequestMessaging
  from '@/components/platform/orders/phys-order-flow/checkout/payment-method/AchRequestMessaging'
import { VIEW_BALANCE_ACCESS } from '@/components/shared/constants/authority.constants'

export default {
  name: 'AccountSwitcherDialogButton',
  components: {
    AchRequestMessaging,
    AccountSwitcherDialog,
    AddFundsDialog
  },
  props: {
    sidebarOpen: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isAccountSwitchDialogOpen: false,
      isFundingDialogOpen: false,
      fundingDialogAccountBalance: '',
      fundingDialogAccount: {}
    }
  },
  computed: {
    ...mapGetters({
      selectedAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    selectedAccountBalance () {
      return this.accountBalance(this.selectedAccount)
    },
    showAccountBalance () {
      return this.$hasAuthority(VIEW_BALANCE_ACCESS)
    }
  },
  methods: {
    accountBalance (account) {
      // availableBalance isn't present on this object if user doesn't have VIEW_BALANCE_ACCESS
      if (!this.showAccountBalance) {
        return ''
      }

      const { currencyCode, availableBalance } = account
      return this.$moneyIntl(availableBalance, currencyCode)
    },
    openFundingDialogForAccount (account) {
      // Funding dialog expects this data
      account.accountIdentifier = account.accountIdentifier || account.identifier
      account.accountName = account.accountName || account.displayName
      this.fundingDialogAccountBalance = this.accountBalance(account)
      this.fundingDialogAccount = account
      this.isAccountSwitchDialogOpen = false
      this.isFundingDialogOpen = true
    },
    handleCloseFundingDialog () {
      this.isFundingDialogOpen = false
      this.isAccountSwitchDialogOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
.account-switcher {
  border: 1px solid var(--t-color-border);
  border-radius: 12px !important;
  margin-top: 4px;
  margin-bottom: 8px !important;
  transition: 300ms;

  .v-list-item__icon:first-child {
    margin-right: 14px;
  }

  img {
    width: 22px;
  }
}

.titles {
  display: none;
}

.controls-open {
  .account-switcher {
    margin-top: 0;
    margin-bottom: 4px !important;
    padding: 4px 11px !important; // override the super specific sidebar styles

    .titles {
      display: initial;
      width: calc(100% - 36px);
    }
  }
}
</style>
