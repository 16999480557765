export const CountryCodes = Object.freeze({
  US: 'US',
  CA: 'CA'
})

export const countryCodeList = [
  {
    country: 'United States of America (+1)',
    iso: CountryCodes.US.toLowerCase(),
    code: '1'
  },
  {
    country: 'Canada (+1)',
    iso: 'ca',
    code: '1'
  },
  {
    country: 'Afghanistan (+93)',
    iso: 'af',
    code: '93'
  },
  {
    country: 'Albania (+355)',
    iso: 'al',
    code: '355'
  },
  {
    country: 'Algeria (+213)',
    iso: 'dz',
    code: '213'
  },
  {
    country: 'Andorra (+376)',
    iso: 'ad',
    code: '376'
  },
  {
    country: 'Angola (+244)',
    iso: 'ao',
    code: '244'
  },
  {
    country: 'Anguilla (+1264)',
    iso: 'ai',
    code: '1264'
  },
  {
    country: 'Antigua and Barbuda (+1268)',
    iso: 'ag',
    code: '1268'
  },
  {
    country: 'Argentina (+54)',
    iso: 'ar',
    code: '54'
  },
  {
    country: 'Armenia (+374)',
    iso: 'am',
    code: '374'
  },
  {
    country: 'Aruba (+297)',
    iso: 'aw',
    code: '297'
  },
  {
    country: 'Australia (+61)',
    iso: 'au',
    code: '61'
  },
  {
    country: 'Austria (+43)',
    iso: 'at',
    code: '43'
  },
  {
    country: 'Azerbaijan (+994)',
    iso: 'az',
    code: '994'
  },
  {
    country: 'Bahamas (+1242)',
    iso: 'bs',
    code: '1242'
  },
  {
    country: 'Bahrain (+973)',
    iso: 'bh',
    code: '973'
  },
  {
    country: 'Bangladesh (+880)',
    iso: 'bd',
    code: '880'
  },
  {
    country: 'Barbados (+1246)',
    iso: 'bb',
    code: '1246'
  },
  {
    country: 'Belarus (+375)',
    iso: 'by',
    code: '375'
  },
  {
    country: 'Belgium (+32)',
    iso: 'be',
    code: '32'
  },
  {
    country: 'Belize (+501)',
    iso: 'bz',
    code: '501'
  },
  {
    country: 'Benin (+229)',
    iso: 'bj',
    code: '229'
  },
  {
    country: 'Bermuda (+1441)',
    iso: 'bm',
    code: '1441'
  },
  {
    country: 'Bhutan (+975)',
    iso: 'bt',
    code: '975'
  },
  {
    country: 'Bolivia (+591)',
    iso: 'bo',
    code: '591'
  },
  {
    country: 'Bosnia-Herzegovina (+387)',
    iso: 'ba',
    code: '387'
  },
  {
    country: 'Botswana (+267)',
    iso: 'bw',
    code: '267'
  },
  {
    country: 'Brazil (+55)',
    iso: 'br',
    code: '55'
  },
  {
    country: 'Brunei (+673)',
    iso: 'bn',
    code: '673'
  },
  {
    country: 'Bulgaria (+359)',
    iso: 'bg',
    code: '359'
  },
  {
    country: 'Burkina Faso (+226)',
    iso: 'bf',
    code: '226'
  },
  {
    country: 'Burundi (+257)',
    iso: 'bi',
    code: '257'
  },
  {
    country: 'Cambodia (+855)',
    iso: 'kh',
    code: '855'
  },
  {
    country: 'Cameroon (+237)',
    iso: 'cm',
    code: '237'
  },
  {
    country: 'Cape Verde Islands (+238)',
    iso: 'cv',
    code: '238'
  },
  {
    country: 'Cayman Islands (+1345)',
    iso: 'ky',
    code: '1345'
  },
  {
    country: 'Central African Republic (+236)',
    iso: 'cf',
    code: '236'
  },
  {
    country: 'Chad (+235)',
    iso: 'td',
    code: '235'
  },
  {
    country: 'Chile (+56)',
    iso: 'cl',
    code: '56'
  },
  {
    country: 'China (+86)',
    iso: 'cn',
    code: '86'
  },
  {
    country: 'Colombia (+57)',
    iso: 'co',
    code: '57'
  },
  {
    country: 'Congo (+242)',
    iso: 'cg',
    code: '242'
  },
  {
    country: 'Congo, Democratic Republic (+243)',
    iso: 'cd',
    code: '243'
  },
  {
    country: 'Cook Islands (+682)',
    iso: 'ck',
    code: '682'
  },
  {
    country: 'Costa Rica (+506)',
    iso: 'cr',
    code: '506'
  },
  {
    country: 'Croatia (+385)',
    iso: 'hr',
    code: '385'
  },
  {
    country: 'Cuba (+53)',
    iso: 'cu',
    code: '53'
  },
  {
    country: 'Cyprus (+357)',
    iso: 'cy',
    code: '357'
  },
  {
    country: 'Czech Republic (+420)',
    iso: 'cz',
    code: '420'
  },
  {
    country: 'Denmark (+45)',
    iso: 'dk',
    code: '45'
  },
  {
    country: 'Djibouti (+253)',
    iso: 'dj',
    code: '253'
  },
  {
    country: 'Dominica (+1767)',
    iso: 'dm',
    code: '1767'
  },
  {
    country: 'Dominican Republic (+1809)',
    iso: 'do',
    code: '1809'
  },
  {
    country: 'Dominican Republic2 (+1829)',
    iso: 'do',
    code: '1829'
  },
  {
    country: 'Dominican Republic3 (+1849)',
    iso: 'do',
    code: '1849'
  },
  {
    country: 'Ecuador (+593)',
    iso: 'ec',
    code: '593'
  },
  {
    country: 'Egypt (+20)',
    iso: 'eg',
    code: '20'
  },
  {
    country: 'El Salvador (+503)',
    iso: 'sv',
    code: '503'
  },
  {
    country: 'Estonia (+372)',
    iso: 'ee',
    code: '372'
  },
  {
    country: 'Ethiopia (+251)',
    iso: 'et',
    code: '251'
  },
  {
    country: 'Falkland Islands (+500)',
    iso: 'fk',
    code: '500'
  },
  {
    country: 'Faroe Islands (+298)',
    iso: 'fo',
    code: '298'
  },
  {
    country: 'Fiji Islands (+679)',
    iso: 'fj',
    code: '679'
  },
  {
    country: 'Finland (+358)',
    iso: 'fi',
    code: '358'
  },
  {
    country: 'France (+33)',
    iso: 'fr',
    code: '33'
  },
  {
    country: 'French Guiana (+594)',
    iso: 'gf',
    code: '594'
  },
  {
    country: 'French Polynesia (+689)',
    iso: 'pf',
    code: '689'
  },
  {
    country: 'Gabon (+241)',
    iso: 'ga',
    code: '241'
  },
  {
    country: 'Gambia (+220)',
    iso: 'gm',
    code: '220'
  },
  {
    country: 'Georgia (+995)',
    iso: 'ge',
    code: '995'
  },
  {
    country: 'Germany (+49)',
    iso: 'de',
    code: '49'
  },
  {
    country: 'Ghana (+233)',
    iso: 'gh',
    code: '233'
  },
  {
    country: 'Gibraltar (+350)',
    iso: 'gi',
    code: '350'
  },
  {
    country: 'Greece (+30)',
    iso: 'gr',
    code: '30'
  },
  {
    country: 'Greenland (+299)',
    iso: 'gl',
    code: '299'
  },
  {
    country: 'Grenada (+1473)',
    iso: 'gd',
    code: '1473'
  },
  {
    country: 'Guadeloupe (+590)',
    iso: 'gp',
    code: '590'
  },
  {
    country: 'Guam (+1671)',
    iso: 'gu',
    code: '1671'
  },
  {
    country: 'Guatemala (+502)',
    iso: 'gt',
    code: '502'
  },
  {
    country: 'Guernsey (+44)',
    iso: 'gg',
    code: '44'
  },
  {
    country: 'Guinea (+224)',
    iso: 'gn',
    code: '224'
  },
  {
    country: 'Guinea-Bissau (+245)',
    iso: 'gw',
    code: '245'
  },
  {
    country: 'Guyana (+592)',
    iso: 'gy',
    code: '592'
  },
  {
    country: 'Haiti (+509)',
    iso: 'ht',
    code: '509'
  },
  {
    country: 'Honduras (+504)',
    iso: 'hn',
    code: '504'
  },
  {
    country: 'Hong Kong (+852)',
    iso: 'hk',
    code: '852'
  },
  {
    country: 'Hungary (+36)',
    iso: 'hu',
    code: '36'
  },
  {
    country: 'Iceland (+354)',
    iso: 'is',
    code: '354'
  },
  {
    country: 'India (+91)',
    iso: 'in',
    code: '91'
  },
  {
    country: 'Indonesia (+62)',
    iso: 'id',
    code: '62'
  },
  {
    country: 'International (+882)',
    iso: '',
    code: '882'
  },
  {
    country: 'Iran (+98)',
    iso: 'ir',
    code: '98'
  },
  {
    country: 'Iraq (+964)',
    iso: 'iq',
    code: '964'
  },
  {
    country: 'Ireland (+353)',
    iso: 'ie',
    code: '353'
  },
  {
    country: 'Isle of Man (+44)',
    iso: 'im',
    code: '44'
  },
  {
    country: 'Israel (+972)',
    iso: 'il',
    code: '972'
  },
  {
    country: 'Italy (+39)',
    iso: 'it',
    code: '39'
  },
  {
    country: 'Ivory Coast (+225)',
    iso: 'ci',
    code: '225'
  },
  {
    country: 'Jamaica (+1876)',
    iso: 'jm',
    code: '1876'
  },
  {
    country: 'Japan (+81)',
    iso: 'jp',
    code: '81'
  },
  {
    country: 'Jersey (+44)',
    iso: 'je',
    code: '44'
  },
  {
    country: 'Jordan (+962)',
    iso: 'jo',
    code: '962'
  },
  {
    country: 'Kazakhstan (+7)',
    iso: 'kz',
    code: '7'
  },
  {
    country: 'Kenya (+254)',
    iso: 'ke',
    code: '254'
  },
  {
    country: 'Korea (+North) (+850)',
    iso: 'kp',
    code: '850'
  },
  {
    country: 'Korea (+South) (+82)',
    iso: 'kr',
    code: '82'
  },
  {
    country: 'Kuwait (+965)',
    iso: 'kw',
    code: '965'
  },
  {
    country: 'Kyrgyzstan (+996)',
    iso: 'kg',
    code: '996'
  },
  {
    country: 'Laos (+856)',
    iso: 'la',
    code: '856'
  },
  {
    country: 'Latvia (+371)',
    iso: 'lv',
    code: '371'
  },
  {
    country: 'Lebanon (+961)',
    iso: 'lb',
    code: '961'
  },
  {
    country: 'Lesotho (+266)',
    iso: 'ls',
    code: '266'
  },
  {
    country: 'Liberia (+231)',
    iso: 'lr',
    code: '231'
  },
  {
    country: 'Libya (+218)',
    iso: 'ly',
    code: '218'
  },
  {
    country: 'Liechtenstein (+423)',
    iso: 'li',
    code: '423'
  },
  {
    country: 'Lithuania (+370)',
    iso: 'lt',
    code: '370'
  },
  {
    country: 'Luxembourg (+352)',
    iso: 'lu',
    code: '352'
  },
  {
    country: 'Macao (+853)',
    iso: 'mo',
    code: '853'
  },
  {
    country: 'Macedonia (+389)',
    iso: 'mk',
    code: '389'
  },
  {
    country: 'Madagascar (+261)',
    iso: 'mg',
    code: '261'
  },
  {
    country: 'Malawi (+265)',
    iso: 'mw',
    code: '265'
  },
  {
    country: 'Malaysia (+60)',
    iso: 'my',
    code: '60'
  },
  {
    country: 'Maldives (+960)',
    iso: 'mv',
    code: '960'
  },
  {
    country: 'Mali Republic (+223)',
    iso: 'ml',
    code: '223'
  },
  {
    country: 'Malta (+356)',
    iso: 'mt',
    code: '356'
  },
  {
    country: 'Martinique (+596)',
    iso: 'mq',
    code: '596'
  },
  {
    country: 'Mauritania (+222)',
    iso: 'mr',
    code: '222'
  },
  {
    country: 'Mauritius (+230)',
    iso: 'mu',
    code: '230'
  },
  {
    country: 'Mayotte Island (+269)',
    iso: 'yt',
    code: '269'
  },
  {
    country: 'Mexico (+52)',
    iso: 'mx',
    code: '52'
  },
  {
    country: 'Moldova (+373)',
    iso: 'md',
    code: '373'
  },
  {
    country: 'Monaco (+377)',
    iso: 'mc',
    code: '377'
  },
  {
    country: 'Mongolia (+976)',
    iso: 'mn',
    code: '976'
  },
  {
    country: 'Montenegro (+382)',
    iso: 'me',
    code: '382'
  },
  {
    country: 'Montserrat (+1664)',
    iso: 'ms',
    code: '1664'
  },
  {
    country: 'Morocco (+212)',
    iso: 'ma',
    code: '212'
  },
  {
    country: 'Mozambique (+258)',
    iso: 'mz',
    code: '258'
  },
  {
    country: 'Myanmar (+95)',
    iso: 'mm',
    code: '95'
  },
  {
    country: 'Namibia (+264)',
    iso: 'na',
    code: '264'
  },
  {
    country: 'Nauru (+674)',
    iso: 'nr',
    code: '674'
  },
  {
    country: 'Nepal (+977)',
    iso: 'np',
    code: '977'
  },
  {
    country: 'Netherlands (+31)',
    iso: 'nl',
    code: '31'
  },
  {
    country: 'Netherlands Antilles (+599)',
    iso: 'nl',
    code: '599'
  },
  {
    country: 'New Caledonia (+687)',
    iso: 'nc',
    code: '687'
  },
  {
    country: 'New Zealand (+64)',
    iso: 'nz',
    code: '64'
  },
  {
    country: 'Nicaragua (+505)',
    iso: 'ni',
    code: '505'
  },
  {
    country: 'Niger (+227)',
    iso: 'ne',
    code: '227'
  },
  {
    country: 'Nigeria (+234)',
    iso: 'ng',
    code: '234'
  },
  {
    country: 'Norway (+47)',
    iso: 'no',
    code: '47'
  },
  {
    country: 'Oman (+968)',
    iso: 'om',
    code: '968'
  },
  {
    country: 'Pakistan (+92)',
    iso: 'pk',
    code: '92'
  },
  {
    country: 'Palestine (+970)',
    iso: 'ps',
    code: '970'
  },
  {
    country: 'Panama (+507)',
    iso: 'pa',
    code: '507'
  },
  {
    country: 'Papua New Guinea (+675)',
    iso: 'pg',
    code: '675'
  },
  {
    country: 'Paraguay (+595)',
    iso: 'py',
    code: '595'
  },
  {
    country: 'Peru (+51)',
    iso: 'pe',
    code: '51'
  },
  {
    country: 'Philippines (+63)',
    iso: 'ph',
    code: '63'
  },
  {
    country: 'Poland (+48)',
    iso: 'pl',
    code: '48'
  },
  {
    country: 'Portugal (+351)',
    iso: 'pt',
    code: '351'
  },
  {
    country: 'Puerto Rico (+1787)',
    iso: 'pr',
    code: '1787'
  },
  {
    country: 'Qatar (+974)',
    iso: 'qa',
    code: '974'
  },
  {
    country: 'Reunion (+262)',
    iso: 're',
    code: '262'
  },
  {
    country: 'Romania (+40)',
    iso: 'ro',
    code: '40'
  },
  {
    country: 'Russia (+7)',
    iso: 'ru',
    code: '7'
  },
  {
    country: 'Rwanda (+250)',
    iso: 'rw',
    code: '250'
  },
  {
    country: 'Saint Kitts and Nevis (+1869)',
    iso: 'kn',
    code: '1869'
  },
  {
    country: 'Saint Lucia (+1758)',
    iso: 'lc',
    code: '1758'
  },
  {
    country: 'Samoa (+685)',
    iso: 'ws',
    code: '685'
  },
  {
    country: 'San Marino (+378)',
    iso: 'sm',
    code: '378'
  },
  {
    country: 'Sao Tome and Principe (+239)',
    iso: 'st',
    code: '239'
  },
  {
    country: 'Saudi Arabia (+966)',
    iso: 'sa',
    code: '966'
  },
  {
    country: 'Senegal (+221)',
    iso: 'sn',
    code: '221'
  },
  {
    country: 'Serbia (+381)',
    iso: 'rs',
    code: '381'
  },
  {
    country: 'Seychelles (+248)',
    iso: 'sc',
    code: '248'
  },
  {
    country: 'Sierra Leone (+232)',
    iso: 'sl',
    code: '232'
  },
  {
    country: 'Singapore (+65)',
    iso: 'sg',
    code: '65'
  },
  {
    country: 'Slovakia (+421)',
    iso: 'sk',
    code: '421'
  },
  {
    country: 'Slovenia (+386)',
    iso: 'si',
    code: '386'
  },
  {
    country: 'Solomon Islands (+677)',
    iso: 'sb',
    code: '677'
  },
  {
    country: 'Somalia (+252)',
    iso: 'so',
    code: '252'
  },
  {
    country: 'South Africa (+27)',
    iso: 'za',
    code: '27'
  },
  {
    country: 'Spain (+34)',
    iso: 'es',
    code: '34'
  },
  {
    country: 'Sri Lanka (+94)',
    iso: 'lk',
    code: '94'
  },
  {
    country: 'St. Vincent and the Grenadines (+1784)',
    iso: 'vc',
    code: '1784'
  },
  {
    country: 'Sudan (+249)',
    iso: 'sd',
    code: '249'
  },
  {
    country: 'Suriname (+597)',
    iso: 'sr',
    code: '597'
  },
  {
    country: 'Swaziland (+268)',
    iso: 'sz',
    code: '268'
  },
  {
    country: 'Sweden (+46)',
    iso: 'se',
    code: '46'
  },
  {
    country: 'Switzerland (+41)',
    iso: 'ch',
    code: '41'
  },
  {
    country: 'Syria (+963)',
    iso: 'sy',
    code: '963'
  },
  {
    country: 'Taiwan (+886)',
    iso: 'tw',
    code: '886'
  },
  {
    country: 'Tajikistan (+992)',
    iso: 'tj',
    code: '992'
  },
  {
    country: 'Tanzania (+255)',
    iso: 'tz',
    code: '255'
  },
  {
    country: 'Thailand (+66)',
    iso: 'th',
    code: '66'
  },
  {
    country: 'Timor-Leste (+670)',
    iso: 'tl',
    code: '670'
  },
  {
    country: 'Togo (+228)',
    iso: 'tg',
    code: '228'
  },
  {
    country: 'Tonga (+676)',
    iso: 'to',
    code: '676'
  },
  {
    country: 'Trinidad and Tobago (+1868)',
    iso: 'tt',
    code: '1868'
  },
  {
    country: 'Tunisia (+216)',
    iso: 'tn',
    code: '216'
  },
  {
    country: 'Turkey (+90)',
    iso: 'tr',
    code: '90'
  },
  {
    country: 'Turkmenistan (+993)',
    iso: 'tm',
    code: '993'
  },
  {
    country: 'Turks and Caicos Islands (+1649)',
    iso: 'tc',
    code: '1649'
  },
  {
    country: 'Uganda (+256)',
    iso: 'ug',
    code: '256'
  },
  {
    country: 'Ukraine (+380)',
    iso: 'ua',
    code: '380'
  },
  {
    country: 'United Arab Emirates (+971)',
    iso: 'ae',
    code: '971'
  },
  {
    country: 'United Kingdom (+44)',
    iso: 'gb',
    code: '44'
  },
  {
    country: 'Uruguay (+598)',
    iso: 'uy',
    code: '598'
  },
  {
    country: 'Uzbekistan (+998)',
    iso: 'uz',
    code: '998'
  },
  {
    country: 'Vanuatu (+678)',
    iso: 'vu',
    code: '678'
  },
  {
    country: 'Venezuela (+58)',
    iso: 've',
    code: '58'
  },
  {
    country: 'Vietnam (+84)',
    iso: 'vn',
    code: '84'
  },
  {
    country: 'Virgin Islands, British (+1284)',
    iso: 'vg',
    code: '1284'
  },
  {
    country: 'Yemen (+967)',
    iso: 'ye',
    code: '967'
  },
  {
    country: 'Zambia (+260)',
    iso: 'zm',
    code: '260'
  },
  {
    country: 'Zimbabwe (+263)',
    iso: 'zw',
    code: '263'
  }
]

export const stateList = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'District of Columbia',
  'Puerto Rico',
  'Guam',
  'American Samoa',
  'U.S. Virgin Islands',
  'Northern Mariana Islands'
]

export const monthMap = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
}

export const canadianProvinceMap = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon'
}

export const stateMap = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'U.S. Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

// these are the states we can actually ship physical deliveries to
export const shippableToStateMap = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

export const countryMap = {
  US: 'United States',
  GB: 'United Kingdom',
  CA: 'Canada',
  AU: 'Australia'
}

export default class GeographyService {
  static getState (stateCode, country) {
    switch (country) {
      case 'US':
        return stateMap[stateCode]
      case 'CA':
        return canadianProvinceMap[stateCode]
      default:
        return stateCode
    }
  }

  static getStateCode (state, country) {
    switch (country) {
      case 'US':
        return Object.keys(stateMap).find(key => stateMap[key] === state)
      case 'CA':
        return Object.keys(canadianProvinceMap).find(key => canadianProvinceMap[key] === state)
      default:
        return state
    }
  }

  static getCountry (countryCode) {
    return countryMap[countryCode]
  }

  static getCountryCode (country) {
    return Object.keys(countryMap).find(key => countryMap[key] === country)
  }

  static getMonthText (monthNumStr) {
    const monthNum = Number.parseInt(monthNumStr)
    if (monthNum && monthNum > 0 && monthNum < 13) {
      return Object.keys(monthMap)[monthNum - 1]
    } else {
      throw new Error('Month short code not found!')
    }
  }

  static getMonthNum (monthText) {
    return monthMap[monthText]
  }

  static getStateList () {
    return stateList
  }

  static getStateMap () {
    return stateMap
  }

  static getShippableToStateMap () {
    return shippableToStateMap
  }

  static getCountryList () {
    return Object.values(countryMap)
  }

  static getCountryMap () {
    return countryMap
  }

  static getMonthList () {
    return Object.keys(monthMap)
  }

  static getCanadianProvinceList () {
    return Object.values(canadianProvinceMap)
  }

  static getCountryCodeList () {
    return countryCodeList
  }
}
