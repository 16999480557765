export class TemplateMarkupCustomization {
  _message

  constructor ({
    message = ''
  } = {}) {
    this._message = message
  }

  get message () {
    return this._message
  }

  set message (message) {
    this._message = message
  }

  updateField (update) {
    const [fieldToUpdate] = Object.keys(update)
    const valueToUse = update[fieldToUpdate]
    this[fieldToUpdate] = valueToUse
  }
}
