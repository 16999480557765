import { HttpClient } from '@/components/shared/security/http-client'
import { FulfillmentType } from '@/dto/order/FulfillmentType'

export const ShoppingCartService = {

  async createShoppingCart (createCartRequest = {}) {
    try {
      return await HttpClient.post('api/shopping-carts', createCartRequest)
    } catch (error) {
      console.error('Failed to create shopping cart', error)
    }
  },

  async getShoppingCart (cartId) {
    try {
      const { data: cart } = await HttpClient.get(`api/shopping-carts/${cartId}`)
      return cart
    } catch (error) {
      console.error('Failed to get shopping cart', error)
    }
  },

  async getActiveShoppingCart () {
    try {
      const { data: cart } = (await HttpClient.get('api/shopping-carts/active'))
      return cart
    } catch (error) {
      console.error('Failed to get active shopping cart', error)
    }

    return null
  },

  async deleteCart (cartId) {
    try {
      await HttpClient.delete(`api/shopping-carts/${cartId}`)
    } catch (error) {
      console.error('Failed to delete shopping cart', error)
    }
  },

  async removeOutOfSyncCarts (cartId) {
    try {
      const cart = cartId ? await this.getShoppingCart(cartId) : await this.getActiveShoppingCart()

      if (cart?.cartId && cart?.lineItemCount > 0) {
        await this.deleteLineItemsByFulfillmentType(cart.cartId, FulfillmentType.DIGITAL)
        await this.deleteLineItemsByFulfillmentType(cart.cartId, FulfillmentType.PHYSICAL)
        await this.deleteCart(cart.cartId)
      }
    } catch (error) {
      console.error('Failed to remove out of sync carts', error)
    }
  },

  async getCartSummary (cartId) {
    try {
      return await HttpClient.get(`api/shopping-carts/${cartId}/summary`)
    } catch (error) {
      console.error('Failed to get cart summary', error)
    }
  },

  async updateCart (cartId, accountIdentifier, updateUploadRequest = {}) {
    try {
      await HttpClient.patch(`api/shopping-carts/${cartId}?accountIdentifier=${accountIdentifier}`, updateUploadRequest)
    } catch (error) {
      console.error('Failed to update cart', error)
    }
  },

  async listLineItems (cartId, page = '', size = 5) {
    try {
      return await HttpClient.get(`api/shopping-carts/${cartId}/line-items?pageToken=${page}&pageSize=${size}`)
    } catch (error) {
      console.error('Failed to list line items', error)
    }
  },

  async updateLegacyLineItem (cartId, legacyIdentifier, updateLineItemRequest) {
    try {
      await HttpClient.patch(`api/shopping-carts/${cartId}/line-items/by-legacy-upload-identifier/${legacyIdentifier}`, updateLineItemRequest)
    } catch (error) {
      console.error('Failed to update legacy line item', error)
    }
  },

  async deleteLegacyLineItem (cartId, legacyIdentifier) {
    try {
      return await HttpClient.delete(`api/shopping-carts/${cartId}/line-items/by-legacy-upload-identifier/${legacyIdentifier}`)
    } catch (error) {
      console.error('Failed to delete legacy line item', error)
    }
  },

  async deleteLineItemsByFulfillmentType (cartId, fulfillmentType) {
    try {
      await HttpClient.delete(`api/shopping-carts/${cartId}/line-items/fulfillment-type/${fulfillmentType}`)
    } catch (error) {
      console.error('Failed to delete line items by fulfillment type', error)
    }
  }
}
