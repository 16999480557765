import store from '@/store'
import { GET_PLATFORM_ASYNC, PRINCIPLE_GET_ASYNC } from '@/store/action-types'
import { getFormattedEnvironment, isProduction } from '@/components/shared/environment'
import { forEach, transform } from 'lodash'
import AuthenticationProvider from '@/components/shared/security/authentication-provider'

const SEGMENT_SOURCE = 'RewardsGenius'
const SEGMENT_NO_PERMISSION_ACTION = 'Not Allowed'
const USER_SEGMENT_PERMISSIONS = Object.freeze({
  ACCOUNT: 'groups_accounts',
  API_KEYS: 'api_keys',
  EMAIL_TEMPLATES: 'delivery_templates',
  EMULATION: 'emulation',
  FUNDING: 'funding',
  INDIVIDUAL_ORDER_HISTORY: 'individual_order_history',
  INTEGRATIONS: 'qualtrics',
  ORDER_HISTORY: 'order_history',
  ORDER_REPORT: 'reports',
  PLACE_ORDER: 'place_order',
  RECIPIENT_WHITELIST: 'recipient_whitelist',
  USER_MANAGEMENT: 'users',
  VIEW_BALANCE: 'show_account_balance',
  ROLE_MANAGEMENT: 'role_management'
})

const isRouteWithoutUserInfo = () => {
  // List routes that cannot get user and platform
  // The platform request will always fail, thus we should short-circuit
  return ['/mfa', '/register-phone'].some(route => location.href.includes(route))
}

const getUser = () => {
  return store.dispatch(PRINCIPLE_GET_ASYNC)
}

const findPlatform = platformId => {
  return store.dispatch(GET_PLATFORM_ASYNC, { platformClientId: platformId })
}

const createUserDetails = (user, platform) => {
  const details = {
    email: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    displayName: `${user.firstName} ${user.lastName}`,
    username: user.username,
    createdAt: user.createdDate,
    mfaType: user.mfaType,
    platformName: platform.name,
    company: {
      id: platform.identityHash,
      createdAt: platform.createdDate,
      platformName: platform.name,
      name: platform.name,
      source: platform.originSource
    },
    ...createUserPermissionProperties(user)
  }

  const currentEnvironment = getFormattedEnvironment()

  // the purpose of this is so that the property will be 'rgId' in production and '${environment}RgId'
  // in all other environments. This strategy lets us just update the map object should the business
  // decide to change the prefix
  const environmentRgId = isProduction() ? 'rgId' : `${currentEnvironment.toLowerCase()}RgId`

  const platformId = isProduction() ? 'platFormId' : `${currentEnvironment.toLowerCase()}PlatformId`

  details[environmentRgId] = user.userId

  details[platformId] = platform.platformClientId
  details.company[platformId] = platform.platformClientId

  return details
}

const getMapOfCurrentUserPermissions = userPermissions => {
  // transform permissions into { 'FUNDING': 'MANAGE' ... }
  return transform(userPermissions, (result, permission) => {
    // ensure MANAGE takes priority over ACCESS
    const shouldAddPortalAreaPermission = !result[permission.portalArea] || permission.action === 'MANAGE'
    if (shouldAddPortalAreaPermission) {
      result[permission.portalArea] = permission.action
    }
  }, {})
}

const getPermissionInformationForCurrentUser = user => {
  const { platformPermissions = [], groupPermissionMap = {}, accountPermissionMap = {} } = user
  const groupPermissionMapKeys = Object.keys(groupPermissionMap)
  const accountPermissionMapKeys = Object.keys(accountPermissionMap)
  let userPermissions = {}
  if (platformPermissions.length) {
    userPermissions = platformPermissions
  } else if (groupPermissionMapKeys.length) {
    userPermissions = groupPermissionMap[groupPermissionMapKeys[0]]
  } else if (accountPermissionMapKeys.length) {
    userPermissions = accountPermissionMap[accountPermissionMapKeys[0]]
  }
  return {
    platformPermissions,
    groupPermissionMapKeys,
    accountPermissionMapKeys,
    userPermissions
  }
}

const createUserPermissionProperties = user => {
  const { platformPermissions, groupPermissionMapKeys, accountPermissionMapKeys, userPermissions } = getPermissionInformationForCurrentUser(user)
  const currentUserPermissionMap = getMapOfCurrentUserPermissions(userPermissions)

  const userPermissionProperties = {
    role: user.roleString,
    organization_access: !!platformPermissions.length,
    group_access: groupPermissionMapKeys.join(',') || null,
    account_access: accountPermissionMapKeys.join(',') || null
  }

  // update userPermissionProperties with each permission and it's access level ex. { 'permissions_funding': 'MANAGE', 'permissions_api_keys': "Not Allowed' ... }
  forEach(USER_SEGMENT_PERMISSIONS, (segmentAreaName, portalArea) => {
    userPermissionProperties[`permissions_${segmentAreaName}`] = currentUserPermissionMap[portalArea] || SEGMENT_NO_PERMISSION_ACTION
  })

  return userPermissionProperties
}

const createProperties = () => ({
  environment: getFormattedEnvironment(),
  source: SEGMENT_SOURCE,
  performedByEmployee: AuthenticationProvider.isEmulationSession()
})

const getCurrentUserAndPlatform = async () => {
  if (isRouteWithoutUserInfo()) {
    return { user: null, platform: null }
  }
  const user = await getUser()
  const platform = user.platformClientId === null ? {} : await findPlatform(`${user.platformClientId}`)
  return { user, platform }
}

const createIdentifyDetails = async () => {
  const { user, platform } = await getCurrentUserAndPlatform()

  if (!user) {
    return {}
  }

  return {
    userId: user.identityHash,
    details: createUserDetails(user, platform)
  }
}

const createGroupDetails = async () => {
  const { user, platform } = await getCurrentUserAndPlatform()

  if (!user) {
    return {}
  }

  const { company: details } = createUserDetails(user, platform)
  return {
    groupId: platform.identityHash,
    details
  }
}

export {
  createProperties,
  createIdentifyDetails,
  createGroupDetails
}
