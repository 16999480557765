import AuthenticationProvider from './authentication-provider'
import { ACCESS_LEVEL_HIERARCHY } from '../constants/access-levels.constants'
import { PlatformKybStatus } from '@/dto/core/platform'
import store from '@/store'
import * as getTypes from '@/store/get-types'
import * as Authorities from '@/components/shared/constants/authority.constants'

/**
 * Determine whether the current platform has successfully completed the
 * KYB process.
 * @private
 * @return {boolean}
 */
function isPlatformKybApproved () {
  const approvedKybStatuses = [
    PlatformKybStatus.APPROVED,
    // Accounts prior to self signup were not back filled; treat NONE as APPROVED
    PlatformKybStatus.NONE
  ]
  const platform = store.getters[getTypes.GET_PLATFORM]

  return approvedKybStatuses.includes(platform?.kybStatus)
}

/**
 * Determine whether any of the provided authorities require the platform
 * to have successfully completed the KYB process.
 * @private
 * @param authorities {Array[string]} The authorities being checked
 * @return {boolean}
 */
function requiresKybApproval (authorities) {
  const authoritiesRequiringKybApproval = [
    Authorities.API_KEYS_MANAGE,
    Authorities.INTEGRATIONS_MANAGE
  ]

  return authorities.some(a => authoritiesRequiringKybApproval.includes(a))
}

function hasAuthority (authorityOrArray) {
  const loggedInClaims = AuthenticationProvider.getClaims() || []

  let authorities = []

  if (Array.isArray(authorityOrArray)) {
    authorities = authorities.concat(authorityOrArray)
  } else {
    authorities.push(authorityOrArray)
  }
  const needsKybApproval = !isPlatformKybApproved() && requiresKybApproval(authorities)
  if (needsKybApproval) {
    return false
  }

  return authorities.every((a) => loggedInClaims.includes(a))
}

function hasAnyAuthority (authorities = []) {
  return authorities.some((a) => hasAuthority(a))
}

function hasAnyAccessLevel (accessLevels = []) {
  const currentAccessLevel = AuthenticationProvider.getCurrentAccessLevel()
  return accessLevels.some((accessLevel) => accessLevel === currentAccessLevel)
}

function hasAccessLevel (requiredAccessLevel) {
  const currentAccessLevel = AuthenticationProvider.getCurrentAccessLevel()
  return ACCESS_LEVEL_HIERARCHY[currentAccessLevel] >= ACCESS_LEVEL_HIERARCHY[requiredAccessLevel]
}

function hasAuthorityWithAccessLevel (authority, accessLevel) {
  return hasAuthority(authority) && hasAccessLevel(accessLevel)
}

function AuthorizedPlugin (Vue) {
  Vue.prototype.$hasAuthority = hasAuthority
  Vue.prototype.$hasAnyAuthority = hasAnyAuthority
  Vue.prototype.$hasAuthorityWithAccessLevel = hasAuthorityWithAccessLevel
  Vue.prototype.$isEmulationSession = AuthenticationProvider.isEmulationSession
  Vue.prototype.$getEmulatedPlatformId = AuthenticationProvider.getEmulatedPlatformId
  Vue.prototype.$getSessionId = AuthenticationProvider.getSessionId
  Vue.prototype.$setSessionId = AuthenticationProvider.setSessionId
  Vue.prototype.$isProd = AuthenticationProvider.isProd
  Vue.prototype.$hasAnyAccessLevel = hasAnyAccessLevel
}

export {
  AuthorizedPlugin,
  hasAuthority,
  hasAnyAuthority,
  hasAccessLevel,
  hasAuthorityWithAccessLevel
}
