function DynamicWidthDirective (el, bindings) {
  el.style.maxWidth = getWidth(bindings.arg, bindings.value.selectedColumn, bindings.value.visibleCount) + '%'
}

function getWidth (currentColumn, selectedColumn, visibleCount) {
  const activeColMultiplier = 300
  const activeColWidth = activeColMultiplier / visibleCount > 50 ? 50 : activeColMultiplier / visibleCount

  if (selectedColumn === null || typeof selectedColumn === 'undefined') {
    return (Math.floor(((12 / visibleCount) / 12) * 100))
  } else if (currentColumn === selectedColumn) {
    return activeColWidth
  } else {
    return Math.round((100 - activeColWidth) / (visibleCount - 1))
  }
}

function ValidationStyleDirective (el, bindings) {
  const errors = bindings.value.errors
  const name = bindings.value.field

  el.classList.add('col')

  if (errors.has(name)) {
    el.classList.add('has-danger')
  } else {
    el.classList.remove('has-danger')
  }
}

export {
  DynamicWidthDirective,
  ValidationStyleDirective
}
