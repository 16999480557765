import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import { LOGIN_ORIGINAL_URL } from '../../components/shared/constants/localstorage.constants'

const state = {
  originalRoute: '',
  errorMessage: null
}

const actions = {
  [actionTypes.LOGIN_CLEAR_ORIGINAL_URL] ({ commit }) {
    commit(mutationTypes.LOGIN_SET_ORIGINAL_ROUTE, '')
  },
  [actionTypes.LOGIN_SET_ERROR] ({ commit }, errorMessage) {
    commit(mutationTypes.LOGIN_UPDATE_ERROR, errorMessage)
  }
}

const getters = {
  [getTypes.LOGIN_GET_ORIGINAL_ROUTE]: state => state.originalRoute || JSON.parse(localStorage.getItem(LOGIN_ORIGINAL_URL)),
  [getTypes.LOGIN_GET_ERROR]: state => state.errorMessage
}

const mutations = {
  [mutationTypes.LOGIN_SET_ORIGINAL_ROUTE] (state, originalRoute) {
    state.originalRoute = originalRoute
    if (originalRoute) {
      localStorage.setItem(LOGIN_ORIGINAL_URL, JSON.stringify(originalRoute))
    } else {
      localStorage.removeItem(LOGIN_ORIGINAL_URL)
    }
  },
  [mutationTypes.LOGIN_UPDATE_ERROR] (state, errorMessage) {
    state.errorMessage = errorMessage
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
