import { segmentModule } from '@/components/shared/segment/segment-plugin'

const LOGIN_ROUTE_NAME = 'Login'

const getNameForPage = ({ meta = {}, name }) => meta.segmentName || name

export const trackPage = (to, from) => {
  const pageShouldBeTracked = to && from && from.name !== to.name
  if (pageShouldBeTracked) {
    if (to.name !== LOGIN_ROUTE_NAME) {
      segmentModule.identify()
      segmentModule.group()
    }
    segmentModule.page(getNameForPage(to), { path: to.path })
  }
}
