import Chameleon from './Chameleon'

/**
 * Chameleon Vue plugin object
 * @type {PluginObject<unknown>}
 */
const ChameleonPlugin = {
  /**
   * @type PluginFunction
   * @param Vue
   */
  install: Vue => {
    /**
     * @type {Chameleon}
     */
    Vue.chameleon = Vue.prototype.$chmln = new Chameleon()
  }
}

export default ChameleonPlugin
