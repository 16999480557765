import { AdaChatbotIntegration } from '@/configuration/ada_chatbot/AdaChatbotIntegration'

export default (to, from, next) => {
  const isChatbotDisabled = to.meta?.disableChatbot ?? false
  if (isChatbotDisabled) {
    AdaChatbotIntegration.stopChatbot()
  } else {
    AdaChatbotIntegration.startupChatbot()
  }
  next()
}
