import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const state = {
  title: '',
  loadingCount: 0,
  usingNewLayout: true,
  accountSwitcherIsVisible: false,
  isApplicationSplitInitialized: false,
  isSplitInitialized: false
}

const getters = {
  [getTypes.APP_GET_TITLE]: state => state.title,
  [getTypes.APP_GET_LOADING]: state => state.loadingCount !== 0,
  [getTypes.APP_GET_USING_NEW_LAYOUT]: state => state.usingNewLayout,
  [getTypes.APP_GET_ACCOUNT_SWITCHER_IS_VISIBLE]: state => state.accountSwitcherIsVisible,
  [getTypes.APP_GET_IS_APPLICATION_SPLIT_INITIALIZED]: state => state.isApplicationSplitInitialized,
  [getTypes.APP_GET_IS_SPLIT_INITIALIZED]: state => state.isSplitInitialized
}

const mutations = {
  [mutationTypes.APP_SET_TITLE] (state, title) {
    state.title = title
    document.title = state.title
  },
  [mutationTypes.APP_SET_LOADING] (state, loading) {
    state.loadingCount += loading ? 1 : -1

    if (state.loadingCount < 0) {
      state.loadingCount = 0
    }
  },
  [mutationTypes.APP_SET_USING_NEW_LAYOUT] (state, value) {
    state.usingNewLayout = value
  },
  [mutationTypes.APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE] (state, newSetAccountSwitcherIsVisible) {
    state.accountSwitcherIsVisible = newSetAccountSwitcherIsVisible
  },
  [mutationTypes.APP_SET_APPLICATION_SPLIT_IS_INITIALIZED] (state, initialized) {
    state.isApplicationSplitInitialized = initialized
  },
  [mutationTypes.APP_SET_SPLIT_IS_INITIALIZED] (state, initialized) {
    state.isSplitInitialized = initialized
  }
}

const actions = {
  [actionTypes.UPDATE_USING_NEW_LAYOUT] (context, value) {
    context.commit(mutationTypes.APP_SET_USING_NEW_LAYOUT, value)
  },
  [actionTypes.APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE] ({ commit }, newSetAccountSwitcherIsVisible) {
    commit(mutationTypes.APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE, newSetAccountSwitcherIsVisible)
  },
  [actionTypes.APP_SET_LOADING] ({ commit }, loading) {
    commit(mutationTypes.APP_SET_LOADING, loading)
  },
  [actionTypes.APP_SET_APPLICATION_SPLIT_IS_INITIALIZED] ({ commit }, initialized) {
    commit(mutationTypes.APP_SET_APPLICATION_SPLIT_IS_INITIALIZED, initialized)
  },
  [actionTypes.APP_SET_SPLIT_IS_INITIALIZED] ({ commit }, initialized) {
    commit(mutationTypes.APP_SET_SPLIT_IS_INITIALIZED, initialized)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
