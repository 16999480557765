const AccountStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  DISABLED: 'DISABLED',
  FROZEN: 'FROZEN'
})

export {
  AccountStatus
}
