import i18n from '@/i18n'
import { HttpClient } from '@/components/shared/security/http-client'
import * as getTypes from '@/store/get-types'
import * as mutationTypes from '@/store/mutation-types'
import * as actionTypes from '@/store/action-types'
import { AmountDetails } from '@/dto/order/AmountDetails'
import { RewardValueType } from '@/dto/order/RewardValueType'
import { chain, some, keyBy } from 'lodash'
import { FulfillmentType } from '@/dto/order/FulfillmentType'
import { ItemAvailabilityStatus } from '@/components/shared/constants/item-availability-status'

const state = {
  catalog: {
    brands: []
  },
  items: [],
  catalogSearchResults: null,
  catalogSearchParams: null,
  searchIsVisible: true,
  selectedFilters: {
    currency: null,
    type: null,
    delivery: null,
    country: null
  }
}

const getters = {
  [getTypes.GET_CATALOG]: ({ catalog }) => catalog,
  [getTypes.GET_CATALOG_BRANDS]: ({ catalog: { brands = [] } }) => brands,
  [getTypes.GET_CATALOG_BRAND_MAP]: ({ catalog: { brands = [] } }) => {
    return keyBy(brands, 'brandKey')
  },
  [getTypes.CATALOG_HAS_SINGLE_BRAND]: (state, getters) => getters[getTypes.GET_CATALOG_BRANDS].length === 1,
  [getTypes.GET_FIXED_ITEMS_BY_UTID]: (_, getters) => (itemUtid, { fulfillmentTypeToFilterOn = FulfillmentType.DIGITAL } = {}) => {
    const { items = [] } = getters[getTypes.GET_BRAND_BY_UTID](itemUtid)
    return items.filter(({ valueType, fulfillmentType }) => valueType === RewardValueType.FIXED_VALUE && fulfillmentType === fulfillmentTypeToFilterOn)
  },
  [getTypes.GET_AMOUNT_DETAILS_BY_UTID]: (_, getters) => (itemUtid, { fulfillmentTypeToFilterOn = FulfillmentType.DIGITAL } = {}) => {
    const { items = [] } = getters[getTypes.GET_BRAND_BY_UTID](itemUtid)
    const { minValue: min, maxValue: max, isWholeAmountValueRequired } = items.find(({ utid }) => utid === itemUtid) || {}
    return new AmountDetails({
      min,
      max,
      isWholeAmountValueRequired,
      // fixed value item options should be ordered
      options: chain(getters[getTypes.GET_FIXED_ITEMS_BY_UTID](itemUtid, { fulfillmentTypeToFilterOn }))
        .sortBy('faceValue')
        .map(({ faceValue, isAvailable, utid, adjustment }) => { return { faceValue, isAvailable, utid, adjustment } })
        .value()
    })
  },
  [getTypes.GET_BRAND_BY_UTID]: ({ catalog: { brands = [] } }) => (itemUtid) => {
    return brands.find(({ items }) => items.find(({ utid }) => utid === itemUtid))
  },
  [getTypes.GET_IS_AVAILABLE_BY_UTID]: (_state, getters) => (utid) => {
    const items = getters[getTypes.GET_BRAND_BY_UTID](utid)?.items ?? []
    const item = items.find(item => item.utid === utid)
    return item.isAvailable && item.itemAvailability?.itemAvailabilityStatus === ItemAvailabilityStatus.AVAILABLE
  },
  [getTypes.GET_BRAND_BY_BRAND_KEY]: (_, getters) => (brandKey) => {
    return getters[getTypes.GET_CATALOG_BRAND_MAP][brandKey]
  },
  [getTypes.CATALOG_HAS_FULFILLMENT_TYPE]: ({ items }) => (fulfillmentTypeToCheck) => {
    return some(items, { fulfillmentType: fulfillmentTypeToCheck })
  },
  [getTypes.GET_CATALOG_SEARCH_PARAMS]: state => state.catalogSearchParams,
  [getTypes.GET_SEARCH_IS_VISIBLE]: state => state.searchIsVisible,
  [getTypes.GET_SELECTED_FILTERS]: state => state.selectedFilters,
  [getTypes.GET_ARE_ALL_ITEMS_SCHEDULABLE]: ({ items }) => (utids) => {
    return !utids.some(utid => !items.find(item => item.utid === utid)?.isSchedulable)
  }
}

const actions = {
  [actionTypes.RETRIEVE_AND_SET_CATALOG_IF_EMPTY]: async ({ commit, dispatch, state }) => {
    if (!state.catalog.catalogID) {
      try {
        const { data: catalog } = await HttpClient.get('api/catalog')
        commit(mutationTypes.SET_CATALOG, catalog)
        dispatch(actionTypes.SET_ITEMS)
        commit(mutationTypes.SET_CATALOG_SEARCH_PARAMS, null)
      } catch (error) {
        console.error(error)
        dispatch(actionTypes.TOAST, { html: i18n.t('catalog.loadError'), type: 'danger' }, { root: true })
      }
    }
  },
  [actionTypes.SET_ITEMS]: ({ commit, getters }) => {
    const items = getters[getTypes.GET_CATALOG_BRANDS].flatMap((brand) => brand.items)
    commit(mutationTypes.SET_ITEMS, items)
  },
  [actionTypes.SET_CATALOG_SEARCH_PARAMS] (context, searchTerm) {
    context.commit(mutationTypes.SET_CATALOG_SEARCH_PARAMS, searchTerm)
  },
  [actionTypes.SET_SEARCH_IS_VISIBLE]: (context, isVisible) => {
    context.commit(mutationTypes.SET_SEARCH_IS_VISIBLE, isVisible)
  },
  [actionTypes.SET_SELECTED_FILTERS]: (context, selectedFilters) => {
    context.commit(mutationTypes.SET_SELECTED_FILTERS, selectedFilters)
  }
}

const mutations = {
  [mutationTypes.SET_CATALOG]: (state, catalog = {}) => {
    Object.assign(state.catalog, catalog)
  },
  [mutationTypes.SET_ITEMS]: (state, items = []) => {
    Object.assign(state.items, items)
  },
  [mutationTypes.SET_CATALOG_SEARCH_PARAMS]: (state, params) => {
    state.catalogSearchParams = params
  },
  [mutationTypes.SET_SEARCH_IS_VISIBLE]: (state, isVisible) => {
    state.searchIsVisible = isVisible
  },
  [mutationTypes.SET_SELECTED_FILTERS]: (state, selectedFilters) => {
    state.selectedFilters = {
      currency: selectedFilters.currency,
      type: selectedFilters.type,
      delivery: selectedFilters.delivery,
      country: selectedFilters.country
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
