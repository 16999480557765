<template>
  <div
    v-if="group.accounts.length"
  >
    <div
      v-if="showGroupHeader"
      class="py-2 group-title"
    >
      <span>{{ group.displayName }}</span>
    </div>
    <div class="pa-2 mb-3 account-group-container">
      <account-selector-row
        v-for="account in group.accounts"
        :key="account.accountID"
        :account="account"
        :is-selected="isSelectedAccount(account)"
        @switch-to-account="$emit('switch-to-account', account)"
        @open-funding-dialog="$emit('open-funding-dialog', account)"
      />
    </div>
  </div>
</template>

<script>
import AccountSelectorRow from '@/components/sidebar/account-switcher/AccountSelectorRow'

export default {
  name: 'AccountSelectorGroup',
  components: { AccountSelectorRow },
  props: {
    group: {
      type: Object,
      required: true
    },
    selectedAccount: {
      type: Object,
      required: true
    },
    showGroupHeader: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    isSelectedAccount (account) {
      return account?.accountID === this.selectedAccount?.accountID
    }
  }
}

</script>

<style scoped lang="scss">

  .account-group-container {
    border-radius: var(--t-radius-3);
    border: var(--t-border-size-1) solid var(--t-color-border);
  }

  .group-title {
    font-weight: var(--t-font-weight-5);
    font-size: var(--t-font-size-0);
    color: #22292F;
  }
</style>
