function sort (arr, key) {
  if (!arr) {
    return []
  }

  if (!key) {
    return arr
  }

  return arr.sort((a, b) => {
    if (a[key] > b[key]) {
      return 1
    } else if (a[key] < b[key]) {
      return -1
    } else {
      return 0
    }
  })
}

function ObjectSortPlugin (Vue) {
  Vue.prototype.$objSort = sort
}

export {
  ObjectSortPlugin
}
