<template>
  <div>
    <v-row>
      <v-col class="text-left pb-0 pt-0">
        <label
          class="mb-1"
          for="credit-card-number"
        >
          {{ $t(`${i18nPrefix}.creditCardInfo.cardNumber`) }}
        </label>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <validation-provider
          ref="creditCardNumberProvider"
          v-slot="{ errors, flags: { touched } }"
          rules="required"
          immediate
          :name="$t(`${i18nPrefix}.warningName.cardNumber`)"
        >
          <v-text-field
            id="credit-card-number"
            v-model="creditCard.creditCardNumber"
            v-cardformat:formatCardNumber
            class="mb-3"
            :class="{ 'disabled': isEditing }"
            dense
            :error-messages="touched ? errors : []"
            :disabled="isEditing"
            hide-details="auto"
            outlined
            @blur="validateCardNumber"
          >
            <template #prepend-inner>
              <funding-method-image :payment-method="creditCardType" />
            </template>
          </v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-left pb-0 pt-0">
        <label
          class="mb-1"
          for="name-on-card"
        >
          {{ $t(`${i18nPrefix}.creditCardInfo.nameOnCard`) }}
        </label>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <validation-provider
          v-slot="{ errors, flags: { touched } }"
          rules="required"
          immediate
          :name="$t(`${i18nPrefix}.warningName.name`)"
        >
          <v-text-field
            id="name-on-card"
            ref="nameOnCardInput"
            v-model="creditCard.nameOnCard"
            class="mb-3"
            dense
            :error-messages="touched ? errors : []"
            hide-details="auto"
            outlined
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-left pb-0 pt-0">
        <label
          class="mb-1"
          for="expiration"
        >
          {{ $t(`${i18nPrefix}.creditCardInfo.expiration`) }}
        </label>
      </v-col>
      <v-col
        v-if="!isEditing"
        class="text-left pb-0 pt-0"
      >
        <label
          class="mb-1"
          for="cvc"
        >
          {{ $t(`${i18nPrefix}.creditCardInfo.cvc`) }}
        </label>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0 pb-0"
        :class="{ 'col-6': isEditing }"
      >
        <validation-provider
          ref="creditCardExpirationDateProvider"
          v-slot="{ errors, flags: { touched } }"
          rules="required"
          immediate
          :name="$t(`${i18nPrefix}.warningName.expiration`)"
        >
          <v-text-field
            id="expiration"
            v-model="creditCard.expirationDate"
            v-cardformat:formatCardExpiry
            class="mb-3"
            dense
            :error-messages="touched ? errors : []"
            hide-details="auto"
            :maxlength="maxExpDate"
            outlined
            :placeholder="$t(`${i18nPrefix}.creditCardInfo.expirationFormat`)"
            @blur="validateCardExpirationDate"
          />
        </validation-provider>
      </v-col>
      <v-col
        v-if="!isEditing"
        class="pt-0 pb-0"
      >
        <validation-provider
          ref="creditCardCvcProvider"
          v-slot="{ errors, flags: { touched } }"
          rules="required"
          immediate
          :name="$t(`${i18nPrefix}.warningName.cvc`)"
        >
          <v-text-field
            id="cvc"
            ref="cvcInput"
            v-model="creditCard.cvc"
            v-cardformat:formatCardCVC
            :placeholder="$t(`${i18nPrefix}.creditCardInfo.cvcFormat`)"
            :maxlength="maxCvc"
            :error-messages="touched ? errors : []"
            dense
            hide-details="auto"
            outlined
            @blur="validateCardCvc"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <a
          v-if="showAddCardNicknameLink"
          id="add-card-nickname"
          class="ml-1"
          @click="addCardNicknameField()"
        >
          {{ $t(`${i18nPrefix}.creditCardInfo.addCardNickname`) }}
        </a>
        <label
          v-if="showAddCardNicknameField"
          class="mb-1"
          for="card-nickname-input"
        >
          {{ $t(`${i18nPrefix}.creditCardInfo.cardNickname`) }}
        </label>
      </v-col>
    </v-row>
    <v-row v-if="showAddCardNicknameField">
      <v-col class="pt-0 pb-0">
        <v-text-field
          id="card-nickname"
          v-model="creditCard.nickname"
          dense
          hide-details="auto"
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FundingMethodImage from '@/components/shared/lists/FundingMethodImage'
import { GET_SELECTED_CUSTOMER_ACCOUNT } from '@/store/get-types'
import { mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import * as FundingConstants from '@/components/platform/funding/FundingConstants'
import { isEmpty } from 'lodash'

const USD_ONLY_CREDIT_CARDS = [FundingConstants.AMERICAN_EXPRESS, FundingConstants.DISCOVER]

export default {
  name: 'CreditCardDetails',
  components: {
    FundingMethodImage,
    ValidationProvider
  },
  props: {
    ccDetails: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      // NOTE: This specific field MUST be present as the VueCreditCardValidation plugin binds to it and automatically
      // sets the brand type as the credit card info is entered
      cardBrand: null,
      creditCard: {
        creditCardNumber: '',
        nameOnCard: '',
        expirationDate: '',
        cvc: '',
        nickname: ''
      },
      isEditing: false,
      i18nPrefix: 'portalfrontendApp.coreCreditCard.dialog',
      maxExpDate: 7,
      maxCvc: 4,
      showAddCardNicknameField: false,
      showAddCardNicknameLink: true
    }
  },
  computed: {
    ...mapGetters({
      selectedCustomerAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    accountHasUsdCurrencyCode () {
      return this.selectedCustomerAccount?.currencyCode === 'USD'
    },
    creditCardType () {
      switch (this.cardBrand?.toLowerCase()) {
        case 'amex':
          return FundingConstants.AMERICAN_EXPRESS
        case 'discover':
          return FundingConstants.DISCOVER
        case 'diners':
          return FundingConstants.DINERS_CLUB
        case 'jcb':
          return FundingConstants.JCB
        case 'mastercard':
          return FundingConstants.MASTER_CARD
        case 'visa':
          return FundingConstants.VISA
      }
      return this.cardBrand
    }
  },
  watch: {
    creditCard: {
      handler (newCreditCard) {
        this.$emit('credit-card-details', {
          ...newCreditCard,
          // this isn't actually used in the cybersource request but will help us generate the account nickname
          creditCardType: this.creditCardType
        })
      },
      deep: true
    },
    'creditCard.creditCardNumber' (newCardNumber) {
      // Remove any leading or trailing whitespace (usually caused by copying and pasting)
      const trimmedCardNumber = newCardNumber.trim()

      // Use $nextTick since you're not really supposed to set the watched value inside the watcher
      this.$nextTick(() => {
        this.creditCard.creditCardNumber = trimmedCardNumber
      })

      if (!this.isEditing) {
        // Automatically move to next input field when current one is valid
        if (this.$cardFormat.validateCardNumber(trimmedCardNumber)) {
          this.$refs.nameOnCardInput.focus()
        }
      }
    },
    'creditCard.expirationDate' (newExpirationDate) {
      const [month, year] = newExpirationDate.split(' / ')

      // vue-credit-card-validation allows 4 digit years but we only want 2
      // Tried a couple ways of doing this but since formatting is handled by an external library, this is the only
      // way I could get it working
      if (year && year.length > 2) {
        // Use $nextTick since you're not really supposed to set the watched value inside the watcher
        this.$nextTick(() => {
          this.creditCard.expirationDate = [month, year.slice(0, 2)].join(' / ')
        })
      }

      if (
        !this.isEditing &&
        (month && year && this.$cardFormat.validateCardExpiry(month, year))
      ) {
        // Automatically move to next input field when current one is valid
        this.$refs.cvcInput.focus()
      }
    }
  },
  beforeMount () {
    if (!isEmpty(this.ccDetails)) {
      this.isEditing = true
      this.creditCard = {
        creditCardNumber: this.ccDetails?.creditCardNumber,
        nameOnCard: this.ccDetails?.nameOnCard,
        expirationDate: this.ccDetails?.expirationDate,
        cvc: this.ccDetails?.cvc,
        nickname: this.ccDetails?.nickname
      }
      if (this.creditCard.nickname) {
        this.showAddCardNicknameField = true
        this.showAddCardNicknameLink = false
      }
      this.cardBrand = this.ccDetails?.cardBrand
    }
  },

  methods: {
    addCardNicknameField () {
      this.showAddCardNicknameLink = false
      this.showAddCardNicknameField = true
    },
    addVeeValidateError (validationProviderRef, errorMessage) {
      // This manually attaches an error to the VeeValidate ValidationProvider.
      // https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#adding-errors-manually
      // Why is this needed? We are using a separate credit card validation library that is way better than the built-in
      // VeeValidate credit card validation BUT we still want to use VeeValidate because it can detect if the entire
      // form is valid or not (among other things). So this notifies VeeValidate that the field contains errors.
      validationProviderRef.applyResult({
        errors: [errorMessage],
        valid: false,
        failedRules: {}
      })
    },
    async validateCardNumber () {
      // only do extra validation if veevalidate validation passes
      const { valid } = await this.$refs.creditCardNumberProvider.validate()
      if (valid) {
        if (!this.accountHasUsdCurrencyCode && USD_ONLY_CREDIT_CARDS.includes(this.creditCardType)) {
          const cardName = FundingConstants.CreditCardName[this.creditCardType]
          this.addVeeValidateError(
            this.$refs.creditCardNumberProvider,
            this.$t(`${this.i18nPrefix}.validationErrors.invalidCardType`, { creditCardType: cardName })
          )
        } else if (!this.$cardFormat.validateCardNumber(this.creditCard.creditCardNumber)) {
          this.addVeeValidateError(
            this.$refs.creditCardNumberProvider,
            this.$t(`${this.i18nPrefix}.validationErrors.invalidCreditCardNumber`)
          )
        }
      }
    },
    async validateCardExpirationDate () {
      // only do extra validation if veevalidate validation passes
      const { valid } = await this.$refs.creditCardExpirationDateProvider.validate()
      if (valid) {
        const [month, year] = this.creditCard.expirationDate.split(' / ')
        if (!month || !year || !this.$cardFormat.validateCardExpiry(month, year)) {
          this.addVeeValidateError(
            this.$refs.creditCardExpirationDateProvider,
            this.$t(`${this.i18nPrefix}.validationErrors.invalidExpirationDate`)
          )
        }
      }
    },
    async validateCardCvc () {
      // only do extra validation if veevalidate validation passes
      const { valid } = await this.$refs.creditCardCvcProvider.validate()
      if (valid) {
        if (!this.$cardFormat.validateCardCVC(this.creditCard.cvc, this.cardBrand)) {
          this.addVeeValidateError(
            this.$refs.creditCardCvcProvider,
            this.$t(`${this.i18nPrefix}.validationErrors.invalidCVC`)
          )
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables";

  #add-card-nickname {
    font-size: .875rem;
    font-weight: 500;
    color: var(--t-color-text-link);

    &:hover {
      text-decoration: underline;
    }
  }

  .disabled {
    background-color: $gray-100;
  }
</style>
