export const EmailTemplateMessageType = Object.freeze({
  SUBJECT: 'subject',
  MESSAGE: 'message',
  CLOSING: 'closing'
})

export const EmailTemplateMustacheTags = Object.freeze({
  RECIPIENT_NAME: 'recipient_name',
  MESSAGE: 'message',
  REWARD_NAME: 'reward_name',
  FROM_NAME: 'from_name'
})

// These values are the result of manual testing and finding the right values to work on all sized screens
export const PREVIEW_TEMPLATE_DEFAULT_WIDTH = 225
export const PREVIEW_TEMPLATE_MAX_WIDTH = 600
export const PREVIEW_TEMPLATE_MIN_WIDTH = 270
export const PREVIEW_TEMPLATE_WIDTH_SCALE = 0.28
export const PREVIEW_TEMPLATE_FONT_DEFAULT = 5
export const PREVIEW_TEMPLATE_FONT_SCALE = 45
