/* eslint import/first: 0 */

require('froala-editor/js/froala_editor.pkgd.min')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')

require('./tango-custom-theme.css')
require('./tango-custom-popup.js')

// Weird behavior with VueFroala and using Core-JS 3+, you _have_ to explicitly import the distributed code
// which is a no-no on the repository's end. They fixed this in version 3+, but that version has breaking changes
// from version 2 that impact Tango specific Froala configuration.
// https://github.com/froala/vue-froala-wysiwyg/issues/104#issuecomment-520313429
import VueFroala from 'vue-froala-wysiwyg/dist/vue-froala.min.js'

function updatePlaceholderText (editor, placeholderText) {
  editor.opts.placeholderText = placeholderText
  editor.placeholder.refresh()
}

function FroalaPlugin (Vue) {
  // Set the Froala Key as required by the library to avoid invalid license message
  localStorage.FEK = process.env.VUE_APP_FROALA_EDITOR_KEY

  Vue.use(VueFroala)
  Vue.prototype.$froalaOptions = function (options) {
    return Object.assign({
      placeholderText: 'Default Placeholder Text',
      toolbarButtons: ['bold', 'italic', 'underline', '|', 'formatOL', 'formatUL', 'insertLink', 'html', '|', 'undo',
        'redo', '|', 'color', '|', 'mtButton'],
      charCounterCount: false,
      pluginsEnabled: ['codeView', 'codeBeautifier', 'link', 'lists', 'mtPlugin', 'colors', 'charCounter'],
      events: {
        'froalaEditor.blur': (e, editor) => {
          updatePlaceholderText(editor, editor.pushPlaceHolderText)
        },
        'froalaEditor.focus': (e, editor) => {
          if (!editor.pushPlaceHolderText) {
            editor.pushPlaceHolderText = editor.opts.placeholderText
          }
          updatePlaceholderText(editor, '')
        }
      }
    }, options)
  }
}

export {
  FroalaPlugin
}
