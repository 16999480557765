import AuthenticationProvider from '../components/shared/security/authentication-provider'
import { LOGIN_SET_ORIGINAL_ROUTE } from '../store/mutation-types'
import store from '../store/index'
import { TOAST } from '../store/action-types'
import i18n from '../i18n'
import { hasAccessLevel } from '../components/shared/security/authorized-plugin'

class AuthenticatedGuard {
  static hasRouteAuthority (to) {
    const loggedInClaims = AuthenticationProvider.getClaims() || []

    const hasRequiredAuthorities = to.meta.authorities && to.meta.authorities.length
    const hasInterchangeableAuthorities = to.meta.hasOneOfAuthorities && to.meta.hasOneOfAuthorities.length
    const requiredAccessLevel = to.meta.accessLevel

    if (!hasRequiredAuthorities && !hasInterchangeableAuthorities) {
      return true
    }

    const hasAllRequiredAuthorities = hasRequiredAuthorities
      ? !to.meta.authorities.some((a) => !loggedInClaims.includes(a))
      : true

    const hasOneOfInterchangeableAuthorities = hasInterchangeableAuthorities
      ? to.meta.hasOneOfAuthorities.some((a) => loggedInClaims.includes(a))
      : true

    const hasRequiredAccessLevel = requiredAccessLevel ? hasAccessLevel(requiredAccessLevel) : true

    return hasAllRequiredAuthorities && hasOneOfInterchangeableAuthorities && hasRequiredAccessLevel
  }

  static isLoggedIn () {
    const loggedInClaims = AuthenticationProvider.getClaims() || []
    return loggedInClaims.includes('IS_LOGGED_IN')
  }

  static use (router) {
    router.beforeEach((to, from, next) => {
      if (document.location.search &&
        to.fullPath.includes('confirm_access')) {
        const params = document.location.search
        window.history.replaceState({}, document.title, '/#/confirm_access')
        next('/confirm_access' + params)
      } else if (document.location.search &&
        to.fullPath.includes('oauth_authorize')) {
        const params = document.location.search
        window.history.replaceState({}, document.title, '/#/oauth_authorize')
        next('/aouth_authorize' + params)
      } else if (AuthenticatedGuard.hasRouteAuthority(to)) {
        next()
      } else {
        if (store) {
          if (AuthenticatedGuard.isLoggedIn()) {
            store.dispatch(TOAST, {
              html: i18n.t('portalfrontendApp.error.notAuthorized'),
              type: 'danger'
            })
          } else {
            store.commit(LOGIN_SET_ORIGINAL_ROUTE, to)
            next({ name: 'Login' })
          }
        }
      }
    })
  }
}

export default AuthenticatedGuard
