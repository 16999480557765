import store from '../../../store'
import { TOAST } from '../../../store/action-types'

function toast (message, type, variant, duration) {
  store.dispatch(TOAST, {
    html: message,
    type,
    variant,
    duration
  })
}

function ToastPlugin (Vue) {
  Vue.prototype.$toast = toast
}

export {
  ToastPlugin
}
