import axios from 'axios'
import AuthenticationProvider from '@/components/shared/security/authentication-provider'
import i18n from '@/i18n'
import store from '@/store'
import { TOAST, LOGIN_SET_ERROR } from '@/store/action-types'
import { getRgFrontendHost } from '@/components/shared/rgfe-api'
import router from '@/router'
import sessionManager, { SessionManager } from '@/components/shared/security/session-manager'
import AuthRoutes from '@/router/auth.routes'
import { isSplitEnabled } from '@/components/shared/split/split'
import { RG_7198_REMOVE_403_IGNORE_LIST } from '@/components/shared/split/split-constants'
import { BannerMessageTypes } from '@/components/authentication/enum/BannerMessageType'
import {
  removeAuthSessionKey,
  setAuthSessionKey,
  validateFullAuthSessionTime
} from '@/components/shared/security/auth-session-validation'

const httpClient = axios.create({ withCredentials: true })

// Credential injector interceptor (exporting only for test access)
export const requestInterceptor = (config) => {
  config.headers[SessionManager.SESSION_ID_HEADER_NAME] = sessionManager.sessionId
  config.baseURL = getRgFrontendHost()

  validateFullAuthSessionTime()

  return config
}

httpClient.interceptors.request.use(requestInterceptor)

// Interceptor applied to all successful responses (exporting only for test access)
export const successResponseInterceptor = (response) => {
  const sessionId = response.headers?.[SessionManager.SESSION_ID_HEADER_NAME]
  if (sessionId) {
    sessionManager.sessionId = sessionId
  }
  // Anytime we successfully interact with the backend we want to refresh the frontend token
  setAuthSessionKey()

  return Promise.resolve(response)
}

// Error auth interceptor
const rejectedResponseInterceptor = (error) => {
  // handle specific errors differently
  if (error.response?.status === 401) handle401(error)
  else if (error.response?.status === 403) handle403(error)

  // always reject the promise
  return Promise.reject(error)
}

export function handle401 (_error) {
  // map the list of auth routes down to just their names to compare to the current route
  const authRoutes = AuthRoutes.map(route => route.name)

  const currentRoute = router.currentRoute.name

  // if the current route is not one of the auth routes, a 401 should redirect to login
  if (currentRoute && !authRoutes.includes(currentRoute)) {
    removeAuthSessionKey()
    store.dispatch(LOGIN_SET_ERROR,
      {
        title: i18n.t('login.messages.error.forceReauthentication'),
        type: BannerMessageTypes.INFO
      }
    )
    AuthenticationProvider.clearTokens()
    router.push({ name: 'Login' })
    console.error(_error)
  }
}

function handle403 (error) {
  // I'm not sure of the full history of this collection of paths that we're okay with returning 403s for, but our best
  // guess is that some of these routes are "expected" to return 403s and we're okay with that and do not want to show
  // the banner. This list used to be consulted for 401s as well, but that is no longer the case - matt.taylor
  const ignoredPaths = [
    'api/mfa',
    'api/account',
    'api/authenticate',
    'api/orders/lineItem',
    'api/accounts', // TODO: Figure out how we want to handle granular 403's (CC: @justin.walker and @peter.nelson)
    '/api/customers',
    '/api/mfa/verify'
  ]

  // when this split is on, ignore the list above
  // remove the list above when we clean up this split if we are keeping the "on" behavior
  const noIgnoreList = isSplitEnabled(RG_7198_REMOVE_403_IGNORE_LIST)

  // toast that the user is not authorized if the path is not one of the paths in the ignore list
  if (noIgnoreList || !ignoredPaths.some((r) => error.config?.url?.includes(r))) {
    console.error(`User was unable to access ${error.config.url} with error code ${error.response.status}.`)
    store.dispatch(TOAST, {
      html: i18n.t('portalfrontendApp.error.notAuthorized'),
      type: 'danger'
    })
  }
}

httpClient.interceptors.response.use(successResponseInterceptor, rejectedResponseInterceptor)

function getHttpError (error, defaultErrorText) {
  const msgKey = error.response && error.response.data && error.response.data.i18nKey
    ? `portalfrontendApp.${error.response.data.i18nKey}`
    : null

  const alternativeMsgKey = error.response && error.response.data && error.response.data.i18nKey
    ? error.response.data.i18nKey
    : null

  const description = error.response && error.response.data && error.response.data.description
    ? error.response.data.description
    : null

  if (msgKey && msgKey !== i18n.t(msgKey)) {
    return i18n.t(msgKey)
  } else if (alternativeMsgKey && alternativeMsgKey !== i18n.t(alternativeMsgKey)) {
    return i18n.t(alternativeMsgKey)
  }

  if (defaultErrorText) {
    return defaultErrorText
  } else if (description) {
    return description
  }

  console.warn('Unable to localize error response from server: ', error)
  return defaultErrorText
}

function HttpClientPlugin (Vue) {
  Vue.prototype.$http = httpClient
  Vue.prototype.$httpError = getHttpError
}

export {
  httpClient as HttpClient,
  getHttpError as httpError,
  HttpClientPlugin
}
