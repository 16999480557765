import {
  AUTH_SESSION_KEY
} from '@/components/shared/constants/sessionstorage.constants'
import { handle401, HttpClient } from '@/components/shared/security/http-client'
import {
  AUTH_SESSION_ERROR,
  AUTH_SESSION_MILLISECONDS,
  AUTH_SESSION_TIMEOUT
} from '@/components/shared/constants/authority.constants'

export const validateFullAuthSessionTime = () => {
  const sessionTimeout = sessionStorage.getItem(AUTH_SESSION_KEY)
  const currentTime = new Date()
  const sessionExpired = sessionTimeout && currentTime > (new Date(sessionTimeout))
  // User hasn't interacted with the page or backend in the AUTH_SESSION_TIMEOUT length
  // force them to logout rather than refresh
  if (sessionExpired) {
    sessionStorage.removeItem(AUTH_SESSION_KEY)
    handle401(AUTH_SESSION_ERROR)
  }
}

export const validateAuthSessionKey = () => {
  const sessionTimeout = sessionStorage.getItem(AUTH_SESSION_KEY)
  const currentTime = new Date()
  if (sessionTimeout) {
    const sessionDate = new Date(sessionTimeout)
    const halfway = new Date(sessionDate.getTime() - AUTH_SESSION_TIMEOUT / 2 * AUTH_SESSION_MILLISECONDS)

    validateFullAuthSessionTime()

    // User session is halfway to the timeout.
    // The user has interacted with the page,
    // but isn't doing anything that would call the backend.
    // Call the backend to force session refresh.
    // Editing email templates can frequently cause this
    if (currentTime > halfway) {
      HttpClient.get('/api/users/sessionRefresh')
    }
  }
}

export const setAuthSessionKey = () => {
  sessionStorage.setItem(AUTH_SESSION_KEY, new Date(new Date().getTime() + AUTH_SESSION_TIMEOUT * AUTH_SESSION_MILLISECONDS).toString())
}
export const removeAuthSessionKey = () => {
  sessionStorage.removeItem(AUTH_SESSION_KEY)
}
