import VueI18n from 'vue-i18n'
import Vue from 'vue'
import deepmerge from 'deepmerge'
import numberFormats from './number-formats'

Vue.use(VueI18n)
const i18nHash = {}

// '../i18n' prefix allows webpack to predict dynamic requires, don't simplify path
require.context('../i18n', true, /\.json$/i).keys().forEach((file) => {
  const resourceParts = file.split('/')
  const lang = resourceParts[1]
  const module = resourceParts[2]
  i18nHash[lang] = deepmerge(i18nHash[lang] || {}, require(`../i18n/${lang}/${module}`))
})

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: i18nHash,
  numberFormats
})

export default i18n
