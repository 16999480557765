<template>
  <div
    v-if="showAnyBanner"
    id="banner-space"
  >
    <div
      :style="{height: alertHeight + 'px' }"
      class="banner-wrapper"
    />
    <div
      ref="stickyAlert"
      class="sticky-alert"
      :class="{'alert-sidebar-open': sidebarOpen }"
      :style="{top: alertY}"
    >
      <template v-for="banner in banners">
        <tc-banner-message
          v-if="showBanner(banner.tag)"
          :id="`${banner.tag}-banner`"
          :key="banner.tag"
          class="banner-content"
          :banner-message="getBannerMessage(banner.tag, banner.message)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {
  GET_PLATFORM,
  GET_SELECTED_CUSTOMER_ACCOUNT,
  PRINCIPLE_GET_AUTHENTICATED,
  PRINCIPLE_GET_USER
} from '@/store/get-types'
import { EMULATION_AUTH_COMPLETE } from '../../shared/security/auth-events'
import * as actionTypes from '@/store/action-types'
import { mapActions, mapGetters } from 'vuex'
import { AccountStatus } from '@/components/platform/accounts-user-management/enum/accountStatus'
import { ERROR, SUCCESS } from '@/components/shared/alert/snack-constants'
import { IS_LOGGED_IN } from '@/components/shared/constants/authority.constants'
import { BannerType, IconAlign } from '@/components/authentication/application-banner/bannerType'
import { BannerMessageTypes } from '@/components/authentication/enum/BannerMessageType'
import { PlatformKybStatus } from '@/dto/core/platform'
import TcBannerMessage from '@/components/authentication/TcBannerMessage.vue'

export default {
  name: 'ApplicationBanner',
  components: {
    TcBannerMessage
  },
  props: {
    sidebarOpen: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      i18nPrefix: 'portalfrontendApp.topBar',
      banners: [
        {
          tag: BannerType.EMULATION,
          message: {
            type: BannerMessageTypes.WARNING,
            icon: 'mdi-information-outline',
            iconAlignment: IconAlign.CENTER,
            title: '',
            description: ''
          }
        },
        {
          tag: BannerType.FROZEN,
          message: {
            type: BannerMessageTypes.WARNING,
            icon: 'mdi-information-outline',
            iconAlignment: IconAlign.CENTER,
            title: '',
            description: ''
          }
        },
        {
          tag: BannerType.KYB,
          message: {
            type: BannerMessageTypes.INFO,
            icon: 'mdi-information-outline',
            iconAlignment: IconAlign.TOP,
            title: this.$t('portalfrontendApp.topBar.kybPending.title'),
            description: this.$t('portalfrontendApp.topBar.kybPending.description')
          }
        }
      ],
      platformName: null,
      emulationAuthComplete: false,
      alertHeight: 0,
      alertY: 0,
      scrollY: 0
    }
  },
  computed: {
    ...mapGetters({
      currentPlatform: GET_PLATFORM
    }),
    account () {
      return this.$store.getters[PRINCIPLE_GET_USER]
    },
    selectedAccount () {
      return this.$store.getters[GET_SELECTED_CUSTOMER_ACCOUNT]
    },
    accountName () {
      return this.selectedAccount?.accountName
    },
    isEmulation () {
      return this.emulationAuthComplete && this.$isEmulationSession() && this.account
    },
    emulationID () {
      return this.$getEmulatedPlatformId()
    },
    isFrozen () {
      return this.$store.getters[PRINCIPLE_GET_AUTHENTICATED] && this.selectedAccount && this.selectedAccount.status === AccountStatus.FROZEN
    },
    isPlatformKybPending () {
      return PlatformKybStatus.UNDER_REVIEW === this.currentPlatform?.kybStatus
    },
    showAnyBanner () {
      return this.$hasAuthority(IS_LOGGED_IN) && (this.isEmulation || this.isFrozen || this.isPlatformKybPending)
    }
  },

  watch: {
    sidebarOpen: function () {
      this.handleResize()
      this.handleScroll()
    },
    selectedAccount: function () {
      this.handleResize()
      this.handleScroll()
    },
    // Because emulation id is stored for refresh when user first loads the saved id may be incorrect.
    // To show the correct value watch the id changing and set the emulation name correctly.
    emulationID: function () {
      this.init()
    }
  },
  async mounted () {
    this.$bus.$on(EMULATION_AUTH_COMPLETE, await this.init)
    if (this.$route.name !== 'EmulationTokenAuth') {
      if (this.$isEmulationSession()) {
        await this.init()
      }
    }
    await this.handleResize()
  },
  created () {
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('resize', this.handleResize, true)
  },
  destroyed () {
    this.$bus.$off(EMULATION_AUTH_COMPLETE, this.init)
    window.removeEventListener('scroll', this.handleScroll, true)
    window.removeEventListener('resize', this.handleResize, true)
  },
  methods: {
    ...mapActions({
      getPlatformAsync: actionTypes.GET_PLATFORM_ASYNC,
      getSelectedCustomerAsync: actionTypes.GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC,
      updateSelectedAccount: actionTypes.UPDATE_THE_SELECTED_ACCOUNT,
      setSelectedCustomerAccount: actionTypes.UPDATE_THE_SELECTED_ACCOUNT
    }),
    async init () {
      this.emulationAuthComplete = true

      try {
        const emulatedPlatform = await this.getPlatformAsync({ platformClientId: this.$getEmulatedPlatformId() })
        this.platformName = emulatedPlatform.name
        await this.getSelectedCustomerAsync(true) // the "true" parameter allows for a force reload of the selected customer
      } catch (error) {
      }
    },
    async handleResize () {
      await this.$nextTick()
      this.alertHeight = this.$refs.stickyAlert?.clientHeight
    },
    handleScroll () {
      this.alertHeight = this.$refs.stickyAlert?.clientHeight
      const windowScroll = window.scrollY
      const downScroll = this.scrollY < windowScroll
      const tallerThanAlert = windowScroll > this.alertHeight
      if (this.isMobile && downScroll && tallerThanAlert) {
        this.alertY = (this.alertHeight * -1) + 'px'
      }
      if (!downScroll) {
        this.alertY = 0
      }
      this.scrollY = window.scrollY ? window.scrollY : this.scrollY
    },
    async resendInvite () {
      this.$http.post(`/api/users/${this.account.userId}/invite`)
        .then(() => {
          this.$snack(this.$t('userManagement.invite.success'), false, SUCCESS)
        })
        .catch((error) => {
          console.error('Error inviting user', error)
          this.$snack(this.$t('userManagement.invite.failure'), false, ERROR)
        })
        .finally(() => {
          this.hideUserActionDialog()
        })
    },
    showBanner (bannerTag) {
      if (!this.$hasAuthority(IS_LOGGED_IN)) {
        return false
      }
      let shouldShow = false
      switch (bannerTag) {
        case BannerType.EMULATION:
          shouldShow = this.isEmulation
          break
        case BannerType.FROZEN:
          shouldShow = this.isFrozen
          break
        case BannerType.KYB:
          shouldShow = this.isPlatformKybPending
          break
      }
      return shouldShow
    },
    // Needed to insert state parameters after mount
    getBannerMessage (bannerTag, bannerMessage) {
      switch (bannerTag) {
        case BannerType.EMULATION:
          bannerMessage.title = this.$t(`${this.i18nPrefix}.emulation.banner`, { id: this.emulationID, name: this.platformName })
          break
        case BannerType.FROZEN:
          bannerMessage.title = this.$t(`${this.i18nPrefix}.frozenAccount`, { account: this.accountName })
          break
      }
      return bannerMessage
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/global.scss";
@import "~@/assets/scss/variables";

.banner-wrapper{
  position: relative;
  display: block;
  flex: 1;
}

.row.banner-content{
  min-height: 40px;
  margin: 5px 5px !important;
  font-size: 16px;
  font-weight: 400;
  &.icon-top{
    align-items: flex-start;
  }
  p{
    margin-top: 0;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.sticky-alert{
  background-color:#fff;
  position: fixed;
  top:0;
  width: calc(100% - 72px);
  left:62px;
  z-index: 9001;
  transition: left 300ms, width 300ms ease;
  margin:auto;

  &.alert-sidebar-open {
    width: calc(100% - 220px);
    left: 220px;
    @include media-breakpoint-up(md) {
      width: calc(100% - 233px);
    }
  }

  .info-icon {
    color:var(--t-color-text);
    margin-right:12px;
  }

  .link-button{
    font-weight: 500;
    color: var(--t-color-text-link);
    text-decoration: underline;
    margin-left:12px;
  }
}
</style>
