export const ACH_REQUEST_DETAILS_ROUTE = 'AchRequestDetails'
export const ACH_CONFIRM_SIGNER = 'AchConfirmSigner'
export const FUNDING_ROUTE = 'Funding'
export const ORDER_CHECKOUT_ROUTE = 'OrderCheckout'
export const ORDER_HISTORY_ROUTE = 'OrderHistory'
export const SEND_REWARDS_ROUTE = 'RewardFirstOrder'
export const SHOPPING_CART_ROUTE = 'ShoppingCart'
export const SIGNUP_ROUTE = 'Signup'
export const ORDER_HISTORY_LINE_ITEMS = 'OrderHistoryLineItems'
export const ORDER_HISTORY_SPECIFIC_ORDER = 'OrderHistorySpecificOrder'
export const ORDER_HISTORY_SPECIFIC_ORDER_SPLIT_OFF = 'OrderHistorySpecificOrderSplitOff'
export const ACCOUNTS_ROUTE = 'Accounts'
export const GROUP_ROUTE = 'Groups'
