export const TANGO_CARD_DEFAULT_CS_MESSAGE = '<p>If you have any problems or questions, please contact customer service at <a style="white-space:nowrap">1-877-55-TANGO (82646)</a> or <a href="mailto:cs&#64;tangocard.com" style="color:#5b5a5b">cs&#64;tangocard.com</a>.</p>'
export const TANGO_CARD_DEFAULT_CS_MESSAGE_R3 = 'If you have any problems or questions, please visit <a href="https://help.tangocard.com" style="white-space:nowrap;color:rgb( 91 , 90 , 91 )" target="_blank" rel="noopener noreferrer">help.tangocard.com</a>.'
export const TANGO_LOGO_URL = 'https://static.tangocard.com/assets/rewardlink/images/bhn_email_header.png'

export const EMAIL_TEMPLATE_LOGO_NOT_SET_VALUE = 'No Logo Defined via Management Tool'

export const EMAIL_TEMPLATE_LOGO_ALT_TEXT_NOT_SET_VALUE = 'No Logo Alt Text Defined via Management Tool'

export const FIRST_ROUTE_NAME = 'LandingRedirect'

export const CUSTOMERS_SIZE = 2000
