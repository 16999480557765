<template>
  <div
    id="strength"
    :style="{'background': colors[colorIndex], 'width': `${width}%`}"
  />
</template>

<script>
export default {
  name: 'PasswordStrengthBar',
  props: {
    passwordToCheck: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      colors: ['#FF0000', '#FFD12F', '#428AF5', '#26C126']
    }
  },
  computed: {
    strength () {
      const p = this.passwordToCheck
      let strength = 0

      // eslint-disable-next-line
      const regex = /[$-/:-?{-~!"^_`\[\]]/g;

      const lowerLetters = /[a-z]+/.test(p)
      const upperLetters = /[A-Z]+/.test(p)
      const numbers = /[0-9]+/.test(p)
      const symbols = regex.test(p)

      const flags = [lowerLetters, upperLetters, numbers, symbols]
      const passedMatches = flags.filter((isMatchedFlag) => {
        return isMatchedFlag === true
      }).length

      strength += 2 * p.length + ((p.length >= 10) ? 1 : 0)
      strength += passedMatches * 10

      // penalty (short password)
      strength = (p.length < 10) ? Math.min(strength, 20) : strength

      // penalty (poor variety of characters)
      strength = (passedMatches === 1) ? Math.min(strength, 10) : strength
      strength = (passedMatches === 2) ? Math.min(strength, 20) : strength
      strength = (passedMatches === 3) ? Math.min(strength, 40) : strength

      this.$emit('strength', strength)

      return strength
    },
    colorIndex () {
      if (this.strength <= 10) return 0
      if (this.strength <= 30) return 1
      if (this.strength <= 40) return 2
      return 3
    },
    width () {
      return 1 / this.colors.length * (this.colorIndex + 1) * 100
    }
  }
}
</script>

<style scoped lang="scss">
  #strength {
    height: 4px;
    border-radius: var(--t-border-size-4);
    margin-bottom: 8px;
  }
</style>
