import * as getTypes from '../get-types'
import * as actionTypes from '../action-types'
import * as mutationTypes from '../mutation-types'
import { HttpClient, httpError } from '../../components/shared/security/http-client'
import { hasAuthority } from '../../components/shared/security/authorized-plugin'
import { RECIPIENT_WHITELIST_MANAGE } from '../../components/shared/constants/authority.constants'

const state = {
  recipientWhitelist: null,
  whitelist: null,
  whitelistExists: null
}

const getters = {
  [getTypes.GET_RECIPIENT_WHITELIST]: state => state.recipientWhitelist,
  [getTypes.GET_RECIPIENT_WHITELIST_EXISTS]: state => !!(state.recipientWhitelist),
  [getTypes.GET_WHITELIST_RECIPIENT_EMAILS]: state => {
    return state.recipientWhitelist ? state.recipientWhitelist.recipients.map(rec => rec.email) : []
  }
}

const getRecipientWhitelistHttpCall = async () => {
  try {
    const orderWhitelistResponse = await HttpClient.get('/api/orderWhitelist/platform')
    return orderWhitelistResponse.data
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      // 404 is an expected error, since a whitelist could possibly not exist for the platform at the time of the call.
      console.error(error)
    }
    return null
  }
}

const actions = {
  async [actionTypes.GET_RECIPIENT_WHITELIST_ASYNC] ({ commit, getters }) {
    if (getters[getTypes.GET_RECIPIENT_WHITELIST_EXISTS]) {
      return getters[getTypes.GET_RECIPIENT_WHITELIST]
    } else {
      const recipientWhitelistGotten = await getRecipientWhitelistHttpCall()
      commit(mutationTypes.SET_RECIPIENT_WHITELIST, recipientWhitelistGotten)
      return recipientWhitelistGotten
    }
  },
  async [actionTypes.CREATE_RECIPIENT_WHITELIST] ({ commit }, recipientWhitelistDTO) {
    if (hasAuthority(RECIPIENT_WHITELIST_MANAGE)) {
      try {
        const createRecipientWhitelistResponse = await HttpClient.post('/api/orderWhitelist', recipientWhitelistDTO)
        const createdRecipientWhitelist = createRecipientWhitelistResponse.data
        commit(mutationTypes.SET_RECIPIENT_WHITELIST, createdRecipientWhitelist)
        return createdRecipientWhitelist
      } catch (error) {
        console.error(error)
        const httpErrorCaught = httpError(error, 'An error occurred when trying to create a recipient order whitelist for the current platform.')
        console.error(httpErrorCaught)
        throw error
      }
    } else {
      const cannotCreateRecipientWhitelistError = new Error('Cannot create a recipient whitelist.')
      console.error(cannotCreateRecipientWhitelistError)
      throw cannotCreateRecipientWhitelistError
    }
  },
  async [actionTypes.UPDATE_RECIPIENT_WHITELIST] ({ commit }, recipientWhitelistDTO) {
    if (hasAuthority(RECIPIENT_WHITELIST_MANAGE)) {
      try {
        const updateRecipientWhitelistResponse = await HttpClient.put('/api/orderWhitelist', recipientWhitelistDTO)
        const updatedRecipientWhitelist = updateRecipientWhitelistResponse.data
        commit(mutationTypes.SET_RECIPIENT_WHITELIST, updatedRecipientWhitelist)
        return updatedRecipientWhitelist
      } catch (error) {
        console.error(error)
        const httpErrorCaught = httpError(error, 'An error occurred when trying to update a recipient order whitelist for the current platform.')
        console.error(httpErrorCaught)
        throw error
      }
    } else {
      const cannotCreateRecipientWhitelistError = new Error('Cannot update a recipient whitelist.')
      console.error(cannotCreateRecipientWhitelistError)
      throw cannotCreateRecipientWhitelistError
    }
  }
}

const mutations = {
  [mutationTypes.SET_RECIPIENT_WHITELIST] (state, recipientWhitelist) {
    state.recipientWhitelist = recipientWhitelist
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
