import { DATA_HANDLE, SCRIPT_ID, SCRIPT_SRC, SCRIPT_TYPE } from './AdaChatbotConstants'
import { isNotProduction } from '@/components/shared/environment'

/**
 * AdaChatbot is an ES6 wrapper around the Ada embed2 script
 * the purpose of this is to encapsulate the functionality of the
 * chatbot and improve stability. The ada chatbot is initialized as
 * a Vue Plugin described in AdaChatbotPlugin.js file.
 *
 * Ada documentation:
 * https://adasupport.github.io/documentation/#introduction
 */
export default class AdaChatbot {
  constructor () {
    this._scriptFailure = false
    this._chatbotLoaded = false
    this._chatbotRunning = false
    this._metaFields = {}
    this._sensitiveMetaFields = {}

    if (window.adaEmbed) {
      console.debug('Ada Chatbot Container is initialized')
      this._chatbotLoaded = true
    } else {
      console.debug('Ada Chatbot Container not initialized')
    }
  }

  /**
   * Injector for adding AdaChatbot embed2 script to the DOM
   * @return {Promise<void>}
   */
  init () {
    return new Promise((resolve) => {
      let element = document.querySelector('script[src="' + SCRIPT_SRC + '"]')
      if (!element) {
        element = document.createElement('script')
        element.setAttribute('id', SCRIPT_ID)
        element.setAttribute('type', SCRIPT_TYPE)
        element.setAttribute('async', 'true')
        element.setAttribute('src', SCRIPT_SRC)
        element.setAttribute('data-lazy', 'true')
        element.onload = () => {
          element.setAttribute('data-loaded', 'true')
          resolve()
        }
        element.onerror = (error) => {
          console.error('https://static.ada.support unreachable.', error)
          this.setScriptFailure(true)
          resolve()
        }

        document.head.appendChild(element)
      } else if (element.hasAttribute('data-loaded')) {
        resolve()
      }
    })
  }

  /**
   * @typedef {Object} AdaEmbed
   * @property {Function} setMetaFields
   * @property {Function} setSensitiveMetaFields
   * @property {Function} triggerCampaign
   * @property {Function} start
   * @property {Function} stop
   * @property {Function} deleteHistory
   * @property {Function} reset
   * @property {Function} getInfo
   * @property {Function} toggle
   */
  /**
   * Private method to fetch the global adaEmbed object
   * @return {AdaEmbed}
   */
  _getChatbot () {
    return window.adaEmbed
  }

  /**
   * Did the chatbot script fail to load
   * @return {boolean}
   */
  isScriptFailure () {
    return this._scriptFailure
  }

  /**
   * Sets script failure flag
   * @param {boolean} failed
   * @return {boolean}
   */
  setScriptFailure (failed) {
    this._scriptFailure = failed
  }

  /**
   * Checks if the chatbot is running
   * @return {boolean}
   */
  isChatbotRunning () {
    return this._chatbotRunning
  }

  /**
   * Checks if the chatbot script is loaded into the DOM
   * @return {boolean}
   */
  isChatbotLoaded () {
    if (!this._chatbotLoaded && window.adaEmbed) {
      console.debug('Ada Chatbot is now loaded based on window.adaEmbed exist check')
      this._chatbotLoaded = true
    }
    return this._chatbotLoaded
  }

  /**
   * Async wrapper around the chatbot start method.
   * Ensures the script is loaded and the chatbot is not
   * running before attempting to start
   * @return {Promise<void>}
   */
  async startChatbot (setAdaSensitiveMetaFields) {
    if (!this.isScriptFailure()) {
      if (this.isChatbotLoaded() && !this.isChatbotRunning()) {
        // https://adasupport.github.io/documentation/#embed2-api-reference-actions-start
        await this._getChatbot().start({
          handle: DATA_HANDLE,
          crossWindowPersistence: false,
          testMode: isNotProduction(),
          metaFields: this._metaFields,
          toggleCallback: (isDrawerOpen) => {
            if (isDrawerOpen && setAdaSensitiveMetaFields) {
              setAdaSensitiveMetaFields()
            }
          }
        }).then(() => {
          this._chatbotRunning = true
          this.setMetaFields({}).then(() => {
            console.debug('Ada Chatbot has been started')
          })
        })
      } else if (this.isChatbotRunning()) {
        throw new Error('Attempted to start Ada Chatbot while it is already running')
      } else {
        throw new Error('Attempted to start Ada Chatbot before its loaded')
      }
    }
  }

  /**
   * Async wrapper around the chatbot stop method.
   * Ensures the script is loaded and the chatbot is
   * running before attempting to stop
   * @return {Promise<void>}
   */
  async stopChatbot () {
    if (!this.isScriptFailure()) {
      if (this.isChatbotRunning() && this.isChatbotLoaded()) {
        await this._getChatbot().stop()
        this._chatbotRunning = false
        this._metaFields = {}
        console.debug('Ada Chatbot has been stopped')
      } else {
        throw new Error('Attempted to stop Ada Chatbot before its running')
      }
    }
  }

  /**
   * Async wrapper around the chatbot reset method.
   * Ensures the script is loaded and the chatbot is
   * running before attempting to deleteHistory and
   * reset the chatbot. Also clears the local metaFields
   * @return {Promise<void>}
   */
  async resetChatbot () {
    if (!this.isScriptFailure()) {
      if (this.isChatbotRunning() && this.isChatbotLoaded()) {
        // https://adasupport.github.io/documentation/#embed2-api-reference-actions-deletehistory
        // Deletes the chatter used to fetch conversation logs for an end-user from storage. When a user opens a new Chat window a new chatter will be generated.
        await this._getChatbot().deleteHistory()
        // https://adasupport.github.io/documentation/#embed2-api-reference-actions-reset
        // Creates a new chatter and refreshes the Chat window
        await this._getChatbot().reset()
        this._metaFields = {}
      } else {
        throw new Error('Attempted to reset Ada Chatbot before its running')
      }
    }
  }

  /**
   * Async wrapper around the chatbot getInfo method.
   * Ensures the script is loaded and the chatbot is
   * running before attempting to fetch chatbot info
   * @return {Promise<object>}
   */
  async getInfo () {
    if (!this.isScriptFailure()) {
      if (this.isChatbotRunning() && this.isChatbotLoaded()) {
        return await this._getChatbot().getInfo()
      } else {
        throw new Error('Attempted to getInfo Ada Chatbot before its running')
      }
    }
  }

  /**
   * Async wrapper around the chatbot setMetaFields method.
   * Ensures the script is loaded and the chatbot is
   * running before attempting to set meta data. If data
   * already exists inside in this instance of the chatbot
   * any new meta data will assigned to the existing data.
   * @param {object} metaFields
   * @return {Promise<void>}
   */
  async setMetaFields (metaFields) {
    if (!this.isScriptFailure()) {
      if (typeof metaFields === 'object') {
        this._metaFields = Object.assign(this._metaFields, metaFields)
        if (this.isChatbotRunning() && this.isChatbotLoaded()) {
          await this._getChatbot().setMetaFields(this._metaFields)
        }
      } else {
        throw new Error('metaFields is not an Object')
      }
    }
  }

  /**
   * Async wrapper around the chatbot setSensitiveMetaFields method.
   * Metadata set here will only be stored a max of 24 hours
   * according to Ada's documentation.
   * Ensures the script is loaded and the chatbot is
   * running before attempting to set meta data. If data
   * already exists inside in this instance of the chatbot
   * any new meta data will assigned to the existing data.
   * @param {object} sensitiveMetaFields
   * @return {Promise<void>}
   */
  async setSensitiveMetaFields (sensitiveMetaFields) {
    if (!this.isScriptFailure()) {
      if (typeof sensitiveMetaFields === 'object') {
        this._sensitiveMetaFields = Object.assign(this._sensitiveMetaFields, sensitiveMetaFields)
        if (this.isChatbotRunning() && this.isChatbotLoaded()) {
          await this._getChatbot().setSensitiveMetaFields(this._sensitiveMetaFields)
        }
      } else {
        throw new Error('sensitiveMetaFields is not an Object')
      }
    }
  }

  /**
   * Async wrapper around the chatbot triggerCampaign method.
   * Ensures the script is loaded and the chatbot is
   * running before attempting to trigger a campaign. If
   * campaignMetaFields is set the data added to the existing
   * chatbot meta data.
   * @param {string} campaignKey
   * @param {object} [campaignMetaFields]
   * @return {Promise<void>}
   */
  async triggerCampaign (campaignKey, campaignMetaFields) {
    if (!this.isScriptFailure()) {
      if (this.isChatbotRunning() && this.isChatbotLoaded()) {
        if (campaignMetaFields) {
          await this.setMetaFields(campaignMetaFields)
        }
        await this._getChatbot().triggerCampaign(campaignKey)
      } else {
        throw new Error('Chatbot must be running before triggering a campaign')
      }
    }
  }

  /**
   * Async wrapper around the chatbot toggle method.
   * Ensures the script is loaded and the chatbot is
   * running before attempting to toggle the chatbot
   * iFrame window open. Will do nothing if it is
   * already open.
   * @return {Promise<void>}
   */
  async toggleChatbotOpen () {
    if (!this.isScriptFailure()) {
      if (this.isChatbotRunning() && this.isChatbotLoaded()) {
        const { isChatOpen } = await this.getInfo()
        if (!isChatOpen) {
          await this._getChatbot().toggle()
        } else {
          console.debug('Chatbot is already open')
        }
      } else {
        throw new Error('Chatbot must be running before toggling chatbot')
      }
    }
  }
}
