export const TemplatesConstants = Object.freeze({
  DEFAULT_PTID: 'P000000',
  MAX_CHARARCTER_LETTERHEAD: 64,
  MAX_CHARACTER_TO_NAME: 70,
  MAX_CHARACTER_FROM_NAME: 70,
  MAX_CHARACTER_MESSAGE: 150,
  MAX_CHARACTER_CSM: 160,
  EDITING_MESSAGE_NAME: 'message',
  EDITING_CUSTOMER_SUPPORT: 'support',
  PRINT_TEMPLATE_REDEMPTION: 'redemption-side',
  PRINT_TEMPLATE_NONREDEMPTION: 'non-redemption-side',
  PRINT_TEMPLATE_REDEMPTION_IMAGE_SIZE: '278w-326ppi',
  WARNING_LENGTH: 20,
  DANGER_LENGTH: 5,
  CHECK_ICON_HEIGHT: 14,
  CHECK_ICON_WIDTH: 16,
  CHECK_ICON_COLOR: '#FFFFFF',
  DEFAULT_LOGO_IMAGE_NAME: 'default-company-logo.png',
  DEFAULT_PRINT_TEMPLATE: () => ({
    name: null,
    companyLogo: null,
    backgroundColor: null,
    letterHeadTitle: null,
    customerServiceMessage: null,
    toName: null,
    fromName: null,
    message: null,
    usingDefaultCSMessage: true,
    useLogo: true
  })
})
