import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const state = {
  isTopbarVisible: true,
  isTopbarMenuVisible: false,
  firstEnter: true
}

const getters = {
  [getTypes.APP_GET_TOPBAR_VISIBLE]: state => state.isTopbarVisible,
  [getTypes.APP_GET_TOPBAR_MENU_VISIBLE]: state => state.isTopbarMenuVisible,
  [getTypes.APP_GET_TOPBAR_FIRST_ENTER]: state => state.firstEnter
}

const actions = {
  [actionTypes.UPDATE_TOPBAR_VISIBILITY] (context, value) {
    context.commit(mutationTypes.APP_SET_TOPBAR_VISIBLE, value)
  },
  [actionTypes.UPDATE_TOPBAR_MENU_VISIBLITY] (context, value) {
    context.commit(mutationTypes.APP_SET_TOPBAR_MENU_VISIBLE, value)
  },
  [actionTypes.UPDATE_TOPBAR_FIRST_ENTER] (context, value) {
    context.commit(mutationTypes.APP_SET_TOPBAR_FIRST_ENTER, value)
  },
  [actionTypes.RESET_TOPBAR] (context) {
    context.commit(mutationTypes.APP_SET_TOPBAR_MENU_VISIBLE, false)
    context.commit(mutationTypes.APP_SET_TOPBAR_FIRST_ENTER, true)
    context.commit(mutationTypes.APP_SET_TOPBAR_VISIBLE, true)
  }
}

const mutations = {
  [mutationTypes.APP_SET_TOPBAR_VISIBLE] (state, value) {
    state.isTopbarVisible = value
  },
  [mutationTypes.APP_SET_TOPBAR_MENU_VISIBLE] (state, value) {
    state.isTopbarMenuVisible = value
  },
  [mutationTypes.APP_SET_TOPBAR_FIRST_ENTER] (state, value) {
    state.firstEnter = value
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
