const blankOrder = () => ({
  steps: {
    RECIPIENTS: {
      displayName: 'Review & Modify',
      visited: false,
      selected: true,
      completed: false,
      previous: null,
      next: 'CHECKOUT'
    },
    CHECKOUT: {
      displayName: 'Checkout',
      visited: false,
      selected: false,
      completed: false,
      previous: 'RECIPIENTS',
      next: null
    }
  },
  groupAndAccount: {},
  selectedReward: {
    utid: null,
    currencyCode: null,
    brandImage: null,
    brandName: null,
    amount: null,
    faceValue: null,
    minValue: null,
    maxValue: null
  },
  selectedTemplate: {
    etid: null,
    emailSubject: null,
    senderName: null,
    message: null
  },
  fileUploadId: null,
  fileName: null,
  totalLines: null,
  orderTotalQuote: null,
  originalOrderAmount: null,
  feeTotal: null,
  showFirstAndLastName: true,
  showNotes: false,
  showDifferentReward: true,
  showDifferentRewardAmount: true,
  showDifferentEmailTemplate: true,
  showDifferentSubjectLine: false,
  showDifferentSenderName: false,
  showDifferentMessage: false,
  updateAllRewards: false,
  changedItems: [],
  selectedRewards: []
})

export class FileUploadOrder {
  constructor (newOrder = {}) {
    Object.assign(this, {
      ...blankOrder(),
      ...newOrder
    })
  }
}
