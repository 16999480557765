export const PLACE_ORDER = 'PLACE_ORDER'
export const ORDER_HISTORY = 'ORDER_HISTORY'
export const INDIVIDUAL_ORDER_HISTORY = 'INDIVIDUAL_ORDER_HISTORY'
export const ACCOUNT = 'ACCOUNT'
export const USER_MANAGEMENT = 'USER_MANAGEMENT'
export const FUNDING = 'FUNDING'
export const EMAIL_TEMPLATES = 'EMAIL_TEMPLATES'
export const INTEGRATIONS = 'INTEGRATIONS'
export const REWARD_RESEND = 'REWARD_RESEND'
export const REWARD_CREDENTIALS = 'REWARD_CREDENTIALS'
export const FUNDING_HISTORY = 'FUNDING_HISTORY'
export const FUNDING_RECEIPT = 'FUNDING_RECEIPT'
export const RECIPIENT_WHITELIST = 'RECIPIENT_WHITELIST'
export const VIEW_BALANCE = 'VIEW_BALANCE'
export const ORDER_REPORT = 'ORDER_REPORT'
export const API_KEYS = 'API_KEYS'
export const ROLE_MANAGEMENT = 'ROLE_MANAGEMENT'
export const ORDER_SETTINGS = 'ORDER_SETTINGS'
export const FREEZE_REWARDS = 'FREEZE_REWARDS'
export const CANCEL_REWARDS = 'CANCEL_REWARDS'
export const REISSUE_REWARDS = 'REISSUE_REWARDS'
export const REISSUE_EXPIRED_PROMO_LINK_REWARDS = 'REISSUE_EXPIRED_PROMO_LINK_REWARDS'
export const QUALTRICS_API_KEYS = 'QUALTRICS_API_KEYS'
export const SUPPORT_CASE_MANAGEMENT = 'SUPPORT_CASE_MANAGEMENT'
// Auth0 settings Management
export const AUTH_MANAGEMENT = 'AUTH_MANAGEMENT'

// Actions
export const ACTION_ACCESS = 'ACCESS'
export const ACTION_MANAGE = 'MANAGE'

// Levels
export const LEVEL_MANAGE = 'MANAGE'
export const LEVEL_VIEW = 'VIEW'
