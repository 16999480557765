import Vue from 'vue'
import Vuex from 'vuex'
import principle from './modules/principle'
import toaster from './modules/toaster'
import snacker from './modules/snacker'
import app from './modules/app'
import login from './modules/login'
import emailTemplates from './modules/email-templates'
import order from './modules/order'
import customerDefaults from './modules/customer-default'
import treatments from './modules/split-treatments'
import platform from './modules/platform'
import onboarding from './modules/onboard'
import account from './modules/account'
import topBar from './modules/top-bar'
import recipientWhitelist from './modules/recipient-whitelist'
import placeOrderRecipientWhitelist from './modules/place-order-recipient-whitelist'
import alerts from './modules/alerts'
import rewardFirstOrder from '@/store/modules/reward-first-order'
import catalog from '@/store/modules/catalog'
import blacklistedEmailDomains from '@/store/modules/blacklisted-email-domains'
import emailStatuses from '@/store/modules/email-statuses'
import { shouldShowDevTools } from '@/components/shared/environment'

if (shouldShowDevTools()) {
  Vue.config.devtools = true
}
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    principle,
    toaster,
    snacker,
    app,
    login,
    emailTemplates,
    order,
    customerDefaults,
    treatments,
    platform,
    onboarding,
    topBar,
    account,
    recipientWhitelist,
    placeOrderRecipientWhitelist,
    alerts,
    rewardFirstOrder,
    catalog,
    blacklistedEmailDomains,
    emailStatuses
  },
  plugins: []
})
