const blankOrder = () => ({
  groupAndAccount: {},
  recipientsIdCount: 0,
  steps: {
    RECIPIENTS: {
      displayName: 'Recipients',
      visited: false,
      selected: true,
      completed: false,
      previous: null,
      next: 'REWARD'
    },
    REWARD: {
      displayName: 'Reward',
      visited: false,
      selected: false,
      completed: false,
      previous: 'RECIPIENTS',
      next: 'EMAILTEMPLATE'
    },
    EMAILTEMPLATE: {
      displayName: 'Email Template',
      visited: false,
      selected: false,
      completed: false,
      previous: 'REWARD',
      next: 'CHECKOUT'
    },
    CHECKOUT: {
      displayName: 'Checkout',
      visited: false,
      selected: false,
      completed: false,
      previous: 'EMAILTEMPLATE',
      next: null
    }
  },
  blastOptionsOpen: false,
  enteringBlastModeOverlay: false,
  exitingBlastModeOverlay: false,
  showFirstAndLastName: true,
  showNotes: false,
  showDifferentReward: false,
  showDifferentRewardAmount: false,
  showDifferentEmailTemplate: false,
  showDifferentSubjectLine: false,
  showDifferentSenderName: false,
  showDifferentMessage: false,
  blastMode: false,
  uploadId: null,
  updatedSinceUpload: true,
  selectedRewards: [],
  recipients: [],
  email: {
    senderName: '',
    etid: '',
    messageBlock: '',
    emailSubject: ''
  },
  reward: {
    brandKey: null,
    utid: null,
    rewardName: null,
    rewardImage: '',
    rewardAmount: {
      currencyCode: '',
      value: null
    }
  },
  orderTotalQuote: null,
  originalOrderAmount: null,
  feeTotal: null,
  paymentSource: null,
  paymentSourceLabel: null
})

export class Order {
  constructor (newOrder = {}) {
    Object.assign(this, {
      ...blankOrder(),
      ...newOrder
    })
  }
}
