<template>
  <div class="text-center maintenance-message">
    <h1>{{ title }}</h1>
    <p v-sanitize="message" />
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
  props: {
    title: {
      type: String,
      required: false,
      default: function () { return this.$t('maintenance.default.title') }
    },
    message: {
      type: String,
      required: false,
      default: function () { return this.$t('maintenance.default.message', { feature: this.featureName }) }
    },
    featureName: {
      type: String,
      required: false,
      default: function () { return this.$t('maintenance.default.feature') }
    }
  }
}
</script>

<style scoped>

</style>
