<template>
  <transition
    enter-active-class="animated fadeIn fastest"
    leave-active-class="animated fadeOut fastest"
  >
    <div
      v-if="html && html.length"
      id="toaster"
      class="fs-unmask"
      :class="toastClass"
    >
      <div
        id="toast"
        :class="`alert alert-${type} alert-dismissible`"
        role="alert"
      >
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div
          id="toast-html"
          v-sanitize="html"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { TOAST_GET_HTML, TOAST_GET_TYPE, TOAST_GET_DURATION, TOAST_GET_VARIANT } from '../../store/get-types'
import { TOAST_SET_HTML } from '../../store/mutation-types'
import { MESSAGE_DISPLAYED } from '@/components/shared/segment/track-messaging'

export default {
  name: 'Toaster',
  data () {
    return {
      timeout: null
    }
  },
  computed: {
    ...mapGetters({
      html: TOAST_GET_HTML,
      type: TOAST_GET_TYPE,
      duration: TOAST_GET_DURATION,
      variant: TOAST_GET_VARIANT
    }),
    sidebarVisible () {
      return typeof this.$route.meta.sidebarVisible === 'undefined' ? true : this.$route.meta.sidebarVisible
    },
    toastClass () {
      return {
        'sidebar-visible': this.sidebarVisible,
        'top-right-sm': this.topRightSm
      }
    },
    topRightSm () {
      return this.variant === 'top-right-sm'
    }
  },
  watch: {
    html (newHtml) {
      if (newHtml && newHtml.length) {
        this.sendSegmentEvent(newHtml)

        if (this.duration > 0) {
          if (this.timeout) {
            clearTimeout(this.timeout)
            this.timeout = null
          }

          this.timeout = setTimeout(() => {
            this.close()
          }, this.duration)
        }
      }
    }
  },
  mounted () {
    if (this.html) {
      this.sendSegmentEvent(this.html)
    }
  },
  methods: {
    sendSegmentEvent (msg) {
      this.$segment.track(MESSAGE_DISPLAYED, {
        type: 'toast',
        message: msg
      })
    },
    close () {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.$store.commit(TOAST_SET_HTML, '')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/variables";

  #toaster {
    width: 80vw;
    margin: 2em 10vw 2em 10vw;
    top: 60px;
    left: 0;
    position: fixed;
    z-index: $zindex-toast;
    background-color: var(--t-color-surface);

    &.top-right-sm {
      width: 400px;
      margin: 0;
      top: 10px;
      right: 10px;
      left: initial;
      position: fixed;
      z-index: 9004;
      background-color: var(--t-color-surface);

      #toast {
        padding: 30px;

        &.alert-black {
          background: var(--t-color-surface-inverted);
          color: var(--t-color-text-inverted);
          box-shadow: 0px 4px 8px 1px rgb(0 0 0 / 30%);

          &:hover {
            span {
              color: var(--t-color-text-inverted);
            }
          }
        }
      }
    }

    &.sidebar-visible {
      /* If we need specific styling when the sidebar is open it can go here. */
    }

    #toast {
      margin: 0 auto;
    }
  }
</style>
