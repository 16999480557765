export const ACCOUNT_SWITCHED = 'Account Switched'
export const ACCOUNT_SWITCHER_OPENED = 'Account Switcher Opened'
export const PLACE_ORDER_NAV_CLICKED = 'Place Order Nav Clicked'
export const ORDERS_NAV_CLICKED = 'Orders Nav Clicked'
export const TEMPLATES_NAV_CLICKED = 'Templates Nav Clicked'
export const FUNDING_NAV_CLICKED = 'Funding Nav Clicked'
export const REPORTS_NAV_CLICKED = 'Reports Nav Clicked'
export const QUALTRICS_NAV_CLICKED = 'Qualtrics Nav Clicked'
export const TEAM_SETTINGS_NAV_CLICKED = 'Team Settings Nav Clicked'
export const REFERRAL_BUTTON_CLICKED = 'Referral Button Clicked'
export const HELP_BUTTON_CLICKED = 'Help Button Clicked'
export const MY_PROFILE_NAV_CLICKED = 'My Profile Nav Clicked'
