import GeographyService from '@/components/shared/GeographyService'

export class AddressPhysical {
  constructor (address) {
    // handle address being passed as null, if we deconstruct the address in the constructor with a default
    // of {}, it lets null through (since it's a value and not undefined) and then we get a runtime error
    const { companyName, streetLine1, streetLine2, city, stateOrProvince, postalCode, country } = address || {}

    this.companyName = companyName || null
    this.streetLine1 = streetLine1 || null
    this.streetLine2 = streetLine2 || null
    this.city = city || null
    this.stateOrProvince = stateOrProvince || null
    this.postalCode = postalCode || null
    this.country = country || null
  }

  get stateOrProvinceName () {
    return GeographyService.getState(this.stateOrProvince, this.country)
  }
}
