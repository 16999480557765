import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import { DEFAULT_TIMEOUT } from '@/components/shared/alert/snack-constants'

const state = {
  message: '',
  loading: false,
  variant: '',
  timeout: DEFAULT_TIMEOUT,
  linkUrl: '',
  linkText: '',
  callback: null
}

const getters = {
  [getTypes.SNACK_GET_MESSAGE]: state => state.message,
  [getTypes.SNACK_GET_LOADING]: state => state.loading,
  [getTypes.SNACK_GET_VARIANT]: state => state.variant,
  [getTypes.SNACK_GET_TIMEOUT]: state => state.timeout || DEFAULT_TIMEOUT,
  [getTypes.SNACK_GET_LINK_URL]: state => state.linkUrl,
  [getTypes.SNACK_GET_LINK_TEXT]: state => state.linkText,
  [getTypes.SNACK_GET_CALLBACK]: state => state.callback
}

const mutations = {
  [mutationTypes.SNACK_SET_MESSAGE] (state, message) {
    state.message = message
  },

  [mutationTypes.SNACK_SET_LOADING] (state, loading) {
    state.loading = loading
  },

  [mutationTypes.SNACK_SET_VARIANT] (state, variant) {
    state.variant = variant
  },

  [mutationTypes.SNACK_SET_TIMEOUT] (state, timeout) {
    state.timeout = timeout
  },

  [mutationTypes.SNACK_SET_LINK_URL] (state, linkUrl) {
    state.linkUrl = linkUrl
  },

  [mutationTypes.SNACK_SET_LINK_TEXT] (state, linkText) {
    state.linkText = linkText
  },

  [mutationTypes.SNACK_SET_CALLBACK] (state, callback) {
    state.callback = callback
  }
}

const actions = {
  [actionTypes.SNACK] (context, { message, loading, variant, timeout, linkUrl, linkText, callback }) {
    context.commit(mutationTypes.SNACK_SET_MESSAGE, message)
    context.commit(mutationTypes.SNACK_SET_LOADING, loading || false)
    context.commit(mutationTypes.SNACK_SET_VARIANT, variant || '')
    context.commit(mutationTypes.SNACK_SET_TIMEOUT, timeout)
    context.commit(mutationTypes.SNACK_SET_LINK_URL, linkUrl)
    context.commit(mutationTypes.SNACK_SET_LINK_TEXT, linkText)
    context.commit(mutationTypes.SNACK_SET_CALLBACK, callback)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
