import libJwt from 'jsonwebtoken'
import { HttpClient } from '@/components/shared/security/http-client'
import Vue from 'vue'
import { get, set } from 'tiny-cookie'
import { isProduction } from '@/components/shared/environment'
import store from '@/store'
import { PRINCIPLE_CLEAR_USER, PRINCIPLE_GET_ASYNC } from '@/store/action-types'
import { GET_SELECTED_CUSTOMER_ACCOUNT, PRINCIPLE_GET_USER } from '@/store/get-types'
import * as AccessLevel from '@/components/shared/constants/access-levels.constants'

export const STORAGE_API_KEY = 'authenticationToken'
export const STORAGE_REFRESH_KEY = 'refreshToken'
export const STORAGE_RESTORE_KEY = 'restoreToken'
export const SESSION_ID_KEY = '__rg_ssid'
export const HIDE_MFA_PROMPT_KEY = 'mfaPrompt'
export const MFA_REMEMBER_ME_COOKIE_DURATION_IN_DAYS = 60

class AuthenticationProvider {
  static getToken () {
    return sessionStorage.getItem(STORAGE_API_KEY) ||
      localStorage.getItem(STORAGE_API_KEY)
  }

  static getRefreshToken () {
    return sessionStorage.getItem(STORAGE_API_KEY) ||
      localStorage.getItem(STORAGE_API_KEY)
  }

  static getRestoreToken () {
    return sessionStorage.getItem(STORAGE_RESTORE_KEY) ||
      localStorage.getItem(STORAGE_RESTORE_KEY)
  }

  static setToken (jws) {
    if (jws === null) {
      return
    }

    sessionStorage.setItem(STORAGE_API_KEY, jws)
    localStorage.setItem(STORAGE_API_KEY, jws)
  }

  static setMfaCookie (mfaRememberCookieName, mfaRememberCookieValue) {
    if (mfaRememberCookieName) {
      const cookieOptions = { expires: MFA_REMEMBER_ME_COOKIE_DURATION_IN_DAYS }
      if (process.env.VUE_APP_SOCIAL_COOKIE_DOMAIN) {
        cookieOptions.domain = process.env.VUE_APP_SOCIAL_COOKIE_DOMAIN
      }
      set(mfaRememberCookieName, mfaRememberCookieValue, cookieOptions)
    }
  }

  static setRefreshToken (jws) {
    if (jws === null) {
      return
    }
    sessionStorage.setItem(STORAGE_REFRESH_KEY, jws)
    localStorage.setItem(STORAGE_REFRESH_KEY, jws)
  }

  static setRestoreToken (jws) {
    if (jws === null) {
      return
    }

    sessionStorage.setItem(STORAGE_RESTORE_KEY, jws)
    localStorage.setItem(STORAGE_RESTORE_KEY, jws)
  }

  static clearTokens () {
    sessionStorage.removeItem(STORAGE_API_KEY)
    localStorage.removeItem(STORAGE_API_KEY)
    sessionStorage.removeItem(STORAGE_REFRESH_KEY)
    localStorage.removeItem(STORAGE_REFRESH_KEY)
    sessionStorage.removeItem(STORAGE_RESTORE_KEY)
    localStorage.removeItem(STORAGE_RESTORE_KEY)
    store.dispatch(PRINCIPLE_CLEAR_USER)
  }

  static async checkMfaRemembered () {
    try {
      // withCredentials allows for cookies to be sent
      const { data: { jwt } } = await HttpClient.get('/api/mfa/remembered')
      const { idToken, idRefreshToken } = jwt || {}

      if (idToken) {
        AuthenticationProvider.setToken(idToken)
        AuthenticationProvider.setRefreshToken(idRefreshToken)
      }

      return jwt
    } catch (error) {
      console.error(`Error calling /api/mfa/remembered: ${error}`)
    }

    return null
  }

  static challengeMfaToken (code, rememberDevice = false) {
    return HttpClient.post(`/api/mfa/verify?code=${code}&rememberDevice=${rememberDevice}`)
      .then((response) => {
        const {
          headers: { 'x-mfarm-name': mfaRememberCookieName, 'x-mfarm-value': mfaRememberCookieValue } = {},
          data: body
        } = response

        if (body && body.idToken) {
          AuthenticationProvider.setToken(body.idToken)
          AuthenticationProvider.setRefreshToken(body.idRefreshToken)
        }

        if (mfaRememberCookieName && mfaRememberCookieValue) {
          AuthenticationProvider.setMfaCookie(mfaRememberCookieName, mfaRememberCookieValue)
        }

        AuthenticationProvider.refreshPrincipleUser()

        return body
      })
  }

  static authenticate (creds) {
    // withCredentials allows for cookies to be sent
    return HttpClient.post('api/authenticate', creds, { withCredentials: true })
      .then((response) => {
        const body = response.data
        if (body && body.jwt && body.jwt.idToken) {
          AuthenticationProvider.setToken(body.jwt.idToken)
        }

        AuthenticationProvider.refreshPrincipleUser()

        return body
      })
  }

  static async updateCustomerAndAccountForToken (customerID, accountID) {
    if (!Vue.prototype.$isEmulationSession()) {
      const response = await HttpClient.post(`api/authenticate/updateActiveAccount/${customerID}/${accountID}`)
      const body = response.data
      if (body && body.idToken) {
        AuthenticationProvider.setToken(body.idToken)
        AuthenticationProvider.setRefreshToken(body.idRefreshToken)
      }
      await AuthenticationProvider.refreshPrincipleUser()
    }
  }

  static authenticateWithEmulationToken (token) {
    const payload = { emulationToken: token }
    return HttpClient.post('api/authenticate/emulate', payload)
      .then((resp) => {
        const body = resp.data

        AuthenticationProvider.setToken(body.emulationToken)
        AuthenticationProvider.setRefreshToken(body.idRefreshToken)
        AuthenticationProvider.setRestoreToken(body.emulationRestoreToken)

        AuthenticationProvider.refreshPrincipleUser()

        return body
      })
  }

  // Only used by JwsRefresher.vue
  static getJwsPayload () {
    const jws = AuthenticationProvider.getToken()
    if (!jws) {
      return null
    }

    return libJwt.decode(jws)
  }

  static getEmulatedPlatformId () {
    const user = store.getters[PRINCIPLE_GET_USER]
    return user?.emulationPlatformId
  }

  // NOTE: This will be replaced in the near future
  static getCurrentAccessLevel () {
    const user = store.getters[PRINCIPLE_GET_USER]
    const selectedAccount = store.getters[GET_SELECTED_CUSTOMER_ACCOUNT]

    if (!user || !selectedAccount) {
      console.warn('Cannot determine access level, missing user or selected account information')
      return null
    }

    let accessLevel = null

    const { accessLevelPermissionInformation } = user
    if (accessLevelPermissionInformation) {
      if (accessLevelPermissionInformation.userHasOrganizationAccess) {
        accessLevel = AccessLevel.PLATFORM
      } else if (accessLevelPermissionInformation.customerIds.includes(selectedAccount.customerID)) {
        accessLevel = AccessLevel.GROUP
      } else if (accessLevelPermissionInformation.accountIds.includes(selectedAccount.accountID)) {
        accessLevel = AccessLevel.ACCOUNT
      }
    }

    return accessLevel
  }

  static isEmulationSession () {
    return !!AuthenticationProvider.getEmulatedPlatformId()
  }

  static isProd () {
    return isProduction()
  }

  static async refreshPrincipleUser () {
    // The payload here is for force reloading the user which is always what we want to ensure we get the latest set
    // of authorities.
    await store.dispatch(PRINCIPLE_GET_ASYNC, true)
  }

  static getClaims () {
    const user = store.getters[PRINCIPLE_GET_USER]
    return user?.authorities ?? null
  }

  static getSessionId () {
    return get(SESSION_ID_KEY)
  }

  static setSessionId (sessionId) {
    set(SESSION_ID_KEY, sessionId)
  }

  static getIdentityProviders () {
    const user = store.getters[PRINCIPLE_GET_USER]
    if (!user?.identityProviders) {
      // Copies legacy behavior
      return null
    }
    const identityProviders = user.identityProviders
    return identityProviders.map((identityProvider) => identityProvider.name)
  }
}

export default AuthenticationProvider
