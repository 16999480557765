export const REWARD_LINK_DESCRIPTION = 'reward link'

export class RewardType {
  constructor ({ rewardTypeID, description = '' }) {
    this.rewardTypeID = rewardTypeID
    this.description = description
  }

  get isRewardLink () {
    return this.description === REWARD_LINK_DESCRIPTION
  }

  toJSON () {
    return {
      rewardTypeID: this.rewardTypeID,
      description: this.description
    }
  }
}
