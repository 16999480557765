import * as getTypes from '../get-types'
import * as types from '../mutation-types'
import * as defaultTypes from '../../components/shared/constants/localstorage.constants'
import * as actionTypes from '../action-types'

const state = {
  defaultCustomerAccount: null,
  lastSelectedCustomerAccount: null
}

const getters = {
  [getTypes.GET_CUSTOMER_ACCOUNT_DEFAULT]: state => state.defaultCustomerAccount || JSON.parse(localStorage.getItem(defaultTypes.DEFAULT_CUSTOMER_ACCOUNT)),
  [getTypes.GET_LAST_SELECTED_CUSTOMER_ACCOUNT]: state => state.lastSelectedCustomerAccount || JSON.parse(localStorage.getItem(defaultTypes.LAST_SELECTED_CUSTOMER_ACCOUNT))
}

const mutations = {
  [types.SET_CUSTOMER_ACCOUNT_DEFAULT] (state, customerAccount) {
    state.defaultCustomerAccount = customerAccount
    localStorage.setItem(defaultTypes.DEFAULT_CUSTOMER_ACCOUNT, JSON.stringify(customerAccount))
  },
  [types.SET_LAST_SELECTED_CUSTOMER_ACCOUNT] (state, customerAccount) {
    state.lastSelectedCustomerAccount = customerAccount
    localStorage.setItem(defaultTypes.LAST_SELECTED_CUSTOMER_ACCOUNT, JSON.stringify(customerAccount))
  }
}

const actions = {
  [actionTypes.REMOVE_CUSTOMER_ACCOUNT_DEFAULT] (context) {
    context.state.defaultCustomerAccount = null
    localStorage.removeItem(defaultTypes.DEFAULT_CUSTOMER_ACCOUNT)
  },
  [actionTypes.REMOVE_LAST_SELECTED_CUSTOMER_ACCOUNT] (context) {
    context.state.lastSelectedCustomerAccount = null
    localStorage.removeItem(defaultTypes.LAST_SELECTED_CUSTOMER_ACCOUNT)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
