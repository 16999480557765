<template>
  <div>
    <table
      :id="`client-table-${id}`"
      class="table"
      :class="{ 'new-layout': usingNewLayout, 'hover-effect': usingNewLayout}"
    >
      <col
        v-for="col in columns"
        :key="col.name"
        :width="col.width"
      >
      <thead class="fs-unmask">
        <tr>
          <th
            v-for="c in columns"
            :key="c.name"
            :class="{header: true, 'cursor-default': !columnSortable(c)}"
          >
            <span
              v-if="columnSortable(c)"
              class="sort"
              @click="toggleSort(c)"
            >
              <span>{{ $t(c.displayKey) }}</span>
              <span :class="sortClassFor(c)" />
            </span>
            <span v-else>
              <span>{{ $t(c.displayKey) }}</span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot
          v-for="(row, index) in sortedRows.rows"
          :id="id"
          name="row"
          :row="row"
          :rows="sortedRows.rows"
          :index="index"
          :columns="columns"
        >
          <tr
            :id="id + '-row-' + index"
            :key="index"
          >
            <td
              v-for="(col, cindex) in columns"
              :id="`${id}-row-${index}-col-${cindex}`"
              :key="col.name"
            >
              {{ col.name ? row[col.name] : '' }}
            </td>
          </tr>
        </slot>
      </tbody>
    </table>
    <b-pagination
      v-if="pagination && sortedRows.totalRows"
      v-model="page"
      size="md"
      :total-rows="sortedRows.totalRows"
      :per-page="pageSize"
      @change="changePage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { APP_GET_USING_NEW_LAYOUT } from '../../../store/get-types'

export default {
  name: 'ClientTable',
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    searchCriteria: {
      type: Object,
      required: true
    },
    pagination: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      predicate: '',
      sortDirection: 'asc',
      pageSize: 20
    }
  },
  computed: {
    sortedRows () {
      const direction = this.sortDirection === 'asc' ? 1 : -1
      const sc = this.searchCriteria
      let rowsForDisplay = this.rows

      if (sc.searchTerm.length > 0 && sc.searchType.name.length > 0) {
        rowsForDisplay = rowsForDisplay.filter((row) => {
          return (row[sc.searchType.name] && row[sc.searchType.name].toLowerCase().includes(sc.searchTerm.toLowerCase()))
        })
      }
      rowsForDisplay.sort((a, b) => {
        if (a[this.predicate] > b[this.predicate]) {
          return direction
        } else if (a[this.predicate] === b[this.predicate]) {
          return 0
        }
        return -direction
      })

      if (this.pagination) {
        const totalRows = this.page * this.pageSize
        const beginningOffsetRows = totalRows - this.pageSize
        return {
          rows: rowsForDisplay.slice(beginningOffsetRows, totalRows),
          totalRows: rowsForDisplay.length
        }
      } else {
        return {
          rows: rowsForDisplay,
          totalRows: rowsForDisplay.length
        }
      }
    },
    ...mapGetters({
      usingNewLayout: APP_GET_USING_NEW_LAYOUT
    })
  },
  mounted () {
    // the 10 specifies the number base. We want 10.
    // If there is no query param, we will default to page size 20
    this.pageSize = parseInt(this.$route.query.pageSize, 10) || 20
  },
  methods: {
    changePage (page) {
      this.page = page
    },
    columnSortable (c) {
      return c.name && c.sortable !== false
    },
    toggleSort (column) {
      if (this.predicate === column.name) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.predicate = column.name
        this.sortDirection = 'asc'
      }
    },
    sortClassFor (column) {
      return {
        tci: column.sortable !== false,
        'tci-sort': column.name !== this.predicate,
        'tci-sort-asc': column.name === this.predicate && this.sortDirection === 'asc',
        'tci-sort-desc': column.name === this.predicate && this.sortDirection === 'desc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/variables';
  @import '~@/assets/scss/mixins';

  .new-layout {
    table-layout: fixed;
    overflow-x: scroll;
  }

  .new-layout.hover-effect{
    @include gotham-medium;
    th {
      border-top: 0;
    }
    td {
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
    }
    tbody{
      &:hover {
        background: var(--t-color-surface);
      }
      tr {
        height: 62px;

        &:hover {
          background: var(--t-color-surface-raised);
        }
      }
    }
  }
</style>
