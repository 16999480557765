import moment from 'moment'
import 'moment-timezone'

// exported api
function date (date) {
  if (!date) return hasDate(date)
  const _moment = checkIfValid(date)
  return humanReadableDate(_moment) + ' ' + offsetTime(_moment)
}

function dateNoTime (date, format) {
  const _moment = checkIfValid(date)
  return _moment.format(format || 'MMM D, YYYY')
}

function dateNoTimeZone (date) {
  const _moment = checkIfValid(date)
  return humanReadableDate(_moment) + ' ' + _moment.format('h:mm A')
}

function dateWithOffset (dateString) {
  if (typeof dateString !== 'string') {
    return dateString
  } else {
    // If no offset, append UTC
    if (!dateString.includes('+') && !dateString.includes('Z')) {
      dateString += '+0000'
    }
    return new Date(dateString)
  }
}

function returnHumanReadableDate (date) {
  const _moment = checkIfValid(date)
  return humanReadableDate(_moment)
}

function returnOffsetTime (date) {
  const _moment = checkIfValid(date)
  return offsetTime(_moment)
}

// internal format to reuse
function humanReadableDate (_moment) {
  return _moment.format('MMM D, YYYY')
}

function offsetTime (_moment) {
  return _moment.format('h:mm A ') + _moment.tz(moment.tz.guess()).zoneAbbr()
}

// internal logic checks
function hasDate (date) {
  if (!date) {
    console.warn('Cannot format empty date')
    return ''
  }
}

function checkIfValid (date) {
  if (!date) return hasDate(date)
  return validMoment(date)
}

function validMoment (date) {
  const _moment = moment(date)
  return _moment.isValid() ? _moment : date
}

// exported functions
function DatePlugin (Vue) {
  Vue.prototype.$date = date
  Vue.prototype.$dateNoTime = dateNoTime
  Vue.prototype.$dateNoTimeZone = dateNoTimeZone
  Vue.prototype.$dateWithOffset = dateWithOffset
  Vue.prototype.$dateHumanReadable = returnHumanReadableDate
  Vue.prototype.$offsetTime = returnOffsetTime
}

export {
  DatePlugin
}
