import Fuse from 'fuse.js'

function search (term, list, keys, options = {}) {
  console.assert(!!Array.isArray(keys), 'Keys must be of type Array')

  const run = new Fuse(list, {
    shouldSort: true,
    threshold: 0.2,
    distance: 100,
    keys,
    ...options
  })
  const results = run.search(term)
  return results
}

function SearchPlugin (Vue) {
  Vue.prototype.$search = search
}

export default SearchPlugin
