// Create User Flow
export const INVITE_USER_CLICKED = 'Invite User Clicked'
export const ACCOUNT_CREATED = 'Account Created'

// User Sign Up Flow
export const SIGN_UP_AUTH_CONFIG_COMPLETE = 'Sign Up Auth Config Complete'
export const ACCOUNT_ADDED_USER = 'Account Added User'

// MFA Flow
export const MFA_PHONE_NUMBER_ENTERED = 'MFA Phone Number Entered'
export const MFA_SEND_CODE_CLICKED = 'MFA Send Code Clicked'
export const MFA_CONFIRM_CLICKED = 'MFA Confirm Clicked'

// Login Flow
export const LOGIN_CLICKED = 'Login Clicked'
export const SSO_CLICKED = 'SSO Clicked'
export const SIGN_OUT_CLICKED = 'Sign Out Clicked'
