export const ACH_REDIRECT_TO_ROUTE = 'ach_redirect_to_route'

export const VISA = 'VISA'
export const AMERICAN_EXPRESS = 'AMERICAN_EXPRESS'
export const JCB = 'JCB'
export const DINERS_CLUB = 'DINERS_CLUB'
export const MASTER_CARD = 'MASTER_CARD'
export const DISCOVER = 'DISCOVER'

export const PaymentSourceType = Object.freeze({
  ACCOUNT_FUNDS: 'ACCOUNT_FUNDS',
  ACH_BANK_ACCOUNT: 'ACH_BANK_ACCOUNT',
  CREDIT_CARD: 'CREDIT_CARD'
})

// Formatted names for each credit card
export const CreditCardName = Object.freeze({
  [VISA]: 'Visa',
  [AMERICAN_EXPRESS]: 'American Express',
  [JCB]: 'JCB',
  [DINERS_CLUB]: 'Diners Club',
  [MASTER_CARD]: 'Mastercard',
  [DISCOVER]: 'Discover'
})
