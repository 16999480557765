<template>
  <div>
    <b-modal
      v-model="promptForRefresh"
      modal-class="fs-unmask"
      hide-header-close
      :title="$t('login.sessionExpiry.dialog.title')"
    >
      <div slot="default">
        <p>{{ $t('login.sessionExpiry.dialog.body') }}</p>
      </div>
      <div
        slot="modal-footer"
        class="w-100"
      >
        <b-btn
          id="extend-login-button"
          class="float-right"
          variant="primary"
          @click="extendLogin()"
        >
          {{ $t('login.sessionExpiry.dialog.actionButton') }}
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AuthenticationProvider from '../shared/security/authentication-provider'
import * as AuthorityConstants from '@/components/shared/constants/authority.constants'

import {
  POLL_INTERVAL_MS,
  REFRESH_THRESHOLD_SEC,
  ACTIVITY_TIMEOUT_MS
} from './jws-refresh-config'

export default {
  name: 'JwsRefresher',
  data () {
    return {
      interval: null,
      inactivityPeriodMs: 0,
      promptForRefresh: false,
      numberOfIntervals: 0
    }
  },
  computed: {
    userActive () {
      return this.inactivityPeriodMs <= ACTIVITY_TIMEOUT_MS
    }
  },
  mounted () {
    this.pollJWTTokenValidity()
    this.interval = setInterval(this.pollJWTTokenValidity, POLL_INTERVAL_MS)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    extendLogin () {
      this.promptForRefresh = false
      this.inactivityPeriodMs = 0
      this.$http.post('/api/authenticate/refresh', {
        idToken: AuthenticationProvider.getToken() || '',
        idRefreshToken: AuthenticationProvider.getRefreshToken() || '',
        emulationRestoreToken: AuthenticationProvider.getRestoreToken() || ''
      }).then((response) => {
        AuthenticationProvider.setToken(response.data.idToken)
        AuthenticationProvider.setRefreshToken(response.data.idRefreshToken)
        AuthenticationProvider.setRestoreToken(response.data.emulationRestoreToken)
      }).catch((error) => {
        console.error(error)
        AuthenticationProvider.clearTokens()
        this.promptForRefresh = false

        this.$router.replace({ name: AuthorityConstants.LOGIN })
        this.$toast(this.$t('login.messages.error.expiredSession'), 'warning')
      })
    },
    getSecondsLeftUntilJWTExpiration (jwtPayload) {
      return jwtPayload.exp - Math.floor(new Date() / 1000)
    },
    pollJWTTokenValidity () {
      const jwsPayload = AuthenticationProvider.getJwsPayload()
      if (!jwsPayload) {
        this.promptForRefresh = false
        return
      }
      const secondsLeftUntilJWTExpiration = this.getSecondsLeftUntilJWTExpiration(jwsPayload)
      if (secondsLeftUntilJWTExpiration <= 0) {
        if (this.numberOfIntervals === 0) {
          console.error('Session has expired too early, as the current browser time needs to be fixed.')
        }
        const isSAML = jwsPayload.identityProviders.toUpperCase() === AuthorityConstants.IDENTITY_PROVIDER.SAML
        AuthenticationProvider.clearTokens()
        this.promptForRefresh = false
        const redirect = isSAML ? AuthorityConstants.SSO_LOGIN : AuthorityConstants.LOGIN
        this.$router.replace({ name: redirect })
        this.$toast(this.$t('login.messages.error.expiredSession'), 'warning')
        return
      }

      if (document.hidden) {
        this.inactivityPeriodMs += POLL_INTERVAL_MS
      } else {
        this.inactivityPeriodMs = 0
      }

      this.numberOfIntervals++

      // Check if the time until expiry falls below the refresh threshold
      if (secondsLeftUntilJWTExpiration < REFRESH_THRESHOLD_SEC) {
        if (this.userActive && !this.promptForRefresh) {
          this.extendLogin()
        } else {
          this.promptForRefresh = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
