import VueI18n from 'vue-i18n'
import Vue from 'vue'
import numberFormats from '../number-formats'
import deepmerge from 'deepmerge'
import glob from 'glob'
import path from 'path'

Vue.use(VueI18n)

const i18nHash = {}

glob.sync(path.join(__dirname, '../**/*.json')).forEach((file) => {
  const resourceParts = file.split('/')
  const lang = resourceParts[resourceParts.length - 2]
  const module = resourceParts[resourceParts.length - 1]
  i18nHash[lang] = deepmerge(i18nHash[lang] || {}, require(`../../i18n/${lang}/${module}`))
})

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: i18nHash,
  numberFormats
})

export default i18n
