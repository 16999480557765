<template>
  <img
    :src="imageSrc.asset"
    :alt="imageSrc.label"
    :class="{ 'funding-method-image': !large, 'pr-3': useImagePadding }"
  >
</template>

<script>
import {
  AMERICAN_EXPRESS,
  DINERS_CLUB,
  DISCOVER,
  JCB,
  MASTER_CARD,
  PaymentSourceType,
  VISA
} from '@/components/platform/funding/FundingConstants'

export default {
  name: 'FundingMethodImage',

  props: {
    paymentMethod: {
      type: String,
      default: ''
    },
    large: {
      type: Boolean,
      default: false
    },
    useImagePadding: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    imageSrc () {
      switch (this.paymentMethod) { // The label helps for testing
        case PaymentSourceType.ACCOUNT_FUNDS:
          return {
            asset: require('@/assets/images/funding/TANGOACCOUNT.svg'),
            label: this.paymentMethod
          }
        case PaymentSourceType.ACH_BANK_ACCOUNT:
          return {
            asset: require('@/assets/images/funding/BANKACCOUNT.svg'),
            label: this.paymentMethod
          }
        // if not the above two, it's a credit card so figure out which specific credit card it is
        case AMERICAN_EXPRESS:
          return {
            asset: require('@/assets/images/funding/AMEX.svg'),
            label: this.paymentMethod
          }
        case JCB:
          return {
            asset: require('@/assets/images/funding/JCB.svg'),
            label: this.paymentMethod
          }
        case VISA:
          return {
            asset: require('@/assets/images/funding/VISA.svg'),
            label: this.paymentMethod
          }
        case DINERS_CLUB:
          return {
            asset: require('@/assets/images/funding/CLUB.svg'),
            label: this.paymentMethod
          }
        case MASTER_CARD:
          return {
            asset: require('@/assets/images/funding/MASTERCARD.svg'),
            label: this.paymentMethod
          }
        case DISCOVER:
          return {
            asset: require('@/assets/images/funding/DISCOVER.svg'),
            label: this.paymentMethod
          }
        default:
          return {
            asset: require('@/assets/images/funding/CREDITCARD.svg'),
            label: this.paymentMethod
          }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/components/shared/lists/FundingMethod.scss";

  .funding-method-image {
    height: $funding-method-image-height;
  }
</style>
