<template>
  <v-chip
    class="v-chip"
    pill
    :outlined="outlined"
    :color="backgroundColor"
    :text-color="textColor"
    :link="link"
  >
    <div
      ref="host"
      :class="{copyable: copyable}"
    >
      <b-popover
        v-if="!!copyable"
        ref="popover"
        :target="`${idName}`"
        placement="top"
        :triggers="[]"
        :class="{ 'flash-popover-fadeout': fadingOut }"
        custom-class="copy-popover"
      >
        Copied
      </b-popover>
      <slot name="content">
        <div
          :id="`${idName}`"
          @click="copy"
        >
          {{ content }}
        </div>
      </slot>
    </div>
  </v-chip>
</template>

<script>
import * as Clipboard from 'clipboard/dist/clipboard.js'

export default {
  name: 'Pill',
  props: {
    content: {
      type: String,
      required: false,
      default: ''
    },
    copyable: {
      type: Boolean,
      required: false,
      default: false
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#e8ebec'
    },
    textColor: {
      type: String,
      required: false,
      default: 'black'
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      fadingOut: false,
      fadeTimeout: null,
      closeTimeout: null
    }
  },
  computed: {
    idName () {
      return this.id ? this.id : `pill-internal-pop-${Math.random()}`
    }
  },
  beforeDestroy () {
    if (this.fadeTimeout) {
      clearTimeout(this.fadeTimeout)
    }

    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout)
    }

    if (this.clipboard) {
      this.clipboard.destroy()
    }
  },
  mounted () {
    const vm = this
    if (this.copyable) {
      this.clipboard = new Clipboard(this.$refs.host, {
        text () {
          return vm.content
        }
      })
    }
  },
  methods: {
    copy () {
      if (!this.copyable) {
        return
      }

      this.$refs.popover.$emit('open')
      this.fadingOut = false

      if (this.fadeTimeout) {
        clearTimeout(this.fadeTimeout)
      }

      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout)
      }

      this.fadeTimeout = setTimeout(() => {
        this.fadingOut = true
      }, 2000)

      this.closeTimeout = setTimeout(() => {
        this.$refs.popover.$emit('close')
        this.fadingOut = false
      }, 3000)
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/scss/variables';
  .portal-pill {
    border-radius: 6px;
    display: inline-block;
    user-select: none;
    text-align: center;
  }

  .portal-pill.copyable {
    cursor: pointer;
  }

  .bs-popover-top {
    .arrow:after {
      border-top-color: var(--t-color-border);
    }
  }

  .copy-popover {
    background-color: var(--t-color-surface-inverted);
    .popover-body {
      color: var(--t-color-text-inverted);
    }
  }
</style>
