<template>
  <div
    class="fs-unmask nav-container"
    :class="{'position-relative': isSticky}"
  >
    <div
      :id="id+'-header'"
      class="title-component"
      :class="{'page-sub-header': isSticky}"
    >
      <div class="secondary-nav">
        <!--Use title main if you left hand content is just text-->
        <h1
          v-if="titleMain"
        >
          {{ titleMain }}
        </h1>
        <!--Slot controls/buttons etc. if you need more than text-->
        <div>
          <slot
            name="header-controls-left"
            class="mr-auto"
          />
          <slot name="header-controls-lower-left" />
        </div>

        <slot name="header-controls-center" />
        <span>
          <slot name="header-controls-right" />
        </span>
      </div>
      <div
        v-if="displayTertiaryNav"
        class="page-tertiary-nav"
      >
        <slot name="page-tertiary-nav" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { APP_GET_USING_NEW_LAYOUT } from '../../../store/get-types'
import { BreadCrumb } from '@/components/shared/breadcrumbs/bread-crumb'

export default {
  name: 'PageHeader',
  // TODO: Deprecate titleMain/titleTop -> title when we apply global layout
  props: {
    titleTop: {
      type: String,
      required: false,
      default: ''
    },
    titleMain: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: ''
    },
    isSticky: {
      type: Boolean,
      required: false,
      default: false
    },
    viewport: {
      type: Object,
      required: false,
      default: () => {}
    },
    breadCrumbs: {
      type: Array,
      required: false,
      default: () => [],
      validator: (items) => items.every(item => item instanceof BreadCrumb)
    },
    displayTertiaryNav: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      usingNewLayout: APP_GET_USING_NEW_LAYOUT
    })
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/variables";
  @import "~@/assets/scss/mixins";

  .dashhead {
    margin-bottom: 1em;
  }

  .page-tertiary-nav {
    margin-block-start: -0.4rem;
    margin-inline-start: 1rem;
  }

  .title-component {
    background: var(--t-color-surface);
    min-height: 60px;
    align-items: center;

    .parent-container {
      align-items: center;
      min-height: 4.5rem;
      background-color: var(--t-color-surface);
    }

    h1 {
      font-size: var(--t-font-size-4);
      font-weight: var(--t-font-weight-5);
      margin-block-start: 1rem;
    }
  .page-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

    .secondary-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 1.75rem;
      margin-block-start: .5rem;
      min-height: 65px;
    }

    h1 {
      margin: 0;
    }
  }
</style>
