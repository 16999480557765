<template>
  <div
    v-if="visible"
    id="loader"
    class="loader"
  >
    <div class="spinningCircle" />
    <div class="loadingText">
      {{ loadingText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loadingText: {
      type: String,
      required: false,
      default: function () { return this.$t('global.loading') }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/variables";

  .loader {
    z-index: 10000;
    text-align: center;
    background-color: transparent;

    &.fixed-loader {
      top: calc(50% - 50px);
      left: calc(50% - 100px);
      margin: 0 auto;
      width: 200px;
      position: fixed;
    }
    &.component-loader {
      margin: auto;
    }

    .loadingText {
      font-weight: 700;
      margin-top: 20px;
    }

    .spinningCircle {
      margin:0 auto;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: 5px solid rgba(255, 255, 255, 0);
      border-top-color: var(--t-color-primary);
      border-right-color: var(--t-color-primary);
      -webkit-animation: single2 2s infinite linear;
      animation: single2 2s infinite linear;
    }

    @-webkit-keyframes single2 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        border-top-color: var(--t-color-primary);
        border-right-color: var(--t-color-primary);
      }
      50% {
        border-top-color: var(--t-color-primary);
        border-right-color: var(--t-color-primary);
      }
      100% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg);
        border-top-color: var(--t-color-primary);
        border-right-color: var(--t-color-primary);
      }
    }

    @keyframes single2 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        border-top-color: var(--t-color-primary);
        border-right-color: var(--t-color-primary);
      }
      50% {
        border-top-color: var(--t-color-primary);
        border-right-color: var(--t-color-primary);
      }
      100% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg);
        border-top-color: var(--t-color-primary);
        border-right-color: var(--t-color-primary);
      }
    }
  }
</style>
