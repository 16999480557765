import AdaChatbot from './AdaChatbot'

/**
 * AdaChatbot Vue plugin object
 * @type {PluginObject<unknown>}
 */
const AdaChatbotPlugin = {
  /**
   * @type PluginFunction
   * @param Vue
   */
  install: Vue => {
    /**
     * @type {AdaChatbot}
     */
    Vue.adaChatbot = Vue.prototype.$adaChatbot = new AdaChatbot()
  }
}

export default AdaChatbotPlugin
