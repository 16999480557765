import { Recipient, recipientProperties } from '@/dto/order/recipient/Recipient'
import { AddressPhysical } from '@/dto/order/recipient/address/AddressPhysical'
import { FulfillmentType } from '@/dto/order/FulfillmentType'

export const EMAIL_PROPERTY = 'email'
export const ADDRESS_PROPERTY = 'address'

export const recipientPhysicalProperties = [
  ...recipientProperties,
  EMAIL_PROPERTY,
  ADDRESS_PROPERTY
]

export class RecipientPhysical extends Recipient {
  _address

  constructor (args) {
    const { address, email, ...superProps } = args || {}
    super(superProps)
    this.address = address
    this.fulfillmentType = FulfillmentType.PHYSICAL
    this[EMAIL_PROPERTY] = email || null
  }

  set address (address) {
    this._address = address instanceof AddressPhysical ? address : new AddressPhysical(address)
  }

  get address () {
    return this._address
  }

  equals (otherRecipientPhysical) {
    return recipientPhysicalProperties.every(prop => this[prop] === otherRecipientPhysical[prop])
  }

  toJSON () {
    const recipient = super.toJSON()
    return {
      ...recipient,
      address: this._address,
      fulfillmentType: this.fulfillmentType,
      [EMAIL_PROPERTY]: this[EMAIL_PROPERTY]
    }
  }
}
