<template>
  <v-container
    class="py-1 selectable-container"
    :class="{ 'active-account': isSelected }"
    @click="switchToAccount"
  >
    <v-row
      no-gutters
      align="center"
      justify="space-between"
      @mouseenter="showCopyAccountIcon = true"
      @mouseleave="showCopyAccountIcon = false"
    >
      <v-col
        v-if="isSelected"
        class="d-flex justify-start"
        cols="1"
      >
        <v-icon class="active-account-check">
          mdi-check
        </v-icon>
      </v-col>
      <v-col class="d-flex justify-start">
        <dl class="ma-0">
          <dt class="account-name">
            {{ account.displayName }}
          </dt>
          <dd class="account-identifier ma-0">
            {{ account.identifier }}
            <v-icon
              v-if="showCopyAccountIcon"
              size="small"
              @click.stop="handleCopyAccount"
            >
              mdi-content-copy
            </v-icon>
            <v-icon
              v-if="showFrozenAccount"
              size="small"
            >
              mdi-snowflake
            </v-icon>
          </dd>
        </dl>
      </v-col>
      <v-col
        v-if="showAccountBalance"
        cols="auto"
        class="account-balance d-flex justify-end"
      >
        {{ $moneyIntl(account.availableBalance, account.currencyCode) }}
      </v-col>
      <v-col
        v-if="showAddFundsButton"
        cols="auto"
        class="d-flex justify-end"
      >
        <v-btn
          class="ml-4 add-funds-button"
          :disabled="!isAccountActive"
          @click.stop="handleClickAddFunds"
        >
          +{{ $currencySymbol(account.currencyCode) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FUNDING_MANAGE, VIEW_BALANCE_ACCESS } from '@/components/shared/constants/authority.constants'
import { AccountStatus } from '@/components/platform/accounts-user-management/enum/accountStatus'
import { mapGetters } from 'vuex'
import * as getTypes from '@/store/get-types'

export default {
  name: 'AccountSelectorRow',

  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showCopyAccountIcon: false
    }
  },

  computed: {
    ...mapGetters({
      selectedCustomerAccount: getTypes.GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    showAccountBalance () {
      return this.$hasAuthority(VIEW_BALANCE_ACCESS)
    },
    showAddFundsButton () {
      return this.$hasAuthority(FUNDING_MANAGE)
    },
    showFrozenAccount () {
      return this.account.status === AccountStatus.FROZEN
    },
    isAccountActive () {
      return this.selectedCustomerAccount?.active
    }
  },

  methods: {
    switchToAccount () {
      if (!this.isSelected) {
        this.$emit('switch-to-account', this.account)
      }
    },
    handleClickAddFunds () {
      this.$emit('open-funding-dialog', this.account)
    },
    async handleCopyAccount () {
      try {
        await navigator.clipboard.writeText(this.account.identifier)
        this.$toast(this.$t('copiedToClipboardSuccess'), 'success')
      } catch (error) {
        this.$toast(this.$t('errorCopyingAccountNumber'), 'danger')
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .add-funds-button {
    min-width: 10px !important;
    max-width: 42px;
    border: var(--t-border-size-1) solid var(--t-color-border);
    border-radius: var(--t-radius-1);
    background-color: var(--t-color-background) !important;
    font-weight: var(--t-font-weight-5);
    font-size: var(--t-font-size-0);
    color: #22292F;
  }

  .active-account-check {
    color: var(--t-color-primary) !important;
  }

  .active-account  {
    border-radius: 8px;
    background-color: var(--t-color-primary-weakest);
    color: var(--t-color-primary) !important;
  }

  .selectable-container {
    cursor:pointer;
  }

  .account-name {
    font-weight: var(--t-font-weight-4);
    font-size: var(--t-font-size-0);
    color: #22292F;
  }

  .account-identifier {
    font-weight: var(--t-font-weight-4);
    font-size: 12px;
    color: #666666;
  }

  .account-balance {
    font-weight: var(--t-font-weight-4);
    font-size: var(--t-font-size-0);
    color: #22292F;
  }
</style>
