<template>
  <div
    class="funding-method-option"
    :class="{ 'dropdown': isDropdown }"
  >
    <funding-method-image
      class="funding-method-image"
      :payment-method="paymentMethod"
    />

    <div>
      <div
        class="display-name"
        :class="{'disabled': fundingMethod.disabled}"
      >
        {{ fundingMethod.displayName }}
      </div>

      <div
        v-if="fundingMethod.helperText"
        class="helper-text"
      >
        {{ fundingMethod.helperText }}
        <span
          v-if="availableMessage.show"
          id="available-balance-dialog-trigger"
          class="account-button cursor-pointer"
          @click="$emit('show-dialog', $event)"
        >{{ $t('portalfrontendApp.coreFunding.depositTab.availableBalance') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FundingMethodImage from '@/components/shared/lists/FundingMethodImage'

export default {
  name: 'FundingMethodOption',

  components: { FundingMethodImage },

  inject: ['availableMessage'],

  props: {
    fundingMethod: {
      type: Object,
      required: true
    },
    isDropdown: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    paymentMethod () {
      return this.fundingMethod.creditCardType || this.fundingMethod.paymentMethod
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/_mixins.scss";
  @import "~@/components/shared/lists/FundingMethod.scss";

  .funding-method-option {
    display: flex;
    align-items: flex-start;

    &.dropdown {
      margin: 6px 0;
    }
  }

  .display-name {
    color: var(--t-color-text);
    font-family: $gotham-font-family;
    font-size: var(--t-font-size-0);
    font-weight: var(--t-font-weight-4);
    line-height: $funding-method-image-height;

    &.disabled {
      color: var(--t-color-text-weakest);
    }
  }

  .helper-text {
    color: var(--t-color-text-weak);
    font-family: $gotham-font-family;
    font-size: 0.75rem;
    font-weight: var(--t-font-weight-4);
  }

  .account-button{
    font-weight: var(--t-font-weight-6);
    color: var(--t-color-primary);
    font-size: 0.75rem;
  }
</style>
