export const PLATFORM = 'PLATFORM'
export const GROUP = 'GROUP'
export const ACCOUNT = 'ACCOUNT'

/**
  These values are not arbitrary, they represent the value the access level has in relation to the others.
  The higher the number, the greater the access. The higher number is also granted permission at the lower levels.
  PLATFORM should always be at the top thus have the highest value, followed by GROUP and ACCOUNT
  This allows for easy comparisons
**/
export const ACCESS_LEVEL_HIERARCHY = {
  [ACCOUNT]: 0,
  [GROUP]: 1,
  [PLATFORM]: 2
}
