<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col class="text-left py-0">
        <label for="primary-email-address">
          {{ $t('portalfrontendApp.coreCreditCard.dialog.receiptsInfo.receiptsText') }}
        </label>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label class="mb-1">
          <small>
            {{ $t('portalfrontendApp.coreCreateCreditCardCriteria.primaryEmail') }}
          </small>
        </label>
        <v-text-field
          id="primary-email-address"
          v-model="primaryEmailAddress"
          class="mb-3 disabled"
          dense
          disabled
          hide-details="auto"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-left py-0">
        <label for="receipts-text">
          {{ $t('portalfrontendApp.coreCreditCard.dialog.receiptsInfo.additionalEmails') }}
        </label>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <validation-provider
          v-slot="{ errors, flags: { touched } }"
          rules="commaSeparatedEmail"
          immediate
          name="email address"
        >
          <v-textarea
            id="receipts-text"
            v-model="emailAddresses"
            :error-messages="touched ? errors : []"
            rows="4"
            dense
            outlined
            no-resize
            auto-grow
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-left py-0">
        <label>
          {{ $t('portalfrontendApp.coreCreditCard.dialog.receiptsInfo.receiptsEmailText') }}
        </label>
        <label>
          {{ $t('portalfrontendApp.coreCreditCard.dialog.receiptsInfo.receiptsDownloadText') }}
        </label>
      </v-col>
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import { PRINCIPLE_GET_USER } from '@/store/get-types'

export default {
  name: 'CreditCardReceipts',
  components: { ValidationProvider },
  props: {
    primaryEmailAddress: {
      type: String,
      required: true
    },
    emailAddresses: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: PRINCIPLE_GET_USER
    })
  },
  watch: {
    emailAddresses: {
      handler (newEmailAddresses) {
        this.$emit('email-addresses', newEmailAddresses)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables";

.disabled {
  background-color: $gray-100;
}
</style>
