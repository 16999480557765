<template>
  <add-edit-credit-card-dialog
    :show-dialog="showDialog"
    :is-new-card="true"
    :submitted.sync="submitted"
    :cc-token="ccToken"
    @submitted="addCard"
    @edited="editCard"
    @confirm-removal="confirmRemoval"
    @close-dialog="closeDialog"
  />
</template>

<script>
import { ADD_CREDIT_CARD_CLICKED, CREDIT_CARD_ADDED } from '@/components/shared/segment/track-funding'
import AddEditCreditCardDialog
  from '@/components/platform/funding/credit-card/dialog/AddEditCreditCardDialog.vue'
import { ERROR, SUCCESS } from '@/components/shared/alert/snack-constants'
import { RG_7542_FUNDING_REFACTOR } from '@/components/shared/split/split-constants'
export default {
  name: 'AddNewCreditCard',
  components: {
    AddEditCreditCardDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    ccToken: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      submitted: false
    }
  },
  computed: {
    isFundingRedesignSplitEnabled () {
      return this.$isSplitEnabled(RG_7542_FUNDING_REFACTOR)
    }
  },
  mounted () {
    this.$segment.track(ADD_CREDIT_CARD_CLICKED)
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog')
    },
    async addCard (creditCardSubmittedEvt) {
      try {
        await this.$http.post('api/creditCards', creditCardSubmittedEvt)
        this.$snack(this.$t('portalfrontendApp.coreCreditCard.dialog.cardSuccess'), false, SUCCESS)
        if (this.isFundingRedesignSplitEnabled) {
          this.$emit('wallet-credit-cards-updated')
        } else {
          this.$emit('payment-source-added')
        }
        this.$segment.track(CREDIT_CARD_ADDED)
      } catch (error) {
        this.$snack(this.$httpError(error, this.$t('portalfrontendApp.coreCreditCard.dialog.cardError')), false, ERROR)
      } finally {
        this.closeDialog()
        this.submitted = false
      }
    },
    async editCard (creditCardSubmittedEvt) {
      this.$snack(this.$t('portalfrontendApp.coreCreditCard.dialog.savingCard'), true)
      const editInfo = this.editCriteriaFromCreditCard(creditCardSubmittedEvt)
      try {
        await this.$http.put(`${'api/creditCards'}/${this.ccToken}`, editInfo)
        this.$snack(this.$t('portalfrontendApp.coreCreditCard.dialog.cardUpdated'), false, SUCCESS)
        if (this.isFundingRedesignSplitEnabled) {
          this.$emit('wallet-credit-cards-updated')
        } else {
          this.$emit('payment-source-added')
        }
      } catch (error) {
        console.error(error)
        this.$snack(this.$httpError(error.response, this.$t('portalfrontendApp.coreCreditCard.dialog.cardUpdateFailed')), false, ERROR)
      } finally {
        this.closeDialog()
        this.submitted = false
      }
    },
    editCriteriaFromCreditCard (criteria) {
      const expirationArray = criteria?.creditCard.expiration.split('-')
      const expirationMonth = expirationArray[1]
      const expirationYear = expirationArray[0]
      return {
        lastFour: criteria?.creditCard.number,
        expirationMonth,
        expirationYear,
        cardNickname: criteria?.creditCard.cardNickname,
        firstName: criteria?.billingAddress.firstName,
        lastName: criteria?.billingAddress.lastName,
        addressOne: criteria?.billingAddress.addressLine1,
        addressTwo: criteria?.billingAddress.addressLine2,
        city: criteria?.billingAddress.city,
        state: criteria?.billingAddress.state,
        country: criteria?.billingAddress.country,
        postalCode: criteria?.billingAddress.postalCode,
        contactInformation: criteria?.contactInformation
      }
    },
    confirmRemoval (payload) {
      this.$emit('confirm-removal', payload)
    }
  }
}
</script>
