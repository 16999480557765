export const NOTES_PROPERTY = 'notes'

export const selectedRewardItemMetadataProperties = [
  NOTES_PROPERTY
]

export class SelectedRewardItemMetadata {
  constructor ({ [NOTES_PROPERTY]: notes = null } = {}) {
    this[NOTES_PROPERTY] = notes
  }

  equals (otherMetadata) {
    return selectedRewardItemMetadataProperties.every(prop => this[prop] === otherMetadata[prop])
  }
}
