import {
  capitalize as lodashCapitalize,
  startCase as lodashStartCase,
  toLower
} from 'lodash'

/**
 * This function takes a string and caplitalizes the first character and uncapitalizes the following characters
 * EX: 'i like eggs' => 'I Like Eggs'
 * EX: 'I LIKE EGGS' => 'I Like Eggs'
 *
 * @param string The string to convert to start-case
 * @returns {string|*} The string in start-case
 */
export const startCase = string => {
  if (!string || string === 'n/a') {
    return string
  }
  return lodashStartCase(toLower(string.split('_').join(' ')))
}

/**
 * This function takes a string and capitalizes just the first character. Also strips out underscores.
 * EX: 'i like eggs' => 'I like eggs'
 * EX: 'I_LIKE_EGGS' => 'I like eggs'
 *
 * @param string The string to capitalize
 * @returns {string|*} The capitalized string
 */
export const capitalize = string => {
  if (!string) {
    return string
  }
  return lodashCapitalize(string.replace(/_/g, ' '))
}
