const accountSwitcherMixin = {
  methods: {
    accountHasAvailableBalance (account) {
      // we have to explicitly check for null and undefined because a balance could be 0, which is falsy
      return account && account.availableBalance !== null && typeof account.availableBalance !== 'undefined'
    }
  }
}

export {
  accountSwitcherMixin as AccountSwitcherMixin
}
