import OneTrustCookieBlocker from '@/plugins/onetrust/OneTrustCookieBlocker'

/**
 * OneTrust cookie banner plugin object
 * @type {PluginObject<unknown>}
 */
const OneTrustPlugin = {
  /**
   * @type PluginFunction
   * @param Vue
   */
  install: Vue => {
    /**
     * @type: {OneTrustCookieBlocker}
     */
    Vue.prototype.$oneTrust = new OneTrustCookieBlocker()
  }
}

export default OneTrustPlugin
