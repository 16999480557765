import { PhysicalTemplateMarkupCustomization } from '@/dto/order/physical-templates/PhysicalTemplateMarkupCustomization'

export class SelectedPhysicalTemplate {
  _markupCustomization

  constructor ({ deliveryTemplateIdentifier = null, identifier = null, name = null, markupCustomization } = {}) {
    this.deliveryTemplateIdentifier = deliveryTemplateIdentifier || identifier
    this.name = name
    this.markupCustomization = markupCustomization
  }

  set markupCustomization (markupCustomization) {
    this._markupCustomization = markupCustomization instanceof PhysicalTemplateMarkupCustomization
      ? markupCustomization
      : new PhysicalTemplateMarkupCustomization(markupCustomization)
  }

  get markupCustomization () {
    return this._markupCustomization
  }

  toJSON () {
    return {
      deliveryTemplateIdentifier: this.deliveryTemplateIdentifier,
      name: this.name,
      markupCustomization: this.markupCustomization.toJSON()
    }
  }
}
