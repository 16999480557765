import * as getTypes from '../get-types'
import * as actionTypes from '../action-types'
import * as mutationTypes from '../mutation-types'
import { HttpClient } from '../../components/shared/security/http-client'

const state = {
  orderRecipientWhitelist: null,
  orderWhitelist: null,
  orderWhitelistExists: null
}

const getters = {
  [getTypes.GET_ORDER_WHITELIST_RECIPIENTS]: state => state.orderRecipientWhitelist,
  [getTypes.GET_ORDER_WHITELIST_RECIPIENTS_EXISTS]: state => !!(state.orderRecipientWhitelist),
  [getTypes.GET_ORDER_WHITELIST_RECIPIENT_EMAILS]: state => {
    return state.orderRecipientWhitelist
      ? state.orderRecipientWhitelist.recipients.map(rec => rec.email)
      : []
  }
}

const getRecipientWhitelistHttpCall = async (uri) => {
  try {
    const orderWhitelistResponse = await HttpClient.get(uri)
    return orderWhitelistResponse.data
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      // 404 is an expected error, since a whitelist could possibly not exist for the platform at the time of the call.
      console.error(error)
    }
    return null
  }
}

const actions = {
  async [actionTypes.GET_ORDER_WHITELIST_RECIPIENTS_ASYNC] ({
    commit,
    getters
  }) {
    if (getters[getTypes.GET_ORDER_WHITELIST_RECIPIENTS_EXISTS]) {
      return getters[getTypes.GET_ORDER_WHITELIST_RECIPIENTS]
    } else {
      const recipientWhitelistGotten = await getRecipientWhitelistHttpCall('/api/orderWhitelist/recipients')
      commit(mutationTypes.SET_ORDER_WHITELIST_RECIPIENTS,
        recipientWhitelistGotten)
      return recipientWhitelistGotten
    }
  }
}

const mutations = {
  [mutationTypes.SET_ORDER_WHITELIST_RECIPIENTS] (state, recipientWhitelist) {
    state.orderRecipientWhitelist = recipientWhitelist
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
