import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { TcThemeLight } from '@tangocard/swag'
import '@tangocard/swag/dist/tc-icons/tc-icons.css'

const config = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      // https://vuetifyjs.com/en/customization/theme/#custom-properties
      customProperties: true
    },
    themes: {
      light: {
        ...TcThemeLight,
        primary: '#248D9F'
      }
    }
  }
}

Vue.use(Vuetify)

export default new Vuetify(config)
