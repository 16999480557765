import { mapGetters } from 'vuex'
import * as getTypes from '../../../../store/get-types'

const orderReportsSplitMixin = {
  computed: {
    ...mapGetters({
      getTreatmentEnabled: getTypes.GET_TREATMENT_ENABLED
    })
  }
}

export {
  orderReportsSplitMixin as OrderReportsSplitMixin
}
