import store from '../../../store'
import { SNACK } from '../../../store/action-types'

function snack (message, loading, variant, timeout, linkUrl, linkText, callback) {
  store.dispatch(SNACK, {
    message,
    loading,
    variant,
    timeout,
    linkUrl,
    linkText,
    callback
  })
}

function SnackPlugin (Vue) {
  Vue.prototype.$snack = snack
}

export {
  SnackPlugin
}
