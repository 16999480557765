import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import { HttpClient } from '@/components/shared/security/http-client'

const state = {
  undeliverableEmailCount: 0
}

const getters = {
  [getTypes.GET_UNDELIVERABLE_EMAIL_COUNT]: state => {
    return state.undeliverableEmailCount
  }
}

const mutations = {
  [mutationTypes.SET_UNDELIVERABLE_EMAIL_COUNT] (state, count) {
    state.undeliverableEmailCount = count
  }
}

const actions = {
  async [actionTypes.FETCH_UNDELIVERABLE_EMAIL_COUNT] ({ commit, state }) {
    try {
      const undeliveredEmailsResponse = await HttpClient.get('/api/email/deliveryStatusCounts')
      if (undeliveredEmailsResponse && undeliveredEmailsResponse.data) {
        const notDeliveredCount = undeliveredEmailsResponse.data.NOT_DELIVERED || 0
        commit(mutationTypes.SET_UNDELIVERABLE_EMAIL_COUNT, notDeliveredCount)
      }
    } catch (error) {
      console.error('Could not fetch and set undelivered email status counts', error)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
