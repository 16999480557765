import * as getTypes from '../get-types'
import * as actionTypes from '../action-types'
import * as types from '../mutation-types'
import { HttpClient } from '@/components/shared/security/http-client'

const state = {
  user: null,
  authenticated: false
}

const getters = {
  [getTypes.PRINCIPLE_GET_USER]: state => state.user,
  [getTypes.PRINCIPLE_GET_AUTHENTICATED]: state => state.authenticated
}

const actions = {
  [actionTypes.PRINCIPLE_CLEAR_USER] (context) {
    context.commit(types.PRINCIPLE_SET_USER, null)
  },
  [actionTypes.PRINCIPLE_GET_ASYNC] (context, forceReload) {
    if (!forceReload && state.user) {
      return Promise.resolve(state.user)
    }

    return HttpClient.get('api/account').then((response) => {
      const accountUser = response.data
      context.commit(types.PRINCIPLE_SET_USER, accountUser)
      context.commit(types.PRINCIPLE_SET_AUTHENTICATED, true)
      return accountUser
    }).catch(() => {
      context.commit(types.PRINCIPLE_SET_USER, null)
      context.commit(types.PRINCIPLE_SET_AUTHENTICATED, false)
    })
  }
}

const mutations = {
  [types.PRINCIPLE_SET_USER] (state, user) {
    state.user = user
  },

  [types.PRINCIPLE_SET_AUTHENTICATED] (state, authenticated) {
    state.authenticated = !!authenticated
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
