import { HttpClient } from '@/components/shared/security/http-client'
import { CUSTOMERS_SIZE } from '@/components/shared/constants'

export const getAccount = async (accountIdentifier) => {
  // Currently we can't actually get the account directly without the customerIdentifier as well
  // So for now we have to do a search and then filter on the result :(
  // Tech Debt Ticket: https://tangocard.atlassian.net/browse/RG-5146
  const accountsResponse = await HttpClient.get('api/customers', { params: { size: CUSTOMERS_SIZE } })
  const filteredAccounts = accountsResponse.data.filter(account => account.identifier === accountIdentifier)
  return filteredAccounts.length ? filteredAccounts[0] : null
}
