import store from '../store'
import { GET_TREATMENT_ENABLED } from '@/store/get-types'
import {
  API_KEY_MANAGEMENT,
  ORDER_SETTINGS,
  QUALTRICS_SPLIT,
  OAUTH_CLIENT_CRED_MGMT, INTEGRATION_CREDENTIALS
} from '@/components/shared/split/split-constants'
import * as authorities from '../components/shared/constants/authority.constants'
import * as AccessLevel from '@/components/shared/constants/access-levels.constants'
import { hasAuthority } from '@/components/shared/security/authorized-plugin'
import {
  API_KEYS_MANAGE,
  QUALTRICS_API_KEYS_ACCESS,
  QUALTRICS_API_KEYS_MANAGE
} from '../components/shared/constants/authority.constants'

const APIKeyManagement = () => import(/* webpackChunkName: "APIKeyManagement" */ '../components/settings/api-key-management/APIKeyManagement')
const QualtricsAPIKeyManagement = () => import(/* webpackChunkName: "QualtricsAPIKeyManagement" */ '../components/settings/api-key-management/QualtricsAPIKeyManagement')
const Settings = () => import(/* webpackChunkName: "Settings" */ '../components/platform/accounts-user-management/user-management/Settings')
const RedirectToRoot = () => import(/* webpackChunkName: "RedirectToRoot" */ '../components/shared/RedirectToRoot')
const OrderSettingsManagement = () => import(/* webpackChunkName: "OrderSettingsManagement" */ '../components/settings/order-settings-mangement/OrderSettingsManagement')
const OAuthClientCredentialsManagement = () => import(/* webpackChunkName: "OAuthClientCredentialsManagement" */ '../components/settings/oauth-client-credentials-management/ClientCredentialsManagement')
const IntegrationsMain = () => import(/* webpackChunkName: "OAuthClientCredentialsManagement" */ '@/components/settings/integrations/IntegrationsMain')

export default [
  {
    path: '/settings/user',
    name: 'User Settings',
    component: Settings,
    meta: {
      segmentName: 'User Settings',
      authorities: [authorities.IS_LOGGED_IN],
      pageTitle: 'settings.title'
    }
  },
  {
    path: '/settings/api-key-management',
    name: 'APIKeyManagement',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](API_KEY_MANAGEMENT)) {
        const apiKeyManagementModule = await APIKeyManagement()
        goTo(apiKeyManagementModule)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      authorities: [authorities.IS_LOGGED_IN, authorities.API_KEYS_MANAGE],
      pageTitle: 'apiKeyManagement.page.title'
    }
  },
  {
    path: '/settings/oauth-client-credentials-management',
    name: 'OAuthClientCredentialsManagement',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](OAUTH_CLIENT_CRED_MGMT)) {
        const oauthClientCredentialsManagementModule = await OAuthClientCredentialsManagement()
        goTo(oauthClientCredentialsManagementModule)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      authorities: [authorities.IS_LOGGED_IN, authorities.API_KEYS_MANAGE],
      pageTitle: 'oauthClientCredentialsManagement.page.title'
    }
  },
  {
    path: '/settings/qualtrics-api-key-management',
    name: 'QualtricsAPIKeyManagement',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](QUALTRICS_SPLIT)) {
        const apiKeyManagementModule = await QualtricsAPIKeyManagement()
        goTo(apiKeyManagementModule)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      authorities: [authorities.IS_LOGGED_IN, authorities.QUALTRICS_API_KEYS_ACCESS],
      pageTitle: 'qualtricsApiKeyManagement.page.title'
    }
  },
  {
    path: '/settings/order-settings-management',
    name: 'OrderSettingsManagement',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](ORDER_SETTINGS)) {
        const orderSettingsManagementModule = await OrderSettingsManagement()
        goTo(orderSettingsManagementModule)
      }
      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      authorities: [authorities.IS_LOGGED_IN, authorities.PLATFORM_ORDER_SETTINGS],
      pageTitle: 'settings.orderSettings.title'
    }
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](INTEGRATION_CREDENTIALS) && (hasAuthority(API_KEYS_MANAGE) || hasAuthority(QUALTRICS_API_KEYS_ACCESS) || hasAuthority(QUALTRICS_API_KEYS_MANAGE))) {
        const integrationModule = await IntegrationsMain()
        goTo(integrationModule)
      }

      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      authorities: [authorities.IS_LOGGED_IN],
      accessLevel: AccessLevel.PLATFORM,
      pageTitle: 'settings.integrations.title',
      activeSubComponent: 'integrations'
    }
  },
  {
    path: '/integrations/history',
    name: 'CredentialHistory',
    component: async (goTo) => {
      if (store.getters[GET_TREATMENT_ENABLED](INTEGRATION_CREDENTIALS) && (hasAuthority(API_KEYS_MANAGE) || hasAuthority(QUALTRICS_API_KEYS_ACCESS) || hasAuthority(QUALTRICS_API_KEYS_MANAGE))) {
        const integrationModule = await IntegrationsMain()
        goTo(integrationModule)
      }

      const redirectModule = await RedirectToRoot()
      goTo(redirectModule)
    },
    meta: {
      authorities: [authorities.IS_LOGGED_IN],
      accessLevel: AccessLevel.PLATFORM,
      pageTitle: 'settings.integrations.tabs.integrationsCredentials.title',
      activeSubComponent: 'integrations_credentials'
    }
  }
]
