import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import { FileUploadOrder, Order } from '../classes'

const createBlankOrder = () => ({
  groupAndAccount: {},
  utid: '',
  rewardName: '',
  rewardAmount: {
    currencyCode: '',
    value: null
  },
  emailTemplate: {
    etid: '',
    name: ''
  },
  messageBlock: '',
  recipients: [],
  completedSteps: [] // array of the step name strings
})

const modules = {
  orders: {
    state: {
      loaded: false,
      order: createBlankOrder()
    },
    actions: {
      [actionTypes.CLEAR_ORDER] (context) {
        context.state.order = createBlankOrder()
        context.state.loaded = false
        localStorage.removeItem('order-draft')
      }
    },
    getters: {
      // NB: Remember, if you make a change to the schema, you need to be
      // backward compatible for users who already have an order saved
      [getTypes.GET_ORDER_DRAFT]: (state) => {
        if (!state.loaded) {
          state.loaded = true
          const draft = localStorage.getItem('order-draft')
          if (draft) {
            state.order = JSON.parse(draft)
          }
        }
        return state.order
      }
    },
    mutations: {
      [mutationTypes.SET_ORDER_DRAFT] (state, order) {
        state.order = order
        localStorage.setItem('order-draft', JSON.stringify(order))
      }
    }
  },
  orders_new: {
    namespaced: true,
    state: {
      loaded: false,
      order: new Order()
    },
    actions: {
      [actionTypes.CLEAR_ORDER] (context) {
        context.state.order = new Order()
        context.state.loaded = false
        localStorage.removeItem('order-draft-v2')
      }
    },
    getters: {
      // NB: Remember, if you make a change to the schema, you need to be
      // backward compatible for users who already have an order saved
      [getTypes.GET_ORDER_DRAFT]: (state) => {
        if (!state.loaded) {
          state.loaded = true
          const draft = localStorage.getItem('order-draft-v2')
          if (draft) {
            state.order = new Order(JSON.parse(draft))
          }
        }
        return state.order
      }
    },
    mutations: {
      [mutationTypes.SET_ORDER_DRAFT] (state, order) {
        // Has steps 1-3 changed?
        const isDirty = state.order.recipients !== order.recipients || state.order.reward !== order.reward || state.order.email !== order.email
        state.order = order
        if (isDirty) {
          // Indicate that the order needs to be reuploaded
          state.order.updatedSinceUpload = true
        }

        if (order.recipients.length || state.order.recipients.length) {
          localStorage.setItem('order-draft-v2', JSON.stringify(order))
        }
      }
    }
  },
  orders_file_upload: {
    namespaced: true,
    state: {
      loaded: false,
      order: new FileUploadOrder()
    },
    actions: {
      [actionTypes.CLEAR_ORDER] (context) {
        context.state.order = new FileUploadOrder()
        context.state.loaded = false
        localStorage.removeItem('order-draft-file-upload-v2')
      },
      [actionTypes.SET_ORDER_DRAFT] ({ commit }, draftOrder) {
        commit(mutationTypes.SET_ORDER_DRAFT, draftOrder)
      }
    },
    getters: {
      // NB: Remember, if you make a change to the schema, you need to be
      // backward compatible for users who already have an order saved
      [getTypes.GET_ORDER_DRAFT]: (state) => {
        if (!state.loaded) {
          state.loaded = true
          const draft = localStorage.getItem('order-draft-file-upload-v2')
          if (draft) {
            state.order = new FileUploadOrder(JSON.parse(draft))
          }
        }
        return state.order
      }
    },
    mutations: {
      [mutationTypes.SET_ORDER_DRAFT] (state, order) {
        state.order = order
        localStorage.setItem('order-draft-file-upload-v2', JSON.stringify(order))
      }
    }
  }
}

export default {
  modules
}
