import { EmailTemplateMarkupCustomization } from '@/dto/order/email-templates/EmailTemplateMarkupCustomization'

export class SelectedEmailTemplate {
  _markupCustomization

  constructor ({ deliveryTemplateIdentifier = null, emailTemplateIdentifier = null, name = null, markupCustomization } = {}) {
    this.deliveryTemplateIdentifier = deliveryTemplateIdentifier || emailTemplateIdentifier
    this.name = name
    this.markupCustomization = markupCustomization
  }

  set markupCustomization (markupCustomization) {
    this._markupCustomization = markupCustomization instanceof EmailTemplateMarkupCustomization
      ? markupCustomization
      : new EmailTemplateMarkupCustomization(markupCustomization)
  }

  get markupCustomization () {
    return this._markupCustomization
  }

  toJSON () {
    return {
      deliveryTemplateIdentifier: this.deliveryTemplateIdentifier,
      name: this.name,
      markupCustomization: this.markupCustomization.toJSON()
    }
  }
}
