import { OrderMetadata } from '@/dto/order/OrderMetadata'
import * as getTypes from '@/store/get-types'
import * as mutationTypes from '@/store/mutation-types'
import * as actionTypes from '@/store/action-types'
import { money } from '@/components/shared/formatters/money'
import { HttpClient } from '@/components/shared/security/http-client'
import i18n from '@/i18n'
import { FulfillmentType } from '@/dto/order/FulfillmentType'
import SelectedRewardItemService from '@/components/shared/SelectedRewardItemService'
import { cloneDeep, map, uniqBy } from 'lodash'
import { hasAnyAuthority } from '@/components/shared/security/authorized-plugin'
import { FUNDING_MANAGE, VIEW_BALANCE_ACCESS } from '@/components/shared/constants/authority.constants'
import { ShoppingCartService } from '@/components/shared/ShoppingCartService'
import {
  RG_7991_SHOPPING_CART_INTEGRATION
} from '@/components/shared/split/split-constants'
import { isSplitEnabled } from '@/components/shared/split/split'

const newCartSummary = {
  fullOrderQuote: {
    // The original amount of the order (includes exchange rate adjusts, does NOT include item adjustments / fees)
    originalAmount: undefined,
    // The final amount of the order with exchange rate calculations and item adjustments
    orderTotal: undefined,
    feeTotal: undefined,
    currencyCode: undefined
  },
  recipientCount: undefined,
  digitalOrders: {
    recipientCount: undefined,
    subtotal: undefined
  },
  physicalOrders: {
    recipientCount: undefined,
    subtotal: undefined
  },
  summaryByUtid: [],
  hasInsufficientFunds: false,
  hasANonPositiveAmountLineItem: false
}

const newShoppingCart = {
  cartId: null,
  status: null,
  platformHash: null,
  groupIdentifier: null,
  accountIdentifier: null,
  userIdentifier: null,
  externalReferenceId: null,
  orderReferenceId: null,
  orderProcessor: null,
  orderProcessorIdentifier: null,
  orderSource: null,
  paymentSourceIdentifier: null,
  estimate: {
    fxSubtotals: [],
    fxFees: [],
    totalAmount: {
      amount: null,
      currencyCode: null,
      amountPlainString: null
    },
    totalFees: {
      amount: null,
      currencyCode: null,
      amountPlainString: null
    }
  },
  errors: [],
  containsInvalidLineItems: false,
  metadata: {},
  lineItemCount: 0
}

const state = {
  previouslyDisplayedCartCount: 0,
  metadata: new OrderMetadata(),
  order: {
    checkingPendingOrder: false,
    hasCheckedPendingOrder: false,
    uploadId: null,
    // Heads up, this identifier isnt being used anywhere at this point but will be very soon
    // https://tangocard.atlassian.net/browse/RG-3260
    identifier: null,
    summary: { ...newCartSummary },
    invalidEtidArray: [],
    cartId: null // the cartId tied to the uploadId in the uploads table
  },
  shoppingCart: newShoppingCart,
  // If the shopping cart split is enabled while a user already has a Portal upload in progress, there will be sync
  // issues between Portal and the new shopping cart. If the carts are out of sync, Portal will fall back to using
  // its own cart. This flag is used to prevent the new cart details from being displayed when the 2 are out of sync.
  shoppingCartSummaryActive: false
}

const getters = {
  [getTypes.GET_ORDER_UPLOAD_ID]: ({ order: { uploadId = null } = {} } = {}) => uploadId,
  [getTypes.GET_SHOPPING_CART_ID]: ({ order: { cartId = null } = {} } = {}) => cartId,
  [getTypes.GET_SHOPPING_CART_SUMMARY_ACTIVE]: ({ shoppingCartSummaryActive }) => shoppingCartSummaryActive,
  [getTypes.GET_ORDER_TOTAL_SUMMARY]: (state) => {
    return state.order
  },
  // This is the order amount prior to item fees
  // Return null if the originalAmount is null, otherwise money will convert to 0.00, which is inaccurate
  [getTypes.GET_FORMATTED_PRECALCULATED_ORDER_TOTAL]: (state) => {
    const { originalAmount } = state.order.summary.fullOrderQuote
    return originalAmount === null ? null : money(originalAmount, { code: state.order.summary.fullOrderQuote.currencyCode })
  },
  // This is the aggregate total of item fees
  [getTypes.GET_FORMATTED_FEE_TOTAL]: (state) => {
    return money(state.order.summary.fullOrderQuote.feeTotal, { code: state.order.summary.fullOrderQuote.currencyCode })
  },
  // This is the order total to charge the customer including exchange rate calculations and item fees
  [getTypes.GET_FORMATTED_ORDER_TOTAL_TO_BE_CHARGED]: (state) => {
    return money(state.order.summary.fullOrderQuote.orderTotal, { code: state.order.summary.fullOrderQuote.currencyCode })
  },
  [getTypes.GET_HAS_INSUFFICIENT_FUNDS]: (state) => {
    return state.order.summary.hasInsufficientFunds
  },
  [getTypes.GET_ORDER_CART_SUMMARY]: (state) => {
    return state.order.summary
  },
  [getTypes.GET_IS_FILE_UPLOAD]: (state) => {
    return state.order.summary.isFileUpload
  },
  [getTypes.GET_UNIQUE_UTIDS]: (state) => {
    return state.order.summary.summaryByUtid.map(({ utid }) => utid)
  },
  [getTypes.GET_ORDER_METADATA]: ({ metadata }) => metadata,
  [getTypes.GET_TOTAL_RECIPIENTS]: ({ order }) => order.summary.recipientCount,
  [getTypes.GET_HAS_PENDING_ORDER]: ({ order }) => order.summary.recipientCount > 0,
  [getTypes.GET_PREVIOUSLY_DISPLAYED_CART_COUNT]: ({ previouslyDisplayedCartCount }) => previouslyDisplayedCartCount,
  [getTypes.GET_ORDER_BRAND_MAP]: (state, getters, rootState, rootGetters) => {
    const brandMap = {}
    state.order.summary.summaryByUtid.forEach(utidSummary => {
      const brand = rootGetters['catalog/' + getTypes.GET_BRAND_BY_UTID](utidSummary.utid)

      // if the UTID from the summary is not in this catalog, don't add it to the brandMap
      if (brand) brandMap[brand.brandKey] = brand
    })
    return brandMap
  },
  [getTypes.GET_ORDER_FULFILLMENT_TYPES]: ({ order }) => {
    const fulfillmentTypes = []
    if (order.summary.digitalOrders.recipientCount > 0) {
      fulfillmentTypes.push(FulfillmentType.DIGITAL)
    }
    if (order.summary.physicalOrders.recipientCount > 0) {
      fulfillmentTypes.push(FulfillmentType.PHYSICAL)
    }
    return fulfillmentTypes
  },
  [getTypes.GET_TOTAL_BRANDS]: (state, { [getTypes.GET_ORDER_BRAND_MAP]: orderBrands }, rootState) => {
    return Object.keys(orderBrands).length
  },
  [getTypes.GET_CART_HAS_PHYSICAL_ITEMS]: ({ order }) => {
    return order.summary.physicalOrders.recipientCount > 0
  },
  [getTypes.ENTERED_REWARD_ITEMS_LOCAL_STORAGE_KEY]: (state, getters, rootState, rootGetters) => {
    const { identityHash } = rootGetters[getTypes.PRINCIPLE_GET_USER] || {}
    return `rg.entered.items.${identityHash}`
  },
  [getTypes.GET_INVALID_ETID_ARRAY]: (state) => {
    return state.order.invalidEtidArray
  },
  [getTypes.GET_SHOPPING_CART]: state => state.shoppingCart
}

const actions = {
  [actionTypes.FETCH_SHOPPING_CART_BY_ID]: async ({ commit, getters }, cartId) => {
    if (!isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) return

    if (!cartId) {
      return getters[getTypes.GET_SHOPPING_CART]
    }

    try {
      const cart = await ShoppingCartService.getShoppingCart(cartId)
      commit(mutationTypes.SET_SHOPPING_CART, cart)
      return cart
    } catch (error) {
      console.error('Error fetching shopping cart:', error)
    }
  },
  [actionTypes.FETCH_ACTIVE_SHOPPING_CART]: async ({ commit, getters }, checkState = true) => {
    if (!isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) return null

    const cart = getters[getTypes.GET_SHOPPING_CART]
    if (checkState && cart?.cartId) {
      return cart
    }

    try {
      const fetchedCart = await ShoppingCartService.getActiveShoppingCart()
      commit(mutationTypes.SET_SHOPPING_CART, fetchedCart)
      return fetchedCart
    } catch (error) {
      console.error('Error fetching or creating shopping cart:', error)
      return null
    }
  },
  async [actionTypes.UPDATE_ORDER_TOTAL_SUMMARY] ({ commit, getters, rootGetters, dispatch }) {
    const selectedCustomerAccount = rootGetters[getTypes.GET_SELECTED_CUSTOMER_ACCOUNT]
    const { currencyCode } = selectedCustomerAccount || {}
    const uploadId = getters[getTypes.GET_ORDER_UPLOAD_ID]
    const orderSummary = {
      summary: cloneDeep(newCartSummary)
    }

    if (currencyCode && uploadId) {
      try {
        let data = (await HttpClient.get(`api/orders/upload/${uploadId}/summary/${currencyCode}`)).data

        let newShoppingCartSummaryActive = false
        if (isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) {
          const cartId = getters[getTypes.GET_SHOPPING_CART_ID]
          const cart = await dispatch(actionTypes.FETCH_SHOPPING_CART_BY_ID, cartId)

          if (cartId && cart.lineItemCount === data.recipientCount) {
            if (cart?.accountIdentifier !== selectedCustomerAccount.accountIdentifier) {
              await ShoppingCartService.updateCart(cart.cartId, selectedCustomerAccount.accountIdentifier)
            }
            // We are intentionally calling 2 different summary endpoints. The first call triggers the backend to
            // compare the order totals between Portal uploads and the shopping cart integration. The accounting
            // team is alerted when the totals do not match.
            data = (await ShoppingCartService.getCartSummary(cartId)).data
            newShoppingCartSummaryActive = true
          }
        }

        commit(mutationTypes.SHOPPING_CART_SUMMARY_ACTIVE, newShoppingCartSummaryActive)

        // Sanitize monetary rounding
        const fractionDigits = 2
        const originalAmount = Number.parseFloat(Number.parseFloat(data.fullOrderQuote.originalOrderAmount).toFixed(fractionDigits))
        const orderTotal = Number.parseFloat(Number.parseFloat(data.fullOrderQuote.quoteAmount).toFixed(fractionDigits))
        const feeTotal = Number.parseFloat(Number.parseFloat(data.fullOrderQuote.feeTotal).toFixed(fractionDigits))

        if (!hasAnyAuthority([VIEW_BALANCE_ACCESS, FUNDING_MANAGE])) {
          // If the user can't view the account balance, check if the account has
          // insufficient funds in the backend.
          try {
            const response = await HttpClient.get(`/api/accounts/${selectedCustomerAccount.accountIdentifier}/sufficientFunds?orderTotal=${orderTotal}`)
            orderSummary.summary.hasInsufficientFunds = !response.data
          } catch (error) {
            console.error('Error determining if the account has sufficient funds')
            orderSummary.hasInsufficientFunds = false
          }
        }

        // Build the object and persist it to the state
        orderSummary.summary.fullOrderQuote.originalAmount = originalAmount
        orderSummary.summary.fullOrderQuote.orderTotal = orderTotal
        orderSummary.summary.fullOrderQuote.feeTotal = feeTotal
        orderSummary.summary.fullOrderQuote.currencyCode = data.fullOrderQuote.quoteCurrency
        orderSummary.summary.recipientCount = data.recipientCount
        orderSummary.summary.digitalOrders.recipientCount = data.digitalOrders.recipientCount
        orderSummary.summary.digitalOrders.subtotal = Number.parseFloat(Number.parseFloat(data.digitalOrders.subtotal).toFixed(fractionDigits))
        orderSummary.summary.physicalOrders.recipientCount = data.physicalOrders.recipientCount
        orderSummary.summary.physicalOrders.subtotal = Number.parseFloat(Number.parseFloat(data.physicalOrders.subtotal).toFixed(fractionDigits))
        orderSummary.summary.summaryByUtid = data.summaryByUtid
        orderSummary.summary.etidUsedForAllUploadLines = data.etidUsedForAllUploadLines
        orderSummary.summary.isFileUpload = data.isFileUpload
        orderSummary.summary.hasANonPositiveAmountLineItem = data.hasANonPositiveAmountLineItem
      } catch (error) {
        console.error(error)
      }
    }
    commit(mutationTypes.SET_ORDER_TOTAL_SUMMARY, orderSummary)
  },
  [actionTypes.CLEAR_ORDER]: ({ commit, getters, dispatch }, metadata) => {
    dispatch(actionTypes.CLEAR_DEFAULT_DELIVERY_TEMPLATES, null, { root: true })
    commit(mutationTypes.CLEAR_ORDER_VALUES, metadata)
    localStorage.removeItem(getters[getTypes.ENTERED_REWARD_ITEMS_LOCAL_STORAGE_KEY])
    if (isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) {
      commit(mutationTypes.CLEAR_SHOPPING_CART)
    }
  },
  [actionTypes.UPLOAD_ORDER]: async ({ commit, dispatch, state }, { accountIdentifier }) => {
    const uploadId = state.order.uploadId

    try {
      if (uploadId == null) {
        const { data: uploadSummary } = await HttpClient.post('api/orders/upload/create', null, { params: { accountIdentifier } })
        commit(mutationTypes.SET_ORDER_TOTAL_SUMMARY, {
          uploadId: uploadSummary.uploadId,
          identifier: uploadSummary.identifier,
          hasCheckedPendingOrder: true
        })
      }
    } catch (error) {
      dispatch(actionTypes.TOAST, { html: i18n.t(error.response.data.description), type: 'danger' }, { root: true })
      throw error
    }
  },
  [actionTypes.UPDATE_ORDER_METADATA]: async ({ commit, dispatch, state, rootGetters }, metadata) => {
    try {
      await HttpClient.patch('/api/bulkorder/upload/' + state.order.uploadId, { ...state.metadata, ...metadata })
      commit(mutationTypes.SET_ORDER_METADATA, metadata)

      if (isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) {
        const selectedCustomerAccount = rootGetters[getTypes.GET_SELECTED_CUSTOMER_ACCOUNT]
        const cartId = (await dispatch(actionTypes.FETCH_ACTIVE_SHOPPING_CART)).cartId
        await ShoppingCartService.updateCart(cartId,
          selectedCustomerAccount.accountIdentifier,
          { ...state.metadata, ...metadata })
      }
    } catch (e) {
      dispatch(actionTypes.TOAST, { html: i18n.t(e.response.data.description), type: 'danger' }, { root: true })
      throw e
    }
  },
  [actionTypes.ADD_ITEMS_TO_CART]: async ({ commit, dispatch, state }, { accountIdentifier, newItems, deliveryType }) => {
    let uploadId = state.order.uploadId

    try {
      // If we dont have an upload, lets create one real quick so we can actually add the items
      if (uploadId == null) {
        await dispatch(actionTypes.UPLOAD_ORDER, { accountIdentifier })
      }

      // regrab the upload id now that have created an uploaded order
      uploadId = state.order.uploadId

      // we are mapping the SelectedRewardItem() objects to the UploadLineDTO format
      const requests = newItems.map(item => SelectedRewardItemService.mapRecipient(item, deliveryType))

      // we give the uploadLineDTO objects and it returns them back except they have the generated data such as identifier populated. We need this for updates/deletes later
      const params = {
        accountIdentifier
      }
      if (isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) {
        let cart = await dispatch(actionTypes.FETCH_ACTIVE_SHOPPING_CART)
        if (!cart?.cartId) {
          cart = await dispatch(actionTypes.CREATE_SHOPPING_CART, { accountIdentifier, uploadId })
        }

        params.cartId = cart.cartId
      }

      const { data: createdUploadLines } = await HttpClient.post(`api/bulkorder/upload/${uploadId}/lines`, requests, { params })
      // re-map those UploadLineDTOs back to SelectedRewardItem() so we can store them in the vuex store
      await Promise.all(createdUploadLines.map(async (uploadLine) => {
        // Java layer doesn't provide delivery template identifier in place of etids yet
        const deliveryTemplateIdentifier = uploadLine.deliveryTemplateIdentifier || uploadLine.etid
        const deliveryTemplate = await dispatch(actionTypes.RETRIEVE_AND_SET_TEMPLATE_IF_EMPTY, deliveryTemplateIdentifier, { root: true })
        return SelectedRewardItemService.mapSelectedRewardItemFromUploadLine(uploadLine, deliveryTemplate)
      }))

      await dispatch(actionTypes.FETCH_DRAFT_ORDER)
    } catch (error) {
      console.log(`Error with network call to add items to cart for order ${uploadId}`)
      await handleShoppingCartErrors({ commit, dispatch, error })
      return false
    }
    return true
  },
  [actionTypes.CLEAR_ALL_ITEMS_IN_CART]: async ({ commit, dispatch, state }, fulfillmentType) => {
    const uploadId = state.order.uploadId

    try {
      console.log(`Clearing all items in cart for order ${uploadId} with fulfillment type ${fulfillmentType}`)
      await HttpClient.delete(`api/bulkorder/upload/${uploadId}/upload-lines/fulfillment/${fulfillmentType}`)

      if (isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) {
        const cartId = (await dispatch(actionTypes.FETCH_SHOPPING_CART_BY_ID, state.shoppingCart.cartId)).cartId
        await ShoppingCartService.deleteLineItemsByFulfillmentType(cartId, fulfillmentType)
        await dispatch(actionTypes.UPDATE_ORDER_TOTAL_SUMMARY)
      }

      // re-fetch the order so we can populate the summary accordingly
      await dispatch(actionTypes.FETCH_DRAFT_ORDER)
    } catch (error) {
      console.log(`Error with network call to remove all items in cart for order ${uploadId} with fulfillment type ${fulfillmentType}`)
      await handleShoppingCartErrors({ commit, dispatch, error })
    }
  },
  [actionTypes.DELETE_REWARD_ITEM_FROM_CART]: async ({ commit, dispatch, state }, identifier) => {
    const uploadId = state.order.uploadId

    try {
      console.log(`Removing reward item ${identifier} from cart ${uploadId}`)
      await HttpClient.delete(`api/bulkorder/upload/${uploadId}/upload-line/${identifier}`)
      await dispatch(actionTypes.UPDATE_ORDER_TOTAL_SUMMARY)
    } catch (error) {
      console.log(`Error with network call to remove reward item ${identifier} from cart ${uploadId}`)
      await handleShoppingCartErrors({ commit, dispatch, error })
    }

    if (isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) {
      const cartId = (await dispatch(actionTypes.FETCH_SHOPPING_CART_BY_ID, state.shoppingCart.cartId)).cartId
      await ShoppingCartService.deleteLegacyLineItem(cartId, identifier)
      await dispatch(actionTypes.UPDATE_ORDER_TOTAL_SUMMARY)
    }
  },
  [actionTypes.UPDATE_REWARD_ITEM_IN_CART]: async ({ commit, dispatch, state }, rewardItem) => {
    const updateRequest = SelectedRewardItemService.mapRecipient(rewardItem, rewardItem.recipient?.deliveryType)
    const uploadId = state.order.uploadId

    if (isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) {
      const cartId = (await dispatch(actionTypes.FETCH_SHOPPING_CART_BY_ID, state.shoppingCart.cartId)).cartId
      await ShoppingCartService.updateLegacyLineItem(cartId, rewardItem.identifier, updateRequest)
      await dispatch(actionTypes.UPDATE_ORDER_TOTAL_SUMMARY)
    }

    try {
      console.log(`Updating item in cart for order ${uploadId} and item ${rewardItem.identifier}`)
      await HttpClient.put(`api/bulkorder/upload/${uploadId}/upload-line/${rewardItem.identifier}`, updateRequest)
      await dispatch(actionTypes.UPDATE_ORDER_TOTAL_SUMMARY)
      return true
    } catch (error) {
      console.log(`Error with network call to update item in cart for order ${uploadId} and item ${rewardItem.identifier}`)
      await handleShoppingCartErrors({ commit, dispatch, error })
      return false
    }
  },
  [actionTypes.UPDATE_PREVIOUSLY_DISPLAYED_CART_COUNT]: ({ commit }, newCartCount) => {
    commit(mutationTypes.SET_PREVIOUSLY_DISPLAYED_CART_COUNT, newCartCount)
  },
  [actionTypes.FETCH_DRAFT_ORDER]: async ({ commit, dispatch, state, rootGetters }) => {
    // Because there are so many callers of FETCH_DRAFT_ORDER, we need to ensure that
    // only one request can be out at a time to prevent weird race conditions
    if (!state.order.checkingPendingOrder) {
      // For now, this number shouldn't go over 2k since thats what we max out the bulk upload at.
      // But, if they bulk upload and then manually add items to the cart, we run into issues by leaving it at 2k
      // thus, this number is set to a higher amount to account for that edge case. But this will be going altogether in the future.
      commit(mutationTypes.SET_ORDER_TOTAL_SUMMARY, {
        checkingPendingOrder: true,
        hasCheckedPendingOrder: false
      })
      const response = await HttpClient.get('/api/orders/upload/pending-upload')
      const pendingOrder = response.data

      if (!response || !pendingOrder) {
        commit(mutationTypes.SET_ORDER_TOTAL_SUMMARY, {
          checkingPendingOrder: false,
          hasCheckedPendingOrder: true,
          summary: cloneDeep(newCartSummary)
        })
        dispatch(actionTypes.REMOVE_OUT_OF_SYNC_CARTS)
        return
      }

      commit(mutationTypes.CLEAR_ORDER_VALUES)
      commit(mutationTypes.SET_ORDER_TOTAL_SUMMARY, {
        uploadId: pendingOrder.uploadId,
        identifier: pendingOrder.identifier,
        hasCheckedPendingOrder: true,
        checkingPendingOrder: false,
        cartId: pendingOrder.cartId
      })

      commit(mutationTypes.SET_ORDER_METADATA, {
        purchaseOrderNumber: pendingOrder.purchaseOrderNumber,
        notes: pendingOrder.notes
      })

      await dispatch(actionTypes.UPDATE_ORDER_TOTAL_SUMMARY)
    }
  },
  [actionTypes.SET_INVALID_ETID_ARRAY]: async ({ state, rootGetters }) => {
    if (!state.order?.uploadId) {
      state.order.invalidEtidArray = []
      return false
    }

    const selectedCustomerAccount = rootGetters[getTypes.GET_SELECTED_CUSTOMER_ACCOUNT]
    // look up valid email templates for this account and find any
    // on this order that are not accessible by this account
    const { data: etidsForAccount } = await HttpClient.get(`/api/email-templates/account/${selectedCustomerAccount.accountIdentifier}/etids`)
    const { data: uploadLines } = await HttpClient.get(`api/orders/upload/${state.order.uploadId}/lines?page=0&size=5000&fulfillmentType=DIGITAL&sort=lineNumber,asc`)
    // Java layer doesn't provide delivery template identifier in place of etids yet
    map(uploadLines, (uploadLine) => (uploadLine.etid = uploadLine.deliveryTemplateIdentifier || uploadLine.etid))
    const deliveryTemplatesArray = map(uniqBy(uploadLines, 'etid'))
    const totalInvalid = deliveryTemplatesArray.filter((item) => {
      return !etidsForAccount.includes(item.etid)
    }).map(function (item) {
      return item.etid
    })

    state.order.invalidEtidArray = totalInvalid
    return totalInvalid.length > 0
  },
  [actionTypes.CREATE_SHOPPING_CART]: async ({ commit, dispatch, rootGetters }, createCartRequest = {}) => {
    if (!isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) return

    try {
      dispatch(actionTypes.REMOVE_OUT_OF_SYNC_CARTS)
      commit(mutationTypes.CLEAR_ORDER_VALUES)
      if (!createCartRequest?.accountIdentifier) {
        const selectedCustomerAccount = rootGetters[getTypes.GET_SELECTED_CUSTOMER_ACCOUNT]
        createCartRequest.accountIdentifier = selectedCustomerAccount.accountIdentifier
      }

      const { data: cart } = await ShoppingCartService.createShoppingCart(createCartRequest)
      commit(mutationTypes.SET_SHOPPING_CART, cart)
      return cart
    } catch (error) {
      console.error('Error creating shopping cart:', error)
    }

    return null
  },
  [actionTypes.REMOVE_OUT_OF_SYNC_CARTS]: async ({ commit }, cartId) => {
    if (!isSplitEnabled(RG_7991_SHOPPING_CART_INTEGRATION)) return

    try {
      await ShoppingCartService.removeOutOfSyncCarts(cartId)
      commit(mutationTypes.CLEAR_SHOPPING_CART)
    } catch (error) {
      console.error('Error removing out of sync carts:', error)
    }
  }
}

const mutations = {
  [mutationTypes.SET_SHOPPING_CART]: (state, shoppingCart) => {
    Object.assign(state.shoppingCart, shoppingCart)
  },
  [mutationTypes.SET_ORDER_TOTAL_SUMMARY]: (state, order) => {
    Object.assign(state.order, order)
  },
  [mutationTypes.CLEAR_ORDER_VALUES]: (state) => {
    state.order.uploadId = null
    state.order.summary = cloneDeep(newCartSummary)
    state.metadata = new OrderMetadata()
    state.shoppingCart = cloneDeep(newShoppingCart)
  },
  [mutationTypes.SET_ORDER_METADATA]: (state, metadata = {}) => {
    Object.assign(state.metadata, metadata)
  },
  [mutationTypes.SET_PREVIOUSLY_DISPLAYED_CART_COUNT]: (state, newCartCount) => {
    state.previouslyDisplayedCartCount = newCartCount
  },
  [mutationTypes.CLEAR_SHOPPING_CART]: () => {
    state.order.summary = cloneDeep(newCartSummary)
    state.order.cartId = null
    state.metadata = new OrderMetadata()
    state.shoppingCart = cloneDeep(newShoppingCart)
  },
  [mutationTypes.SHOPPING_CART_SUMMARY_ACTIVE]: (state, newShoppingCartSummaryActive) => {
    state.shoppingCartSummaryActive = newShoppingCartSummaryActive
  }
}

const handleShoppingCartErrors = async ({ commit, dispatch, error }) => {
  if (error?.response) {
    console.error('Error encountered with shopping cart:', error)
    if (error?.response?.status === 423) {
      // LOCKED happens when an upload is PLACED (already ordered) or in the process of ordering.
      dispatch(actionTypes.TOAST, { html: i18n.t('portalfrontendApp.error.alreadyPlacedOrder'), type: 'danger' }, { root: true })
      commit(mutationTypes.CLEAR_ORDER_VALUES)
      await dispatch(actionTypes.FETCH_DRAFT_ORDER)
    } else {
      const data = error.response.data
      let htmlForToast = i18n.t('portalfrontendApp.error.genericSavingError')
      if (data && data.i18nKey) {
        const key = `portalfrontendApp.rewardValidation.errors.${data.i18nKey}.detail`
        const message = i18n.t(key)
        if (message && (message !== key)) {
          // We'd rather show the default message than the literal i18nkey or an empty string
          htmlForToast = i18n.t(`portalfrontendApp.rewardValidation.errors.${data.i18nKey}.detail`)
        }
      }
      dispatch(actionTypes.TOAST, { html: htmlForToast, type: 'danger' }, { root: true })
    }
  } else {
    console.error('Error with no response status encountered with shopping cart:', error)
    dispatch(actionTypes.TOAST, { html: i18n.t('portalfrontendApp.error.genericSavingError'), type: 'danger' }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
