import 'es6-promise/auto'
import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import i18n from './i18n'
import App from './App'
import router from './router'
import DatePicker from 'vue2-datepicker'
import { HttpClientPlugin } from '@/components/shared/security/http-client'
import PageHeader from './components/shared/header/PageHeader'
import ServerTable from './components/shared/server-tables/ServerTable'
import ClientTable from './components/shared/client-tables/ClientTable'
import ValidationTip from './components/shared/forms/ValidationTip'
import PasswordStrengthBar from './components/shared/forms/PasswordStrengthBar'
import Pill from './components/shared/pill/Pill'
import { AuthorizedPlugin } from './components/shared/security/authorized-plugin'
import { CapitalizePlugin } from './components/shared/formatters/capitalize'
import { DatePlugin } from './components/shared/formatters/date'
import { BusPlugin } from './components/shared/bus/event-bus'
import { ValidatorPlugin } from './components/shared/forms/validator-plugin'
import { TruncatePlugin } from './components/shared/formatters/truncate'
import { FroalaPlugin } from './components/shared/froala-editor/froala-plugin'
import { ObjectSortPlugin } from './components/shared/formatters/object-sort'
import { LanguagePlugin } from './components/shared/formatters/language'
import { MoneyPlugin } from './components/shared/formatters/money'
import { ExpirationDatePlugin } from './components/shared/formatters/expiration-date'
import { ToastPlugin } from './components/shared/toast/toast'
import { ModalPlugin } from './components/shared/modal/modal'
import { SplitPlugin } from './components/shared/split/split'
import { SegmentPlugin } from './components/shared/segment/segment-plugin'
import SiftPlugin from './components/shared/sift/sift-plugin'
import Search from './components/shared/search/search'
import Select from './components/shared/select/Select'
import Maintenance from './components/shared/Maintenance'
import { DynamicWidthDirective, ValidationStyleDirective } from './components/shared/forms/dynamic-column-width'
import vueSelect from 'vue-select'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import './assets/scss/overrides.scss'
import { TcVBtn } from '@tangocard/mango'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VSanitize from 'v-sanitize'
import { SANITIZE_DEFAULTS } from './plugins/v-sanitize'
import AdaChatbotPlugin from './plugins/ada_chatbot/AdaChatbotPlugin'
import { shouldShowDevTools } from '@/components/shared/environment'
import OneTrustPlugin from '@/plugins/onetrust/OneTrustPlugin'
import VueCardFormat from 'vue-credit-card-validation'
import store from '@/store'
import { PRINCIPLE_GET_ASYNC } from '@/store/action-types'
import { SnackPlugin } from '@/components/shared/alert/snack'
import ChameleonPlugin from '@/plugins/chameleon/ChameleonPlugin'

(async () => {
  // We need to pull user authorities and other info so do that first in case the page is being refreshed.
  // If this is ever removed, we'll have to make sure to update anything that uses PRINCIPLE_GET_USER when initializing.
  await store.dispatch(PRINCIPLE_GET_ASYNC)

  if (shouldShowDevTools()) {
    Vue.config.devtools = true
  }
  cssVars()

  Vue.use(SegmentPlugin, router)
  Vue.use(HttpClientPlugin)
  Vue.use(BootstrapVue)
  Vue.use(AuthorizedPlugin)
  Vue.use(CapitalizePlugin)
  Vue.use(ObjectSortPlugin)
  Vue.use(DatePlugin)
  Vue.use(TruncatePlugin)
  Vue.use(BusPlugin)
  Vue.use(ValidatorPlugin)
  Vue.use(FroalaPlugin)
  Vue.use(LanguagePlugin)
  Vue.use(MoneyPlugin)
  Vue.use(ExpirationDatePlugin)
  Vue.use(ToastPlugin)
  Vue.use(SnackPlugin)
  Vue.use(ModalPlugin) // MUST appear after Vue.use(BusPlugin)
  Vue.use(SplitPlugin)
  Vue.use(DatePicker)
  Vue.use(Search)
  Vue.use(SiftPlugin, router)
  Vue.use(VueTelInputVuetify, { vuetify })
  Vue.use(VSanitize, SANITIZE_DEFAULTS)
  Vue.use(AdaChatbotPlugin)
  Vue.use(OneTrustPlugin)
  Vue.use(VueCardFormat)
  Vue.use(ChameleonPlugin)

  Vue.component('TcSelect', Select)
  Vue.component('VueSelect', vueSelect)
  Vue.component('ServerTable', ServerTable)
  Vue.component('ClientTable', ClientTable)
  Vue.component('PageHeader', PageHeader)
  Vue.component('Pill', Pill)
  Vue.component('ValidationTip', ValidationTip)
  Vue.component('PasswordStrengthBar', PasswordStrengthBar)
  Vue.component('Maintenance', Maintenance)
  Vue.component('TcVBtn', TcVBtn)

  Vue.directive('dynamic-width', DynamicWidthDirective)
  Vue.directive('input-style', ValidationStyleDirective)

  Vue.config.productionTip = false

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    components: { App },
    render: h => h(App),
    vuetify,
    i18n
  })
})()
