import * as getTypes from '../get-types'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const state = {
  activeReportAlertCount: 0
}

const getters = {
  [getTypes.GET_REPORT_ALERT_COUNT]: state => {
    return state.activeReportAlertCount
  }
}

const mutations = {
  [mutationTypes.SET_REPORT_ALERT_COUNT] (state, alertCount) {
    state.activeReportAlertCount = alertCount
  }
}

const actions = {
  [actionTypes.SET_REPORT_ALERT_COUNT] ({ commit }, alertCount) {
    commit(mutationTypes.SET_REPORT_ALERT_COUNT, alertCount)
  },
  [actionTypes.CLEAR_REPORT_ALERT_COUNT] ({ commit }) {
    commit(mutationTypes.SET_REPORT_ALERT_COUNT, 0)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
