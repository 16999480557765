import Vue from 'vue'
import { HttpClient } from '@/components/shared/security/http-client'
import * as getTypes from '../get-types'
import * as types from '../mutation-types'
import * as actionTypes from '../action-types'
import i18n from '@/i18n'
import { TANGO_CARD_STANDARD_EMAIL_TEMPLATE_ETID } from '@/components/shared/delivery-templates/EmailTemplate'
import { TANGO_CARD_STANDARD_EMAIL_TEMPLATE_PTID } from '@/components/shared/delivery-templates/PhysicalTemplate'
import { FulfillmentType } from '@/dto/order/FulfillmentType'
import { TemplatesConstants } from '@/components/platform/email-templates/print-templates/templates-constants'
import { ACTION_MANAGE, LEVEL_MANAGE, LEVEL_VIEW } from '@/components/shared/constants/permission.constants'
import { TANGO_CARD_DEFAULT_CS_MESSAGE_R3 } from '@/components/shared/constants'
import AuthenticationProvider
  from '@/components/shared/security/authentication-provider'

const defaultPrintTemplate = TemplatesConstants.DEFAULT_PRINT_TEMPLATE

const defaultPermissionState = {
  PLATFORM: undefined,
  CUSTOMER: {},
  ACCOUNT: {}
}

const state = {
  digitalTemplateDraft: null,
  physicalTemplateDraft: null,
  selectedDefaultEmailTemplate: null,
  selectedDefaultPhysicalTemplate: null,
  templatesCached: {},
  logoList: [{ defaultLogo: true }],
  emailTemplatesPermissionLevel: { ...defaultPermissionState }
}

const getters = {
  [getTypes.GET_TEMPLATE_DRAFT]: (state) => {
    return (fulfillmentType) => {
      if (fulfillmentType === FulfillmentType.PHYSICAL) {
        return state.physicalTemplateDraft
      } else {
        return state.digitalTemplateDraft
      }
    }
  },
  [getTypes.GET_TEMPLATE_BY_DELIVERY_TEMPLATE_IDENTIFIER]: (state) => {
    return (deliveryTemplateIdentifier) => {
      if (!deliveryTemplateIdentifier) {
        return undefined
      }
      return state.templatesCached[deliveryTemplateIdentifier]
    }
  },
  [getTypes.GET_SELECTED_DEFAULT_TEMPLATE]: state => {
    return (fulfillmentType) => {
      if (fulfillmentType === FulfillmentType.PHYSICAL) {
        return state.selectedDefaultPhysicalTemplate
      } else {
        return state.selectedDefaultEmailTemplate
      }
    }
  },
  [getTypes.GET_EMAIL_TEMPLATE_PERMISSION_LEVEL]: (state) => {
    return state.emailTemplatesPermissionLevel
  }
}

const mutations = {
  [types.SET_TEMPLATE_DRAFT] (state, { templateDraft, fulfillmentType }) {
    if (fulfillmentType === FulfillmentType.PHYSICAL) {
      state.physicalTemplateDraft = templateDraft
    } else {
      state.digitalTemplateDraft = templateDraft
    }
  },
  [types.CACHE_TEMPLATE] (state, { template, deliveryTemplateIdentifier }) {
    Vue.set(state.templatesCached, deliveryTemplateIdentifier, template)
  },
  [types.REMOVE_TEMPLATE_FROM_CACHE] (state, deliveryTemplateIdentifier) {
    Vue.delete(state.templatesCached, deliveryTemplateIdentifier)
  },
  [types.SET_SELECTED_DEFAULT_TEMPLATE] (state, { template, fulfillmentType }) {
    if (fulfillmentType === FulfillmentType.PHYSICAL) {
      state.selectedDefaultPhysicalTemplate = template
    } else {
      state.selectedDefaultEmailTemplate = template
    }
  },
  [types.SET_EMAIL_TEMPLATE_PERMISSION_LEVEL]: (state, payload) => {
    state.emailTemplatesPermissionLevel = payload
  }
}

export const convertAccountIdToAccountIdentifier = (accountId, platform) => {
  for (let i = 0; i < platform.customers.length; i++) {
    const customer = platform.customers[i]
    for (let j = 0; j < customer.accounts.length; j++) {
      const account = customer.accounts[j]
      if (account.accountID === Number(accountId)) {
        return account.identifier
      }
    }
  }
  return undefined
}

export const determinePermissions = (context) => {
  const perms = context.getters[getTypes.GET_EMAIL_TEMPLATE_PERMISSION_LEVEL]
  if (perms.PLATFORM !== undefined) {
    // only need to build it once?
    return perms
  }
  const emailTemplatePermission = 'EMAIL_TEMPLATES'
  const currentUser = context.rootGetters[getTypes.PRINCIPLE_GET_USER]
  const platform = context.rootGetters[getTypes.GET_PLATFORM]

  const userPermissions = { ...defaultPermissionState }

  const managePlatformPermission = currentUser.platformPermissions.find(permission => permission.portalArea === emailTemplatePermission && permission.action === ACTION_MANAGE)

  if (managePlatformPermission || AuthenticationProvider.isEmulationSession()) {
    userPermissions.PLATFORM = LEVEL_MANAGE
    return userPermissions // No need to lookup the rest if you have platform permission
  } else {
    userPermissions.PLATFORM = LEVEL_VIEW
  }

  platform.customers.forEach(customer => {
    const permissionsForCustomer = currentUser.groupPermissionMap[customer.customerID]
    const manageCustomerPermission = permissionsForCustomer && permissionsForCustomer.find(permission => permission.portalArea === emailTemplatePermission && permission.action === ACTION_MANAGE)
    const customerLevel = manageCustomerPermission ? LEVEL_MANAGE : LEVEL_VIEW

    userPermissions.CUSTOMER[customer.identifier] = customerLevel
    customer.accounts.forEach(account => {
      // If you can manage the Customer, you can manage its child accounts
      userPermissions.ACCOUNT[account.identifier] = customerLevel
    })
  })

  Object.keys(currentUser.accountPermissionMap).forEach(accountId => {
    const permissionForAccount = currentUser.accountPermissionMap[accountId]
    const accountIdentifier = convertAccountIdToAccountIdentifier(accountId, platform)

    const manageAccountPermission = permissionForAccount && permissionForAccount.find(permission => permission.portalArea === emailTemplatePermission && permission.action === ACTION_MANAGE)

    userPermissions.ACCOUNT[accountIdentifier] = manageAccountPermission ? LEVEL_MANAGE : LEVEL_VIEW
  })

  return userPermissions
}

const actions = {
  async [actionTypes.RETRIEVE_AND_SET_TEMPLATE_IF_EMPTY] (context, deliveryTemplateIdentifier) {
    if (!deliveryTemplateIdentifier) {
      console.log('Failed to retrieve and set template because deliveryTemplateIdentifier is:', deliveryTemplateIdentifier)
      return undefined
    }
    let template = context.getters[getTypes.GET_TEMPLATE_BY_DELIVERY_TEMPLATE_IDENTIFIER](deliveryTemplateIdentifier)
    if (!template) {
      const deliveryTemplateEndpoint = deliveryTemplateIdentifier[0] === 'P' ? '/api/delivery-template/physical' : '/api/email-templates/template'
      try {
        console.log(`Fetching delivery template with id ${deliveryTemplateIdentifier} with ${deliveryTemplateEndpoint}`)
        const { data } = await HttpClient.get(`${deliveryTemplateEndpoint}/${deliveryTemplateIdentifier}`)
        template = data
        context.commit(types.CACHE_TEMPLATE, { template, deliveryTemplateIdentifier })
      } catch (error) {
        console.error(`Error fetching delivery template ${deliveryTemplateIdentifier} with ${deliveryTemplateEndpoint}`, error)
        context.dispatch(actionTypes.TOAST, {
          html: i18n.t('portalfrontendApp.emailTemplate.preview.failedToRetrieveTemplate'),
          type: 'danger'
        }, { root: true })
      }
    }
    return template
  },
  [actionTypes.CLEAR_TEMPLATE_CACHE_FOR_DELIVERY_TEMPLATE_IDENTIFIER] (context, deliveryTemplateIdentifier) {
    context.commit(types.REMOVE_TEMPLATE_FROM_CACHE, deliveryTemplateIdentifier)
  },
  [actionTypes.CLEAR_DEFAULT_DELIVERY_TEMPLATES] (context) {
    context.commit(types.SET_SELECTED_DEFAULT_TEMPLATE, { fulfillmentType: FulfillmentType.DIGITAL })
    context.commit(types.SET_SELECTED_DEFAULT_TEMPLATE, { fulfillmentType: FulfillmentType.PHYSICAL })
  },
  [actionTypes.SELECT_DEFAULT_TEMPLATE] (context, { template, fulfillmentType }) {
    context.commit(types.SET_SELECTED_DEFAULT_TEMPLATE, { template, fulfillmentType })
  },
  [actionTypes.SET_TANGOCARD_STANDARD_TEMPLATE_AS_SELECTED] ({ getters, dispatch }, fulfillmentType) {
    const standardDeliveryTemplateIdentifier = fulfillmentType === FulfillmentType.PHYSICAL ? TANGO_CARD_STANDARD_EMAIL_TEMPLATE_PTID : TANGO_CARD_STANDARD_EMAIL_TEMPLATE_ETID
    let tangoCardStandardTemplate = getters[getTypes.GET_TEMPLATE_BY_DELIVERY_TEMPLATE_IDENTIFIER](standardDeliveryTemplateIdentifier)

    if (tangoCardStandardTemplate === undefined) {
      dispatch(actionTypes.RETRIEVE_AND_SET_TEMPLATE_IF_EMPTY, standardDeliveryTemplateIdentifier)
      tangoCardStandardTemplate = getters[getTypes.GET_TEMPLATE_BY_DELIVERY_TEMPLATE_IDENTIFIER](standardDeliveryTemplateIdentifier)
    }
    dispatch(actionTypes.SELECT_DEFAULT_TEMPLATE, { template: tangoCardStandardTemplate, fulfillmentType })
  },
  [actionTypes.SET_USER_DEFAULT_DELIVERY_TEMPLATE_AS_SELECTED] ({ dispatch }, { deliveryTemplates, selectedCustomerAccount, fulfillmentType }) {
    let accountDefault, groupDefault, platformDefault
    if (selectedCustomerAccount && fulfillmentType === FulfillmentType.DIGITAL) {
      const { platformClientId, customerIdentifier, accountIdentifier } = selectedCustomerAccount
      if (accountIdentifier !== undefined) {
        accountDefault = deliveryTemplates.find(({ defaults = [] }) => defaults.find(d => d.accountIdentifier === accountIdentifier))
      }
      if (customerIdentifier !== undefined) {
        groupDefault = deliveryTemplates.find(({ defaults = [] }) => defaults.find(d => d.customerIdentifier === customerIdentifier && !d.accountIdentifier))
      }
      if (platformClientId !== undefined) {
        platformDefault = deliveryTemplates.find(({ defaults = [] }) => defaults.find(d => d.platformClientId === platformClientId && !d.customerIdentifier))
      }

      const templateToUse = accountDefault || groupDefault || platformDefault
      if (templateToUse !== undefined) {
        dispatch(actionTypes.SELECT_DEFAULT_TEMPLATE, { template: templateToUse, fulfillmentType })
      } else {
        dispatch(actionTypes.SET_TANGOCARD_STANDARD_TEMPLATE_AS_SELECTED, fulfillmentType)
      }
    } else {
      dispatch(actionTypes.SET_TANGOCARD_STANDARD_TEMPLATE_AS_SELECTED, fulfillmentType)
    }
  },
  [actionTypes.NEW_PRINT_TEMPLATE_DRAFT] (context) {
    context.commit(types.SET_TEMPLATE_DRAFT, { templateDraft: defaultPrintTemplate(), fulfillmentType: FulfillmentType.PHYSICAL })
  },
  [actionTypes.NEW_PRINT_TEMPLATE_DRAFT_WITH_NAME] (context, name) {
    const template = defaultPrintTemplate()
    template.name = name
    context.commit(types.SET_TEMPLATE_DRAFT, { templateDraft: template, fulfillmentType: FulfillmentType.PHYSICAL })
  },
  [actionTypes.SET_TEMPLATE_DRAFT] (context, { templateDraft, fulfillmentType }) {
    context.commit(types.SET_TEMPLATE_DRAFT, { templateDraft, fulfillmentType })
  },
  [actionTypes.BUILD_EMAIL_TEMPLATE_PERMISSION_LEVEL] (context) {
    const userPermissions = determinePermissions(context)

    // commit it
    context.commit(types.SET_EMAIL_TEMPLATE_PERMISSION_LEVEL, userPermissions)
    return userPermissions
  },
  async [actionTypes.RETRIEVE_DEFAULT_CUSTOMER_SUPPORT_MESSAGE] (context) {
    const { customerSupportMessage } = await context.dispatch(actionTypes.RETRIEVE_AND_SET_TEMPLATE_IF_EMPTY, TANGO_CARD_STANDARD_EMAIL_TEMPLATE_ETID)
    return customerSupportMessage || TANGO_CARD_DEFAULT_CS_MESSAGE_R3
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
