/**
 * Return true when the email provided uses a domains that is not on the list of blacklisted domains
 * @param email
 * @param blacklistedDomains
 * @returns {boolean}
 */
export function isValidEmailDomain (email, blacklistedDomains) {
  if (blacklistedDomains === null) {
    return true
  } else {
    const lastIndex = email.lastIndexOf('.')
    const domain = email.substr(lastIndex + 1).toLowerCase()
    return blacklistedDomains.every(blacklistedDomain => !equalsIgnoreCase(blacklistedDomain, domain))
  }
}

/**
 * Returns true when two strings are equal, case-insensitive
 * @param a
 * @param b
 * @returns {boolean}
 */
function equalsIgnoreCase (a, b) {
  return (a || '').toLowerCase() === (b || '').toLowerCase()
}
