<template>
  <div>
    <!-- Put country first as a hint to users that we only support these 4 countries atm -->
    <v-row>
      <v-col class="text-left pb-0 pt-0">
        <label
          class="mb-1"
          for="country-region"
        >
          {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.countryRegion') }}
        </label>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <v-select
          id="country-region"
          v-model="billingAddress.country"
          class="mb-3"
          dense
          hide-details="auto"
          :items="Object.values(countries)"
          outlined
          @change="updateCountryValue"
        />
      </v-col>
    </v-row>

    <!-- Show address autocomplete with single search field and link to enter address manually -->
    <div v-if="!enteringAddressManually">
      <v-row>
        <v-col class="text-left pb-0 pt-0">
          <label
            class="mb-1"
            for="billing-address"
          >
            {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.billingAddress') }}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0">
          <validation-provider
            ref="billingAddressProvider"
            v-slot="{ errors, flags: { touched } }"
            rules="requiredAddress"
            immediate
            :name="$t('portalfrontendApp.coreCreditCard.dialog.warningName.billingAddress')"
          >
            <address-autocomplete
              id="billing-address"
              class="mb-3"
              :countries="supportedCountryCodes"
              dense
              :error-messages="touched ? errors : []"
              hide-details="auto"
              outlined
              :placeholder="$t('portalfrontendApp.coreCreditCard.dialog.billingInfo.search')"
              @change="addressAutocompleted"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <a
        id="enter-address-manually"
        class="dialog-link ml-1"
        @click="enteringAddressManually = true"
      >
        {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.enterAddressManually') }}
      </a>
    </div>

    <!-- Else show ALL address fields -->
    <div v-else>
      <v-row>
        <v-col class="text-left pb-0 pt-0">
          <label
            class="mb-1"
            for="street-address"
          >
            {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.addressLine1') }}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0">
          <validation-provider
            v-slot="{ errors, flags: { touched } }"
            rules="required"
            immediate
            :name="$t('portalfrontendApp.coreCreditCard.dialog.warningName.streetAddress')"
          >
            <v-text-field
              id="street-address"
              v-model="billingAddress.addressLine1"
              class="mb-3"
              dense
              :error-messages="touched ? errors : []"
              hide-details="auto"
              outlined
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-left pb-0 pt-0">
          <label
            class="mb-1"
            for="other-address"
          >
            {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.addressLine2') }}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-text-field
            id="other-address"
            v-model="billingAddress.addressLine2"
            class="mb-3"
            dense
            hide-details="auto"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-left pb-0 pt-0">
          <label
            class="mb-1"
            for="city"
          >
            {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.city') }}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0">
          <validation-provider
            v-slot="{ errors, flags: { touched } }"
            rules="required"
            immediate
            :name="$t('portalfrontendApp.coreCreditCard.dialog.warningName.city')"
          >
            <v-text-field
              id="city"
              v-model="billingAddress.city"
              class="mb-3"
              :error-messages="touched ? errors : []"
              dense
              outlined
              hide-details="auto"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-left pb-0 pt-0">
          <label
            class="mb-1"
            for="state-province"
          >
            {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.stateProvince') }}
          </label>
        </v-col>
        <v-col class="text-left pb-0 pt-0">
          <label
            class="mb-1"
            for="zip-postal-code"
          >
            {{ $t('portalfrontendApp.coreCreditCard.dialog.billingInfo.postalCode') }}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0 w-50">
          <validation-provider
            ref="stateOrProvinceProvider"
            v-slot="{ errors, flags: { touched } }"
            rules="required"
            immediate
            :name="$t('portalfrontendApp.coreCreditCard.dialog.warningName.state')"
          >
            <v-select
              v-if="billingAddress.country === 'United States'"
              id="state-province"
              v-model="billingAddress.stateProvince"
              class="mb-3"
              dense
              :error-messages="touched ? errors : []"
              hide-details="auto"
              :items="stateProvinces"
              outlined
              :placeholder="$t('portalfrontendApp.coreCreditCard.dialog.select')"
            />
            <v-select
              v-else-if="billingAddress.country === 'Canada'"
              id="ca-state-province"
              v-model="billingAddress.stateProvince"
              class="mb-3"
              dense
              :error-messages="touched ? errors : []"
              hide-details="auto"
              :items="canadianProvinces"
              outlined
              :placeholder="$t('portalfrontendApp.coreCreditCard.dialog.select')"
            />
            <v-text-field
              v-else
              id="other-state-province"
              v-model="billingAddress.stateProvince"
              class="mb-3"
              dense
              :error-messages="touched ? errors : []"
              hide-details="auto"
              outlined
            />
          </validation-provider>
        </v-col>
        <v-col class="pt-0 pb-0 w-50">
          <validation-provider
            v-slot="{ errors, flags: { touched } }"
            rules="required"
            immediate
            :name="$t('portalfrontendApp.coreCreditCard.dialog.warningName.zip')"
          >
            <v-text-field
              id="zip-postal-code"
              v-model="billingAddress.postalCode"
              class="mb-3"
              dense
              :error-messages="touched ? errors : []"
              hide-details="auto"
              outlined
            />
          </validation-provider>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GeographyService from '@/components/shared/GeographyService'
import AddressAutocomplete from '@/components/shared/address-autocomplete/AddressAutocomplete'
import { ValidationProvider } from 'vee-validate'
import { isEmpty } from 'lodash'

export default {
  name: 'CreditCardBillingAddress',
  components: {
    AddressAutocomplete,
    ValidationProvider
  },
  props: {
    ccBillingAddress: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      billingAddress: {
        country: '',
        stateProvince: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: ''
      },
      isEditing: false,
      enteringAddressManually: false,
      countries: [],
      stateProvinces: [],
      canadianProvinces: []
    }
  },
  computed: {
    supportedCountryCodes () {
      return Object.keys(this.countries)
    }
  },
  watch: {
    billingAddress: {
      handler (newBillingAddress) {
        this.$emit('billing-address', newBillingAddress)
      },
      deep: true
    }
  },

  beforeMount () {
    this.stateProvinces = GeographyService.getStateList()
    this.countries = GeographyService.getCountryMap()
    this.canadianProvinces = GeographyService.getCanadianProvinceList()

    if (!isEmpty(this.ccBillingAddress)) {
      this.billingAddress = {
        country: this.ccBillingAddress?.country,
        stateProvince: this.ccBillingAddress?.stateProvince,
        addressLine1: this.ccBillingAddress?.addressLine1,
        addressLine2: this.ccBillingAddress?.addressLine2,
        city: this.ccBillingAddress?.city,
        postalCode: this.ccBillingAddress?.postalCode
      }
      this.enteringAddressManually = true
    }
  },

  async mounted () {
    const defaultCountry = this.countries.US
    if (isEmpty(this.ccBillingAddress)) {
      this.billingAddress.country = defaultCountry
      this.updateCountryValue(defaultCountry)
      // VeeValidate usually attaches to the input's v-model but for billing address we can't do that so manually trigger
      // validation. When we remove the AddressAutocomplete component out of mango we can switch the prop type from string
      // to object then this shouldn't be necessary.

      await this.$refs.billingAddressProvider.validate(this.billingAddress)
    }
  },

  methods: {
    async addressAutocompleted (autocompletedAddress) {
      this.billingAddress = {
        country: GeographyService.getCountry(autocompletedAddress.country),
        stateProvince: GeographyService.getState(autocompletedAddress.stateOrProvince, autocompletedAddress.country),
        addressLine1: autocompletedAddress.streetLine1,
        addressLine2: autocompletedAddress.streetLine2 ?? '',
        city: autocompletedAddress.city,
        postalCode: autocompletedAddress.postalCode
      }
      if (!this.enteringAddressManually) {
        await this.$refs.billingAddressProvider.validate(this.billingAddress)
      }
    },
    updateCountryValue (country) {
      this.billingAddress.country = country
      this.billingAddress.stateProvince = null
      // manually reset touched state of validation provider to avoid showing an error message when we set to null
      if (this.$refs.stateOrProvinceProvider) {
        this.$refs.stateOrProvinceProvider.reset()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/_mixins.scss";

  ::v-deep .v-select__selections {
    font-family: $gotham-font-family;
  }

  // "use-vuetify" on the address-autocomplete doesn't work correctly through mango so we need to manually restyle the
  // address-autocomplete to look like a vuetify component
  .billing-address {
    border: 1px solid var(--t-color-border) !important;
    border-radius: 4px !important;
    height: 40px;

    &:hover {
      border: 1px solid var(--t-color-text) !important;
    }
    &:focus {
      border: 2px solid var(--t-color-primary) !important;
    }
  }

  .dialog-link {
    font-size: .875rem;
    font-weight: 500;
    color: var(--t-color-text-link);

    &:hover {
      text-decoration: underline !important;
    }
  }
</style>
