import { getAccount } from '@/components/platform/funding/account-utilities'
import {
  GET_LAST_SELECTED_CUSTOMER_ACCOUNT,
  PRINCIPLE_GET_USER
} from '@/store/get-types'

import {
  FETCH_AND_SET_THE_SELECTED_ACCOUNT,
  GET_PLATFORM_ASYNC,
  SNACK,
  UPDATE_TOKEN_FOR_CUSTOMER_ACCOUNT
} from '@/store/action-types'
import store from '@/store'
import i18n from '../i18n'
import { ERROR } from '@/components/shared/alert/snack-constants'

export async function AuthAccountCheck () {
  // Added to allow auth0 to bypass MFA step.
  // Users with limited access and permissions weren't getting their account set.
  const account = await store.getters[PRINCIPLE_GET_USER]
  const lastSelectedCustomerAccount = await store.getters[GET_LAST_SELECTED_CUSTOMER_ACCOUNT]
  const platformClientId = account.platformClientId
  const platform = await store.dispatch(GET_PLATFORM_ASYNC, { platformClientId })

  // Go through customers on a platform until we find an active account
  let defaultAccount
  const defaultCustomer = platform?.customers.find(customer => {
    const firstActiveAccount = customer.accounts.find(account => account.active)
    defaultAccount = firstActiveAccount
    return firstActiveAccount
  })

  if (!defaultAccount) {
    await store.dispatch(SNACK, {
      message: i18n.t('portalfrontendApp.error.noActiveAccounts'),
      variant: ERROR
    })
  }

  if (defaultCustomer && defaultAccount) {
    let customerIdentifier = defaultCustomer.identifier
    let accountIdentifier = defaultAccount.identifier
    // Added to check if account exists on the current platform to fix issue when switching platforms
    const lastSelectedExistsInPlatform = AuthCheckSelectedAccountExists(lastSelectedCustomerAccount, platform)
    if (lastSelectedExistsInPlatform) {
      customerIdentifier = lastSelectedCustomerAccount.customerIdentifier
      accountIdentifier = lastSelectedCustomerAccount.identifier
    }
    await AuthUpdateSelectedAccount(customerIdentifier, accountIdentifier, platformClientId)
  }
}

async function AuthUpdateSelectedAccount (customerIdentifier, accountIdentifier, platformClientId) {
  await store.dispatch(FETCH_AND_SET_THE_SELECTED_ACCOUNT, {
    customerIdentifier,
    accountIdentifier
  })
  const accountToSwitch = await getAccount(accountIdentifier)
  await store.dispatch(UPDATE_TOKEN_FOR_CUSTOMER_ACCOUNT, accountToSwitch)
  await store.dispatch(GET_PLATFORM_ASYNC, { platformClientId, forceRefresh: true })
}

function AuthCheckSelectedAccountExists (lastSelectedCustomerAccount, platform) {
  if (!lastSelectedCustomerAccount) {
    return false
  }

  return platform.customers.find((customer) => {
    return customer.accounts.length && customer.accounts.find(
      (account) => account.identifier === lastSelectedCustomerAccount.identifier && account.active)
  })
}
