const PRODUCTION_ENVIRONMENT = 'Production'

export const environments = Object.freeze({
  DEV: 'Dev',
  QA: 'QA',
  GAMMA: 'Gamma',
  SANDBOX: 'Sandbox',
  PROD: 'Production'
})

export const getFormattedEnvironment = () => process.env.VUE_APP_FORMATTED_ENVIRONMENT_NAME || ''
export const isProduction = () => getFormattedEnvironment().toLowerCase() === PRODUCTION_ENVIRONMENT.toLowerCase()
export const isNotProduction = () => !isProduction()
export const shouldShowDevTools = () => [environments.DEV, environments.GAMMA, environments.QA].includes(getFormattedEnvironment())
