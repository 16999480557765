export const TANGO_CARD_STANDARD_EMAIL_TEMPLATE_ETID = 'E000000'
export const TANGO_CARD_STANDARD_DEFAULT_TEMPLATE_MESSAGE = '<p>{{recipient_name}},</p><p>Your {{reward_name}} is Here!</p>'
export const MUSTACHE_TAG_MESSAGE = '{{message}}'

export class EmailTemplate {
  constructor ({
    id,
    name = '',
    platformClientId,
    platformName = '',
    customerIdentifier = '',
    accountIdentifier = '',
    brandKey = '',
    campaign = '',
    locale = '',
    fromName = '',
    fromEmailAddress = '',
    replyToEmailAddress = '',
    bccEmailAddressStrings = [],
    subjectTemplate = '',
    logoUrl = '',
    logoAltText = '',
    message = '',
    messageBlock = '',
    closingMessage = '',
    customerSupportMessage = '',
    primaryColor = '',
    status = '',
    emailTemplateIdentifier = '',
    defaults = [],
    templateDefaults = [],
    templateAccess = [],
    showPoweredByTangoCard
  } = {}) {
    this.id = id
    this.name = name
    this.platformClientId = platformClientId
    this.platformName = platformName
    this.customerIdentifier = customerIdentifier
    this.accountIdentifier = accountIdentifier
    this.brandKey = brandKey
    this.campaign = campaign
    this.locale = locale
    this.fromName = fromName
    this.fromEmailAddress = fromEmailAddress
    this.replyToEmailAddress = replyToEmailAddress
    this.bccEmailAddressStrings = bccEmailAddressStrings
    this.subjectTemplate = subjectTemplate
    this.logoUrl = logoUrl
    this.logoAltText = logoAltText
    this.message = message
    this.messageBlock = messageBlock
    this.closingMessage = closingMessage
    this.customerSupportMessage = customerSupportMessage
    this.primaryColor = primaryColor
    this.status = status
    this.emailTemplateIdentifier = emailTemplateIdentifier
    this.deliveryTemplateIdentifier = emailTemplateIdentifier
    this.defaults = defaults
    this.templateDefaults = templateDefaults
    this.templateAccess = templateAccess
    this.showPoweredByTangoCard = showPoweredByTangoCard
  }

  get senderName () {
    return this.fromName
  }

  get subject () {
    return this.subjectTemplate
  }

  set senderName (val) {
    this.fromName = val
  }

  set subject (val) {
    this.subjectTemplate = val
  }

  get isTheTangoCardStandardEmailTemplate () {
    return this.emailTemplateIdentifier === TANGO_CARD_STANDARD_EMAIL_TEMPLATE_ETID
  }
}
