import { TemplateMarkupCustomization } from '@/dto/order/TemplateMarkupCustomization'

export class PhysicalTemplateMarkupCustomization extends TemplateMarkupCustomization {
  _fromName
  _toName
  _letterHeadTitle

  constructor (args) {
    const { fromName = '', toName = '', letterHeadTitle = '', ...superProps } = args || {}
    super(superProps)
    this._fromName = fromName
    this._toName = toName
    this._letterHeadTitle = letterHeadTitle
  }

  get fromName () {
    return this._fromName
  }

  set fromName (fromName) {
    this._fromName = fromName
  }

  get toName () {
    return this._toName
  }

  set toName (toName) {
    this._toName = toName
  }

  get letterHeadTitle () {
    return this._letterHeadTitle
  }

  set letterHeadTitle (letterHeadTitle) {
    this._letterHeadTitle = letterHeadTitle
  }

  equals (otherPhysicalTemplateOrderCustomization = {}) {
    return this.fromName === otherPhysicalTemplateOrderCustomization.fromName &&
      this.letterHeadTitle === otherPhysicalTemplateOrderCustomization.letterHeadTitle &&
      this.message === otherPhysicalTemplateOrderCustomization.message &&
      this.toName === otherPhysicalTemplateOrderCustomization.toName
  }

  toJSON () {
    const { fromName, letterHeadTitle, message, toName } = this
    return { fromName, letterHeadTitle, message, toName }
  }
}
