export const BILL_TO_ADDRESS_POSTAL_CODE_FIELD = 'bill_to_address_postal_code'
export const EFT_FORM = 'TANGO_CARD_ELECTRONIC_FUNDS_TRANSFER_AUTHORIZATION'
export const PAYMENT_AUTH_FORM = 'CYBER_SOURCE_PAYMENT_AUTHORIZATION_RECURRING_PAYMENTS'
export const ECHECK_SEC_CODE = 'CCD'
export const MAX_VALUE_RULE = 'max_value'
export const POSTAL_CODE_LENGTH_LONG_WITHOUT_HYPHENS = 9
export const ACCOUNT_NUMBER_LENGTH = 17
export const ACCOUNT_NUMBER_MIN_LENGTH = 8
export const ROUTING_NUMBER_LENGTH = 9
export const TEXT_FIELD_MAX_LENGTH = 64
export const NAME_MAX_LENGTH = 60
export const PERMISSION_AREA = 'FUNDING'
export const PERMISSION_ACTION = 'MANAGE'
export const QUERY_COUNT = 200
export const QUERY_PREDICATE = 'createdDate'
export const QUERY_DIRECTION = 'ASC'
export const ACH_DIALOG_MAX_WIDTH_WIDE = '680'
export const ACH_DIALOG_MAX_WIDTH_NARROW = '480'
export const ACCOUNT_NUMBERS_TO_SHOW = 4
export const CREATE_PAYMENT_TOKEN = 'CREATE_PAYMENT_TOKEN'
export const UPDATE_PAYMENT_TOKEN = 'UPDATE_PAYMENT_TOKEN'
export const SALE_TOKEN = 'SALE'
export const FUNDING_LIMIT_PRESETS = [5000, 10000, 25000, 50000, 75000, 100000]
export const DEFAULT_FUNDING_LIMIT = 10000
export const CONFIDENCE_ATTRIBUTE_TRUSTED = 'TRUSTED'
export const ACH_DIALOG_ARTICLE_URL = 'https://help.rewardsgenius.com/s/article/Consumer-Reporting-Agency-Notice'

// For creating and editing ACH accounts, we need to pass in a list of all the signed fields and unsigned fields. Since
// this is a big long nasty string that rarely changes, it's being put in this ACH constants file.

// Fields marked as signed in both create and edit ACH requests
const sharedSignedFieldNames = 'bill_to_address_line2,bill_to_email,currency,profile_id,access_key,transaction_type,amount,' +
  'locale,reference_number,transaction_uuid,signed_date_time,signed_field_names,unsigned_field_names,' +
  'bill_to_forename,bill_to_surname,bill_to_address_line1,bill_to_address_state,bill_to_address_postal_code,' +
  'bill_to_address_country,bill_to_address_city,bill_to_phone,payment_method,echeck_account_type,echeck_sec_code,' +
  'merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4,merchant_defined_data5,merchant_defined_data6,' +
  'merchant_defined_data7,merchant_defined_data8,merchant_defined_data9,merchant_defined_data10,merchant_defined_data11'

export const CREATE_ACH_SIGNED_FIELDS = sharedSignedFieldNames
export const CREATE_ACH_UNSIGNED_FIELDS = 'echeck_account_number,echeck_routing_number'
export const EDIT_ACH_SIGNED_FIELDS = `${sharedSignedFieldNames},allow_payment_token_update,payment_token,identifier`
export const EDIT_ACH_UNSIGNED_FIELDS = 'label'
export const USD_CURRENCY_CODE = 'USD'
export const IS_PERSONAL_BANK_ACCOUNT = 'isPersonalBankAccount'
