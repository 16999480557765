import Vue from 'vue'
import Router from 'vue-router'
import AuthenticatedGuard from './authenticated-guard'
import SplitGuard from './split-guard'
import MfaGuard from './mfa-guard'
import PageTitleGuard from './page-title-guard'
import AuthRoutes from './auth.routes'
import PlatformRoutes from './platform.routes'
import AdminRoutes from './admin.routes'
import IntegrationRoutes from './integrations.routes'
import OnboardingGuard from './onboarding-guard'
import TopbarGuard from './topbar-guard'
import { hasAuthority } from '@/components/shared/security/authorized-plugin'
import SettingsRoutes from './settings.routes'
import PlatformOrderRoutes from './platform.order.routes'
import LazyLoadGuard from './lazy-load-guard'
import { trackPage } from '@/router/middleware/track-page'
import { FIRST_ROUTE_NAME } from '@/components/shared/constants'
import ChatbotRouterGuard from '@/router/chatbot-router-guard'

// Override Router push method to globally remove "NavigationDuplicated" errors that flood the browser console. This is
// "official" code from the author of vue-router for how to ignore that error. The alternative is to add error handling
// to every $router.push(...) call in the code (no bueno).
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated) || Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(Router)
const router = new Router({
  routes: [
    ...AuthRoutes,
    ...PlatformRoutes,
    ...AdminRoutes,
    ...IntegrationRoutes,
    ...SettingsRoutes,
    ...PlatformOrderRoutes,
    {
      path: '*',
      redirect: () => {
        if (hasAuthority('IS_LOGGED_IN')) {
          return { name: FIRST_ROUTE_NAME }
        } else {
          return { name: 'Login' }
        }
      }
    }
  ]
})

AuthenticatedGuard.use(router)
SplitGuard.use(router)
PageTitleGuard.use(router)
OnboardingGuard.use(router)
MfaGuard.use(router)
TopbarGuard.use(router)
LazyLoadGuard.use(router)

router.beforeEach(ChatbotRouterGuard)
router.afterEach(trackPage)

export default router
