// Product Events
export const PRODUCTS_SEARCHED = 'Products Searched'
export const PRODUCT_CLICKED = 'Product Clicked'
export const PRODUCT_VIEWED = 'Product Viewed'
export const PRODUCT_LIST_FILTERED = 'Product List Filtered'
export const PRODUCT_LIST_VIEWED = 'Product List Viewed'
export const PRODUCT_FIELD_UPDATED = 'Product Field Updated'
export const PRODUCT_ADDED = 'Product Added'
export const PRODUCT_EDITED = 'Product Edited'
export const PRODUCT_REMOVED = 'Product Removed'
// Upload Events
export const UPLOAD_BULK_ORDER_CLICKED = 'Upload Bulk Order Clicked'
export const UPLOAD_BULK_ORDER_FILE_UPLOADED = 'Upload Bulk Order File Uploaded'
// Cart Events
export const ADD_TO_CART_CLICKED = 'Add To Cart Clicked'
export const CART_CLICKED = 'Cart Clicked'
export const CART_VIEWED = 'Cart Viewed'
export const CART_VIEW_BY_SELECTED = 'Cart View By Selected'
export const CART_DELETE_ALL_PRODUCTS_ACTION = 'Cart Delete All Products Action'
export const CART_EDIT_PRODUCT_CLICKED = 'Cart Edit Product Clicked'
// Address Events
export const ADDRESS_AUTOCOMPLETE_SELECTED = 'Address Autocomplete Selected'
// Checkout/Complete Order Events
export const CHECKOUT_STARTED = 'Checkout Started'
export const PAYMENT_ERROR_DISPLAYED = 'Payment Error Displayed'
export const ORDER_INFORMATION_FIELD_UPDATED = 'Order Information Field Updated'
export const ACCOUNT_SWITCHED = 'Account Switched'
export const ORDER_COMPLETED = 'Order Completed'
export const LINE_ITEM_CANCELED = 'Line Item Canceled'
export const ORDER_PAID_WITH_ACCOUNT_BALANCE = 'Reward Order Paid For With Account Balance'
export const ORDER_PAID_WITH_BANK_ACCOUNT = 'Reward Order Paid For With Bank Account'
export const ORDER_PAID_WITH_CREDIT_CARD = 'Reward Order Paid For With Credit Card'
export const ORDER_VIEWED = 'Order Viewed'
// Email/Print Template Events
export const EMAIL_TEMPLATE_EDIT_CLICKED = 'Email Template Editing Clicked'
export const PRINT_TEMPLATE_EDIT_CLICKED = 'Print Template Editing Clicked'
export const EMAIL_ADVANCED_OPTIONS_CLICKED = 'Email Templates Advanced Options Clicked'
export const PRINT_ADVANCED_OPTIONS_CLICKED = 'Print Templates Advanced Options Clicked'
export const EMAIL_TEMPLATE_MODIFIED = 'Email Template Modified'
export const PRINT_TEMPLATE_MODIFIED = 'Print Template Modified'
export const EMAIL_TEMPLATE_SWITCHED = 'Email Template Switched'
export const PRINT_TEMPLATE_SWITCHED = 'Print Template Switched'
// Recipients Page
export const RECIPIENTS_PAGE_VIEWED = 'Recipients Page Viewed'

// Tango Product Cards
export const LINK_LEARN_ABOUT_API_CLICKED = 'Link Learn About API Clicked'
export const LINK_LEARN_ABOUT_INTERNATIONAL_CLICKED = 'Link Learn About International Clicked'
export const LINK_LEARN_ABOUT_CUSTOM_REWARDS_CLICKED = 'Link Learn About Custom Rewards Clicked'
export const LINK_LEARN_ABOUT_DIRECT_BRANDS_CLICKED = 'Link Learn About Direct Brands Clicked'
export const LINK_LEARN_ABOUT_OPEN_LOOP_CLICKED = 'Link Learn About Open Loop Clicked'
export const LINK_LEARN_ABOUT_INTEGRATIONS_CLICKED = 'Link Learn Integrations Clicked'
