// PRINCIPLE

export const PRINCIPLE_GET_USER = 'PRINCIPLE_GET_USER'
export const PRINCIPLE_GET_AUTHENTICATED = 'PRINCIPLE_GET_AUTHENTICATED'

// TOAST

export const TOAST_GET_HTML = 'TOAST_GET_HTML'
export const TOAST_GET_TYPE = 'TOAST_GET_TYPE'
export const TOAST_GET_DURATION = 'TOAST_GET_DURATION'
export const TOAST_GET_VARIANT = 'TOAST_GET_VARIANT'

// SNACK
export const SNACK_GET_MESSAGE = 'SNACK_GET_MESSAGE'
export const SNACK_GET_LOADING = 'SNACK_GET_LOADING'
export const SNACK_GET_TIMEOUT = 'SNACK_GET_TIMEOUT'
export const SNACK_GET_VARIANT = 'SNACK_GET_VARIANT'
export const SNACK_GET_LINK_URL = 'SNACK_GET_LINK_URL'
export const SNACK_GET_LINK_TEXT = 'SNACK_GET_LINK_TEXT'
export const SNACK_GET_CALLBACK = 'SNACK_GET_CALLBACK'

// APP

export const APP_GET_TITLE = 'APP_GET_TITLE'
export const APP_GET_LOADING = 'APP_GET_LOADING'
export const APP_GET_USING_NEW_LAYOUT = 'APP_GET_USING_NEW_LAYOUT'
export const APP_GET_TOPBAR_VISIBLE = 'APP_GET_TOPBAR_VISIBLE'
export const APP_GET_TOPBAR_MENU_VISIBLE = 'APP_GET_TOPBAR_MENU_VISIBLE'
export const APP_GET_TOPBAR_FIRST_ENTER = 'APP_GET_TOPBAR_FIRST_ENTER'
export const APP_GET_ACCOUNT_SWITCHER_IS_VISIBLE = 'APP_GET_ACCOUNT_SWITCHER_IS_VISIBLE'
export const APP_GET_IS_APPLICATION_SPLIT_INITIALIZED = 'APP_GET_IS_APPLICATION_SPLIT_INITALIZED'
export const APP_GET_IS_SPLIT_INITIALIZED = 'APP_GET_IS_SPLIT_INITALIZED'
export const GET_UNDELIVERABLE_EMAIL_COUNT = 'GET_UNDELIVERABLE_EMAIL_COUNT'

// LOGIN

export const LOGIN_GET_ORIGINAL_ROUTE = 'LOGIN_GET_ORIGINAL_ROUTE'

// DELIVERY TEMPLATES

export const GET_TEMPLATE_DRAFT = 'GET_TEMPLATE_DRAFT'
export const GET_TEMPLATE_BY_DELIVERY_TEMPLATE_IDENTIFIER = 'GET_TEMPLATE_BY_DELIVERY_TEMPLATE_IDENTIFIER'
export const GET_ORDER_METADATA = 'GET_ORDER_METADATA'
export const GET_ORDER_ITEMS = 'GET_ORDER_ITEMS'
export const GET_EMAIL_TEMPLATE_PERMISSION_LEVEL = 'GET_EMAIL_TEMPLATE_PERMISSION_LEVEL'

// ORDER

export const GET_ORDER_DRAFT = 'GET_ORDER_DRAFT'
export const GET_BLACKLISTED_EMAIL_DOMAINS = 'GET_BLACKLISTED_EMAIL_DOMAINS'

// ONBOARDING

export const GET_ONBOARD_DRAFT = 'GET_ONBOARD_DRAFT'

// CUSTOMER/ACCOUNT

export const GET_CUSTOMER_ACCOUNT_DEFAULT = 'GET_CUSTOMER_ACCOUNT_DEFAULT'
export const GET_LAST_SELECTED_CUSTOMER_ACCOUNT = 'GET_LAST_SELECTED_CUSTOMER_ACCOUNT'
export const GET_SELECTED_CUSTOMER_ACCOUNT = 'GET_SELECTED_CUSTOMER_ACCOUNT'
export const GET_ONLY_SHOW_USD_ACCOUNTS = 'GET_ONLY_SHOW_USD_ACCOUNTS'
export const GET_CUSTOMER_ACCOUNTS = 'GET_CUSTOMER_ACCOUNTS'
export const GET_ACCOUNTS_BY_GROUPS = 'GET_ACCOUNTS_BY_GROUPS'

// TREATMENTS

export const GET_TREATMENTS = 'GET_TREATMENTS'
export const GET_SINGLE_TREATMENT = 'GET_SINGLE_TREATMENT'
export const GET_TREATMENT_ENABLED = 'GET_TREATMENT_ENABLED'

// PLATFORM

export const GET_PLATFORM = 'GET_PLATFORM'

// RECIPIENT WHITELIST

export const GET_RECIPIENT_WHITELIST = 'GET_RECIPIENT_WHITELIST'
export const GET_ORDER_WHITELIST_RECIPIENTS = 'GET_ORDER_WHITELIST_RECIPIENTS'
export const GET_RECIPIENT_WHITELIST_EXISTS = 'GET_RECIPIENT_WHITELIST_EXISTS'
export const GET_ORDER_WHITELIST_RECIPIENTS_EXISTS = 'GET_ORDER_WHITELIST_RECIPIENTS_EXISTS'
export const GET_WHITELIST_RECIPIENT_EMAILS = 'GET_WHITELIST_RECIPIENT_EMAILS'
export const GET_ORDER_WHITELIST_RECIPIENT_EMAILS = 'GET_ORDER_WHITELIST_RECIPIENT_EMAILS'

// REPORT ALERTS

export const GET_REPORT_ALERT_COUNT = 'GET_REPORT_ALERT_COUNT'

// REWARD FIRST ORDER

export const GET_ORDER_TOTAL_SUMMARY = 'GET_ORDER_TOTAL_SUMMARY'
export const GET_ORDER_SUMMARY = 'GET_ORDER_SUMMARY'
export const GET_HAS_INSUFFICIENT_FUNDS = 'GET_HAS_INSUFFICIENT_FUNDS'
export const GET_FORMATTED_PRECALCULATED_ORDER_TOTAL = 'GET_FORMATTED_PRECALCULATED_ORDER_TOTAL'
export const GET_FORMATTED_FEE_TOTAL = 'GET_FORMATTED_FEE_TOTAL'
export const GET_FORMATTED_ORDER_TOTAL_TO_BE_CHARGED = 'GET_FORMATTED_ORDER_TOTAL_TO_BE_CHARGED'
export const GET_UNIQUE_UTIDS = 'GET_UNIQUE_UTIDS'
export const GET_ORDER_BRAND_MAP = 'GET_ORDER_BRAND_MAP'
export const GET_ORDER_FULFILLMENT_TYPES = 'GET_ORDER_FULFILLMENT_TYPES'
export const GET_SELECTED_DEFAULT_TEMPLATE = 'GET_SELECTED_DEFAULT_TEMPLATE'
export const GET_TOTAL_RECIPIENTS = 'GET_TOTAL_RECIPIENTS'
export const GET_HAS_PENDING_ORDER = 'GET_HAS_PENDING_ORDER'
export const GET_PREVIOUSLY_DISPLAYED_CART_COUNT = 'GET_PREVIOUSLY_DISPLAYED_CART_COUNT'
export const GET_TOTAL_BRANDS = 'GET_TOTAL_BRANDS'
export const GET_ORDER_UPLOAD_ID = 'GET_ORDER_UPLOAD_ID'
export const GET_CART_HAS_PHYSICAL_ITEMS = 'GET_CART_HAS_PHYSICAL_ITEMS'
export const ENTERED_REWARD_ITEMS_LOCAL_STORAGE_KEY = 'ENTERED_REWARD_ITEMS_LOCAL_STORAGE_KEY'
export const GET_ORDER_CART_SUMMARY = 'GET_ORDER_CART_SUMMARY'
export const GET_IS_FILE_UPLOAD = 'GET_IS_FILE_UPLOAD'
export const GET_INVALID_ETID_ARRAY = 'GET_INVALID_ETID_ARRAY'

// SHOPPING CART
export const GET_SHOPPING_CART = 'GET_SHOPPING_CART'
export const GET_SHOPPING_CART_ID = 'GET_SHOPPING_CART_ID'
export const GET_SHOPPING_CART_SUMMARY_ACTIVE = 'GET_SHOPPING_CART_SUMMARY_ACTIVE'

// CATALOG

export const GET_CATALOG = 'GET_CATALOG'
export const CATALOG_HAS_SINGLE_BRAND = 'CATALOG_HAS_SINGLE_BRAND'
export const GET_CATALOG_BRANDS = 'GET_CATALOG_BRANDS'
export const GET_CATALOG_BRAND_MAP = 'GET_CATALOG_BRAND_MAP'
export const GET_AMOUNT_DETAILS_BY_UTID = 'GET_AMOUNT_DETAILS_BY_UTID'
export const GET_FIXED_ITEMS_BY_UTID = 'GET_FIXED_ITEMS_BY_UTID'
export const GET_BRAND_BY_UTID = 'GET_BRAND_BY_UTID'
export const GET_IS_AVAILABLE_BY_UTID = 'GET_IS_AVAILABLE_BY_UTID'
export const GET_BRAND_BY_BRAND_KEY = 'GET_BRAND_BY_BRAND_KEY '
export const CATALOG_HAS_FULFILLMENT_TYPE = 'CATALOG_HAS_FULFILLMENT_TYPE'
export const GET_ARE_ALL_ITEMS_SCHEDULABLE = 'GET_ARE_ALL_ITEMS_SCHEDULABLE'
export const GET_CATALOG_SEARCH_PARAMS = 'GET_CATALOG_SEARCH_PARAMS'
export const GET_SEARCH_IS_VISIBLE = 'GET_SEARCH_IS_VISIBLE'
export const GET_SELECTED_FILTERS = 'GET_SELECTED_FILTERS'

// Login Errors
export const LOGIN_GET_ERROR = 'LOGIN_GET_ERROR'
