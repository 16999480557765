import { hasAuthority } from '@/components/shared/security/authorized-plugin'
import store from '@/store/index'
import { LOGIN_CLEAR_ORIGINAL_URL } from '@/store/action-types'
import { getFirstAccessibleRoute } from '@/common/routing-helpers'
import AuthenticatedGuard from '@/router/authenticated-guard'
import { SIGNUP_ROUTE } from '@/router/route-name-constants'
import { FIRST_ROUTE_NAME } from '@/components/shared/constants'
import { AuthAccountCheck } from '@/router/authAccountCheck'

const Signup = () => import(/* webpackChunkName: "Signup" */ '@/components/authentication/signup/Signup')
const LoginAuth0 = () => import(/* webpackChunkName: "Login" */ '@/components/authentication/LoginAuth0')
const SSOLogin = () => import(/* webpackChunkName: "Login" */ '@/components/authentication/SSOLogin')
const OauthConfirmAccess = () => import(/* webpackChunkName: "OauthConfirmAccess" */ '@/components/authentication/oauth/OauthConfirmAccess')
const OAuthAuthorize = () => import(/* webpackChunkName: "OAuthAuthorize" */ '@/components/authentication/oauth/OAuthAuthorize')
const MfaReturn = () => import(/* webpackChunkName: "MfaReturn" */ '@/components/authentication/MfaReturn')
const RegisterPhone = () => import(/* webpackChunkName: "RegisterPhone" */ '@/components/authentication/RegisterPhone')
const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ '@/components/authentication/reset-password/forgot-password/ForgotPassword')
const ForgotPasswordSuccess = () => import(/* webpackChunkName: "ForgotPasswordSuccess" */ '@/components/authentication/reset-password/forgot-password/ForgotPasswordSuccess')
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ '@/components/authentication/reset-password/ResetPassword')
const EmulationTokenAuthentication = () => import(/* webpackChunkName: "EmulationTokenAuthentication" */ '@/components/authentication/application-banner/EmulationTokenAuthentication')
const AcceptInvitation = () => import(/* webpackChunkName: "AcceptInvitation" */ '@/components/authentication/AcceptInvitation')

export default [
  {
    path: '/',
    redirect: () => {
      if (hasAuthority('IS_LOGGED_IN')) {
        return getFirstAccessibleRoute()
      } else {
        store.dispatch(LOGIN_CLEAR_ORIGINAL_URL)
        return '/login'
      }
    }
  },
  {
    path: '/landing-redirect',
    name: 'LandingRedirect',
    redirect: () => {
      if (hasAuthority('IS_LOGGED_IN')) {
        return getFirstAccessibleRoute()
      } else {
        store.dispatch(LOGIN_CLEAR_ORIGINAL_URL)
        return '/login'
      }
    }
  },
  {
    path: '/confirm_access',
    name: 'Confirm Access',
    component: OauthConfirmAccess,
    meta: {
      segmentName: 'Confirm Access',
      authorities: ['IS_LOGGED_IN'],
      sidebarVisible: false,
      pageTitle: 'oauth.confirm_access.title'
    },
    props: (route) => ({
      clientId: route.query.client_id,
      scope: route.query.scope,
      state: route.query.state,
      requestId: route.query.request_id,
      csrf: route.query._csrf
    })
  },
  {
    path: '/oauth_authorize',
    name: 'Oauth Authorize',
    component: OAuthAuthorize,
    meta: {
      segmentName: 'OAuth Authorize Access',
      authorities: ['IS_LOGGED_IN'],
      sidebarVisible: false,
      pageTitle: 'oauth.confirm_access.title'
    },
    props: (route) => ({
      clientId: route.query.client_id,
      state: route.query.state,
      scope: route.query.scope
    })
  },
  {
    path: '/signup',
    name: SIGNUP_ROUTE,
    component: Signup,
    meta: {
      sidebarVisible: false,
      isChromeLess: true,
      segmentName: 'Signup',
      authorities: [],
      pageTitle: 'register.title',
      disableChatbot: true
    },
    beforeEnter (to, from, next) {
      if (AuthenticatedGuard.isLoggedIn()) {
        next({ path: '/' })
      } else {
        next()
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: async (goTo) => {
      const loginAuth0 = await LoginAuth0()
      goTo(loginAuth0)
    },
    meta: {
      segmentName: 'Login',
      authorities: [],
      sidebarVisible: false,
      sidePageVisible: true,
      pageTitle: 'login.title'
    }
  },
  {
    path: '/login/SSO',
    name: 'SSOLogin',
    component: async (goTo) => {
      const ssoLoginModule = await SSOLogin()
      goTo(ssoLoginModule)
    },
    meta: {
      segmentName: 'Login',
      authorities: [],
      sidebarVisible: false,
      sidePageVisible: true,
      pageTitle: 'login.title'
    },
    props: {
      defaultUserName: '',
      ssoButton: true
    }
  },
  {
    path: '/mfa',
    name: 'MfaReturn',
    component: MfaReturn,
    meta: {
      segmentName: 'MFA',
      authorities: [],
      sidebarVisible: false,
      sidePageVisible: true,
      pageTitle: 'portalfrontendApp.mfa.title',
      unvalidated: false,
      disableChatbot: true
    },
    async beforeEnter (to, from, next) {
      if (AuthenticatedGuard.isLoggedIn()) {
        await AuthAccountCheck()
        next({ name: FIRST_ROUTE_NAME })
      } else {
        next()
      }
    }
  },
  {
    path: '/forgot/password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      segmentName: 'Forgot Password',
      authorities: [],
      sidebarVisible: false,
      isChromeLess: true,
      pageTitle: 'reset.request.title'
    }
  },
  {
    path: '/forgot/password/success',
    name: 'ForgotPasswordSuccess',
    component: ForgotPasswordSuccess,
    props: true,
    meta: {
      segmentName: 'Forgot Password Confirmation',
      authorities: [],
      sidebarVisible: false,
      isChromeLess: true,
      pageTitle: 'reset.request.title'
    }
  },
  {
    path: '/resetPassword/:tokenId',
    name: 'ResetPasswordComponent',
    component: ResetPassword,
    meta: {
      segmentName: 'Reset Password',
      authorities: [],
      sidebarVisible: false,
      isChromeLess: true,
      pageTitle: 'global.menu.account.password'
    }
  },
  {
    path: '/emulation-token-auth',
    name: 'EmulationTokenAuth',
    component: EmulationTokenAuthentication,
    meta: {
      segmentName: 'Emulation Passthrough',
      authorities: [],
      sidebarVisible: false,
      isChromeLess: true,
      pageTitle: 'login.title'
    }
  },
  {
    path: '/invite/token/:token',
    name: 'AcceptInvitation',
    component: AcceptInvitation,
    meta: {
      segmentName: 'Accept Invite',
      authorities: [],
      sidebarVisible: false,
      isChromeLess: true,
      pageTitle: 'activate.title',
      disableChatbot: true
    }
  },
  {
    path: '/register-phone',
    name: 'RegisterPhone',
    component: RegisterPhone,
    meta: {
      segmentName: 'Register Phone',
      authorities: [],
      sidebarVisible: false,
      sidePageVisible: true,
      isChromeLess: true,
      pageTitle: 'global.menu.account.settings',
      disableChatbot: true
    }
  }
]
