// THIS COMPONENT IS DEPRECATED AND WILL BE REMOVED SOON

<template>
  <div
    id="top-bar-main-container"
    class="fs-unmask"
  >
    <div
      id="nav-bar"
      :class="{'transition': !firstEnter}"
      class="d-flex align-center"
    >
      <div class="pull-left d-flex h-100">
        <button
          id="open-close"
          @click="$emit('open-sidebar', !sidebarOpen)"
          @mouseenter="handleMouseEnterOnHamburgerMenu"
          @mouseleave="handleMouseLeaveOnHamburgerMenu"
        >
          <span id="open" />
        </button>
        <div id="platform-name">
          <p
            v-if="platform"
            class="text-truncate"
          >
            {{ platform.name }}
          </p>
        </div>
      </div>
      <div
        id="beamer-container"
        class="pull-right d-flex flex-column justify-content-center align-items-center"
      >
        <a
          v-show="$isProd()"
          id="beamer"
        >
          <span class="beamer-icon" />
        </a>
      </div>
      <div
        v-if="accountSwitcherIsVisible"
        class="position-relative pull-right account-menu d-flex align-items-center justify-content-between text-white"
      >
        <span>{{ $t('portalfrontendApp.coreAccount.accountSwitcher.sidebarTitle') }}</span>
        <span
          id="account-switcher-close-icon"
          class="cursor-pointer tci-cross"
          @click="setAccountSwitcherIsVisible(false)"
        />
      </div>
    </div>
    <div
      id="hover-bar"
      @mouseover="mouseOver"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { mapActions, mapGetters } from 'vuex'
import {
  APP_GET_ACCOUNT_SWITCHER_IS_VISIBLE,
  APP_GET_TOPBAR_FIRST_ENTER,
  APP_GET_TOPBAR_MENU_VISIBLE,
  APP_GET_TOPBAR_VISIBLE,
  GET_PLATFORM,
  GET_SELECTED_CUSTOMER_ACCOUNT
} from '@/store/get-types'
import {
  APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE,
  CLEAR_SELECTED_CUSTOMER_ACCOUNT,
  GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC,
  UPDATE_THE_SELECTED_ACCOUNT,
  UPDATE_TOPBAR_FIRST_ENTER,
  UPDATE_TOPBAR_MENU_VISIBLITY,
  UPDATE_TOPBAR_VISIBILITY
} from '@/store/action-types'
import { AccountSwitcherMixin } from '../../platform/accounts/AccountSwitcherMixin'
import { EMAIL_TEMPLATES_ACCESS, IS_LOGGED_IN } from '../constants/authority.constants'
import OneTrustCookieBlocker from '@/plugins/onetrust/OneTrustCookieBlocker'
import { CookieCategories } from '@/plugins/onetrust/OneTrustConstants'

export default {
  name: 'Topbar',
  mixins: [
    AccountSwitcherMixin
  ],
  props: {
    sidebarOpen: {
      type: Boolean,
      default: false
    },
    viewport: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      controlsTimer: 0
    }
  },
  computed: {
    ...mapGetters({
      platform: GET_PLATFORM,
      isTopBarVisible: APP_GET_TOPBAR_VISIBLE,
      isTopBarMenuVisible: APP_GET_TOPBAR_MENU_VISIBLE,
      firstEnter: APP_GET_TOPBAR_FIRST_ENTER,
      accountSwitcherIsVisible: APP_GET_ACCOUNT_SWITCHER_IS_VISIBLE,
      selectedCustomerAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    accountNameToDisplay () {
      return this.selectedCustomerAccount ? this.selectedCustomerAccount.accountName : this.$t('portalfrontendApp.topBar.noAccountsPreviewMessage')
    },
    customerNameToDisplay () {
      return this.selectedCustomerAccount ? this.selectedCustomerAccount.customerName : this.$t('portalfrontendApp.topBar.noAccountsExpandedMessage')
    },
    userOnlyHasUserManagementPermissions () {
      return !(this.$hasAnyAuthority(['PLACE_ORDER_ACCESS', 'ACCOUNT_ACCESS', 'FUNDING_MANAGE'])) && this.$hasAuthority('USER_ADMINISTRATION')
    },
    userOnlyHasAccountViewPermissions () {
      return !(this.$hasAuthority('USER_ADMINISTRATION')) && this.$hasAnyAuthority(['PLACE_ORDER_ACCESS', 'ACCOUNT_ACCESS', 'FUNDING_MANAGE'])
    },
    accountUsersTextToDisplay () {
      let accountUsersTextToDisplay
      if (this.$hasAuthority('USER_ADMINISTRATION') && this.$hasAnyAuthority(['PLACE_ORDER_ACCESS', 'ACCOUNT_ACCESS', 'FUNDING_MANAGE'])) {
        accountUsersTextToDisplay = this.$t('portalfrontendApp.topBar.accountsUsers')
      } else if (this.userOnlyHasUserManagementPermissions) {
        accountUsersTextToDisplay = this.$t('portalfrontendApp.topBar.users')
      } else if (this.userOnlyHasAccountViewPermissions) {
        accountUsersTextToDisplay = this.$t('portalfrontendApp.topBar.accounts')
      }
      return accountUsersTextToDisplay
    },
    canViewManageRewardTemplates () {
      return this.$hasAuthority(IS_LOGGED_IN) && this.$hasAuthority(EMAIL_TEMPLATES_ACCESS)
    },
    isNonMobileDevice () {
      return !this.viewport.isMobile
    }
  },
  async mounted () {
    if (this.$isEmulationSession()) {
      this.clearSelectedAccount()
    }
    await this.getSelectedCustomerAsync()
    await this.updateSelectedAccount()
    if (this.$isProd()) {
      this.createBeamerIntegration()
    }
  },
  destroyed () {
    this.setAccountSwitcherIsVisible(false)
  },
  methods: {
    mouseOver () {
      this.$store.dispatch(UPDATE_TOPBAR_FIRST_ENTER, false)
      this.$store.dispatch(UPDATE_TOPBAR_VISIBILITY, true)
    },
    toggleMenu () {
      this.$store.dispatch(UPDATE_TOPBAR_MENU_VISIBLITY, !this.isTopBarMenuVisible)
    },
    /**
     * `mouseenter` behaves very non-deterministically and different on an Android
     * mobile device due to being emitted when the device is tapped.
     *
     * Therefore: on mobile devices we need to _ignore_ the `mouseenter` event.
     */
    handleMouseEnterOnHamburgerMenu () {
      if (this.isNonMobileDevice) {
        this.$emit('open-sidebar', true)
      }
    },
    /**
     * `mouseleave` behaves very non-deterministically and different on an Android
     * mobile device due to being emitted when the device is tapped.
     *
     * Therefore: on mobile devices we need to _ignore_ the `mouseleave` event.
     */
    handleMouseLeaveOnHamburgerMenu () {
      if (this.isNonMobileDevice) {
        this.closeSidebar()
      }
    },
    closeMenu () {
      this.$store.dispatch(UPDATE_TOPBAR_MENU_VISIBLITY, false)
    },
    closeSidebar () {
      clearTimeout(this.controlsTimer)
      this.controlsTimer = setTimeout(() => {
        this.$emit('open-sidebar', false)
      }, 225)
    },
    ...mapActions({
      setAccountSwitcherIsVisible: APP_SET_ACCOUNT_SWITCHER_IS_VISIBLE,
      updateTopbarMenuVisibility: UPDATE_TOPBAR_MENU_VISIBLITY,
      getSelectedCustomerAsync: GET_SELECTED_CUSTOMER_ACCOUNT_ASYNC,
      updateSelectedAccount: UPDATE_THE_SELECTED_ACCOUNT,
      clearSelectedAccount: CLEAR_SELECTED_CUSTOMER_ACCOUNT
    }),
    handleSwitchAccountButtonClick () {
      this.setAccountSwitcherIsVisible(true)
      this.updateTopbarMenuVisibility(false)
    },
    handleRoutingForAccountsAndUserManagement () {
      if (!(this.$hasAuthority('PLACE_ORDER_ACCESS', 'ACCOUNT_ACCESS', 'FUNDING_MANAGE')) && this.$hasAuthority('USER_ADMINISTRATION')) {
        // if a user does NOT have "Account / Customer" access permissions, then see if they at least have "Users" access permissions
        this.$router.push({ name: 'Users' })
      } else {
        this.$router.push({ name: 'Groups' })
      }
    },
    // Top 1 most clever thing I've done with JS cuz I'm not good at it. We only want Beamer to show up post-login so we can't initialize their scripts in index.html.
    // We're doing it here instead to prosper where we want to
    createBeamerIntegration () {
      // Append the config before loading the script
      const beamerConfig = document.createElement('script')
      beamerConfig.innerHTML = `
        var beamer_config = {
          product_id : "aASrtbdsnull",
          display : "right",
          right: 12,
          top: 4,
          selector : "beamer", /*Optional: Id, class (or list of both) of the HTML element to use as a button*/
        };`
      document.body.appendChild(beamerConfig)

      const beamer = document.createElement('script')
      beamer.setAttribute('type', 'text/javascript')
      beamer.setAttribute('src', 'https://app.getbeamer.com/js/beamer-embed.js')

      OneTrustCookieBlocker.addRequiredScriptAttributes(beamer, CookieCategories.FUNCTIONAL, 'tcBeamerScript')

      document.body.appendChild(beamer)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/mixins";
  @import "~@/assets/scss/variables";

  #top-bar-main-container {
    width: 100%;
  }

  #nav-bar {
    color: var(--t-color-surface);
    height: $navbar-height;
    width: 100%;
    background-color: var(--t-color-navigation-surface-inverted);
    position: fixed;
    z-index: 9000;
    top: -70px;
  }

  #hover-bar {
    height: 10px;
    background-color: transparent;
    width: 100%;
    position: fixed;
    z-index: 9000;
  }

  #open-close {
    background: var(--t-color-navigation-surface-inverted);
    padding: 0;
    border-width: 0;
    &:focus, &:active, &:hover {
      box-shadow: none;
    }
  }

  #open {
    @include nav-icon-open-close('../../../assets/images/nav/new/hamburger_menu.svg');
  }

  #platform-name {
    align-content: center;
    display: flex;

    > p {
      color: var(--t-color-text-inverted);
      margin: 0;
      margin-left: 32px;
      line-height: $navbar-height;
      font-size: $font-size-base;
      display: none;
      @include media-breakpoint-up(sm) {
        max-width: 200px;
        display: flex;
        align-self: center;
        line-height: 100%;

      }
      @include media-breakpoint-up(md) {
        font-size: var(--t-font-size-2);
        font-weight: var(--t-font-weight-5);
        max-width: 400px;
      }
    }
  }

  .transition {
    transition: top 1s ease 0s;
  }

  .beamer-icon {
    height: 30px;
    width: 30px;
    display: inline-block;
  }

  #beamer {
    @include nav-icon('../../../assets/images/megaphone-outline.svg', '../../../assets/images/megaphone-outline.svg');
  }

  #beamer-container {
    height: 100%;
    width: 70px;
  }

  .account-menu {
    border-radius: .5rem;
    height: 2.5rem;
    width: 315px;
    margin-inline-end: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: var(--t-color-overlay);

    #account-information-menu {
      background-color: var(--t-color-surface);
      color: var(--t-color-text);
      width: 315px;
      right: 0;
      top:3rem;
      position: absolute;
      padding: .75rem;
      box-shadow: 0 4px 6px 0 rgba(0,0,0,0.1);
      border-radius: .5rem;
      z-index: 9003;

      #menu-information {
        span {
          display: inline-block;
        }

        .label-title {
          color: var(--t-color-text);
        }

        .label-value {
          color: var(--t-color-text);
        }
      }
    }
  }

  .icon {
    color: var(--t-color-icon-inverted);
  }
  .quick-animation {
    animation-duration: 400ms;
  }

  .navigate-button {
    background-color: var(--t-color-surface);
    width: 100%;
    padding: .5rem;
    color: var(--t-color-text);
    cursor: pointer;
  }

  .pointer-down {
    margin-left: 4px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid var(--t-color-surface);
  }

  #create-account-icon {
    background: center no-repeat url('../../../assets/images/add-circle.svg');
    height: 20px;
    width: 20px;
    margin-right: 8px;
    filter: brightness(0) invert(1);
  }
  .menu-information {
    background-color: var(--t-color-surface);
    margin-bottom: 1rem;
    padding: .5rem;
    border-radius: .25rem;
  }
  #account-switcher-close-icon {
    font-size: 28px;
  }
  .menu-information-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .menu-information-row-label {
    flex: 0 0 auto;
    font-weight: 500;
    margin-right: auto;
  }

  .menu-information-row-value{
    font-size: 14px;
    word-wrap: break-word;
    width: 175px;
    text-align: end;
  }

  @media (max-width: $breakpoint-md) {
    .slider-header {
      width: 100%;
      position: absolute !important;
    }
  }
</style>
