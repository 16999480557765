import {
  CookieCategories,
  SCRIPT_DOMAIN_ID_ATTRIBUTE,
  SCRIPT_ID,
  SCRIPT_SRC
} from '@/plugins/onetrust/OneTrustConstants'

import { AttachOneTrustToRGScripts } from '@/components/shared/segment/segment-plugin'

export default class OneTrustCookieBlocker {
  dataDomainId = process.env.VUE_APP_ONE_TRUST_SCRIPT_ID

  constructor () {
    this._oneTrust = window.OneTrust
  }

  init () {
    if (!this.isEnabled || this.isLoaded) return

    // BEFORE we init OneTrust and AFTER the App component mounts, we need to finish attaching the OneTrust classes
    // and handlers to any RG scripts that are dynamically added to the DOM.
    //
    AttachOneTrustToRGScripts(CookieCategories, OneTrustCookieBlocker)

    const existingScript = document.querySelector(`#${SCRIPT_ID}`)
    if (!existingScript) {
      const script = document.createElement('script')
      script.id = SCRIPT_ID
      script.type = 'text/javascript'
      script.src = SCRIPT_SRC
      script.setAttribute(SCRIPT_DOMAIN_ID_ATTRIBUTE, this.dataDomainId)
      script.onload = () => {
        this._oneTrust = window.OneTrust
      }

      document.head.appendChild(script)
    }
  }

  get isEnabled () {
    return this.dataDomainId
  }

  get isLoaded () {
    return !!this._oneTrust
  }

  /**
   * OneTrust depends on these attributes being included to properly block the script
   * from attaching cookies.
   *
   * @param {HTMLScriptElement} element The script element being injected into the DOM
   * @param {String} category The OneTrust category that the script belongs to
   * @param {String} prefix The prefix to be used for method names being attached to the window
   */
  static addRequiredScriptAttributes (element, category, prefix) {
    // OneTrust will, based on the category in the class, update the
    // `type` here to `text/javascript` triggering the script to run if
    // the recipient has allowed the category.
    if (element) {
      element.type = 'text/plain'
      element.setAttribute('type', 'text/plain')
      element.setAttribute('class', `optanon-category-${category}`)

      const successMethodName = `${prefix}SuccessFn`
      const errorMethodName = `${prefix}ErrorFn`
      const emptyFn = () => {
      }
      window[successMethodName] = element.onload ?? emptyFn
      window[errorMethodName] = element.onerror ?? emptyFn
      element.setAttribute('onload', `${successMethodName}()`)
      element.setAttribute('onerror', `${errorMethodName}()`)
    }
  }
}
