export const FIRST_NAME_PROPERTY = 'firstName'
export const LAST_NAME_PROPERTY = 'lastName'

export const recipientProperties = [
  FIRST_NAME_PROPERTY,
  LAST_NAME_PROPERTY
]

export class Recipient {
  constructor (args) {
    const {
      [FIRST_NAME_PROPERTY]: firstName = '',
      [LAST_NAME_PROPERTY]: lastName = ''
    } = args || {}
    this[FIRST_NAME_PROPERTY] = firstName
    this[LAST_NAME_PROPERTY] = lastName
  }

  equals (otherRecipient) {
    return recipientProperties.every(prop => this[prop] === recipientProperties[prop])
  }

  updateField (update) {
    const [fieldToUpdate] = Object.keys(update)
    const valueToUse = update[fieldToUpdate]
    this[fieldToUpdate] = valueToUse
  }

  toJSON () {
    return {
      [FIRST_NAME_PROPERTY]: this[FIRST_NAME_PROPERTY],
      [LAST_NAME_PROPERTY]: this[LAST_NAME_PROPERTY]
    }
  }
}
