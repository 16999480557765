import Vue from 'vue'
import store from '@/store'
import AuthenticationProvider from '@/components/shared/security/authentication-provider'
import {
  GET_PLATFORM,
  PRINCIPLE_GET_USER
} from '@/store/get-types'
import { HttpClient } from '@/components/shared/security/http-client'

/**
 * @typedef {AdaChatbot} Vue.adaChatbot
 */
export class TCAdaChatbotIntegration {
  /**
   * Private method to fetch the chatbot client from the Vue
   * plugin context
   * @return {AdaChatbot}
   * @private
   */
  static _getChatbot () {
    return Vue.adaChatbot
  }

  startupChatbot () {
    TCAdaChatbotIntegration._getChatbot().init().then(() => {
      if (!TCAdaChatbotIntegration._getChatbot().isChatbotRunning()) {
        this.setAdaMetadata()

        // Pass setAdaSensitiveMetaFields to a callback that will trigger when
        // the chatbot is opened.
        TCAdaChatbotIntegration._getChatbot().startChatbot(this.setAdaSensitiveMetadata)
      }
    }).catch(() => {
      console.error('Error initializing Ada')
    })
  }

  stopChatbot () {
    if (TCAdaChatbotIntegration._getChatbot().isChatbotRunning()) {
      TCAdaChatbotIntegration._getChatbot().stopChatbot().then()
    }
  }

  static isLoggedIn () {
    const loggedInClaims = AuthenticationProvider.getClaims() || []
    return loggedInClaims.includes('IS_LOGGED_IN')
  }

  /**
   * Sets the default metadata needed for most campaigns
   * to function. Called as the bot is first initialized.
   */
  async setAdaMetadata () {
    if (TCAdaChatbotIntegration.isLoggedIn()) {
      const userHash = store.getters[PRINCIPLE_GET_USER]?.identityHash
      const platformHash = store.getters[GET_PLATFORM]?.identityHash

      // user_hash and platform_hash are Ada specific variables set for
      // business use. Reach out to business before changing these variable
      // names.
      const metaFields = {
        user_hash: userHash,
        platform_hash: platformHash
      }

      TCAdaChatbotIntegration._getChatbot().setMetaFields(metaFields)
    }
  }

  /**
   * Used to set metadata when the chatbot is opened by a user.
   */
  async setAdaSensitiveMetadata () {
    if (TCAdaChatbotIntegration.isLoggedIn()) {
      try {
        const response = await HttpClient.get('/api/external/users/createSingleUseToken')

        // user_request_key is an Ada specific variable set for
        // business use. Reach out to business before changing this variable
        // name.
        const metaFields = {
          user_request_key: response.data
        }

        TCAdaChatbotIntegration._getChatbot().setMetaFields(metaFields)
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export const AdaChatbotIntegration = new TCAdaChatbotIntegration()
