import currencyFormatter from 'currency-formatter'

// https://www.npmjs.com/package/currency-formatter
function money (number, opt) {
  return currencyFormatter.format(number, opt)
}

function moneyWithCode (number, opt) {
  return opt && opt.code ? money(number, opt) + ' ' + opt.code : money(number, opt)
}
function moneyToNumber (string, opt) {
  return currencyFormatter.unformat(string, opt)
}
function moneyIntl (number, currency, additionalOptions = {}) {
  return new Intl.NumberFormat(getLocale(), {
    style: 'currency',
    currency: currency || 'USD',
    ...additionalOptions
  }).format(number)
}

function formatMoneyForDisplay (amount, currency) {
  const formatted = moneyIntl(amount, currency, {
    currencyDisplay: 'narrowSymbol'
  })

  return `${formatted} ${currency}`
}

// gets the actual symbol of a currency code (i.e. USD -> $)
function currencySymbol (opt) {
  return currencyFormatter.findCurrency(opt).symbol
}

function getLocale () {
  return navigator.languages ? navigator.languages[0] : navigator.language
}

function MoneyPlugin (Vue) {
  Vue.prototype.$money = money
  Vue.prototype.$moneyWithCode = moneyWithCode
  Vue.prototype.$moneyToNumber = moneyToNumber
  Vue.prototype.$moneyIntl = moneyIntl
  Vue.prototype.$currencySymbol = currencySymbol
  Vue.prototype.$formatMoneyForDisplay = formatMoneyForDisplay
}

export {
  money,
  currencySymbol,
  moneyWithCode,
  moneyToNumber,
  moneyIntl,
  MoneyPlugin
}
