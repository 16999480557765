import AuthenticatedGuard from './authenticated-guard'
import AuthenticationProvider from '@/components/shared/security/authentication-provider'
import { FIRST_ROUTE_NAME } from '@/components/shared/constants'
const url = process.env.VUE_APP_SIGN_UP_FORM

class OnboardingGuard {
  static hasCompletedOnboarding () {
    const loggedInClaims = AuthenticationProvider.getClaims() || []
    return loggedInClaims.includes('STEP4_ONBOARD_INFO_COMPLETE')
  }

  static use (router) {
    router.beforeEach((to, from, next) => {
      // We are checking to see that the user has completed the onboarding steps for a new user.
      // If so, we will send them to the landing page, if not we will send them to either the signup page.
      // If they signed up with the old sign up but never clicked the invite email, they will be sent to the old onboarding flow.
      if (to.name !== 'Onboarding' && AuthenticatedGuard.isLoggedIn() && !OnboardingGuard.hasCompletedOnboarding()) {
        window.location.href = url
      } else if (to.name === 'Onboarding' && OnboardingGuard.hasCompletedOnboarding()) {
        next({ name: FIRST_ROUTE_NAME })
      } else {
        next()
      }
    })
  }
}

export default OnboardingGuard
