export const PRINT_TEMPLATE_EDIT_ROUTE = 'PrintTemplateEdit'
export const PRINT_TEMPLATE_CREATE_ROUTE = 'PrintTemplateCreate'
export const PRINT_TEMPLATE_DUPLICATE_ROUTE = 'PrintTemplateDuplicate'
export const PRINT_TEMPLATE_NON_REDEMPTION_EDIT_ROUTE = 'PrintTemplateNonRedemptionEdit'
export const PRINT_TEMPLATE_NON_REDEMPTION_CREATE_ROUTE = 'PrintTemplateNonRedemptionCreate'
export const PRINT_TEMPLATE_NON_REDEMPTION_DUPLICATE_ROUTE = 'PrintTemplateNonRedemptionDuplicate'
export const EMAIL_TEMPLATES_SUB_COMPONENT = 'emailTemplates'
export const PRINT_TEMPLATES_SUB_COMPONENT = 'printTemplates'
export const PRINT_TEMPLATE_REDEMPTION_SUB_COMPONENT = 'printTemplateRedemption'
export const PRINT_TEMPLATE_NON_REDEMPTION_SUB_COMPONENT = 'printTemplateNonRedemption'
export const PRINT_TEMPLATE_PHYS_ORDER_FLOW_SIMPLE_ROUTE = 'PhysOrderFlowSimplePrintTemplate'
export const PRINT_TEMPLATE_PHYS_ORDER_FLOW_SIMPLE_NON_REDEMPTION_ROUTE = 'PhysOrderFlowSimplePrintTemplateNonRedemption'
export const PRINT_TEMPLATE_PHYS_ORDER_FLOW_ADVANCED_ROUTE = 'PhysOrderFlowAdvancedPrintTemplate'
export const PRINT_TEMPLATE_PHYS_ORDER_FLOW_ADVANCED_NON_REDEMPTION_ROUTE = 'PhysOrderFlowAdvancedPrintTemplateNonRedemption'
export const EMAIL_TEMPLATE_PHYS_ORDER_FLOW_SIMPLE_ROUTE = 'PhysOrderFlowSimpleDeliveryTemplate'
export const EMAIL_TEMPLATE_PHYS_ORDER_FLOW_ADVANCED_ROUTE = 'PhysOrderFlowDeliveryTemplate'
export const EMAIL_TEMPLATE_CREATE_DETAILS_ROUTE = 'EmailTemplateCreateDetails'
export const EMAIL_TEMPLATE_CREATE_ACCESS_LEVEL_ROUTE = 'EmailTemplateCreateAccessLevel'
export const EMAIL_TEMPLATE_CREATE_DEFAULT_ROUTE = 'EmailTemplateCreateDefault'
export const EMAIL_TEMPLATE_EDIT_DETAILS_ROUTE = 'EmailTemplateEditDetails'
export const EMAIL_TEMPLATE_EDIT_ACCESS_LEVEL_ROUTE = 'EmailTemplateEditAccessLevel'
export const EMAIL_TEMPLATE_EDIT_DEFAULT_ROUTE = 'EmailTemplateEditDefault'
export const EMAIL_TEMPLATE_DUPLICATE_ROUTE = 'EmailTemplateDuplicateDetails'
