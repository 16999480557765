<template>
  <tc-dialog
    :value="showPendingDialog"
    :title="$t(`${i18nPrefix}.pendingDialog.pendingTitle`)"
    :cancel-button-text="$t('entity.action.close')"
    @cancel="$emit('hide-dialog')"
  >
    <div>
      <v-row>
        <v-col>
          <p id="total-funds">
            {{ $t(`${i18nPrefix}.pendingDialog.totalFunds`) }}
          </p>
          <p id="pending-funds">
            {{ $t(`${i18nPrefix}.pendingDialog.pendingFunds`) }}
          </p>
          <p id="available-balance">
            {{ $t(`${i18nPrefix}.availableBalance`) }}
          </p>
        </v-col>
        <v-col class="text-right">
          <p id="pending-total-balance">
            + {{ totalBalance }} {{ currencyCode }}
          </p>
          <p id="pending-total-pending">
            - {{ totalPending }} {{ currencyCode }}
          </p>
          <p id="pending-total-available">
            = {{ availableBalance }} {{ currencyCode }}
          </p>
        </v-col>
      </v-row>
      <v-row
        class="pending-message"
      >
        <v-col
          cols="1"
          class="font-weight-medium p-0 mr-3"
        >
          <v-icon medium>
            mdi-information-outline
          </v-icon>
        </v-col>
        <v-col class="p-0 mb-3 text-left">
          <p
            id="pending-title"
            class="font-weight-medium"
          >
            {{ $t(`${i18nPrefix}.pendingDialog.title`) }}
          </p>
          <p
            id="pending-message"
            class="mb-0"
          >
            {{ $t(`${i18nPrefix}.pendingDialog.fundsMessage`) }}
            <br>
            <br>
            {{ $t(`${i18nPrefix}.pendingDialog.sooner`) }}
            <a href="https://help.rewardsgenius.com/s/article/HowtoFundviaACHorWire">
              {{ $t(`${i18nPrefix}.pendingDialog.link`) }}
            </a>
          </p>
        </v-col>
      </v-row>
    </div>
  </tc-dialog>
</template>

<script>
import TcDialog from '@/components/shared/tc-dialog/TcDialog'
import { USD_CURRENCY_CODE } from '@/components/platform/funding/ach/constants'
export default {
  name: 'AchPendingDialog',

  components: {
    TcDialog
  },

  props: {
    currencyCode: {
      type: String,
      default: USD_CURRENCY_CODE
    },
    totalPending: {
      type: String,
      default: ''
    },
    availableBalance: {
      type: String,
      default: ''
    },
    totalBalance: {
      type: String,
      default: ''
    },
    showPendingDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      i18nPrefix: 'portalfrontendApp.coreFunding.depositTab'
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/theme';

.pending-message{
  background-color:#E4E3EF;
  border-radius:0.5rem;
  padding: 0.75rem;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  .v-icon {
    color: var(--t-color-text);
  }
}
</style>
