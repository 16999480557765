import * as getTypes from '../get-types'
import * as actionTypes from '../action-types'
import * as mutationTypes from '../mutation-types'
import { HttpClient } from '../../components/shared/security/http-client'

const state = {
  blacklistedDomains: null
}

const getters = {
  [getTypes.GET_BLACKLISTED_EMAIL_DOMAINS]: state => state.blacklistedDomains
}

const getBlacklistedEmailDomains = async () => {
  try {
    const blacklistedDomainsResponse = await HttpClient.get(
      '/api/security/blacklistedDomains')
    return blacklistedDomainsResponse.data
  } catch (error) {
    return []
  }
}

const actions = {
  async [actionTypes.FETCH_BLACKLISTED_EMAIL_DOMAINS] ({
    state,
    commit,
    getters
  }) {
    if (state.blacklistedDomains === null) {
      const blacklistedDomains = await getBlacklistedEmailDomains()
      commit(mutationTypes.SET_BLACKLISTED_EMAIL_DOMAINS,
        blacklistedDomains)
    }

    return getters[getTypes.GET_BLACKLISTED_EMAIL_DOMAINS]
  }
}

const mutations = {
  [mutationTypes.SET_BLACKLISTED_EMAIL_DOMAINS] (state, blacklistedDomains) {
    state.blacklistedDomains = blacklistedDomains
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
