<template>
  <small
    v-if="errorBag.firstByRule(control, rule)"
    class="form-control-feedback"
  >
    {{ text }}
  </small>
</template>

<script>
export default {
  name: 'ValidationTip',
  props: {
    errorBag: {
      type: Object,
      required: true
    },
    control: {
      type: String,
      required: true
    },
    rule: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  mounted () {
    console.assert(!!this.errorBag, 'Error bag must be defined for validation tips')
    console.assert(!!this.control, 'Form control must be defined for validation tips')
    console.assert(!!this.rule, 'Form control rule must be defined for validation tips')
    console.assert(!!this.text, 'Validation tip text must be defined')
  }
}
</script>
