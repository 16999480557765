export const TANGO_CARD_STANDARD_EMAIL_TEMPLATE_PTID = 'P000000'

export class PhysicalTemplate {
  constructor ({
    deliveryTemplateIdentifier,
    identifier = null,
    name = null,
    companyLogo = null,
    backgroundColor = null,
    letterHeadTitle = null,
    customerServiceMessage = null,
    toName = null,
    fromName = null,
    message = null,
    usingDefaultCSMessage = true,
    useLogo = true
  } = {}) {
    this.deliveryTemplateIdentifier = deliveryTemplateIdentifier || identifier // On the api it's just 'identifier'
    this.name = name
    this.companyLogo = companyLogo
    this.backgroundColor = backgroundColor
    this.letterHeadTitle = letterHeadTitle
    this.customerServiceMessage = customerServiceMessage
    this.toName = toName
    this.fromName = fromName
    this.message = message
    this.usingDefaultCSMessage = usingDefaultCSMessage
    this.useLogo = useLogo
  }
}
