import store from '../store/index'
import { APP_GET_IS_SPLIT_INITIALIZED, APP_GET_IS_APPLICATION_SPLIT_INITIALIZED } from '../store/get-types'
import { PRINCIPLE_GET_ASYNC, GET_PLATFORM_ASYNC } from '../store/action-types'
import { initApplicationSplitClient, initSplitClients } from '../components/shared/split/split'
import AuthenticationProvider from '../components/shared/security/authentication-provider'

class SplitGuard {
  static isLoggedIn () {
    const loggedInClaims = AuthenticationProvider.getClaims() || []
    return loggedInClaims.includes('IS_LOGGED_IN')
  }

  static use (router) {
    router.beforeEach(async (to, from, next) => {
      // don't bother to try to check split if there isn't a logged in user
      if (to.name === 'EmulationTokenAuth' || !this.isLoggedIn()) {
        const isSplitInitialized = store.getters[APP_GET_IS_APPLICATION_SPLIT_INITIALIZED]
        if (!isSplitInitialized) {
          await initApplicationSplitClient()
          next()
        } else {
          next()
        }
      } else {
        const isSplitInitialized = store.getters[APP_GET_IS_SPLIT_INITIALIZED]
        if (!isSplitInitialized) {
          // grab the user hash real quick
          const user = await store.dispatch(PRINCIPLE_GET_ASYNC)
          // need the platform hash
          const platform = await store.dispatch(GET_PLATFORM_ASYNC, { platformClientId: user.platformClientId })
          const userHash = user.identityHash
          const platformHash = platform.identityHash
          await initSplitClients(userHash, platformHash)
          next()
        } else {
          next()
        }
      }
    })
  }
}

export default SplitGuard
